import { Box, Divider, Drawer, Stack, Typography } from '@mui/material'
import React from 'react'
import { ThemeModes } from './Options';

function Controls(props) {
    const { openControls, closeControls } = props;
  return (
    <Drawer
        open={openControls}
        anchor='right'
        onClose={closeControls}
        sx={{zIndex:'9999999'}}
        elevation={1}
    >
        <Box sx={{width:{xl:400, lg:260, md:220}}}>
            <Box sx={{px:2, py:1}}>
                <Typography variant='subtitle1'> Theme Customizer </Typography>
                <Typography variant='body1'>Customize & Preview in Real Time</Typography>
            </Box>
            <Divider/>
            <Box sx={{px:2, py:2}}>
                <Stack direction='column'>
                    <ThemeModes/>
                </Stack>
            </Box>
        </Box>
    </Drawer>
  )
}

export default Controls