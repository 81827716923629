import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
//import InputAdornment from '@material-ui/core/InputAdornment';
import InputAdornment from "@mui/material/InputAdornment";
import {
  StyledDropDown,
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFilterMenuItem,
  StyledFilterSearch,
  StyledFormControlLabel,
} from "../../theme/StyledEle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160,
    },
  },
};

function MultipleSelect({
  label,
  options,
  onChange,
  optionsDisplayKey = "title",
  uniqueKey = "title",
  onSearch,
  isShow = true,
  fieldName,
  selValue = "",
}) {
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    (selValue === ""
      ? options && options[0] && options[0][uniqueKey]
      : selValue) || -1
  );

  //console.log({selectedValue: options && options[0] && options[0][uniqueKey]})

  const handleSearch = (searchText) => {
    onSearch(searchText);
  };

  React.useEffect(() => {
    setSelectedValue(selValue === "" ? options[0]?.[uniqueKey] : selValue);
  }, [options]);

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const theme = useTheme();

  const handleChangeValue = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      {isShow && (
        <StyledFilterFormControl>
          <StyledFormControlLabel>{label}</StyledFormControlLabel>
          <StyledDropDown
            //defaultValue={(options!==undefined && options.length>0)?(options[0] ? options[0][uniqueKey] : ''):""}
            defaultValue={
              selValue === ""
                ? options !== null && options.length > 0
                  ? options[0][uniqueKey]
                  : -1
                : selValue
            }
            onChange={handleChangeValue}
            MenuProps={MenuProps}
            name={fieldName}
            onClose={() => setSearchText("")}
            value={selValue == "" ? selectedValue : selValue}
            size="small"
          >
            <StyledFilterSearch
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ fontSize: "inherit" }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />

            {options.map((option) => (
              <StyledFilterMenuItem
                key={option[uniqueKey]}
                value={option[uniqueKey]}
              >
                {option[optionsDisplayKey]}
              </StyledFilterMenuItem>
            ))}
        </StyledDropDown>
        </StyledFilterFormControl>
      )}
    </>
  );
}
export default MultipleSelect;
