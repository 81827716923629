import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader, Typography } from "@mui/material";
import OrganizationServices from "../../services/organizationServices";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "./organizationDropdown";
import OrgnDealerDropdown from "./orgnDealerDropdown";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import logServices from "../../services/logService";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useAuditLogMutation,
  useOrgListMutation,
  useOrgDeleteMutaion,
  useOrgDeleteMutation,
} from "../../store/slice/ApiSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledModel from "../../Utils/StyledModel";
import { useLocation, useNavigate } from "react-router-dom";

const ListOrganization = ({ onViewOrg, onEditOrg, menuUser }) => {

  const navigate = useNavigate();
  const location = useLocation();
  let hashId = location.hash;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let userorgType = userData.userOrgType;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [loadStatus, setLoadStatus] = useState(true);
  const [selectedDropdownAry, setSelectedDropdownAry] = useState({});
  const [exportRequest, setExportRequest] = useState({});
  const [arraySortOrder, setArraySortOrder] = useState({});
  const [payload, setPayload] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [loading, setLoading] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Org Type",
        accessor: "orgTypeName",
      },
      {
        Header: "Region",
        accessor: "region",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Customer",
        accessor: "customerName",
        show:orgType !== 3 && true
      },
      // {
      //   Header: "Organization Name",
      //   accessor: "orgName",
      // },
      // {
      //   Header: "User Name",
      //   accessor: "userName",
      // },
      {
        Header: "Email",
        accessor: "orgBizEmail",
      },
      {
        Header: "State",
        accessor: "stateName",
      },
      {
        Header: "City",
        accessor: "cityName",
      },
      {
        Header: "Phone Number",
        accessor: "orgPhoneNumber",
      },
      {
        Header: "ZipCode",
        accessor: "orgZip",
      },
      {
        Header: "Created Date ",
        accessor: "orgCreatedDate",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );
  //   var globalOrgId = 1;
  //   var globalRoleId = 0;
  //   var globalResellerId = 1;
  //   var globalJson = {};
  //   var globalOrgType = 2;
  //   var globalDisplayView = 0;
  //   if (
  //     "null" === localStorage.getItem("userData") ||
  //     null === localStorage.getItem("userData")
  //   ) {
  //   } else {
  //     globalJson =
  //       localStorage.getItem("userData") !== null
  //         ? JSON.parse(localStorage.getItem("userData"))
  //         : 0;
  //   }
  //   globalOrgId = globalJson.orgId;
  //   globalOrgType = globalJson.userOrgType;
  //   globalRoleId = globalJson.roleId;
  //   globalResellerId = globalJson.resellerId;
  //   globalDisplayView = globalJson.displayView;

  // var globalOrgId = 1;
  // var globaluserId = 1;
  // var globalRoleId = 0;
  // var globalJson = {};
  // var globalResellerId = 2;
  // var globalOrgType = 2;
  // var globalCustomerId = 0;
  // if (
  //   "null" === localStorage.getItem("userData") ||
  //   null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   globalJson =
  //     localStorage.getItem("userData") !== null
  //       ? JSON.parse(localStorage.getItem("userData"))
  //       : 0;
  //   globalOrgId = globalJson.orgId;
  //   globalOrgType = globalJson.userOrgType;
  //   globaluserId = globalJson.id;
  //   globalRoleId = globalJson.roleId;
  //   globalResellerId = globalJson.resellerId;
  //   globalCustomerId = globalJson.customerId;
  // }

  // Get Data from New Dropdown

  // Get Data from New Dropdown
  const [getGridViewForOrg, { data: organizationdata, isLoading, isSuccess }] =
    useOrgListMutation();
  let commonPayload = {};
  const getData = (data) => {
    // var dataStr = {};
    // dataStr.edit = false;
    // dataStr.delete = false;
    // dataStr.view = false;
    // dataStr.excel = false;
    // dataStr.pdf = false;
    // var strval;
    // strval = menuUser.split(",");
    // for (var i = 0; i < strval.length; i++) {
    //   if (parseInt(strval[i]) === 3) {
    //     dataStr.edit = true;
    //   }
    //   if (parseInt(strval[i]) === 2) {
    //     dataStr.view = true;
    //   }
    //   if (parseInt(strval[i]) === 4) {
    //     dataStr.delete = true;
    //   }
    //   if (parseInt(strval[i]) === 6) {
    //     dataStr.pdf = true;
    //   }
    //   if (parseInt(strval[i]) === 5) {
    //     dataStr.excel = true;
    //   }
    // }
    // setCommonFilter(dataStr);

    commonPayload.orgId = orgId;
    commonPayload.orgType = roleId === 1 ? data.orgnizationType : data.orgType;
    //  commonPayload.orgType = 2;
    commonPayload.searchText = "";
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.orgfleet = data.fleet;
    commonPayload.custrec = roleId === 1 ? 1 : data.customer;
    commonPayload.status = 1;
    commonPayload.reslId = roleId === 1 ? data.orgnization : resellerId;
    commonPayload.fleetParentId = data.fleet;
    commonPayload.selectOrgId = orgId;
    commonPayload.dealerId = roleId === 1 ? 0 : data.dealer;
    commonPayload.regionId = roleId === 1 ? 0 : data.region;
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;

    commonPayload.customerId = data.customer;
    commonPayload.regionorgType = data.orgType;
    commonPayload.roleId = roleId;
    commonPayload.selectedOrgId = -1;
    commonPayload.seltdResellerId = resellerId;
    commonPayload.seltdLastOrgId = -1;
    commonPayload.seltdOrgTypeId = data.orgType;

    //Service Call
    setPayload(commonPayload);
    getGridViewForOrg(commonPayload);


    auditPayload.message = "Admin > Organization > List Organization > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.userOrganizationType+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

 
  };
  useEffect(async () => {
    auditPayload.message = "Admin > Organization > List Organization";
    auditPayload.action = "View";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  //Search Text
  const handleSearch = async (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.size = 25;
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      getGridViewForOrg(payload);
    } else if (payload.searchText.length === 0) {
      getGridViewForOrg(payload);
    }
  
    
};
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {

    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getGridViewForOrg(payload);
  };
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (organizationdata.data !== null) {
        setResponseData(organizationdata.data.content);
        setTotalElements(organizationdata.data.totalElements);
        setTotalPages(organizationdata.data.totalPages);
      }
      var dataStr = {};
      dataStr.edit = false;
      dataStr.delete = false;
      dataStr.view = false;
      dataStr.excel = false;
      dataStr.pdf = false;
      var strval;
      strval = menuUser.split(",");
      for (var i = 0; i < strval.length; i++) {
        if (parseInt(strval[i]) === 4) {
          dataStr.edit = true;
        }
        if (parseInt(strval[i]) === 2) {
          dataStr.view = true;
        }
        if (parseInt(strval[i]) === 3) {
          dataStr.delete = true;
        }
        if (parseInt(strval[i]) === 6) {
          dataStr.pdf = true;
        }
        if (parseInt(strval[i]) === 5) {
          dataStr.excel = true;
        }
      }
      setCommonFilter(dataStr);
    }
  }, [isSuccess]);

  // const handleSubmit = async (
  //   seltdOrgTypeId,
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdLastOrgId,
  //   seltdAgencyId,
  //   seltdDealerId,
  //   seltdRegionId
  // ) => {
  //   if (globalOrgType === 3) {
  //     seltdCustomerId = globalOrgId;
  //   }
  //   var request = {};
  //   request.resellerId = seltdResellerId;
  //   request.seltdResellerId = seltdResellerId;
  //   request.orgId = seltdLastOrgId;
  //   request.seltdLastOrgId = seltdLastOrgId;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "";
  //   request.columnName = "ORG_NAME";
  //   request.orderDir = "desc";
  //   request.orgfleet = seltdFleetId;
  //   request.seltdFleetId = seltdFleetId;
  //   request.custrec = seltdCustomerId;
  //   request.dealerId = seltdDealerId;
  //   request.regionId = seltdRegionId;
  //   request.customerId = seltdCustomerId;
  //   request.seltdCustomerId = seltdCustomerId;
  //   request.regionorgType = seltdOrgTypeId;
  //   request.roleId = globalRoleId;
  //   request.status = 1;
  //   request.reslId = seltdResellerId;
  //   request.fleetParentId = seltdCustomerId;
  //   request.selectedOrgId = seltdLastOrgId;
  //   request.orgType = seltdOrgTypeId;
  //   request.seltdOrgTypeId = seltdOrgTypeId;
  //   request.timeZoneValue = "+05:30";

  //   setSelectedDropdownAry(request);
  //   setExportRequest(request);

  //   try {
  //     const responseData = (
  //       await OrganizationServices.getGridViewForOrg(request)
  //     ).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  const onView = async (handleOrgView) => {
    onViewOrg(handleOrgView);

    auditPayload.message = "Admin > Organization > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onEdit = (itemEdited) => {
    onEditOrg(itemEdited);

    auditPayload.message = "Admin > Organization > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useOrgDeleteMutation();
  const deleteItems = () => {
    let payload = {
      orgId: deleteItem.id,
      orgType:deleteItem.orgType
    };
    delteItem(payload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );

      getGridViewForOrg(payload);
    }
  }, [isDeleteSuccess]);

  const onDelete = async (itemDeleted) => {
    navigate('#delete')
    setDeleteItem(itemDeleted);
  };

  // const fetchOrgDetails = async (
  //   searchText,
  //   seltdResellerId,
  //   seltdLastOrgId,
  //   seltdOrgTypeId,
  //   columnName,
  //   orderdir
  // ) => {
  //   // console.log(columnName,orderdir)
  //   var request = {};

  //   request.resellerId = selectedDropdownAry.seltdResellerId;
  //   request.orgId = selectedDropdownAry.seltdLastOrgId;
  //   request.page = 0;
  //   request.size = rowsPerPage;
  //   request.searchText = searchText;
  //   request.orgfleet = selectedDropdownAry.orgfleet;
  //   request.custrec = selectedDropdownAry.custrec;
  //   request.dealerId =  selectedDropdownAry.dealerId;
  //   request.regionId =  selectedDropdownAry.regionId;;
  //   request.customerId = selectedDropdownAry.seltdCustomerId;
  //   request.regionorgType = selectedDropdownAry.regionorgType;
  //   request.roleId = selectedDropdownAry.roleId;
  //   request.status = selectedDropdownAry.status;
  //   request.reslId = selectedDropdownAry.reslId;
  //   request.fleetParentId = selectedDropdownAry.fleetParentId;
  //   request.selectedOrgId = selectedDropdownAry.selectedOrgId;
  //   request.orgType = selectedDropdownAry.orgType;
  //   request.timeZoneValue = "+05:30";

  //   if (columnName === undefined) {
  //     request.columnName = "ORG_NAME";
  //   } else {
  //     request.columnName = columnName;
  //   }
  //   if (orderdir === undefined) {
  //     request.orderDir = "desc";
  //   } else {
  //     request.orderDir = orderdir;
  //   }
  //   try {
  //     const responseData = (
  //       await OrganizationServices.getGridViewForOrg(request)
  //     ).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  const onChangeXcl = async () => {
    var request = {};
    // if (globalOrgType === 3) {
    //   selectedDropdownAry.customerId = globalOrgId;
    //   selectedDropdownAry.seltdResellerId = globalResellerId;
    //   selectedDropdownAry.orgType = 3;
    // }

    // request.resellerId = data.seltdResellerId;
    // request.reslId = exportRequest.reslId;
    // request.orgId = exportRequest.orgId;
    // request.searchText = "";
    // request.columnName = exportRequest.columnName;
    // request.orderDir = exportRequest.orderDir;
    // request.orgfleet = exportRequest.orgfleet;
    // request.custrec = exportRequest.customerId;
    // request.status = exportRequest.status;
    // request.timeZoneValue = "+05:30";
    // request.selectedOrgId = exportRequest.selectedOrgId;
    // request.fleetParentId = exportRequest.customerId;
    // request.dealerId = exportRequest.dealerId;
    // request.customerId = exportRequest.customerId;
    // request.regionorgType = exportRequest.regionorgType;
    // request.orgType = exportRequest.orgType;
    // request.regionId = exportRequest.regionId;
    // request.roleId = exportRequest.roleId;

    payload.timeZoneValue = "+05:30";

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "organization/organizationListXLS.xls?resellerId=" +
        payload.reslId +
        "&reslId=" +
        payload.reslId +
        "&orgId=" +
        payload.orgId +
        "&searchText=" +
        payload.searchText +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&orgfleet=" +
        payload.orgfleet +
        "&custrec=" +
        payload.custrec +
        "&status=" +
        payload.status +
        "&timeZoneValue=" +
        payload.timeZoneValue +
        "&selectedOrgId=" +
        payload.selectedOrgId +
        "&fleetParentId=" +
        payload.fleetParentId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&regionorgType=" +
        payload.regionorgType +
        "&roleId=" +
        payload.roleId +
        "&regionId=" +
        payload.regionId +
        "&orgType=" +
        payload.orgType;
      window.open(url);

      /*
      var request1 = new XMLHttpRequest(); 
      var key;           
      request1.open("GET", url, true); 
      request.setRequestHeader("Content-type", "application/json");
      request.send("grant_type=client_credentials&client_id="+clientID+"&"+"client_secret="+clientSecret); // specify the credentials to receive the token on request
      request.onreadystatechange = function () {
          if(request.readyState == request.DONE) {
              var response = request.responseText;
              var obj = JSON.parse(response); 
              key = obj.access_token; 
              token_ = key; 
          }
      }
       */

      // fetch(url, {
      //   headers: {
      //     Authorization:
      //       "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
      //   },
      // }) // FETCH BLOB FROM IT
      //   .then((response) => {
      //     response.blob();
      //   })
      //   .then((blob) => {
      //     //window.open(url);
      //     window.open(url);
      /*
        var _url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");    
        var link=document.body.appendChild(a);
        link.download="Organization";
        link.href=_url;
        link.method='post';
        link.click();
        */
      /*
        if(undefined!==blob){
        var _url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");    
        var link=document.body.appendChild(a);
        link.download="Organization";
        link.href=_url;
        link.method='post';
        link.click();
        window.URL.revokeObjectURL(_url);  
        a.removeAttribute("download");
        a.removeAttribute("post");
        a.removeAttribute("href");
        document.body.removeChild(a);
        }else{
          //alert("unAuthorized error");
        }
        */
      // })
      // .catch((err) => {
      //   console.log(err);
      // });

      auditPayload.message = "Admin > Organization > List > Export >Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Organization";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
      // const responseData = await logServices.saveAuditLogDetails(request);
    } catch (error) {}
  };
  // const onChangepdf = async () => {
  //   var request = {};
  //   if (globalOrgType === 3) {
  //     selectedDropdownAry.customerId = globalOrgId;
  //     selectedDropdownAry.seltdResellerId = globalResellerId;
  //     selectedDropdownAry.orgType = 3;
  //   }

  //   request.resellerId = exportRequest.seltdResellerId;
  //   request.reslId = exportRequest.reslId;
  //   request.orgId = exportRequest.orgId;
  //   request.searchText = "";
  //   request.columnName = "ORG_NAME";
  //   request.orderDir = "desc";
  //   request.orgfleet = exportRequest.orgfleet;
  //   request.custrec = exportRequest.customerId;
  //   request.status = exportRequest.status;
  //   request.timeZoneValue = "+05:30";
  //   request.selectedOrgId = exportRequest.seltdLastOrgId;
  //   request.fleetParentId = exportRequest.customerId;
  //   request.dealerId =  exportRequest.dealerId;
  //   request.customerId = exportRequest.customerId;
  //   request.regionorgType = exportRequest.regionorgType;
  //   request.roleId = exportRequest.roleId;;
  //   request.orgType = exportRequest.orgType;
  //   request.regionId = exportRequest.regionId;
  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "organization/organizationListPDF.pdf?resellerId=" +
  //       request.resellerId +
  //       "&reslId=" +
  //       request.reslId +
  //       "&orgId=" +
  //       request.orgId +
  //       "&searchText=" +
  //       request.searchText +
  //       "&columnName=" +
  //       request.columnName +
  //       "&orderDir=" +
  //       request.orderDir +
  //       "&orgfleet=" +
  //       request.orgfleet +
  //       "&custrec=" +
  //       request.custrec +
  //       "&status=" +
  //       request.status +
  //       "&timeZoneValue=" +
  //       request.timeZoneValue +
  //       "&selectedOrgId=" +
  //       request.selectedOrgId +
  //       "&fleetParentId=" +
  //       request.fleetParentId +
  //       "&dealerId=" +
  //       request.dealerId +
  //       "&customerId=" +
  //       request.customerId +
  //       "&regionorgType=" +
  //       request.regionorgType +
  //       "&roleId=" +
  //       request.roleId +
  //       "&regionId=" +
  //       request.regionId +
  //       "&orgType=" +
  //       request.orgType;
  //     //window.open(url);

  //     fetch(url, {
  //       headers: {
  //         Authorization:
  //           "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
  //       },
  //     }) // FETCH BLOB FROM IT
  //       .then((response) => {
  //         response.blob();
  //       })
  //       .then((blob) => {
  //         //window.open("data:application/pdf," + blob);
  //         window.open(url);

  //         //console.log(blob);
  //         //var binaryData = [];
  //         //binaryData.push(blob);
  //         //var _url = window.URL.createObjectURL(new Blob(binaryData, {type: "data:application/pdf"}))
  //         /*
  //       var _url = window.URL.createObjectURL(new Blob(blob));
  //       var a = window.document.createElement("a");
  //       var link=window.document.body.appendChild(a);
  //       link.download="Organization";
  //       link.href=_url;
  //       link.method='post';
  //       link.click();
  //       */
  //         /*
  //       window.URL.revokeObjectURL(_url);
  //       a.removeAttribute("download");
  //       a.removeAttribute("post");
  //       a.removeAttribute("href");
  //       document.body.removeChild(a);
  //       */
  //       })

  //       .catch((err) => {
  //         console.log(err);
  //       });

  //   request.message = "Admin > Organization > PDF";
  //   request.action = "View";
  //   request.featureName = "Organization";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;

  //   const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  // const callbackLoadFn = async (
  //   orgId,
  //   resellerId,
  //   orgType,
  //   selectedCustomerId,
  //   selectedDealerId,
  //   selectedRegionId,
  //   selectedRoleId
  // ) => {
  //   var dataStr = {};
  //   dataStr.edit = false;
  //   dataStr.delete = false;
  //   dataStr.view = false;
  //   dataStr.excel = false;
  //   dataStr.pdf = false;
  //   var strval;
  //   strval = menuUser.split(",");
  //   for (var i = 0; i < strval.length; i++) {
  //     if (parseInt(strval[i]) === 3) {
  //       dataStr.edit = true;
  //     }
  //     if (parseInt(strval[i]) === 4) {
  //       dataStr.delete = true;
  //     }
  //     if (parseInt(strval[i]) === 6) {
  //       dataStr.pdf = true;
  //     }
  //     if (parseInt(strval[i]) === 5) {
  //       dataStr.excel = true;
  //     }
  //   }
  //   setCommonFilter(dataStr);

  //   if (loadStatus === true) {
  //     var request = {};

  //     request.orgId = orgId;
  //     request.page = newPage;
  //     request.size = rowsPerPage;
  //     request.orgType = orgType;
  //     request.searchText = "";

  //     request.columnName = "ORG_NAME";
  //     request.orderDir = "desc";
  //     request.orgfleet = -1;
  //     request.custrec = selectedCustomerId;
  //     request.status = 1;
  //     request.timeZoneValue = "+05:30";

  //     request.reslId = resellerId;
  //     request.fleetParentId = -1;
  //     request.selectOrgId = orgId;

  //     request.dealerId = selectedDealerId;
  //     request.regionId = selectedRegionId;
  //     request.customerId = selectedCustomerId;
  //     request.regionorgType = orgType;
  //     request.roleId = globalRoleId;

  //     request.selectedOrgId = -1;
  //     request.seltdResellerId = resellerId;
  //     request.seltdLastOrgId = -1;
  //     request.seltdOrgTypeId = orgType;

  //     setSelectedDropdownAry(request);
  //     setExportRequest(request);

  //     const responseData = (
  //       await OrganizationServices.getGridViewForOrg(request)
  //     ).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   }
  //   setLoadStatus(false);
  // };

  // const onDelete = async (itemDeleted) => {
  //   try {
  //     setDataStatusConfirm(false);
  //     setOpen(true);
  //     var obj = {};
  //     obj.dialogButtonText = "Delete";
  //     obj.submitButtonText = "Confirm";
  //     obj.closeButtonText = "Close";
  //     obj.dialogText = "Are you Sure? Do you want to Delete";
  //     setDialogContent(obj);
  //     setDataStatus(true);
  //     setSavedData(itemDeleted);
  //   } catch (err) {}
  // };

  // const handleSbmtClose = async () => {
  //   var deleteRow = {};
  //   deleteRow.orgId = savedData.id;
  //   await OrganizationServices.deleteOrgById(deleteRow);
  //   const updatedResponse = responseData.filter(
  //     (item) => item.id !== savedData.id
  //   );
  //   setResponseData(updatedResponse);
  //   var obj = {};
  //   obj.dialogButtonText = "Delete Status";
  //   obj.submitButtonText = "";
  //   obj.closeButtonText = "Close";
  //   obj.dialogText = "Data Deleted Successfully";
  //   setDialogContent(obj);
  //   setDataStatusConfirm(false);
  //   setDataStatus(true);
  //   setOpen(true);
  // };

  // const handleSearch = (searchText) => {
  //   var selObj = { ...selectedDropdownAry };
  //   fetchOrgDetails(
  //     searchText,
  //     selObj.seltdResellerId,
  //     selObj.seltdOrgTypeId,
  //     selObj.seltdLastOrgId
  //   );
  // };

  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   //console.log({ sortColumn });
  //   var obj = { sortColumn };

  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "userName") {
  //     if (obj1.userName !== undefined && obj1.userName === "asc") {
  //       obj1.userName = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.userName = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "USER_NAME";
  //   } else if (obj.sortColumn.id === "orgBizEmail") {
  //     if (obj1.orgBizEmail !== undefined && obj1.orgBizEmail === "asc") {
  //       obj1.orgBizEmail = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.orgBizEmail = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "OrgBizEmail";
  //   }
  //   setArraySortOrder(obj1);

  //   var objSel = { ...selectedDropdownAry };

  //   fetchOrgDetails(
  //     "",
  //     objSel.seltdResellerId,
  //     objSel.seltdOrgTypeId,
  //     objSel.seltdLastOrgId,
  //     seltdsrtngcolumn,
  //     orderdir
  //   );
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);

  //   var request = {};
  //   request.resellerId = selectedDropdownAry.seltdResellerId;
  //   request.orgId = selectedDropdownAry.seltdLastOrgId;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = selectedDropdownAry.searchText;
  //   request.columnName = selectedDropdownAry.columnName;
  //   request.orderDir = selectedDropdownAry.orderDir;
  //   request.orgfleet = selectedDropdownAry.seltdFleetId;
  //   request.custrec = selectedDropdownAry.customerId;
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   request.customerId = selectedDropdownAry.seltdCustomerId;
  //   request.regionorgType = selectedDropdownAry.regionorgType;
  //   request.roleId = selectedDropdownAry.roleId;
  //   request.status = selectedDropdownAry.status;
  //   request.reslId = selectedDropdownAry.seltdResellerId;
  //   request.fleetParentId = selectedDropdownAry.seltdResellerId;
  //   request.selectedOrgId = selectedDropdownAry.seltdLastOrgId;
  //   request.orgType = selectedDropdownAry.seltdOrgTypeId;
  //   request.timeZoneValue = selectedDropdownAry.timeZoneValue;

  //   try {
  //     const responseData = (
  //       await OrganizationServices.getGridViewForOrg(request)
  //     ).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  // //get Data
  // const getData = (data) => {
  //   console.log('data',data);
  // }

  return (
    <Box>
      <AdminDropdown
        getData={getData}
        regionDrop={true}
        dealerDrop={true}
        customerDrop={true}
        orgDrop={true}
        hideOrg={false}
      />
      <Box>

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Organization List</StyledTableTitle>}
          />
          <CardContent sx={{ py: 0 }}>
            {/* <DataTable
              loading={loading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangepdf}
              onEdit={onEdit}
              callbck={handleSort}
              onDelete={onDelete}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
              commonFilter={commonFilter}
            /> */}
            <DataTable
              // initialState={initialState1}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              // onChangePdf={onChangePDF}
              handleChange={handleSearch}
              onDelete={onDelete}
              onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
              commonFilter={commonFilter}
              onEdit={onEdit}
            />
          </CardContent>
        </StyledCardShadow>
        {deleteItem !== null && hashId === "#delete" && (
          <StyledModel data={deleteItem} deleteItem={deleteItems}>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Org Name: {deleteItem.orgName}
              </Typography>
            </Box>
          </StyledModel>
        )}
      </Box>

    </Box>
  );
};

export default ListOrganization;
