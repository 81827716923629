
import React,{useState} from 'react'

import ManualCanDataReport from "./VehicleDataReport";
import ManualCanDataReportKG from "./KGVehicleDataReport";
import ManualCanDataReport3WKG from "./3WKGVehicleDataReport";
import ManualCanDataReportMontra from "./MontraVehicleDataReport";
import ManualCanDataReportSCV from './SCVVehicleDataReport';
import ManualCanDataReportTerraMoto from './TerraVehicleDataReport';
import ManualCanDataReportPricol from './PricolVehicleDataReport';
import ManualCanDataReport3WMontra from './3WMontraVehicleDataReport';
import ManualCanDataReportTractor from './TractorVehicleDataReport';

const VehicleDataReportIndex = () => {

    var globalOrgId = 1;
    var globaluserId = 1;
    var globalRoleId = 0;
    var globalJson = {};
    var globalResellerId = 2;
    var globalOrgType = 2;
    var globalUserName = "";
    var globalOrgName = "";
    if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
    ) {
    } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    }
   
    if(globalResellerId === 109951){   
        return (
        
            <ManualCanDataReportKG/>

        )
    }else if(globalResellerId === 109936){
        return (
        
            <ManualCanDataReport3WKG/>

        )
    }else if(globalResellerId === 110224){
        return (
        
            <ManualCanDataReportMontra/>

        )
    }else if(globalResellerId === 114613){
        return (
            <ManualCanDataReportSCV/>

        )
    }else if(globalResellerId === 116269){
        return (
            <ManualCanDataReportTerraMoto/>

        )
    }else if(globalResellerId === 116300){
        return (
            <ManualCanDataReportPricol/>

        )
    }else if(globalResellerId === 110050){

        return (
            <ManualCanDataReport3WMontra/>
        )
    }else if(globalResellerId === 114663){
        return (
            <ManualCanDataReportTractor/>
        )
    }else{
        return (
        
            <ManualCanDataReport/>
        
        )
    }
}

export default VehicleDataReportIndex