import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateDevice from "./CreateDevice";
import ListDevice from "./ListDevice";
import { Breadcrumb } from "../shared/Breadcrumbs";
import DeviceUpload from "./deviceUpload";
import RecentActivity from "./recentActivity";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import DeviceView from "./deviceView";
import FormDialog from "../../Utils/FormDialog";
import { useLocation, useNavigate } from "react-router-dom";

const DeviceOnBoard = () => {
  const [deviceToEdit, setDeviceToEdit] = React.useState(null);
  const [create, setCreate] = useState("Create Device");
  const [id, setId] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tabShow, setTabShow] = React.useState("1");
  const [menuUser, setMenuUser] = useState();
  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "deviceOnBoard"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }

    var tabshowval = "6";
    var displaydefaulttab = 3;  
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("7");
      tabshowval = "7";
      displaydefaulttab = 1;
    }
    return displaydefaulttab;
  };
  const [tabValue, setTabValue] = useState(() => callfun());

  const handleDevEdit = (devEditedId) => {

    setDeviceToEdit(devEditedId);
    setCreate("Edit Device");
    var dataStr = { ...tempcommonFilter };
    var displaydefaulttab = 1;
    if (tabShow === "1") {
      setTabShow("1");
      displaydefaulttab = 0;
    }else if (tabShow === "2") {
      displaydefaulttab = 5;
    } else if (tabShow === "3") {
      displaydefaulttab = 2;
    }  else if (tabShow === "4") {
      displaydefaulttab = 2;
    } else if (tabShow === "5") {
      displaydefaulttab = 0;
    } else if (tabShow === "6") {
      displaydefaulttab = 1;
    } else if (tabShow === "7") {
      setTabShow("5");
      displaydefaulttab = 0;
    }
    setTabValue(displaydefaulttab);

  };

  const handleDevView = (devEditedId) => {
    location.state={'id':devEditedId.id}
    navigate('#view');
    setId(devEditedId.id);

  };

  const handleTabChange = (event, newValue) => {
    setDeviceToEdit(null);
    setTabValue(newValue);
    setCreate("Create Device");
  };

  const handleCancel = () => {
    setCreate("Create Device");
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("7");
      tabshowval = "7";
      displaydefaulttab = 1;
    }
    setTabValue(displaydefaulttab);
  };



  

  const TABS1 = [
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} />,
    },
  ];


  const TABS2 = [
    {
      label: create,
      children: (
        <CreateDevice deviceToEdit={deviceToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} />,
    },
  ];

  const TABS3 = [
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: create,
      children: (
        <CreateDevice deviceToEdit={deviceToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} onViewDevice={handleDevView}/>,
    },
  ];

  const TABS4 = [
    {
      label: "Import Device Details",
      children: <DeviceUpload onCancel={handleCancel} />,
    },
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: create,
      children: (
        <CreateDevice deviceToEdit={deviceToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Device List",

      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} onViewDevice={handleDevView}/>,
    },
  ];

  const TABS5 = [
    {
      label: "Import Device Details",
      children: <DeviceUpload onCancel={handleCancel}/>,
    },
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: "Device List",

      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} onViewDevice={handleDevView}/>,
    },
  ];

 
  const TABS6 = [
    {
      label: "Import Device Details",
      children: <DeviceUpload onCancel={handleCancel}/>,
    },
    {
      label: create,
      children: (
        <CreateDevice deviceToEdit={deviceToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} onViewDevice={handleDevView}/>,
    },
  ];



  const TABS7 = [

    {
      label: "Import Device Details",
      children: <DeviceUpload onCancel={handleCancel}/>,
    },
    {
      label: "Device List",
      children: <ListDevice onEditDevice={handleDevEdit} menuUser={menuUser} onViewDevice={handleDevView}/>,
    },
  ];


  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(isSuccess.isSuccess){
      setTimeout(() => dispatch(snackService([null, null, null])),6000)
    }
  },[isSuccess.isSuccess])

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Device"></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS1}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "3" && (
        <StyledTabs
          tabs={TABS3}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "4" && (
        <StyledTabs
          tabs={TABS4}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "5" && (
        <StyledTabs
          tabs={TABS5}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "6" && (
        <StyledTabs
          tabs={TABS6}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "7" && (
        <StyledTabs
          tabs={TABS7}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {
          isSuccess.isSuccess &&
          <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
        }

         {/* Edit Popup */}
         <FormDialog >
            <DeviceView  ids={id}/>
        </FormDialog>
    </StyledPageLayout>
  );
};

export default DeviceOnBoard;
