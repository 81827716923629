import { Navigate } from "react-router-dom"
import React from 'react'

const validateUser = (parent) => {
    if (true) {
        return <parent.component />
    } else {
        console.log('User doesnt have access to the page, redirecting to login...')
        return <Navigate replace to='/login' />
    }
}

export const getElement = (parent) => {
    return parent.isProtected ? validateUser(parent) : <parent.component />
}