import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Typography, Stack, IconButton, Grid } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import {Button} from "@mui/material";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import logServices from "../../services/logService";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useAuditLogMutation, useFaultCodeMutation } from "../../store/slice/ApiSlice";
import { HiMiniEye } from "react-icons/hi2";
import FormDialog from "../../Utils/FormDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { useFaultFeedDetailMutation } from "../../store/slice/ReportSlice/ReportSlice";



const text =
  "Fault Code Report shows the defect Occured in the vehicle for a selected time period.";
  
const FaultCodeReport = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();
  const location = useLocation();
  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }
  useEffect(async () => {
    auditPayload.message = 'Reports > FaultCode';
    auditPayload.action = 'View';
    auditPayload.featureName = 'FaultCode';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },
      {
        Header: "Fault Level",
        accessor: "faultLevel",
      },
      {
        Header: "Fault Code",
        accessor: "faultCode",
      },
      {
        Header: "Description",
        accessor: "faultDescription",
        width:250,
      },
      {
        Header: "Severity",
        accessor: "severity",
        width:120,
      },
      {
        Header: "Active/InActive",
        accessor: "status",
      },
      {
        Header: "Fault Location (Click To View Location In Map)",
        accessor: "faultLocation",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }

          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.faultLocation} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.faultLocation} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank"  className="latlink"  ref={refLink} id={row.original.faultLocation}>{row.original.faultLocation}</a>
                <a  href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank" className="address" ref={refAddres} id={row.original.faultLocation} key={row.original.faultLocation}></a>
            </Box>
        </Box>
          )
        },
      },
      {
        Header: "First Occurance",
        accessor: "firstOccurance",
        Cell:({row}) => {
          return (
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="body2">{row.original.firstOccurance}</Typography>
                {
                  resellerId === 109882 && <IconButton size="small" color="info" onClick={() => viewFaultCode(row.original.firstOccurance, row.original.vehicleId, resellerId)}><HiMiniEye /></IconButton>
                }
                
            </Stack>
            
          )
        }
      },
      {
        Header: "Fault Closed  Date",
        accessor: "faultClosedDate",
      },
      {
        Header: "Occurance Count",
        accessor: "occuranceCount",
      },
    ],
    []
  );


  const MontraColumns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "Fault Code",
        accessor: "faultCode",
      },
      {
        Header: "Description",
        accessor: "faultDescription",
      },
      {
        Header: "Active/InActive",
        accessor: "status",
      },
      {
        Header: "Fault Location (Click To View Location In Map)",
        accessor: "faultLocation",
      
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }

          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.faultLocation} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.faultLocation} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank"  className="latlink"  ref={refLink} id={row.original.faultLocation}>{row.original.faultLocation}</a>
                <a  href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank" className="address" ref={refAddres} id={row.original.faultLocation} key={row.original.faultLocation}></a>
            </Box>
        </Box>
          )
        },
      },
      {
        Header: "First Occurance",
        accessor: "firstOccurance",
        Cell:({row}) => {
          return (
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="body2">{row.original.firstOccurance}</Typography>
                {
                  resellerId === 109882 && <IconButton size="small" color="info" onClick={() => viewFaultCode(row.original.firstOccurance, row.original.vehicleId, resellerId)}><HiMiniEye /></IconButton>
                }
                
            </Stack>
            
          )
        }
      },
    ],
    []
  );

  const tractorColumns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        width:120,
      },
      {
        Header: "Fault Code",
        accessor: "faultCode",
      },
      {
        Header: "Description",
        accessor: "faultDescription",
      },
      {
        Header: "Active/InActive",
        accessor: "status",
      },
      {
        Header: "Fault Location (Click To View Location In Map)",
        accessor: "faultLocation",
      
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }

          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.faultLocation} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.faultLocation} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank"  className="latlink"  ref={refLink} id={row.original.faultLocation}>{row.original.faultLocation}</a>
                <a  href={`https://www.google.com/maps/search/?api=1&query=${row.original.faultLocation}`} target="_blank" className="address" ref={refAddres} id={row.original.faultLocation} key={row.original.faultLocation}></a>
            </Box>
        </Box>
          )
        },
      },
      {
        Header: "First Occurance",
        accessor: "firstOccurance",
        Cell:({row}) => {
          return (
            <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="body2">{row.original.firstOccurance}</Typography>
                {
                  resellerId === 109882 && <IconButton size="small" color="info" onClick={() => viewFaultCode(row.original.firstOccurance, row.original.vehicleId, resellerId)}><HiMiniEye /></IconButton>
                }
                
            </Stack>
            
          )
        }
      },
      {
        Header: "Fault Close Date",
        accessor: "faultClosedDate",
      },
    ],
    []
  );

  
 
    const navigate =  useNavigate();
    const [ FeedDetails, {data:feedData, isLoading:feedLoading, isSuccess:feedSuccess } ] = useFaultFeedDetailMutation();
    const [feedDetailData, setfeedDetailData] = useState(null);
  //View Popup
    const viewFaultCode = (time, vehicleId, resellerId) => {
      //
      getFeedDetails(time, vehicleId, resellerId);
    }


    //get Data from service
    const getFeedDetails = async (time, vehicleId, resellerId) => {
      let payload = {
        "vehicleId": vehicleId,
        "occuranceTime": time,
        "resellerId": resellerId
      }
      const feedResponse = await FeedDetails(payload);
      if(feedResponse.data.resultCode === 0){
          navigate('#view');
          setfeedDetailData(feedResponse.data.data);
      }
    }


    const [exportOpt, setExportOpt] = useState(false);
    // Get Data from New Dropdown
    const [ getFaultcode, {data:faultcodeDatta, isLoading, isSuccess} ] = useFaultCodeMutation();
    let commonPayload = {}
    const onSubmitForm = (data) => {
      setNewPage(0);
      setRowsPerPage(50);
          commonPayload.reselId = resellerId;
          commonPayload.regionId = data.regionId;
          commonPayload.dealerId = data.dealerId;
          commonPayload.custId = data.customerId;
          commonPayload.orgId = data.fleetId;
          commonPayload.vehicleId = data.vehicleId;
          commonPayload.search = "";
          commonPayload.severity = data.severityId;
          commonPayload.status = data.status;
          commonPayload.startDate = data.fromDate;
          commonPayload.endDate = data.toDate;
          commonPayload.orderDir = "desc";
          commonPayload.page = newPage;
          commonPayload.length = rowsPerPage;
          //Service Call
          setPayload(commonPayload);
          getFaultcode(commonPayload);

          auditPayload.message = "Reports > Fault Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.severityName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
          auditPayload.action = "Submit";
          auditPayload.featureName = "Fault Report";
          auditPayload.customerName = "All";
          auditPayload.fleetName = "All";
          auditPayload.result = "Success";
          auditPayload.access = "Portal";
          auditPayload.severity = "INFO";
          auditPayload.userName = userName;
          auditLog(auditPayload);
    }
     //Export PDF & Excel
     const [payload, setPayload] = useState(null);
     const onChangeXcl = async () => {
      if(totalElements > 5000){
        navigate('#popup');
      }else{
        try {
              var url =
                process.env.REACT_APP_API_BASE_URL +
                "FaultCodeReport/FaultCodeXLS.xls?startDate=" +
                payload.startDate +
                "&endDate=" +
                payload.endDate +
                "&reselId=" +
                payload.reselId +
                "&orgId=" +
                payload.orgId +
                "&vehicleId=" +
                payload.vehicleId +
                "&status=" +
                payload.status +
                "&regionId=" +
                payload.regionId +
                "&dealerId=" +
                payload.dealerId +
                "&custId=" +
                payload.custId +
                "&severity=" +
                payload.severity;
                window.open(url);
    
                auditPayload.message = 'Reports > Fault Code > XLS';
                auditPayload.action = 'View';
                auditPayload.featureName = 'Fault Code';
                auditPayload.customerName = 'All';
                auditPayload.fleetName = 'All';
                auditPayload.result = 'Success';
                auditPayload.access = 'Portal';
                auditPayload.severity = 'INFO';
                auditLog(auditPayload);
          
    
          fetch(url, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
            },
          }) // FETCH BLOB FROM IT
            .then((response) => {
              response.blob();
            })
            .then((blob) => {})
            .catch((err) => {});
        } catch (error) {}
      }
     };
     const onChangePDF = async () => {
   
       try {
             var url =
              process.env.REACT_APP_API_BASE_URL +
              "FaultCodeReport/FaultCodePDF.pdf?startDate=" +
              payload.startDate +
              "&endDate=" +
              payload.endDate +
              "&reselId=" +
              payload.reselId +
              "&orgId=" +
              payload.orgId +
              "&vehicleId=" +
              payload.vehicleId +
              "&status=" +
              payload.status +
              "&regionId=" +
              payload.regionId +
              "&dealerId=" +
              payload.dealerId +
              "&custId=" +
              payload.custId;
              window.open(url);
   
         auditPayload.message = 'Reports > Fault Code > PDF';
         auditPayload.action = 'View';
         auditPayload.featureName = 'Fault Code';
         auditPayload.customerName = 'All';
         auditPayload.fleetName = 'All';
         auditPayload.result = 'Success';
         auditPayload.access = 'Portal';
         auditPayload.severity = 'INFO';
         auditLog(auditPayload);
       } catch (error) {}
     };
    //Search Text
    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(50);
        payload.page = 0;
        payload.rowsPerPage = 50;
        if(payload.search.length >= 3){
          getFaultcode(payload);
        }else if(payload.search.length === 0){
          getFaultcode(payload);
        }
    };
    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
      setNewPage(newPage);
      setRowsPerPage(rowsPerPage);
      payload.page = newPage;
      payload.length = rowsPerPage;
      getFaultcode(payload);
    } 
    useEffect(() => {
      if(isSuccess){
        setResponseData([]);
        if(faultcodeDatta.data !== null){
          setResponseData(faultcodeDatta.data.content);
          setTotalElements(faultcodeDatta.data.totalElements);
          setTotalPages(faultcodeDatta.data.totalPages)
        }
      }
    },[isSuccess])


    

    //onClick={handlerefreshTable}
    const handlerefreshTable = () => {
          commonPayload.reselId = resellerId;
          commonPayload.regionId = payload.regionId;
          commonPayload.dealerId = payload.dealerId;
          commonPayload.custId = payload.custId;
          commonPayload.orgId = payload.orgId;
          commonPayload.vehicleId = payload.vehicleId;
          commonPayload.search = "";
          commonPayload.severity = payload.severity;
          commonPayload.status = payload.status;
          commonPayload.startDate = payload.startDate;
          commonPayload.endDate = payload.endDate;
          commonPayload.orderDir = "desc";
          commonPayload.page = newPage;
          commonPayload.length = rowsPerPage;
          getFaultcode(commonPayload);
    }
    
  
  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="EV Fault Code" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"EV Fault Code"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          isSeverity={true}
          isStatus={true}
          displayDate={true}
          allowDays={7}
        /> */}
         <FilterDropdown getData={onSubmitForm} isFaultCode={true} isSeverity = {resellerId === 114663 ? false : true} allowDays = {resellerId === 110224 ? 31 : 7}
          time = {true} allowedMonth={resellerId === 110224 ? 6 : 3} last6Month={true} last31={resellerId === 110224 ? true : false}/>
        <StyledCardShadow sx={{ mt: 2 }} >
         
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              columns={resellerId === 110224 ? MontraColumns : resellerId === 114663 ? tractorColumns : columns}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              includeExportButton={true}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              specificPage={"EV Fault Code"}
              isLoading={isLoading}
              reload={true}
              handlerefreshTable={handlerefreshTable}

            />
          </CardContent>
        </StyledCardShadow>
      </Box>

      {/* View Popup */}
      {
        location.hash === '#view' &&
        <FormDialog title={'Feed Details'} size='sm'>
            {
              feedDetailData !== null &&
              <Grid container spacing={1}>
                <Grid item lg={4}>
                  <Typography variant="body2">IMEI</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.vehicleNo}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">Created Date</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.createdDate}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">Location</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.location}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">Status</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.status}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">Battery Voltage (V)</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.batteryVoltage}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">Battery Current (A)</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.batteryCurrent}</Typography>
                </Grid>
              
                <Grid item lg={4}>
                  <Typography variant="body2">SOC (%)</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.soc}</Typography>
                </Grid>
                
                  <Grid item lg={4}>
                  <Typography variant="body2">Odometer (km)</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.odometer}</Typography>
                </Grid>

                <Grid item lg={4}>
                  <Typography variant="body2">{resellerId === 110224 ? "Available Energy (kWh)" : "Available Energy (Wh)"}</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.availableEnergy}</Typography>
                </Grid>
                
                <Grid item lg={4}>
                  <Typography variant="body2">Internal Battery Voltage (V)</Typography>
                </Grid>
                <Grid item lg={8}>
                  <Typography variant="body2">{feedDetailData.internalBatteryVoltage}</Typography>
                </Grid>
                
 
              </Grid>
            }
      </FormDialog>
      }
      

      {
        location.hash === '#popup' && 
        <FormDialog title={'Alert'} size='sm'>
        <Typography>Exceeds limit , Use Filters Criteria</Typography>
      </FormDialog>
      }
        </StyledPageLayout>
  );
};

export default FaultCodeReport;
