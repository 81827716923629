import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent,Button } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CommonDropDown from "./LogDropDown";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { useImbalanceLogMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import ReportServices from "../../services/reportServices";

const ImbalanceLog = () => {
    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
  
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let RoleId = userData.roleId;
  
    const text =
      "This screen shows the Cell Imbalance Log.";
  
      const columns = useMemo(
        () => [
                 {
            Header: "Date",
            accessor: "createdDate",
          },
          {
            Header: "Vehicle No",
            accessor: "vehicleNo",
          },
          {
            Header: "Location",
            accessor: "gpsLocation",
            Cell: ({ row, column, cell }) => {
              const refAddres = React.useRef(row);
              var refLink = React.useRef(row);
              var getAddressBtn = React.useRef(row);
              var toggleAddressBtn = React.useRef(row);
              const getAddress = async (e) => {
                if (refAddres.current.innerText === "") {
                  var request = {};
                  request.latLng = e.target.value;
                  // rowid = e.target.id;
                  const responseData = (
                    await ReportServices.getGeocodeAddress(request)
                  ).data;
                  refAddres.current.innerText = responseData;
                  if (responseData !== "") {
                    refLink.current.style.display = "none";
                    refAddres.current.style.display = "block";
                    toggleAddressBtn.current.style.display = "block";
                    getAddressBtn.current.style.display = "none";
                    toggleAddressBtn.current.innerText = "LatLng";
                  }
                }
              };
    
              //Address toggle
              const ToggleAdderss = () => {
                if (refAddres.current.style.display === "block") {
                  refAddres.current.style.display = "none";
                  refLink.current.style.display = "block";
                  toggleAddressBtn.current.innerText = "Address";
                } else {
                  refAddres.current.style.display = "block";
                  refLink.current.style.display = "none";
                  toggleAddressBtn.current.innerText = "LatLng";
                }
              };
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    className="addresslink"
                    id={row.original.gpsLocation}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      onClick={(e) => getAddress(e)}
                      value={row.original.gpsLocation}
                      ref={getAddressBtn}
                    >
                      Address
                    </Button>
                    <Button
                      onClick={(e) => ToggleAdderss(e)}
                      ref={toggleAddressBtn}
                      sx={{ display: "none" }}
                    >
                      LatLng
                    </Button>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                      target="_blank"
                      className="latlink"
                      ref={refLink}
                      id={row.original.gpsLocation}
                    >
                      {row.original.gpsLocation}
                    </a>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                      target="_blank"
                      className="address"
                      ref={refAddres}
                      id={row.original.gpsLocation}
                      key={row.original.gpsLocation}
                    ></a>
                  </Box>
                </Box>
              );
            },
          },
          {
            Header: "Alert Type",
            accessor: "alertType",
          },
          {
            Header: "Vehicle Status",
            accessor: "vehicleStatus",
          },
          {
            Header: "SOC (%)",
            accessor: "stateOfCharge",
          },
          {
            Header: "Max Cell Voltage (V)",
            accessor: "maxCellVoltage",
          },
          {
            Header: "Min Cell Voltage (V)",
            accessor: "minCellVoltage",
          },
          {
            Header: "Max Cell",
            accessor: "maxCellVoltageNo",
          },
          {
            Header: "Min Cell",
            accessor: "minCellVoltageNo",
          },
          {
            Header: "Max CellBox",
            accessor: "maxCellVoltageBox",
          },
          {
            Header: "Min CellBox",
            accessor: "minCellVoltageBox",
          },
          {
            Header: "Delta Voltage (V)",
            accessor: "deltaVoltage",
          },
        ],
        []
      );
    
      const [auditLog] = useAuditLogMutation();
      let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, 
        orgName: orgName, 
        userName: userName,
      };
      const [getImbalanceLog, { data: logData, isLoading, isSuccess }] =
      useImbalanceLogMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getImbalanceLog(commonPayload);

    auditPayload.message = "Logs > Imbalance > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "ImbalanceLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "CellImbalance/getCellImbalanceLogCSV?regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&length=" +
        payload.length +
        "&start=" +
        payload.start +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&sortBy=" +
        payload.sortBy +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Logs > Imabalance Log > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "ImabalanceLog";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.rowsPerPage = 25;
    if (payload.search.length >= 3) {
      getImbalanceLog(payload);
    } else if (payload.search.length === 0) {
        getImbalanceLog(payload);
    }
  };


  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getImbalanceLog(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getImbalanceLog(payload);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (logData.data !== null) {
        setResponseData(logData.data.content);
        setTotalElements(logData.data.totalElements);
        setTotalPages(logData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Logs"
        subDiv="Imbalance Log"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Cell Imbalance Log"} />

      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = { 7 } 
        time = {false} allowedMonth = {3} last6Month={true} last31={false}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
    
}

export default ImbalanceLog;