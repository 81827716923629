export const listDeviceData = [
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three1',
      value10: 'Fleet2',
      value11: 'KA53HA9912New2',
      value12: '07-06-2022 13:33:38',
      value13: '13.0315017',
      value14: '13',    
      value15: 'some value three1',
      value16: ['Edit','Delete']
      // id: '1'
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete']
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete']
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete'],
    },
    {
      value0: ['checkbox'],
      value1: 'Fleet',
      value2: 'KA53HA9912New',
      value3: '07-06-2022 13:33:37',
      value4: '13.0315016',
      value5: '12',
      value6: 'some value three',
      value7: 'some value one 1',
      value8: 'some value four',
      value9: 'some value three',
      value10: 'Fleet',
      value11: 'KA53HA9912New',
      value12: '07-06-2022 13:33:37',
      value13: '13.0315016',
      value14: '12',
      value15: 'some value three',
      value16: ['Edit','Delete']
    }
  ]
