import * as React from 'react'
import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';

const StyledIconButton = styled(IconButton)(
    ({ theme }) => ({
        margin: '0 .5em',
        backgroundColor: theme.palette.background.whitesmoke,
    })
)
export default function FilterButton() {

    return (
        <StyledIconButton>
            <FilterListIcon />
        </StyledIconButton>
    )
}