import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import SingleDropdown from '../NewDropdown/SingleDropdown'
import NewViewTicket from './NewViewTicket'

function HelpDeskNew() {
  return (
    <Box>
       <NewViewTicket />
    </Box>
  )
}

export default HelpDeskNew