import React, { createRef, useEffect, useState } from "react";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import {
  HiMiniFolderMinus,
  HiMiniFolderPlus,
  HiRectangleGroup,
  HiUser,
} from "react-icons/hi2";
import {
  useChargingMutation,
  useGetRegionListMutation,
  useStopPageMutation
} from "../../../store/slice/ApiSlice";
import {
  Collapse,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Vehicle from "./Vehicle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedVehicleData,
  selectedVehicleTrailsData,
  isLoadingState,
  singleVehicle,

  refresh,
  searchKeyWord,
  getOtherMarkers,
} from "../../../store/slice/ControlsSlice";
import { useGetVehicleDetailsMutation, useAuditLogMutation } from "../../../store/slice/ApiSlice";

function Fleet(props) {
  const {
    isLogin,
    whoIam,
    buttonIndex,
    isCutomer,
    fleetItem,
    index,
    openfleetId,
    userData,
    regionName,
    dealerName,
    customerName,
    payload,
    fleetPayload
  } = props;

  const [open, setOpen] = useState(false);
  const vehicleRef = fleetItem.vehicles.map(() => createRef());
  //let [selctedVehile, setSelectedVehicle] = useState(null);
// console.log("props=>",props);
  //get selected vehicle
  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );
  const date = useSelector((state) => state.controls.treeview.date);
  const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);
  // const isSearchKeyword = useSelector(
  //   (state) => state.controls.treeview.searchKeyWord
  // );
  //set selcted vehicle
  const dispatch = useDispatch();

  let orgId = userData?.orgId;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [orgNameByFleet, setOrgNameByFleet] = useState(null);
  const showVehicleDetails = (data) => {
    //setOrgNameByFleet(data.current.getAttribute('data-orgName'));
    setOrgNameByFleet(data.current.getAttribute('data-fleetIndex'));
    //setSelectedVehicle(data.current.id);
    dispatch(selectedVehicleData(data.current.id));
    getSingleVehicleDetails(data.current.id);
  };

  //get single vehicle details
  const [
    getVehicleDetails,
    {
      data: getDetails,
      isLoading: getDetailsLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleDetailsMutation();


  const getSingleVehicleDetails = (id) => {
    let body = {
      roleId: 6,
      sessionOrgId: fleetItem?.orgId,
      vehicleId: id,
      logoName: "string",
      strDate: date[0],
      endDate: date[1],
      statusFlag: 5,
      orgId: fleetItem?.orgId,
      mins: "1",
      orgType: "2",
      agencyId: 0,
      enLatLng: 0,
      sessionOrgVehicleDisplay: 1,
      startOdometer: 0,
      endOdometer: 0,
      storepack: 0,
      userId: "2",
      viewTrails30sec: 0,
      cngVehicle: true,
      hoursFeed: trailsTime,
      view_trails30sec: 0,
    };
    dispatch(isLoadingState(true));
    dispatch(singleVehicle(body));
    getVehicleDetails(body);
  };

  useEffect(() => {
    dispatch(isLoadingState(false));
    if (date !== null && selectedVehicle !== null) {
      getSingleVehicleDetails(selectedVehicle);
    }
  }, [date, trailsTime]);

 //API Slice
const [getVehicles, { data:updateVehicleData, isSuccess }] = useGetRegionListMutation();

  const [vehicleList, setVehicleList] = useState(null);

  useEffect(() =>{
    if(selectedVehicle !== null && isSuccess && orgNameByFleet !== null){
        //let filterVehicles = updateVehicleData?.treeviewList[0]?.orgs?.map(item => item.vehicles.filter(vehicle => vehicle.orgName === orgNameByFleet)).filter(item => item.length > 0);
        //console.log('filterVehicles', filterVehicles)
        //setVehicleList(filterVehicles);
        setVehicleList(updateVehicleData.treeviewList[0].orgs[orgNameByFleet]);
    }
  
  },[selectedVehicle, isSuccess, orgNameByFleet])

  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );

  const isLoading = useSelector((state) => state.controls.isloading);

  const updateVehicleList = () => {

    if (selectedVehicle !== null && open && isSearchKeyword === null ) {
     if (fleetPayload === null || fleetPayload === undefined){
          getVehicles(payload);
     }else{
          getVehicles(fleetPayload);
     }}

    if (selectedVehicle !== null && open && isSearchKeyword !== null ) {
      if (fleetPayload === null || fleetPayload === undefined){
            payload.search=isSearchKeyword;
            getVehicles(payload);
    }else{
      fleetPayload.search=isSearchKeyword;
   
      getVehicles(fleetPayload);
    }

    setOpen(true) //test
    }   
  }

  useEffect(() => {
    updateVehicleList();
  }, [selectedVehicle]);


  // useEffect(() => {
  //   if (isSuccess) {
  //     // setFleetList(data);
  //     console.log("Fleet=>",data);
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    if (getVehicleSuccess) {
      dispatch(selectedVehicleTrailsData(getDetails));
      dispatch(isLoadingState(false));
    }
  }, [getVehicleSuccess]);

  //collabse open
  const collabseOpen = () => {
    setVehicleList(fleetItem)
    setOpen(!open); //rest
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }

    auditPayload.message = "Tracking > Map > Treeview > "+userData?.orgName+" > "+regionName+" > "+dealerName+" > "+customerName+" > "+fleetItem?.orgName;
    auditPayload.action = "Select";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
  //  auditLog(auditPayload);
  };


  // useEffect(() => {

  // },[getVehicleSuccess])

  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (
        fleetItem.vehicles !== null &&
        isSearchKeyword !== null &&
        isSearchKeyword.length >= 3
      ) {
        //if(fleetItem.vehicles.length === 0){
        setOpen(true);
        getSingleVehicleDetails(fleetItem.vehicles[0].vehicleId);
        dispatch(selectedVehicleData(fleetItem.vehicles[0].vehicleId));
        //}
      } else {
        dispatch(selectedVehicleData(null));
        dispatch(selectedVehicleTrailsData(null));
        dispatch(refresh([false, false]));
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearchKeyword]);


  //Onload Expand
  useEffect(() => {
    if ( (isSearchKeyword === null || isSearchKeyword === "")) {
      setOpen(false);
      //
    }else{
      collabseOpen();
    }
  }, []);


  return (
    <TreeViewAccodionBox rolles={"fleet"}>
      <TreeViewListItemBtn
        primary={"fleet"}
        secondaryAction={
          <IconButton onClick={collabseOpen}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        key={index}
        buttonIndex={isCutomer ? buttonIndex : buttonIndex + 60}
      >
        <ListItemIcon>
          <HiRectangleGroup />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{fleetItem?.orgName}</Typography>
              <Typography>{`(${fleetItem?.vehicleCount})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>
      <Collapse in={open}>
      {vehicleList !== null &&
          vehicleList !== undefined &&
          vehicleList.vehicles.map((vehicles, indexVehicle) => (
            <Vehicle
              key={indexVehicle}
              vehiclesItem={vehicles}
              buttonIndex={buttonIndex}
              index={index}
              selectVehicle={showVehicleDetails}
              selctedVehile={selectedVehicle}
              regionName={regionName}
              dealerName={dealerName}
              customerName={customerName}
              fleetName={fleetItem.orgName}
              updateVehicleList={updateVehicleList}
            />
          ))}
      </Collapse>
    </TreeViewAccodionBox>
  );
}

export default Fleet;
