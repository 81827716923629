import { dashboardApi } from "../ApiSlice";

const BannerSlice = dashboardApi.injectEndpoints({
    endpoints:builder => ({
      
        //bannerList
        getBanner: builder.mutation({
          query: (body) => ({
            url: "Banner/getBannerFileList",
            method: "POST",
            body:body
          }),
        }),
        //savebanner
        saveBanner: builder.mutation({
          query: (body) => ({
            url: "Banner/UploadBanner",
            method: "POST",
            body:body
          }),
        }),
        //deletebanner
        deleteBanner: builder.mutation({
          query: (body) => ({
            url: "Banner/deleteBannerFile",
            method: "POST",
            body:body
          }),
        }),
    })
})


export const { useGetBannerMutation, useSaveBannerMutation, useDeleteBannerMutation } = BannerSlice;