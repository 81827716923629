import React, { useState, useEffect } from "react";
import OTAServices from "../../services/otaService";
import { useForm } from "react-hook-form";
import {
  Box,
  MenuItem,
  CardContent,
  Stack,
  CardActions,
  FormHelperText,
  Typography,
  Collapse,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFilterMenuItem,
  StyledFormControl,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import OTADropdown from "../OTA/OTADropdown"
import StyledSnackBar from "../../Utils/StyledSnackBar";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160,
    },
  },
};

const FotaSupport = () => {

  const [seltdCustomerId, setSeltdCustomerId] = useState("-1");
  const [seltdFleetId, setSeltdFleetId] = useState("-1");
  const [seltdDealerId, setSeltdDealerId] = useState("-1");
  const [seltdRegionId, setSeltdRegionId] = useState("-1");
  const [seltdImei, setSeltdImei] = useState("0");
  const [vehicleVal, setVehicleVal] = useState("-1");
  const [textMsg, setTextMsg] = useState("");
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);

  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let globalOrgId = userData.orgId;
  let globalOrgType = userData.userOrgType;
  let globaluserId = userData.id;
  let globalResellerId = userData.resellerId;
  let globalLogoName = userData.orgLogo;
  let globalOrgName = userData.orgName;
  let globalUserName = userData.userName;
  let roleId=userData.roleId;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

useEffect(async () => {
getFirmware()
}, []);

const [firmwareList,setFirmwareList] = useState([]);


const getFirmware = async() =>{
  const commandListPayload = {

    orgId:globalOrgId,
    modelName:"firmware"
 
  };
  const { data: commandList } = await OTAServices.getFirmwareListData(
    commandListPayload
  );
  setFirmwareList(commandList);

}

  const handleFirmChange = (e) => {
   setTextMsg(e.target.value)
  };

 

  const onSubmit = async (data, event) => {
    event.preventDefault();
    var request = {};
    request.imei = seltdImei;
    request.message = textMsg;
    request.commandType = "write";
    request.orgType = globalOrgType;
    request.firmwareId = 0;
    request.vehicleId = vehicleVal;
    request.otaType = "write";
    request.modelId = "";
    request.otaId = "";
    request.otaType = 0;
    request.otaCommand = "firmware";
    request.dealerId = seltdDealerId;
    request.customerId = seltdCustomerId;
    request.regionId = seltdRegionId;
    request.fleetId = seltdFleetId;
    request.resellerId = globalResellerId;
    request.logoName = globalLogoName;

    const responseData = await OTAServices.saveCommands(request);

    var obj1 = {};
    if (responseData.data === "success") {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDialogContent(obj1);
    setDataStatusConfirm("Success");
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };
  const getData = (data) => {
    setSeltdImei(data.imei);
    setSeltdDealerId(data.dealer);
    setSeltdCustomerId(data.customer);
    setSeltdRegionId(data.region);
    setSeltdFleetId(data.fleet);
    setVehicleVal(data.vehicle);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
 <OTADropdown
          getData={getData}
          regionDrop={true}
          dealerDrop={true}
          customerDrop={true}
          fleetDrop={true}
          vehicleDrop={true}
          imeiDrop={true}
          onLoadData={false}
        /> 
 {/* <Collapse in={ vehicleVal !== '-1'? true : false}> */}
        <StyledCardShadow elevation={0} sx={{ mt: 2 }}>

          <CardContent>

 
              <Box sx={{ marginTop: 0}}>

 <StyledFormControl sx={{ minWidth: "390px" }} size="small">
                <StyledFormControlLabel required>
                Firmware Version
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.firmwareVersion && true}
                  {...register("firmwareVersion", {
                    required: {
                      value: true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return (
                        fieldValue !== "0" || " Please select Firmware Version"
                      );
                    },
                  })}
                  defaultValue={"0"}
                  size="small"
                  onChange={handleFirmChange}
                  MenuProps={MenuProps}
                 
                >
                  <MenuItem value={"0"}>Select</MenuItem>
                  {firmwareList.map((option) => (
                    <StyledFilterMenuItem value={option.deviceModel}>
                      {option.command}
                    </StyledFilterMenuItem>
                  ))}
                </StyledDropDown>
                <FormHelperText error={errors.firmwareVersion && true}>
                  {errors.firmwareVersion?.message}
                </FormHelperText>
              </StyledFormControl>
              </Box>
          
            <StyledFormControl sx={{ minWidth: "600px", marginTop: 5 }}>
              <StyledFormControlLabel>DATA</StyledFormControlLabel>
              <TextareaAutosize
                variant="filled"
                disabled
                value={textMsg}
                maxRows="5"
              />
            </StyledFormControl>
              
        
          </CardContent>
          <CardActions>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2, width: "100%" }}
              justifyContent={"flex-end"}
            >
            
              <SoftButton
                variant="contained"
                size="small"
                type="submit"
                color="success"
              >
                Send
              </SoftButton>
             
            </Stack>
          </CardActions>
        </StyledCardShadow>
        {/* </Collapse> */}
        {open && (
          <StyledSnackBar
            open={true}
            message={dialogContent.dialogText}
            status={dataStatusConfirm}
          />
        )}
      </form>
    </Box>
  );
};

export default FotaSupport;
