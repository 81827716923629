import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import { StyledFilterFormControl, StyledFiterButton, StyledFormControlLabel } from "../../theme/StyledEle";

export default function MaintenanceCard() {
  const [value, setValue] = React.useState(null);
  const [endValue, setEndValue] = React.useState(null);
  const [remValue, setRemValue] = React.useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleChangeEnd = (newValue) => {
    setEndValue(newValue);
  };
  const handleChangeRem = (newValue) => {
    setRemValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={2} alignItems='center'>
      <StyledFilterFormControl sx={{minWidth:'100%'}}>
      <StyledFormControlLabel>
      Last Service Date
        </StyledFormControlLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
      </StyledFilterFormControl>
      <StyledFilterFormControl sx={{minWidth:'100%'}}>
      <StyledFormControlLabel>
      Next Service Date
        </StyledFormControlLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={endValue}
          onChange={handleChangeEnd}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
      </StyledFilterFormControl>
      <StyledFilterFormControl sx={{minWidth:'100%'}}>
      <StyledFormControlLabel>
      Remainder Date
        </StyledFormControlLabel>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={remValue}
          onChange={handleChangeRem}
          renderInput={(params) => (
            <TextField
              size="small"
              fullWidth
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Select Date",
              }}
            />
          )}
        />
      </StyledFilterFormControl>   
      <StyledFiterButton
            variant="contained"
            size="small"
            sx={{alignSelf:'end'}}
            color="success"
          >
            Save
          </StyledFiterButton>
      </Stack>
    </LocalizationProvider>
  );
}
