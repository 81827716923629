import React, {useState,useEffect } from "react";
import {
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTabsGroup,
} from "../../theme/StyledEle";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {  Box } from "@mui/material";
import CreateRouteTrip from "./CreateRouteTrip";
import { useDispatch, useSelector } from "react-redux";
import ListRouteTrip from "./ListViewRoute";
import ListManageTrip from "./ListManageRoute";
import { useLocation, useNavigate } from "react-router-dom";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import ListRouteTripHistory from "../Reports/RouteTripHistoryReport";
import OppositeContentTimeline from "./RecentActivity";


function RouteTrip() {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let roleId = userData.roleId;
  
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 

  //Create Route Trip

  //Tabs Value
  const [tabsValue, setTabsValue] = useState(2);
  const [lableName, setLabelName] = useState("Create Route");
  const [routeToEdit, setRouteToEdit] = useState(null);
  const tabChange = (event,newValue) => {
    setLabelName("Create Route");
    setTabsValue(newValue);
    setRouteToEdit(null);
    if(newValue === 2){
      navigate("#manage");
    }else if(newValue === 3){
      navigate("#view");
    }else if(newValue == 4){
      navigate("#recentActivity");
    }else {
      navigate(location.pathname)
    }
  };

  useEffect(() => {
    {
      (roleId === 17 || roleId === 8 )?tabChange('', 2):tabChange('', 3)
    }
    
  },[])

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        sx={{ width: "100%" }}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  }

  //Alert Popup auto close

  // useEffect(() => {
  //   if(saveRouteSuccess){
  //       console.log('saveRouteData', saveRouteData);
  //   }
  // },[saveRouteSuccess])

  //Handle Edit
  const handleEditRoute = (routeId) => {
    var tempProps = JSON.parse(JSON.stringify(routeId));
    tempProps.clickedval = "edit";
    setRouteToEdit(Object.preventExtensions(tempProps));
    setLabelName("Edit Route");
    setTabsValue(1);
  };
  //Handle View
  const handleViewRoute = (routeId) => {
    var tempProps = JSON.parse(JSON.stringify(routeId));
    tempProps.clickedval = "view";
    setRouteToEdit(Object.preventExtensions(tempProps));
    setLabelName("View Route");
    setTabsValue(1);
  };
  //Cancel call
  const handleCancel = () => {
    setLabelName("Create Route");
    setTabsValue(2);
    setRouteToEdit(null);
  };

  //Handle Recent Activities
  // const handleRecentActivities = () => {
  //   setLabelName("Recent Activities");
  //   setTabsValue(3);
  //   setRouteToEdit(null);
  // };
 //sector

 useEffect(() => {
   if (isSuccess.isSuccess) {
     setTimeout(() => dispatch(snackService([null, null, null])), 6000);
   }
 }, [isSuccess.isSuccess]);



  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Route Trips" includePopup={true} />
      {/* Save Loading */}
      {/* <Backdrop open={saveRouteLoading}/> */}
      {/* Tabs */}
      <Box>
                  
      {(roleId === 17 || roleId === 8) ?
            <>
        <StyledTabBox>
          <StyledTabsGroup
            value={tabsValue}
            onChange={tabChange}
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ "&  .MuiTabs-flexContainer": { flexDirection: "row" }}}
          >
            <StyledTab value={3} label="View Trips" /> //routeTripHistory
             {/* <StyledTab value={3} label="View Trips" /> */}
            <StyledTab value={2} label="Manage Route" />
            <StyledTab value={1} label={lableName} />
          {roleId === 17 &&   <StyledTab value={4} label="Recent Activity" /> }
          </StyledTabsGroup>
        </StyledTabBox>
        </>
        :        <StyledTabBox>
        <StyledTabsGroup
          value={tabsValue}
          onChange={tabChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ "&  .MuiTabs-flexContainer": { flexDirection: "row" }}}
        >
           <StyledTab value={3} label="View Trips" /> //routeTripHistory
           {/* <StyledTab value={3} label="View Trips" /> */}
          {/* <StyledTab value={2} label="Manage Route Trips" />
          <StyledTab value={1} label={lableName} /> */}
          
         
        
        </StyledTabsGroup>
      </StyledTabBox>
}
        <CustomTabPanel value={tabsValue} index={1}>
          <CreateRouteTrip routeToEdit={routeToEdit} onCancel={handleCancel} />
        </CustomTabPanel>
        <CustomTabPanel value={tabsValue} index={2}>
          <ListManageTrip
            onEditRoute={handleEditRoute}
            onViewRoute={handleViewRoute}
          />
        </CustomTabPanel>

        <CustomTabPanel value={tabsValue} index={3}>
          <ListRouteTripHistory />
          {/* Time line */}
          {/* <TimelineBase /> */}
        </CustomTabPanel>
        <CustomTabPanel value={tabsValue} index={4}>
          <OppositeContentTimeline />
        </CustomTabPanel>
        {/* <CustomTabPanel value={tabsValue} index={3}>
          <ListRouteTrip />
        </CustomTabPanel> */}
      </Box>
        {
          isSuccess.isSuccess &&
          <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
        } 


    </StyledPageLayout>
  );
}

export default RouteTrip;
