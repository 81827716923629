import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";

import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTable } from "../shared/data_table/DataTable";

import CommonDropDown from "./viewAlertDropDown";
import LogServices from "../../services/logService";
import { format } from "date-fns";
import { CardHeader, CardContent, Button } from "@mui/material";
import { StyledCardShadow, StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import ReportServices from "../../services/reportServices";
import AlertDropdown from "./AlertsDropdown/AlertDropdown";
import { useViewAlertsMutation } from "../../store/slice/AlertSlice/AlertSlice";
import { is } from "immutable";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
const ViewAlert = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Alerts > View Alerts";
    auditPayload.action = "View";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const text =
    "Alerts captured are based on assignment (via Alerts > Assign Alerts) for each vehicle at the time of occurrence.";


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "gpsDate",
        width:150,
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width:180,
      },
      {
        Header: "Region",
        accessor: "regionName",
        width:150,
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        width:150,
      },
      {
        Header: "Customer",
        accessor: "customerName",
      },
      {
        Header: "Fleet",
        accessor: "fleetName",
      },
      {
        Header: "Alert Type",
        accessor: "alertType",
        width:180,
      },
      {
        Header: "Lat Long",
        accessor: "gpsLocation",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpsLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.gpsLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpsLocation}
                >
                  {row.original.gpsLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpsLocation}
                  key={row.original.gpsLocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },

      {
        Header: "Description",
        accessor: "message",
        width:350,
      },
    ],
    []
  );

    
  let initialState1 = {};
  var arr = [];
  if (orgType === 3) {
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 5){
    arr.push("regionName");
    arr.push("dealerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 1){
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    arr.push("fleetName");
    initialState1.hiddenColumns = arr;
  }

  // const fetchAlertsDetails = async (value) => {
  //   var request = {};
  //   request.orgId = -1;
  //   request.resellerId = globalResellerId;
  //   request.orgType = 2;
  //   request.logoName = "";
  //   request.vehicleId = -1;
  //   request.alertType = "All";
  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "";
  //   request.columnName = "GPS_DATE";
  //   request.orderDir = "desc";
  //   request.customerId = -1;
  //   request.regionId = -1;
  //   request.dealerId = -1;
  //   request.isWeb = true;
  //   if (globalOrgType === 3) {
  //     request.orgId = value;
  //     request.customerId = globalOrgId;
  //   } else if (globalOrgType === 5) {
  //     request.customerId = value;
  //     request.dealerId = globalOrgId;
  //   }
  //   setSelectedDropdownAry(request);

  //   const responseData = (await LogServices.viewAlertLog(request)).data;
  //   setResponseData(responseData.datas.content);
  //   setLoading(responseData.resultMessage);
  //   setTotalElements(responseData.datas.totalElements);
  //   setTotalPages(responseData.datas.totalPages);
  // };

  // const handleSubmit = async (formData) => {
  //   console.log("formData.statusvalue", formData.statusvalue);
  //   var request = {};
  //   request.orgId = formData.fleet;
  //   request.resellerId = formData.reseller;
  //   request.orgType = 2;
  //   request.logoName = "";
  //   request.vehicleId = formData.vehicle;
  //   request.alertType = formData.statusvalue;
  //   request.startDate = formData.from_date;
  //   request.endDate = formData.to_date;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "";
  //   request.columnName = "GPS_DATE";
  //   request.orderDir = "desc";
  //   request.customerId = formData.customer;
  //   request.regionId = formData.region;
  //   request.dealerId = formData.dealer;
  //   request.isWeb = "true";
  //   setSelectedDropdownAry(request);

  //   const responseData = (await LogServices.viewAlertLog(request)).data;
  //   setResponseData(responseData.datas.content);
  //   setLoading(responseData.resultMessage);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.datas.totalElements);
  //   setTotalPages(responseData.datas.totalPages);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   var request = {};
  //   request.orgId = selectedDropdownAry.orgId;
  //   request.resellerId = selectedDropdownAry.resellerId;
  //   request.orgType = 2;
  //   request.logoName = "";
  //   request.vehicleId = selectedDropdownAry.vehicleId;
  //   request.alertType = selectedDropdownAry.alertType;
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "";
  //   request.columnName = "GPS_DATE";
  //   request.orderDir = "desc";
  //   request.customerId = selectedDropdownAry.customerId;
  //   request.regionId = selectedDropdownAry.regionId;
  //   request.dealerId = selectedDropdownAry.dealerId;
  //   request.isWeb = true;

  //   const responseData1 = (await LogServices.viewAlertLog(request)).data;
  //   setResponseData([]);
  //   setResponseData(responseData1.datas.content);
  //   setLoading(responseData1.resultMessage);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData1.datas.totalElements);
  //   setTotalPages(responseData1.datas.totalPages);
  // };

  const [getViewAlertList, { data, isLoading, isSuccess }] =
    useViewAlertsMutation();
  const [payload, setpayload] = useState(null);
  const commonPayload = {
    resellerId: resellerId,
    orgType: orgType,
    logoName: "",
    columnName: "GPS_DATE",
    orderDir: "desc",
    isWeb: "true",
  };
  const getData = (data) => {
    commonPayload.orgId = data.fleetId;
    commonPayload.regionId = data.regionId;
    commonPayload.customerId = data.customerId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.alertType = data.alertType;
    commonPayload.searchText = "";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    setpayload(commonPayload);
    getViewAlertList(commonPayload);

    //Audit Log
    auditPayload.message = "Alerts > View Alerts > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.alertTypeName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Alerts";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    
  };

  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      getViewAlertList(payload);
    } else if (payload.searchText.length === 0) {
      getViewAlertList(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getViewAlertList(payload);
  };
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "Alerts/getReportListXLS?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId +
        "&searchText=" +
        payload.searchText +
        "&alertType=" +
        payload.alertType +
        "&orgType=" +
        payload.orgType +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&isWeb=" +
        payload.isWeb;
       window.open(url);
     

      auditPayload.message = "Alerts > View Alerts > Export Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "Alerts";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (data.datas !== null) {
        setResponseData(data.datas.content);
        setTotalElements(data.datas.totalElements);
        setTotalPages(data.datas.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Alerts"
        subDiv="View Alerts"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"View Alerts"} />
      <Box>
        {/* <CommonDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          isStatus={true}
          callbackLoadFn={fetchAlertsDetails}
        /> */}
        <AlertDropdown getData={getData} isShowAll={true} allowedDays={7} allowedMonth={resellerId === 110224 ? 6 : 3}/>

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 2 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              initialState={initialState1}
              isLoading={isLoading}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              includeExportButton={true}
              onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default ViewAlert;
