import Service from "./service";

const featureServices = {
  getGridViewForFeature: function (resource) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/feature/listMenu", resource, null);
  },
  saveFeatureDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/feature/saveMenu", data, null);
  },
  deleteDeviceById: (deviceId) => {
    return Service.post("/device/deleteDevice", deviceId, null);
  },
  editOrgById: (featureData) => {
    return Service.post("/feature/getMenu", featureData, null);
  },
  updateFeatureDetails: function (data) {
    return Service.post("/feature/updateMenu", data, null);
  },
  getReference: function (resource) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/reference/listReference", resource, null);
  },
  getReferenceButton: function (resource) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/reference/listReferenceButton", resource, null);
  },
  getGroupList: function (resource) {
    return Service.post("/feature/getGroupList", resource, null);
  },
  getLinkGroup: function (data) {
    return Service.post("/feature/listgroup", data, null);
  },
  getLinkOrder: function (data) {
    return Service.post("/feature/getlinkorder", data, null);
  },
};

export default featureServices;
