 import { Box, Stack,} from '@mui/material'
import React,{useState,useEffect} from 'react'
import { SoftButton,StyledPaperShadow } from '../../theme/StyledEle'
import { FiPlus } from 'react-icons/fi'
import DrawerModel from '../../Utils/DrawerModel'
import { useNavigate } from 'react-router-dom'
import CreateFota from './CreateFota'
import FirmwareList from './GridView'
import { useGetFirmListMutation } from '../../store/slice/FotaSlice/FotaSlice'


function ListFota() {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;

  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [firmwareList, setFirmwareList] = useState([]);
  const [payload, setPayload] = useState(null);

const navigate = useNavigate();

 //Slice Mution
 const [ getFirmwareList,  {data:FirmwareListData, isLoading:FirmwareListLoading, isSuccess:FirmwareListSuccess}] = useGetFirmListMutation();

 let firmListPayload = {
 }

 const GetFirmware = async () => {
  setNewPage(0);
  setRowsPerPage(25);
  firmListPayload.page = 0;
  firmListPayload.size = 25;
  firmListPayload.search = "";
  firmListPayload.orgId = orgId;

  setPayload(firmListPayload);
  const GetFirmareResponse = await getFirmwareList(firmListPayload);
  if (
    GetFirmareResponse.data !== undefined &&
    GetFirmareResponse.data !== null
  ) {
    if (GetFirmareResponse.data.data.content !== null) {
      setFirmwareList(GetFirmareResponse.data.data.content);
      setTotalElements(GetFirmareResponse.data.data.totalElements);
      setTotalPages(GetFirmareResponse.data.data.totalPages);
    } else {
      setFirmwareList([]);
    }
  } else {
    setFirmwareList([]);
  }
};

//Onload Get Firmware List
useEffect(() => {
  GetFirmware();
}, []);


const GetFirmwareViaPayload = async (payload) => {
  const GetFirmareResponse = await getFirmwareList(payload);
  if (
    GetFirmareResponse.data !== undefined &&
    GetFirmareResponse.data !== null
  ) {
    if (GetFirmareResponse.data.content !== null) {
      setFirmwareList(GetFirmareResponse.data.content);
      setTotalElements(GetFirmareResponse.data.totalElements);
      setTotalPages(GetFirmareResponse.data.totalPages);
    } else {
      setFirmwareList([]);
    }
  } else {
    setFirmwareList([]);
  }
}

//handle pagination
const pageChange = async (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;

  GetFirmwareViaPayload(payload)

};


  //Search Firmware
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetFirmwareViaPayload(payload)
    } else if (payload.searchText.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetFirmwareViaPayload(payload)
    }
  };


  return (

    <Box>
        <StyledPaperShadow>
          <Stack direction={'row'} spacing={2} alignItems={'end'} justifyContent={'space-between'} sx={{width:'100%'}}>
          <Box>
          {/* <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
                          DROPDOWN
                      </SoftButton> */}
              </Box>
              <Box>
                  <Stack direction={'row'} spacing={1}>
                      <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
                          Create FOTA
                      </SoftButton>
                  </Stack>
              </Box>
          </Stack>
        </StyledPaperShadow>

        {/* Grid Start */}
        <StyledPaperShadow sx={{mt:2}}>
          <FirmwareList isLoading={FirmwareListLoading} data={firmwareList} totalElements={totalElements}
              totalPages={totalPages} newPage={newPage} rowsPerPage={rowsPerPage} pageChange={pageChange} handleSearch={handleSearch}/>
        </StyledPaperShadow>
        
        {/* Create Ticket */}
        <DrawerModel position={'right'} width={'35vw'} title={'Create FOTA'}>
            <CreateFota reload={GetFirmware}/>
        </DrawerModel>
        
    </Box>
  
  );
}

export default ListFota;
