import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Button,
  CardContent,
  CardMedia,
  Fab,
  Box,
  FormHelperText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import moment from "moment";
import DraggableDialog from "../shared/DailogDrag";
import customerKYCServices from "../../services/customerKYCService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, DatePicker } from "@mui/x-date-pickers";
import { Delete } from "@mui/icons-material";

import {
  StyledCardShadow,
  FileUploadBox,
  StyledDropDown,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTextField,
} from "../../theme/StyledEle";

import CommonDropDown from "./KycCreateDropDown.js";
import KycLogDropdown from "../LogDropdown/KYCDropdown.js";
import {
  useCreateKYCMutation,
  useGetVinMutation,
  useUpdateKycMutation,
} from "../../store/slice/LogsSlice/LogsSlice.js";
import StyledSnackBar from "../../Utils/StyledSnackBar.js";
import ScaleLoader from "react-spinners/ScaleLoader.js";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

export default function CreateKycForm({ kycToEdit, onCancel, tabValue }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [dealer, setDealer] = useState(null);
  const [error, setError] = useState(false);
  const [statusEdit, setStatusEdit] = useState(false);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [radioOptions, setRadioOptions] = React.useState([]);
  const [selectedGstIn, setSelectedGstIn] = useState([]);
  const [selectedFormVin, setSelectedFormVin] = useState([]);
  const [reason, setReason] = useState([]);
  const [disableState, setDisableState] = useState("");
  const shouldIncludeAllOption = true;
  const [selectedOrgType, setSelectedOrgType] = React.useState([]);
  const [clickedval, setClickedval] = React.useState(false);
  const [disDate, setDisDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");

  const profileRef = useRef();
  const aadharFRef = useRef();
  const aadharBRef = useRef();
  const lincenseFRef = useRef();
  const lincenseBRef = useRef();
  const invoiceRef = useRef();

  const [profile, setProfile] = useState(null);
  const [aadharF, setAadharF] = useState(null);
  const [aadharB, setAadharB] = useState(null);
  const [licenseF, setLicenseF] = useState(null);
  const [licenseB, setLicenseB] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [profile1, setProfile1] = useState("");
  const [aadharF1, setAadharF1] = useState("");
  const [aadharB1, setAadharB1] = useState("");
  const [licenseF1, setLicenseF1] = useState("");
  const [licenseB1, setLicenseB1] = useState("");
  const [invoice1, setInvoice1] = useState("");


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const {
    register,
    setValue,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "all" });

  const [dealerDetails, setDealerDetails] = useState(null);
  useEffect(async () => {
    if (kycToEdit) {
      setStatusEdit(true);
      const responseData = (await customerKYCServices.editKycById(kycToEdit.id))
        .data;
      setDealer(responseData);
      setSelectedFormVin(responseData.formData.formVin);
      setSelectedGstIn(responseData.formData.gstInNew);
      setDealerDetails(responseData?.formData);
      reset({
        gstInNew: responseData.formData.gstInNew,
        formCustomerName: responseData.formData.formCustomerName,
        formCustomerEmail: responseData.formData.formCustomerEmail,
        formCustomerMobile1: responseData.formData.formCustomerMobile1,
        formRetailInvoiceNumber: responseData.formData.formRetailInvoiceNumber,
        aadharNumber: responseData.formData.aadharNumber,
        licenseNumber: responseData.formData.licenseNumber,
        formCustomerAddress: responseData.formData.formCustomerAddress,
        vin: responseData.formData.formVin,
      });
      setSelectedGstIn(responseData.formData.gstInNew);
      setDisableState(responseData.formData.state);
      // setAadharF(responseData.formData.licenseFront);
      // setAadharB(responseData.formData.licenseFront);
      // setLicenseF(responseData.formData.licenseFront);
      // setLicenseB(responseData.formData.licenseFront);
      // setProfile(responseData.formData.licenseFront);
      // setInvoice(responseData.formData.licenseFront);


      setStatusOptions([
        { title: "Approve", value: "Approved" },
        { title: "Rejected", value: "Rejected" },
      ]);
      setRadioOptions([
        { label: "YES", value: "1" },
        { label: "NO", value: "0" },
      ]);
      setReason([
        {
          title: "Vehicle retail name & ID proof name are different",
          value: "0",
        },
        { title: "Blank/Blurred picture", value: "1" },
        { title: "Invalid ID proof", value: "2" },
        { title: "Incomplete ID proof", value: "3" },
        { title: "Pre-standard fitment case", value: "4" },
      ]);
      
    }
  }, [setDealer]);

  const handleCancelClick = () => {
    onCancel();
  };

  const conver2base64 = (file, id) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (id === 1) {
        setProfile1(reader.result.toString());
      }
      if (id === 2) {
        setAadharF1(reader.result.toString());
      }
      if (id === 3) {
        setAadharB1(reader.result.toString());
      }
      if (id === 4) {
        setLicenseF1(reader.result.toString());
      }
      if (id === 5) {
        setLicenseB1(reader.result.toString());
      }
      if (id === 6) {
        setInvoice1(reader.result.toString());
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadProfile = (e) => {
    const id = 1;
    setProfile(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleImageUploadadadharF = (e) => {
    const id = 2;
    setAadharF(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleImageUploadadadharB = (e) => {
    const id = 3;
    setAadharB(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleImageUploadlicenseF = (e) => {
    const id = 4;
    setLicenseF(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleImageUploadlicenseB = (e) => {
    const id = 5;
    setLicenseB(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleImageUploadInvoice = (e) => {
    const id = 6;
    setInvoice(URL.createObjectURL(e.target.files[0]));
    conver2base64(e.target.files[0], id);
  };

  const handleDisDateChange = (newValue) => {
    setDisDate(newValue);
    setValue("licenseDOB", moment(newValue).format("DD/MM/yyyy"));
  };

  //Slice Start
  const [
    getVin,
    { data: gstInData, isLoading: gstInLoading, isSuccess: gstInSuccess },
  ] = useGetVinMutation();
  const [
    saveKyc,
    { data: saveKycData, isLoading: saveKycLoading, isSuccess: saveKycSuccess },
  ] = useCreateKYCMutation();
  const [
    updateKyc,
    {
      data: updateKycData,
      isLoading: updateKycLoading,
      isSuccess: updateKycSuccess,
    },
  ] = useUpdateKycMutation();
  const [payload, setpayload] = useState(null);

  const getVforDealer = async (data) => {
    let Payload = {
      id: data.dealerId,
    };
    try {
      setSelectedGstIn(null);
      setSelectedFormVin(null);
      reset({ gstInNew: null, vin: null });
      const responceData = await getVin(Payload).unwrap();
      reset({ gstInNew: responceData[0].GST_IN, vin: data.vehicleVin });
      setSelectedGstIn(responceData[0].GST_IN);
      setSelectedFormVin(data.vehicleVin);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (data) => {
    if (!kycToEdit) {
      saveKycDetails(data);
    } else {
      updateKYCApproval(data);
      setDealerDetails(data);
    }
  };

  const getData = (data) => {
    setpayload(data);
    if (
      data.vehicleId !== undefined &&
      data.vehicleId !== null &&
      data.vehicleId !== "-1"
    ) {
      getVforDealer(data);
    } else {
      reset({ gstInNew: null, vin: null });
      setSelectedGstIn(null);
      setSelectedFormVin(null);
    }
  };

  //Save KYC
  const saveKycDetails = async (data) => {
    let finalData = {
      formVin: selectedFormVin,
      formCustomerName: data.formCustomerName,
      formCustomerEmail: data.formCustomerEmail,
      formCustomerMobile1: data.formCustomerMobile1,
      formRetailInvoiceNumber: data.formRetailInvoiceNumber,
      aadharNumber: data.aadharNumber,
      licenseNumber: data.licenseNumber,
      formCustomerAddress: data.formCustomerAddress,
      licenseDOB: data.licenseDOB,
      gstInNew: selectedGstIn,
      profileImg: profile1,
      formProofOfAddress: aadharF1,
      formProofOfIdentity: aadharB1,
      licenseFront: licenseF1,
      licenseBack: licenseB1,
      invoiceImg:invoice1,
    };
    const formData = new FormData();
    formData.append(`finaldata`,JSON.stringify(finalData))
    saveKyc(formData);
    setDealerDetails(formData);

    //const responseData = await customerKYCServices.saveKycDetails(formData);
    //

    var obj1 = {};
    obj1.dialogButtonText = "Saved Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Data Saved Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
  };

  //Update
  const updateKYCApproval = async (obj) => {
    // obj.id = kycToEdit;
    // obj.vin = assignedobj.formVin;
    // obj.customerName = assignedobj.formCustomerName;
    // obj.customerEmail = assignedobj.formCustomerEmail;
    // obj.customerMobile1 = assignedobj.formCustomerMobile1;
    // obj.customerId = assignedobj.formCustomerId;
    // obj.gstIn = assignedobj.gstInNew;

    //console.log('obj', obj);
    updateKyc(obj);
    //const responseData = await customerKYCServices.updateKYCApproval(obj);
    // var obj1 = {};
    // obj1.dialogButtonText = "Update Status";
    // obj1.submitButtonText = "";
    // obj1.closeButtonText = "Close";
    // obj1.dialogText = "Data Saved Successfully";
    // setDataStatus(true);
    // setDialogContent(obj1);
    // setDataStatusConfirm(false);
    // setOpen(true);
    // // if (responseData.status === 200) {
    // //   setTimeout(() => onCancel(), 2000);
    // // }
  };

  useEffect(() => {
    if (saveKycSuccess || updateKycSuccess) {

    }

    auditPayload.message = "Reports > KYC Log > Create KYC Form";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

  }, [updateKycSuccess]);

  return (
    <Box>
      {/* <CommonDropDown  handleSmt={handleGstSelect}/> */}
      <KycLogDropdown
        getData={getData}
        isShowAll={false}
        showCalendar={false}
        tabValue={tabValue}
        onload={false}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    GST IN
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.gstInNew && true}
                    size="small"
                    {...register("gstInNew", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    disabled={true}
                    helperText={errors.gstInNew && errors.gstInNew.message}
                    value={selectedGstIn}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>VIN</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.gstInNew && true}
                    size="small"
                    fullWidth
                    {...register("vin", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    disabled={true}
                    helperText={errors.vin && errors.gstInNew.message}
                    value={selectedFormVin}
                  />
                </StyledFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>Name</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.formCustomerName && true}
                    size="small"
                    fullWidth
                    {...register("formCustomerName", {
                      required: {
                        value: kycToEdit ? false : true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) =>
                      setValue("formCustomerName", e.target.value)
                    }
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.formCustomerName && errors.formCustomerName.message
                    }
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Email
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.formCustomerEmail && true}
                    size="small"
                    fullWidth
                    {...register("formCustomerEmail", {
                      required: {
                        value: kycToEdit ? false : true,
                        message: "Required Field ",
                      },
                      validate: {
                        matchPattren: (v) =>
                          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                            v
                          ) || "Email address must be a valid address",
                      },
                    })}
                    onChange={(e) =>
                      setValue("formCustomerEmail", e.target.value)
                    }
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.formCustomerEmail &&
                      errors.formCustomerEmail.message
                    }
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Mobile
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.formCustomerMobile1 && true}
                    size="small"
                    fullWidth
                    {...register("formCustomerMobile1", {
                      required: {
                        value: kycToEdit ? false : true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) =>
                      setValue("formCustomerMobile1", e.target.value)
                    }
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.formCustomerMobile1 &&
                      errors.formCustomerMobile1.message
                    }
                  />
                </StyledFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Retail Invoice Number
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.formRetailInvoiceNumber && true}
                    size="small"
                    fullWidth
                    {...register("formRetailInvoiceNumber", {
                      required: {
                        value: kycToEdit ? false : true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) =>
                      setValue("formRetailInvoiceNumber", e.target.value)
                    }
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.formRetailInvoiceNumber &&
                      errors.formRetailInvoiceNumber.message
                    }
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel>Aadhar Number</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.aadharNumber && true}
                    size="small"
                    fullWidth
                    {...register("aadharNumber", {
                      required: {
                        value:  false ,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("aadharNumber", e.target.value)}
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.aadharNumber && errors.aadharNumber.message
                    }
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel>
                    License Number
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.licenseNumber && true}
                    size="small"
                    fullWidth
                    {...register("licenseNumber", {
                      required: {
                        value: false ,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) => setValue("licenseNumber", e.target.value)}
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.licenseNumber && errors.licenseNumber.message
                    }
                  />
                </StyledFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: 0 }}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Address
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.formCustomerAddress && true}
                    size="small"
                    fullWidth
                    {...register("formCustomerAddress", {
                      required: {
                        value: kycToEdit ? false : true,
                        message: "Required Field ",
                      },
                    })}
                    onChange={(e) =>
                      setValue("formCustomerAddress", e.target.value)
                    }
                    disabled={kycToEdit != null ? true : clickedval}
                    helperText={
                      errors.formCustomerAddress &&
                      errors.formCustomerAddress.message
                    }
                  />
                </StyledFormControl>
              </Grid>

              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel>
                    Date Of Birth (As per License)
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={disDate}
                      onChange={handleDisDateChange}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          {...params}
                          error={errors.licenseDOB && true}
                          {...register("licenseDOB")}
                          disabled={kycToEdit != null ? true : clickedval}
                          helperText={
                            errors.licenseDOB && errors.licenseDOB.message
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item lg={2}>
                <StyledFormControlLabel name="profileImg">
                  Profile Image
                </StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && profile !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setProfile(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}

                  <input
                    type="file"
                    className="fileupload"
                    ref={profileRef}
                    onChange={(e) => handleImageUploadProfile(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      profile === null || dealerDetails?.profileImg === ""
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : profile
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={2}>
                <StyledFormControlLabel>Aadhar Front</StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && aadharF !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setAadharF(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    ref={aadharFRef}
                    onChange={(e) => handleImageUploadadadharF(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      aadharF === null || aadharF !== " "
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : aadharF
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={2}>
                <StyledFormControlLabel>Aadhar Back</StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && aadharB !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setAadharB(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    ref={aadharBRef}
                    onChange={(e) => handleImageUploadadadharB(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      aadharB === null
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : aadharB
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={2}>
                <StyledFormControlLabel>License Front</StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && licenseF !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setLicenseF(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    ref={lincenseFRef}
                    onChange={(e) => handleImageUploadlicenseF(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      licenseF === null || dealerDetails?.licenseFront === ""
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : licenseF
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              <Grid item lg={2}>
                <StyledFormControlLabel>License Back</StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && licenseB !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setLicenseB(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    ref={lincenseBRef}
                    onChange={(e) => handleImageUploadlicenseB(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      licenseB === null || dealerDetails?.licenseBack === ""
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : licenseB
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>

            <Grid item lg={2}>
                <StyledFormControlLabel>Invoice Image</StyledFormControlLabel>
                <FileUploadBox>
                  {kycToEdit === null && invoice !== null && (
                    <Fab
                      aria-label="deleteImage"
                      onClick={() => setInvoice(null)}
                      className="deleteIcon"
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: "-10px",
                        top: "-10px",
                      }}
                    >
                      {" "}
                      <Delete />
                    </Fab>
                  )}
                  <input
                    type="file"
                    className="fileupload"
                    ref={invoiceRef}
                    onChange={(e) => handleImageUploadInvoice(e)}
                    disabled={kycToEdit != null ? true : clickedval}
                  />
                  <CardMedia
                    component="img"
                    src={
                      invoice === null || dealerDetails?.invoiceImg === ""
                        ? "https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                        : invoice
                    }
                    sx={{ width: "65px", height: "65px" }}
                  />
                </FileUploadBox>
              </Grid>
              </Grid>
            {kycToEdit !== null && (
              <Grid container spacing={2} sx={{ mt: 0 }}>
                <Grid item lg={1}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Status
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.state && true}
                      {...register("state", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                      })}
                      onChange={(e) => {
                        setValue("state", e.target.value);
                        setSelectedStatus(e.target.value);
                      }}
                      defaultValue={
                        kycToEdit !== null
                          ? kycToEdit.state === "Pending"
                            ? "Approved"
                            : kycToEdit.state
                          : ""
                      }
                      size="small"
                    >
                      {statusOptions.length > 0 &&
                        statusOptions.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.value}>
                              {option.title}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>

                    {errors.state && (
                      <FormHelperText error={errors.state && true}>
                        {errors.state.message}
                      </FormHelperText>
                    )}
                  </StyledFormControl>
                </Grid>
                {selectedStatus === "Rejected" && (
                  <Grid item lg={3}>
                    <StyledFormControl fullWidth>
                      <StyledFormControlLabel required>
                        Rejected reason
                      </StyledFormControlLabel>
                      <StyledDropDown
                        error={errors.reason && true}
                        {...register("reason", {
                          required: {
                            value: true,
                            message: "Required Field",
                          },
                          validate: (fieldValue) => {
                            return fieldValue !== "6" || " Required Field";
                          },
                        })}
                        onChange={(e) => {
                          setValue("reason", e.target.value);
                        }}
                        defaultValue={reason[0].value}
                        size="small"
                      >
                        {reason.length > 0 &&
                          reason.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option.value}>
                                {option.title}
                              </MenuItem>
                            );
                          })}
                      </StyledDropDown>

                      {errors.reason && (
                        <FormHelperText error={errors.reason && true}>
                          {errors.reason.message}
                        </FormHelperText>
                      )}
                    </StyledFormControl>
                  </Grid>
                )}
              </Grid>
            )}
            <Stack direction={"row"} justifyContent={"flex-end"} spacing={2} sx={{mt:2}}>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button variant="contained" color="success" type="submit">
                {saveKycLoading ? (
                  <ScaleLoader color="#fff" height={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </CardContent>
        </StyledCardShadow>
      </form>
      {saveKycSuccess && (
        <StyledSnackBar
          message={saveKycData.resultMessage}
          status={saveKycData.resultText}
        />
      )}
    </Box>
  );
}
