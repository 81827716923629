import React, { useEffect, useRef, useState } from 'react';
import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItemButton,
  Button,
  Stack,
  Tab,
  Tabs,
  Select,
  Box, MenuItem
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import {
  SoftButton,
  StyledPaperShadow,
  StyledTab,
  StyledTabsGroup,
  StyledTextField,
  StyledSwitch, StyledFilterFormControl
} from "../../theme/StyledEle";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import dropdownServices from '../../services/dropdownServices';
import { useMapTypeMutation, useGetMapTypeMutation } from '../../store/slice/ApiSlice';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FaGoogle } from "react-icons/fa";
import { FaLeaf } from "react-icons/fa";

const MapType = (props) => {
  const { tabValue } = props;
  const [resellers, setResellers] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState("");
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [open, setOpen] = useState(false);

  const [saveMapType, { isLoading: saveMapTypeLoading, isSuccess: saveMapTypeSucccess }] = useMapTypeMutation();
  const [getMapType, { data: getMapSettingData, isLoading: getTMapTypeLoading, isSuccess: getMapTypeSucccess }] = useGetMapTypeMutation();

  const [alignment, setAlignment] = useState(null);

  const handleSaveButtonClick = () => {
    const mapType = alignment === 'google' ? 1 : 2;
    const payload = {
      id: selectedReseller,
      mapType: mapType,
    };
    saveMapType(payload);
    setDataStatusConfirm("Success");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  useEffect(() => {
    if (selectedReseller) {
      const payload = {
        id: selectedReseller,
      };
      getMapType(payload);
    }
  }, [selectedReseller]);

  useEffect(() => {
    if (getMapSettingData && getMapSettingData.data) {
      const mapType = getMapSettingData.data.mapType;
      if (mapType === 1) {
        setAlignment('google');
      } else if (mapType === 2) {
        setAlignment('leaflet');
      }
    }
  }, [getMapSettingData]);

  const fetchData = async () => {
    const resellerPayload = {
      value: "reseller",
      orgType: "2",
      resellerId: 1,
      dealerId: 0,
      roleId: 1,
      id: 1,
      searchText: "",
    };

    try {
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(resellerPayload);
      setResellers(orgTypeResponseNew.data?.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleResellerChange = (event) => {
    setSelectedReseller(event.target.value);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  return (
    <Grid item lg={4}>
      <TabPanel value={tabValue} key={5} index={5} className='tabsPanel'>
        <StyledPaperShadow sx={{ display: 'block' }}>
          <Typography gutterBottom sx={{ mb: 1 }}>Map Settings</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <StyledFilterFormControl size='small' sx={{ minWidth: '80%' }}>
                  <FormLabel required>Select Reseller</FormLabel>

                  <Select
                    value={selectedReseller}
                    onChange={handleResellerChange}
                    displayEmpty
                    fullWidth
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value="" disabled>
                      Select a Reseller
                    </MenuItem>
                    {resellers.map((reseller) => (
                      <MenuItem key={reseller.key} value={reseller.key}>
                        {reseller.value}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFilterFormControl>
              </Grid>

              <Grid item lg={12}>
                <Box>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="google" disabled={alignment === null}>
                      <span style={{paddingRight:"10px"}}>GoogleMap</span>  <FaGoogle />
                    </ToggleButton>
                    <ToggleButton value="leaflet" disabled={alignment === null}>
                    <span style={{paddingRight:"10px"}}>  LeafletMap </span> <FaLeaf />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item lg={12}>
                <Stack direction='row' sx={{ mt: 2, display: "flex", justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={handleSaveButtonClick}
                    disabled={!selectedReseller || alignment === null}
                  >
                    Update
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </StyledPaperShadow>
      </TabPanel>
      {open && (
        <StyledSnackBar message={"Map Uploaded successfully"} open={true} status={dataStatusConfirm} />
      )}
    </Grid>
  );
};

export default MapType;
