import { Backdrop, Box, Button, DialogActions, DialogContent, DialogTitle, SvgIcon, Typography, alpha, styled, useTheme } from '@mui/material'
import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Timeout } from '../../constants/DualIcons'
import { useNavigate } from 'react-router-dom'
import userServices from '../../services/userServices'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/slice/ControlsSlice'

function Sessionout() {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    var expire = localStorage.getItem("expjwToken");
    const userId = JSON.parse(localStorage.getItem("userData"))?.id;
    
    useEffect( async () => {
        let payload = {
            'userId':userId
        }
        const response = await userServices.saveLogout(payload);
        window.localStorage.setItem("expjwToken", null);
        window.localStorage.removeItem("jwToken");
        window.localStorage.removeItem("rshjwToken");
        window.localStorage.removeItem("userData");
        window.localStorage.removeItem("skip");
    },[])
    
    setTimeout(() => navigate('/'), 5000)
    
    const logoutNow = () => {
        window.localStorage.setItem("expjwToken", null);
        window.localStorage.removeItem("menuList");
        window.localStorage.removeItem("userData");
        window.localStorage.removeItem("jwToken");
        window.localStorage.removeItem("rshjwToken");
        window.localStorage.removeItem("skip");
        dispatch(logout());
        navigate('/');
    }
  return (
    <Dialog 
    open={true} 
    maxWidth='xs' 
    fullWidth
    BackdropComponent={	styled(Backdrop, { name: 'MuiModal', slot: 'Backdrop', overridesResolver: (props, styles) => { return styles.backdrop; }, })({ backdropFilter:'blur(3px)', background:alpha(theme.palette.background.paper, .2)})}
    >
        <DialogContent sx={{display:'flex',justifyContent:'center', flexDirection:'column', borderRadius:'15px'}}>
        <Box sx={{margin:'auto'}}>
            <Timeout />
        </Box>
        <Typography sx={{textAlign:'center'}} gutterBottom variant='h5'>Your session has expired</Typography>
        <Typography sx={{textAlign:'center'}} gutterBottom variant='body2'>
            You weren't clicking around any more, so we logged you out for your production. To get back in, just login again
        </Typography>
            <Button variant='contained' sx={{my:2}} onClick={logoutNow}>Login</Button>
        </DialogContent>
    </Dialog>
  )
}

export default Sessionout