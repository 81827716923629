import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Alert, Snackbar } from '@mui/material';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export default function DraggableDialog({open,dialogButtonText,dialogText,closeButtonText,submitButtonText,handleSbmtClose,handleClearCls}) {
    //const [open, setOpen] = React.useState(true);  
    
    const handleClose = () => {   
     handleClearCls();
    };
    const handleSubmitClose = () => {
        handleSbmtClose();    
      };    
    return (     
        <Snackbar
          open={open}
          onClose={handleClose}
          //PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          message={dialogText}
          autoHideDuration={2000}
          anchorOrigin={{  vertical: 'top', horizontal: 'center', }}
        />
          /* <Alert severity="success" >
            
          </Alert> */
        //   {/* <DialogTitle style={{ cursor: 'pointer' }} id="draggable-dialog-title">
        //     {dialogButtonText}
        //   </DialogTitle> */}
        //   {/* <DialogContent>
        //     <DialogContentText>
        //       {dialogText}
        //     </DialogContentText>
        //   </DialogContent> */}
        //   {/* <DialogActions>
        //     <Button autoFocus onClick={handleSubmitClose}>
        //     {submitButtonText}
        //     </Button>
        //     <Button onClick={handleClose}>{closeButtonText}</Button>
        //   </DialogActions> */}
        // {/* </Snackbar> */}
    );
  }