export const userLogAccessData = [
  {
    id: 1,
    key: "Portal",
    value: "Portal",
  },
  {
    id: 2,
    key: "Mobile",
    value: "Mobile",
  },
];

export const vinLogStatus = [
  {
    id: 1,
    key: "PROCESSED",
    value: "PROCESSED",
  },
  {
    id: 2,
    key: "UNPROCESSED",
    value: "UNPROCESSED",
  },
];

//Sent Log
export const alertModeDataMock = [
  {
    id: 1,
    key: "1",
    value: "Email",
  },
  {
    id: 2,
    key: "2",
    value: "SMS",
  },
  {
    id: 3,
    key: "3",
    value: "Push Notification",
  },
];

//VIN Log
export const SearchByVin = [
  {
    id: 1,
    key: "Date",
    value: "Date",
  },
  {
    id: 2,
    key: "IMEI",
    value: "IMEI",
  },
  {
    id: 3,
    key: "VIN",
    value: "VIN",
  },
  {
    id: 4,
    key: "All",
    value: "All",
  },
];

//Airtel Mockdata
export const actionData = [
  {
    id: 1,
    key: "activate",
    value: "Activate",
  },
  {
    id: 2,
    key: "Safe Custody",
    value: "Safe Custody",
  },
  {
    id: 3,
    key: "Out Of Safe Custody",
    value: "Out Of Safe Custody",
  },
  {
    id: 4,
    key: "Disconnect",
    value: "Disconnect",
  },
  {
    id: 5,
    key: "Resume from temp disconnect",
    value: "Resume from TD",
  },
  {
    id: 6,
    key: "Plan Change",
    value: "Plan Change",
  },
  {
    id: 7,
    key: "WhiteListing",
    value: "WhiteListing",
  },
];

export const searchByDataAirtel = [
  {
    id: 1,
    key: "1",
    value: "Date",
  },
];


export const typeStatus = [
  {
    id: 1,
    key: "1",
    value: "VIN-IMEI",
  },
  {
    id: 2,
    key: "2",
    value: "VIN-DEALER",
  },
  {
    id: 3,
    key: "3",
    value: "DEALER CREATION",
  },
];