import { Box, IconButton, InputBase, Paper, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { TbPlayerSkipBack, TbPlayerSkipForward } from 'react-icons/tb'
import {BiArrowToLeft, BiArrowToRight, BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi'
import { Button } from 'rsuite'

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props
    const [pageNum, setPageNum] = useState(page);


    //Next page
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    }
    //last page
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    //First page 
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    }
    
    //back Page
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    }
 
    useEffect(() => {
        setPageNum(page + 1)
    },[page])

    //goPage
    const goPage = (event) => {
        setPageNum(event.target.value);
        if(event.target.value !== '' && event.target.value > 0 && event.target.value !== Math.ceil(count/rowsPerPage)){
            if(event.target.value <= Math.ceil(count / rowsPerPage)){
                onPageChange(event, event.target.value - 1);
                setPageNum(event.target.value);
            }else{
                onPageChange(event, Math.ceil(count / rowsPerPage) - 1)
                setPageNum(Math.ceil(count / rowsPerPage))
            }   
        }
        // else{
        //     setPageNum(1)
        // }
        
    }

  return (
    <Box>
        <Stack direction={'row'} spacing={1.2} alignItems={'center'}>
            <Box>
                <Tooltip arrow title='First'>
                    <IconButton 
                    size='small'
                    disabled={page === 0}
                    onClick={handleFirstPageButtonClick}
                    ><BiArrowToLeft /></IconButton>
                </Tooltip>
            </Box>
            <Box>
                <Tooltip arrow title='Back'>
                <IconButton 
                size='small'
                disabled={page === 0}
                onClick={handleBackButtonClick}
                ><BiLeftArrowAlt /></IconButton>
                </Tooltip>
            </Box>
            <Box>
                <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                    <Typography variant='body2'>Page</Typography>
                    <InputBase value={pageNum} size='small' sx={{width:'80px',padding:'5px', background:theme.palette.background.paper, border:`.5px solid ${theme.palette.divider}`}} onChange={goPage} type='number' inputProps={{min:1}}/>
                    <Typography sx={{textWrap:'nowrap'}} variant='body2'>of {Math.ceil(count/rowsPerPage)} </Typography>
                </Stack>
            </Box>
            <Box>
                <Tooltip arrow title='Next'>
                <IconButton 
                size='small'
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                ><BiRightArrowAlt /></IconButton>
                </Tooltip>
            </Box>
            <Box>
                <Tooltip arrow title='Last'>
                <IconButton 
                size='small'
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                ><BiArrowToRight /></IconButton>
                </Tooltip>
            </Box>
        </Stack>
    </Box>
  )
}

export default TablePaginationActions