export const ActionMockData = [
    {
        id:0,
        value:'Select',
        key:0
    },
    {
        id:1,
        value:'Activate',
        key:1
    },
    {
        id:2,
        value:'Device Status',
        key:2
    },
    {
        id:3,
        value:'Lot Number',
        key:3
    },
    {
        id:4,
        value:'Device upload',
        key:4
    },
    {
        id:5,
        value:'Device Sim Map',
        key:5
    },
    {
        id:6,
        value:'Lot Update',
        key:6
    }
]

export const ActionMockDataSystem = [
    {
        id:0,
        value:'Select',
        key:0
    },
    {
        id:1,
        value:'Device Sim Map',
        key:1
    },
    {
        id:2,
        value:'Device upload',
        key:2
    },
    {
        id:3,
        value:'Device Move to Reseller',
        key:3
    },
   
   
   
]


export const ActionMockDataVendor = [
    {
        id:0,
        value:'Select',
        key:0
    },
    {
        id:1,
        value:'Device Sim Map',
        key:1
    },
    {
        id:2,
        value:'Device upload',
        key:2
    }

]

export const CplanMockData = [
    {
        id:1,
        key:'0',
        value:'None'
    },
    {
        id:2,
        key:'PKG_28684',
        value:'Plus'
    },
    {
        id:2,
        key:'PKG_28683',
        value:'Basic'
    },
]


export const TplanMockData = [
    {
        id:1,
        key:'0',
        value:'None'
    },
    {
        id:2,
        key:'PKG_36396',
        value:'Test'
    },
]