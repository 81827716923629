import React, { useMemo, useState, useEffect } from "react";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DataTable } from "../shared/data_table/DataTable";
import { CardHeader, CardContent, Button } from "@mui/material";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useGetServiceHistoryListMutation } from "../../store/slice/ReportSlice/ServiceSlaSlice";
import ReportServices from "../../services/reportServices";

import { Box } from "@mui/system";
import MouseOverPopover from "../shared/PopOver";
import {
    StyledCardShadow,
    StyledPageLayout,
    StyledTableTitle,
  } from "../../theme/StyledEle";

const ServiceHistoryList = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
  
    const text =  "This report shows various instances when the vehicle stayed in Dealer Workshop more than 4 hours.";
    
    const [getServiceDetailsList, { data: serviceDetails, isLoading, isSuccess }] =
    useGetServiceHistoryListMutation();

    const [responseData, setResponseData] = useState([]);
    const [newPage, setNewPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalElements, setTotalElements] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [auditLog] = useAuditLogMutation();


    let auditPayload = {
        orgId: orgId,
        userId: userId, 
        orgName: orgName, 
        userName: userName,
      };
      useEffect(async () => {
        auditPayload.message = "Reports > Service SLA";
        auditPayload.action = "View";
        auditPayload.featureName = "Service SLA";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
      }, []);

      let commonPayload = {};

    const onSubmitForm = (data) => {
        setNewPage(0);
        setRowsPerPage(25);
        commonPayload.resellerId = resellerId;
        commonPayload.regionId = data.regionId;
        commonPayload.dealerId = data.dealerId;
        commonPayload.customerId = data.customerId;
        commonPayload.orgId = data.fleetId;
        commonPayload.vehicleId = data.vehicleId;
        commonPayload.search = "";
        commonPayload.startDate = data.fromDate;
        commonPayload.endDate = data.toDate;
        commonPayload.sortBy = "desc";
        commonPayload.page = newPage;
        commonPayload.size = rowsPerPage;
        commonPayload.hourDiff=data.hourDiffFilter;
        commonPayload.start = newPage;
        commonPayload.length = rowsPerPage;

        //Service Call
        setPayload(commonPayload);
        getServiceDetailsList(commonPayload);

        auditPayload.message = "Reports > Service SLA > " + data.regionName + " > " + data.dealerName + " > " + data.customerName + " > " + data.fleetName + " > " + data.vehicleName + " > " + data.selectedPeriodName + "(" + data.fromDate + "-" + data.toDate + ")" + " > " + "Submit";
        auditPayload.action = "Submit";
        auditPayload.featureName = "Service SLA";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = userName;
        auditLog(auditPayload);
    };

    const [payload, setPayload] = useState(null);
    const onChangeXcl = async () => {
        try {
          var url =
            process.env.REACT_APP_API_BASE_URL +
            "service-sla/getServiceHistoryCSV?regionId=" +
            payload.regionId +
            "&resellerId=" +
            payload.resellerId +
            "&length=" +
            payload.length +
            "&start=" +
            payload.start +
            "&startDate=" +
            payload.startDate +
            "&endDate=" +
            payload.endDate +
            "&sortBy=" +
            payload.sortBy +
            "&dealerId=" +
            payload.dealerId +
            "&orgId=" +
            payload.orgId +
            "&vehicleId=" +
            payload.vehicleId +
            "&customerId=" +
            payload.customerId +
            "&hourDiff=" +
            payload.hourDiff;
          window.open(url);
    
          auditPayload.message = "Reports > Service SLA > XLS";
          auditPayload.action = "View";
          auditPayload.featureName = "Service SLA";
          auditPayload.customerName = "All";
          auditPayload.fleetName = "All";
          auditPayload.result = "Success";
          auditPayload.access = "Portal";
          auditPayload.severity = "INFO";
          auditLog(auditPayload);
    
          fetch(url, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
            },
          }) // FETCH BLOB FROM IT
            .then((response) => {
              response.blob();
            })
            .then((blob) => {})
            .catch((err) => {});
        } catch (error) {}
      };

    const columns = useMemo(
        () => [
            {
                Header: "Dealer",
                accessor: "dealerName",
            },
            {
                Header: "Service Center",
                accessor: "showroomName",
                width: 120,
            },
            {
                Header: "Vehicle",
                accessor: "vehicleNo",
                width: 120,
            },
            {
                Header: "Service Center Gps",
                accessor: "gpsLocation",
                width: 300,
                Cell: ({ row, column, cell }) => {
                    const refAddres = React.useRef(row);
                    var refLink = React.useRef(row);
                    var getAddressBtn = React.useRef(row);
                    var toggleAddressBtn = React.useRef(row);
                    const getAddress = async (e) => {
                        if (refAddres.current.innerText === "") {
                            var request = {};
                            request.latLng = e.target.value;
                            // rowid = e.target.id;
                            const responseData = (
                                await ReportServices.getGeocodeAddress(request)
                            ).data;
                            refAddres.current.innerText = responseData;
                            if (responseData !== "") {
                                refLink.current.style.display = "none";
                                refAddres.current.style.display = "block";
                                toggleAddressBtn.current.style.display = "block";
                                getAddressBtn.current.style.display = "none";
                                toggleAddressBtn.current.innerText = "LatLng";
                            }
                        }
                    };

                    //Address toggle
                    const ToggleAdderss = () => {
                        if (refAddres.current.style.display === "block") {
                            refAddres.current.style.display = "none";
                            refLink.current.style.display = "block";
                            toggleAddressBtn.current.innerText = "Address";
                        } else {
                            refAddres.current.style.display = "block";
                            refLink.current.style.display = "none";
                            toggleAddressBtn.current.innerText = "LatLng";
                        }
                    };
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box
                                className="addresslink"
                                id={row.original.gpsLocation}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Button
                                    onClick={(e) => getAddress(e)}
                                    value={row.original.gpsLocation}
                                    ref={getAddressBtn}
                                >
                                    Address
                                </Button>
                                <Button
                                    onClick={(e) => ToggleAdderss(e)}
                                    ref={toggleAddressBtn}
                                    sx={{ display: "none" }}
                                >
                                    LatLng
                                </Button>
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                                    target="_blank"
                                    className="latlink"
                                    ref={refLink}
                                    id={row.original.gpsLocation}
                                >
                                    {row.original.gpsLocation}
                                </a>
                                <a
                                    href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                                    target="_blank"
                                    className="address"
                                    ref={refAddres}
                                    id={row.original.gpsLocation}
                                    key={row.original.gpsLocation}
                                ></a>
                            </Box>
                        </Box>
                    );
                },
            },
            {
                Header: "In Time",
                accessor: "zoneInTime",
            },
            {
                Header: "Out Time",
                accessor: "zoneOutTime",
            },
            {
                Header: "Duration",
                accessor: "duration",
            },
           
        ],
        []
    );

    const handleSearch = async (searchText) => {
        payload.search = searchText;
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
        payload.start = 0;
        payload.length = 25;
        if (payload.search.length >= 3) {
            getServiceDetailsList(payload);
        } else if (payload.search.length === 0) {
            getServiceDetailsList(payload);
        }
    };
    //Page Change
    const pageChange = (newPage, rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        payload.size = rowsPerPage;
        if (totalElements < rowsPerPage * newPage) {
            setNewPage(0)
            payload.page = 0;
            payload.start = 0;
            getServiceDetailsList(payload);
        } else {
            setNewPage(newPage);
            payload.page = newPage;
            payload.start = newPage;
            getServiceDetailsList(payload);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            setResponseData([]);
            if (serviceDetails.data !== null) {
                const contentData = serviceDetails.data.content.map((e) => ({
                    ...e,
                    buttonEdit: ["edit", "delete"]
                }));
                setResponseData(contentData);
                setTotalElements(serviceDetails.data.totalElements);
                setTotalPages(serviceDetails.data.totalPages);
            }
        }
    }, [isSuccess]);

    return (
        <Box>
            <MouseOverPopover text={text}  />
            <FilterDropdown
                getData={onSubmitForm}
                status={false}
                allowDays={7}
                allowToday={true}
                setToday={true}
                showToday={true}
                isShowAll={true}
                last7={true}
                allowedMonth={3}
                serviceSlaHour={true}
            />
            <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
        </Box>
    );
};

export default ServiceHistoryList;