import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader, Divider, Paper, Stack, Typography } from "@mui/material";

import DriverService from "../../services/driverService";
import DraggableDialog from "../shared/DailogDrag";
import DropDown from "../shared/DropDown";
import { StyledCardShadow, StyledPaperShadow, StyledTableTitle } from '../../theme/StyledEle'

import CustomizedDialogs from "./DriverDialog";

const AssignDriver = ({ onEditDriver }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await fetchDriverDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Driver ID",
        accessor: "id",
      },
      {
        Header: "ID File",
        accessor: "vehicleId",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
      },
      {
        Header: "License Number",
        accessor: "licenseNumber",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditDriver(itemEdited.id);
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  const fetchDriverDetails = async () => {
    var request = {};
    request.resellerId = 10;
    request.orgId = 2;
    request.page = 0;
    request.size = 10;
    request.searchText = "";
    const responseData = (await DriverService.getGridViewForDriver(request))
      .data;
    setResponseData(responseData.data.content);
  };

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(true);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContentConfirm(obj);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await DriverService.deleteDriverById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
  };

  return (
    <Box>
      {/* <Paper sx={{ padding: "0.8em", marginTop: 3 }}>
        {/* <DropDown /> 
      </Paper> */}
      <StyledCardShadow elevation={0}>
        {/* <Stack
          direction="row"
          justifyContent='space-between'
          alignItems='center'
          sx={{position:'relative', p:1}}
        >
          <StyledTableTitle>Assign Drivers List</StyledTableTitle>
          <CustomizedDialogs />
        </Stack> */}
        <CardHeader 
          title={<StyledTableTitle>Assign Drivers List</StyledTableTitle>}
          action={<CustomizedDialogs />}
        />
        <CardContent sx={{py:0}}>
        <DataTable
          includeSearchBox={true}
          onEdit={onEdit}
          onDelete={onDelete}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
        />
        </CardContent>
        
      </StyledCardShadow>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
};

export default AssignDriver;
