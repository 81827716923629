import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import React, { useMemo, useState, useEffect, Suspense, lazy } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import deviceServices from "../../services/deviceService";
import {
  StyledPaperShadow,
  StyledTableTitle,
  StyledTimeLineCardRight,
  StyledTimeLineChipLeft,
  StyledTimeLineContentRight,
} from "../../theme/StyledEle";
import {
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";


export default function RecentActivity() {
  const [auditLog] = useAuditLogMutation();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    callbackChangePage(value);
  };

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const style1 = { color: "green" };
  const style2 = { color: "blue" };
  const style3 = { color: "red" };

  const [file, setFile] = useState([]);

  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;

  var userName = globalJson1.userName;
  var orgId = globalJson1.orgId;
  let userId = globalJson1.id;
  let orgName = globalJson1.orgName;

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  useEffect(async () => {
    await callbackLoadFn();

      auditPayload.message = "Admin > Device > Recent Activity";
      auditPayload.action = "View";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.orgId = orgId;
      auditPayload.userId = userId;
      auditPayload.userName = userName;
  
      auditLog(auditPayload);
  }, []);

  const callbackLoadFn = async () => {
    var request = {};
    request.page = newPage;
    request.size = rowsPerPage;
    request.simHistoryId = 0;
    request.orgId = orgId;

    const responseData = (await deviceServices.getDeviceHistory(request)).data
      .dataObj;
    setResponseData(responseData);
  };

  const callbackChangePage = async (value) => {
    var request = {};
    request.page = parseInt(value) - 1;
    request.size = rowsPerPage;
    request.simHistoryId = 0;
    request.orgId = orgId;

    const responseData = (await deviceServices.getDeviceHistory(request)).data
      .dataObj;
    setResponseData(responseData);
  };

  return (
    <StyledPaperShadow>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.1,
          },
        }}
      >
        {responseData.length > 0 &&
          responseData.map((item) => {
            return (
              <TimelineItem>
                <TimelineOppositeContent>
                  <Chip label={item.key} color="primary" size="small" />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <StyledTimeLineContentRight>
                  {item.obj.length > 0 &&
                    item.obj.map((det) => {
                      return (
                        <StyledTimeLineCardRight elevation={0}>
                          <Chip
                            label={det.action}
                            size="small"
                            color={
                              det.action === "create"
                                ? "success"
                                : det.action === "Update"
                                ? "info"
                                : "error"
                            }
                          />
                          <CardHeader
                            title={
                              <StyledTableTitle>{userName}</StyledTableTitle>
                            }
                            subheader={det.createdDate}
                            sx={{ py: 1, px: 0 }}
                          />
                          <CardContent sx={{ p: 0 }}>
                            <Typography variant="body2">
                              {det.description}
                            </Typography>
                          </CardContent>
                          {/* <Typography>{userName}</Typography> */}

                          {/* <Typography
                            sx={
                              det.action === "create"
                                ? style1
                                : det.action === "update"
                                ? style2
                                : style3
                            }
                          >
                            {det.action}
                          </Typography> */}
                          {/* <Typography>{det.createdDate}</Typography> */}
                        </StyledTimeLineCardRight>
                      );
                    })}
                </StyledTimeLineContentRight>
              </TimelineItem>
            );
          })}
      </Timeline>
      <Stack spacing={2}>
        <Typography>Page: {page}</Typography>
        <Pagination count={10} page={page} onChange={handleChange} />
        <p></p>
      </Stack>
    </StyledPaperShadow>
  );
}
