import { Box, FormLabel, Grid, Typography } from '@mui/material'
import React from 'react'
import { StyledFormControl } from '../../theme/StyledEle'

function ViewDealer({data}) {

  return (
    <Box>
        <Grid container spacing={2}>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Legal Name</FormLabel>
                    <Typography>{data.legalName !== null && data.legalName !== '' ? data.legalName : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>GST IN</FormLabel>
                    <Typography>{data.gstIn !== null && data.gstIn !== '' ? data.gstIn : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Trade Name</FormLabel>
                    <Typography>{data.tradeName !== null && data.tradeName !== '' ? data.tradeName : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Aadhar No</FormLabel>
                    <Typography>{data.aadharNo !== null && data.aadharNo !== '' ? data.aadharNo : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>PAN No</FormLabel>
                    <Typography>{data.panNo !== null && data.panNo !== '' ? data.panNo : '-'}</Typography>
                </StyledFormControl>
            </Grid>

            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Dealer Id</FormLabel>
                    <Typography>{data.dealerId !== null && data.dealerId !== '' ? data.dealerId : '-'}</Typography>
                </StyledFormControl>
            </Grid>

            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Mobile</FormLabel>
                    <Typography>{data.mobileNo !== null && data.mobileNo !== '' ? data.mobileNo : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Email ID</FormLabel>
                    <Typography>{data.email !== null && data.email !== '' ? data.email : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            

            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Region</FormLabel>
                    <Typography>{data.region !== null && data.region !== '' ? data.region : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Address</FormLabel>
                    <Typography>{data.address !== null && data.address !== '' ? data.address : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Country</FormLabel>
                    <Typography>{data.countryName !== null && data.countryName !== '' ? data.countryName : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>State/Province</FormLabel>
                    <Typography>{data.stateName !== null && data.stateName !== '' ? data.stateName : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>City</FormLabel>
                    <Typography>{data.panNo !== null && data.panNo !== '' ? data.panNo : '-'}</Typography>
                </StyledFormControl>
            </Grid>
            
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Pincode</FormLabel>
                    <Typography>{data.pinCode !== null && data.pinCode !== '' ? data.pinCode : '-'}</Typography>
                </StyledFormControl>
            </Grid>
          
            
            
           
            <Grid item lg={4}>
                <StyledFormControl>
                    <FormLabel>Status</FormLabel>
                    <Typography>{data.status !== null && data.status !== '' ? data.status : '-'}</Typography>
                </StyledFormControl>
            </Grid>
        </Grid>
    </Box>
  )
}

export default ViewDealer