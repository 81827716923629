import { BsFileEarmarkPdfFill } from "react-icons/bs"
import { RiFileExcel2Fill } from "react-icons/ri"

export const DashboardTabs = [
    {
        id:1,
        valaue:39,
        label:'LastConnected',
    },
    {
        id:2,
        valaue:40,
        label:'VehicleSummary',
    },
    {
        id:3,
        valaue:41,
        label:'BatteryDetails',
    },
    {
        id:4,
        valaue:42,
        label:'MotorProfile',
    }
]

export const ExportBtns = [
    {
        id:1,
        valaue:5,
        label:'Excel',
        role:'excel',
        icon:<RiFileExcel2Fill />,
        color:'success'
    },
    {
        id:2,
        valaue:6,
        label:'Pdf',
        role:'pdf',
        icon:<BsFileEarmarkPdfFill />,
        color:'error'
    }
]

export const FeedLogTabs = [
    {
        id:1,
        value:47,
        label:'Raw Feed',
    },
    {
        id:2,
        value:46,
        label:'Staging Feed',
    },
    {
        id:3,
        value:45,
        label:'Rejected Staging Feed',
    },
    {
        id:4,
        value:44,
        label:'Rejected Raw Feed',
    },
    {
        id:5,
        value:43,
        label:'Device Raw Feed',
    }
]

export const GeoZoneAction = [
    {
        id:1,
        value:1,
        label:'Create',
        color:'success'
    },
    {
        id:2,
        value:3,
        label:'Delete',
        color:'error'
    },
    {
        id:3,
        value:4,
        label:'Edit',
        color:'info'
    }
]


export const ChargingReportTabs = [
    {
        id:1,
        valaue:48,
        label:'Charging Report',
    },
    {
        id:2,
        valaue:49,
        label:'Charging Temperature Behaviour',
    },
    {
        id:3,
        valaue:50,
        label:'Discharging Temperature Behaviour',
    },
    {
        id:4,
        valaue:54,
        label:'Deep Discharge',
    }
    
    
]
    
export const vehicleLatestDetailTabs = [
    {
        id:1,
        valaue:51,
        label:'Vehicle Last Connected',
    },
    {
        id:2,
        valaue:52,
        label:'Voltage Difference',
    },
    {
        id:3,
        valaue:53,
        label:'Degradation',
    }
]
