import { Circle, DrawingManager, InfoWindow, Marker, Polygon } from '@react-google-maps/api'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import zoneMarker from '../../assets/images/geoZoneMarker.png'
import ZoneInfo from './InfoWindow';
import { useTheme } from '@mui/material';
function SelectTypeMarker(props) {
const { data, type, lat, lng, isEdit, zoneRadius, createMarkerData, singleMarker} = props;
let [drawingMode, setDrawingMode] = useState(null);
const [polyArray, setPolyArray] = useState([]);
let polygonRef = useRef();
const dragPoints = (event) => {
   let nre =  polygonRef.current
}

const theme = useTheme();

useEffect(() => {
    if(isEdit){
        setDrawingMode(true);
    }
},[isEdit])

  //Info Window
  const [ZoneData, setZoneData] = useState(null);

  const getMarkerData = (data) => {
    setZoneData(data);
  }

  const circleOptions = {
    fillColor:theme.palette.secondary.main,
    fillOpacity:.5,
    strokeWeight:1.2,
    strokeColor:theme.palette.secondary.main,
  }



    if(data !== null && data.zoneType === 'Circles'){
        return(
        <>
        <Marker 
        position={{lat:parseFloat(createMarkerData.lat), lng:parseFloat(createMarkerData.lng)}}
        animation={window.google.maps.Animation.DROP}
        icon={zoneMarker}
        clickable
        onClick={() => singleMarker(createMarkerData)}
        /> 
        <Circle
            radius={zoneRadius}
            center={{lat:parseFloat(createMarkerData.lat), lng:parseFloat(createMarkerData.lng)}}
            options={circleOptions}
        />  
        </>
        )
    }else{
    let polyFill = JSON.parse(data.cordinates);
    return(
        <>
        {
            !isEdit &&  <Polygon 
            paths={polyFill}
            ref={polygonRef}
            onClick={() => singleMarker(createMarkerData)}
            />
        }
       </>
    )
    }
}

export default SelectTypeMarker