import React, { useCallback, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, OutlinedInput, TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { StyledFormLabel } from "./StyledEle";
import SvgIcon from "@mui/material/SvgIcon";
import { BsDeviceSsdFill, BsCheckSquareFill } from "react-icons/bs";
import {
  FaCarAlt,
  FaUsers,
  FaCompass,
  FaExclamationCircle,
  FaBuilding,
  FaCalendar,
  FaWifi,
} from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import {
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFilterMenuItem,
  StyledFilterSearch,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import { AlertType, Alerts, Calender, Customer, Dealer, Fleet, IMEI, LastConnect, Org, Region, Status, Vehicle } from "../../constants/DualIcons";
import { maxWidth } from "@mui/system";

import { useLocation } from 'react-router-dom';

function MultipleSelect({
  label,
  options,
  onChange,
  optionsDisplayKey = "value",
  uniqueKey = "key",
  onSearch,
  isShow = true,
  fieldName,
  selValue = "",
  disabledKey,
  standardValue,
  ResellerId
}) {
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState(
    (selValue === ""
      ? options && options[0] && options[0][uniqueKey]
      : selValue) || -1
  );

  //console.log({selectedValue: options && options[0] && options[0][uniqueKey]})



const location = useLocation();

const [loc, setLoc] = useState(location.pathname);

  const handleSearch = (searchText) => {
        onSearch(searchText);
  };
 

  React.useEffect(() => {
    setSelectedValue(selValue === "" ? options[0]?.[uniqueKey] : selValue);
  }, [options]);
 
  useEffect(useCallback(() => {
    if(ResellerId === 113802){
      setSelectedValue(selValue === "" ? label === "Region" ? "1" : label === "Dealer" ? standardValue : options[0]?.[uniqueKey]: selValue);
    }
  }),[options])
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const theme = useTheme();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      elevation: 0,
      sx:{
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        overflowX: "hidden",
        padding:'0',
        borderRadius:'15px',
        boxShadow:'0px 2px 8px rgba(0,0,0,0.32)',
        //filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        marginTop:'10 px',
        minWidth:'150px !important',
        maxWidth:'220px',
        '&::-webkit-scrollbar':{
          width:'5px'
        },
        '&::-webkit-scrollbar-track':{
          background:theme.palette.grey[500]
        }
      },
    },
  }; 
  const handleChangeValue = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };


  return (
    <>
      {isShow && (
        <StyledFilterFormControl >
          <StyledFormControlLabel>{label}</StyledFormControlLabel>
          <StyledFilterDropDown
            //defaultValue={(options!==undefined && options.length>0)?(options[0] ? options[0][uniqueKey] : ''):""}
            className="icon"
            defaultValue={
              selValue === ""
                ? options !== null && options.length > 0
                  ? options[0][uniqueKey]
                  : -1
                : selValue
            }
            renderValue={(selected) => {
              const res = options.find((item) => item.key === selected);
              return (
                  <Box sx={{ display: "flex", alignItems:'center', gap:1}}>
                  <SvgIcon sx={{fontSize:'22px', color:theme.palette.mode === 'light' ?  theme.palette.secondary.main : theme.palette.grey[300]}}>
                    {
                      label === "Customer" ? <Customer /> : label === "Vehicle" ? <Vehicle/> : label === 'Fleet' ? <Fleet/> : label === 'Region' ? <Region/> : label === "Dealer" ? <Dealer /> : label === "Severity" ? <Alerts /> : label === "Organization" || label === "Organization Type" ? <Org /> : label === "IMEI" || label === "Device Model" ? <IMEI /> : label === "Device status" || label === "Sim status" || label === "Status" ? <Status /> : label === "Created Date" ? <Calender /> : label === "Last Connected" ? <LastConnect /> : label === "AlertType" ? <AlertType/> : ""
                    }
                  </SvgIcon>
                  {res?.value}
                </Box>
              );
            }}
            onChange={handleChangeValue}
            MenuProps={MenuProps}
            name={fieldName}
            onClose={() => setSearchText("")}
            value={selValue === "" ? selectedValue : selValue}
            size="small"
            disabled={disabledKey === true ? true : false}
            
          >
            <StyledFilterSearch
              //sx={searchDropdown}
              size="small"
              placeholder={`Search ${label}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ fontSize: "inherit" }} />
                  </InputAdornment>
                ),
              }}
              
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />

            {options.map((option) => (
              <StyledFilterMenuItem
                key={option[uniqueKey]}
                value={option[uniqueKey]}
                //sx={menuStyles}
                id={option[optionsDisplayKey]}
                noWrap
              >
                {option[optionsDisplayKey]}
              </StyledFilterMenuItem>
            ))}
          </StyledFilterDropDown>
        </StyledFilterFormControl>
      )}
    </>
  );
}
export default MultipleSelect;
