import React, { useState, useEffect, useRef } from "react";
import dropdownServices from "../../services/dropdownServices";
import {
  OutlinedInput,
  Divider,
  InputAdornment,
  ListItemText,
  ListItemButton,
  ClickAwayListener,
  TextField,
} from "@mui/material";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/styles";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker as RsuitePik, IconButton } from "rsuite";
import { DatePicker } from "rsuite";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { format, getMonth, set } from "date-fns";
import "../../components/vehicle/vehicle.css";
import {
  StyledFiterButton,
  StyledPaperShadow,
  StyledCalandar,
  StyledCalandarMenu,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import { FaCalendar } from "react-icons/fa";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import moment from "moment";
import { DatePicker as Anday, Space } from "antd";
import "antd/dist/antd.min.css";
import { useLocation } from "react-router-dom";
const CommonDropDown = ({
  onSubmit,
  isShowVeh,
  isIMEI,
  isSeverity,
  isStatus,
  displayDate,
  displayMonth,
  lastmonth,
  currentmonth,
  allowDays,
  allowToday,
  callbackLoadFn,
}) => {
  const theme = useTheme();
  const dropdownStyle = {
    //boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px",
  };
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;
  var selectedRoleId = null;
  var selectedVehicleId = null;

  const [selCreatedDate, setSelCreatedDate] = useState(0);
  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesImei, setVehiclesImei] = useState([]);

  const [status, setStatus] = useState([
    { key: -1, value: "All" },
    { key: 1, value: "Active" },
    { key: 0, value: "InActive" },
  ]);

  const [severity, setSeverity] = useState([
    { key: 0, value: "All" },
    { key: 1, value: "Critical" },
    { key: 2, value: "Non-Critical" },
    { key: 3, value: "Regular" },
  ]);

  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;

  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true; //TODO: add
  const [orgListJSON, setOrgListJSON] = useState([]);
  const [selectedDealerId, setSelectedDealerId] = useState("-1");
  const [selectedRegionId, setSelectedRegionId] = useState("-1");
  const [seltdResellerId, setSeltdResellerId] = useState("0");
  const [seltdCustomerId, setSeltdCustomerId] = useState("-1");
  const [seltdFleetId, setSeltdFleetId] = useState("-1");
  const [seltdVehId, setSeltdVehId] = useState("-1");
  const [seltdImei, setSeltdImei] = useState("-1");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedSeverity, setSelectedSeverity] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("0");
  const refFromDate = useRef("");
  const refToDate = useRef("");
  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customCal, setCustomCal] = useState(false);
  const [customMon, setCustomMon] = useState(false);
  const [severityVal, setSeverityVal] = useState(severity[0].key);
  const [statusVal, setStatusVal] = useState(severity[0].key);
  const [selectedPeriod, setSelectedPeriod] = useState(0);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCustomCal(false);
  };
  const handleCloseMon = () => {
    setCustomMon(false);
  };

  //Today
  const getToday = () => {
    setFromDate(format(new Date(), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
    handleClose();
    setSelCreatedDate(1);
    setSelectedPeriod(0);
  };

  const getYesderday = () => {
    setFromDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59");
    handleClose();
    setSelCreatedDate(2);
    setSelectedPeriod(1);
  };

  const getLast7day = () => {
    setFromDate(format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(subDays(new Date(), 1), "yyyy-MM-dd") + " 23:59:59");
    handleClose();
    setSelCreatedDate(3);
    setSelectedPeriod(7);
  };

  const getLastmonth = () => {
    setFromDate(
      moment()
        .subtract(1, "month")
        .startOf("month")
        .format("yyyy-MM-DD" + " 00:00:00")
    );
    setToDate(
      moment()
        .subtract(1, "month")
        .endOf("month")
        .format("yyyy-MM-DD" + " 23:59:59")
    );
    handleClose();
    setSelCreatedDate(3);
    setSelectedPeriod(3);
  };

  const getCurrentmonth = () => {
    setFromDate(
      moment()
        .startOf("month")
        .format("yyyy-MM-DD" + " 00:00:00")
    );
    setToDate(moment(new Date()).format("yyyy-MM-DD h:mm:ss"));
    handleClose();
    setSelCreatedDate(3);
    setSelectedPeriod(2);
    // console.log("getCurrentmonth=>",selectedRegionId);
  };

  const customRage = () => {
    //setDate('Custom');
    handleClose();
    setCustomCal(true);
    setSelCreatedDate(4);
    setSelectedPeriod(9);
  };

  const customMonthRange = (event) => {
    setCustomMon(true);
  };
  useEffect(() => {
    getYesderday();
  }, []);

  const selectDropdown = {
    "&.MuiInputBase-root": { width: "320px" },
  };

  const refDisplay = { display: "none" };

  const getResellerId = (listOfResellers) => {
    return listOfResellers[0].key;
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };
  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
  }

  useEffect(async () => {
    setRegions(getOptions);
    setDealers(getOptions);
    setCustomers(getOptions);
    setFleet(getOptions);
    isShowVeh === true && setVehicles(getOptions);

    switch (globalOrgType) {
      case 8:
        if (globaldisplayview !== false) {
          const resellerPayload = {
            value: "reseller",
            orgType: "2",
            resellerId: globalOrgId,
            dealerId: 0,
            roleId: globalRoleId,
            id: globalOrgId,
            searchText: "",
          };
          const { data: resellerResponseNew } =
            await dropdownServices.getResellers(resellerPayload);
          const { content: orgnListJSON } = resellerResponseNew.data;

          setOrgListJSON(orgnListJSON);

          setSeltdResellerId(
            orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1"
          );
          regionList();
        }
        break;
      case 2:
        setSeltdResellerId(globalOrgId);
        // setSeltdLastOrgId(globalOrgId);
        if (globaldisplayview === false) {
          const customersListPayload = {
            value: "customer",
            resellerId: globalResellerId,
            dealerId: 0,
            searchText: "",
          };
          const { data: customerListResponse } =
            await dropdownServices.getResellers(customersListPayload);
          const { content: customersList } = customerListResponse.data;
          const customerAll = getOptions(customersList);
          setCustomers([]);
          setCustomers(customerAll);
          setSeltdCustomerId(
            customerAll.length > 0 ? customerAll[0].key : "-1"
          );
        } else {
          regionList();
        }

        break;

      case 5: // login as Dealer
        // customer call

        setSelectedDealerId(globalOrgId);

        const customersListPayload = {
          value: "customer",
          id: globalResellerId,
          resellerId: globalResellerId,
          dealerId: globalOrgId,
          searchText: "",
        };
        const { data: customerListResponse } =
          await dropdownServices.getResellers(customersListPayload);
        const { content: customersList } = customerListResponse.data;
        // const customerAll = getOptions(customersList);
        setCustomers([]);
        setCustomers(customersList);
        setSeltdCustomerId(
          customersList.length > 0 ? customersList[0].key : "-1"
        );
        handleCustomerSelect(
          customersList.length > 0 ? customersList[0].key : "-1"
        );
        callbackLoadFn(customersList.length > 0 ? customersList[0].key : "-1");
        break;
      case 3: // login as customer
        // fleet call

        setSeltdCustomerId(globalOrgId);
        const fleetListPayload = {
          value: "fleet",
          customerId: globalOrgId,
          resellerId: globalResellerId,

          dealerId: 0,
          searchText: "",
        };
        const { data: fleetListResp } = await dropdownServices.getResellers(
          fleetListPayload
        );
        const { content: fleetListResponse } = fleetListResp.data;

        // const fleets = getOptions(fleetListResponse);
        setFleet([]);
        setFleet(fleetListResponse);
        setSeltdFleetId(
          fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
        );

        handleFleetSelect(
          fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
        );
        callbackLoadFn(
          fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
        );
        break;
      case 1: // login as fleet
        // vehicle call

        setSeltdFleetId(globalOrgId);
        const vehicleListPayload = {
          value: "orgId",
          paramValue: globalOrgId,
          searchText: "",
        };
        const { data: vehicleListResponse } =
          await dropdownServices.getFleetList(vehicleListPayload);
        const { content: vehicleList } = vehicleListResponse.data;

        setVehicles([]);
        setVehicles(vehicleList);
        setSeltdVehId(vehicleList.length > 0 ? vehicleList[0].key : "-1");

        var data1 = [];
        for (let i = 0; i < vehicleList.length; i++) {
          var obj = {};
          obj.value = vehicleList[i].refVal;
          obj.key = vehicleList[i].refVal;
          data1.push(obj);
        }
        setVehiclesImei(data1);
        setSeltdImei(data1.length > 0 ? data1[0].key : "-1");
    }
  }, []);

  const regionList = async () => {
    const regionListPayload = {
      value: "region",
      searchText: "",
    };
    const { data: regionResponseBody } = await dropdownServices.getResellers(
      regionListPayload
    );
    const { content: regionList } = regionResponseBody.data;
    const _regions = getOptions(regionList);
    setRegions([]);
    setRegions(_regions);
    // console.log("dgdfgfg=>",_regions,_regions[1]?.key);
    setSelectedRegionId(globalResellerId == 113802 ? _regions[1]?.key : -1);
    handleRegionSelect(globalResellerId == 113802 ? _regions[1]?.key : -1);
  };

  const handleResellerSelect = async (resellerId) => {
    setSeltdResellerId(resellerId);
  };

  const handleRegionSelect = async (regionId) => {
    setSelectedRegionId(regionId);
    if (regionId !== -1) {
      const dealerListPayload = {
        value: "dealer",
        resellerId:
          userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
        dealerId: 0,
        searchText: "",
        regionId: regionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealers([]);
      const _dealers = getOptions(dealerList);
      setDealers(_dealers);

      setSelectedRegionId(regionId);
      setSelectedDealerId(_dealers[0].key);
    } else {
      setDealers([]);
      setDealers(getOptions);
      setSelectedDealerId(-1);
    }
    handleDealerSelect(globalResellerId === 113802 ? 113806 : -1);
  };

  // useEffect(() => {
  //   setSelectedRegionId("-1");
  //   handleRegionSelect("-1");
  // },[])

  const handleDealerSelect = async (dealerId) => {
    setSelectedDealerId(dealerId);

    if (dealerId !== -1) {
      const customersListPayload = {
        value: "customer",
        orgType: "3",
        id: userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
        resellerId:
          userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
        dealerId: dealerId,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
    } else {
      setCustomers([]);
      setCustomers(getOptions);
      setSeltdCustomerId(-1);
    }
    handleCustomerSelect(-1);
  };

  var globalDisplayView = 0;

  const handleCustomerSelect = async (customerId) => {
    setSeltdCustomerId(customerId);
    selectedCustomerId = customerId;
    if (customerId !== -1) {
      const fleetListPayload = {
        value: "fleet",
        customerId: customerId,
        resellerId:
          userData.userOrgType === 1 ? seltdResellerId : globalResellerId,

        dealerId: globalOrgType === 5 ? globalOrgId : selectedDealerId,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;

      const fleets = getOptions(fleetListResponse);

      setFleet(fleets);

      setSeltdFleetId(-1);
    } else {
      setFleet([]);
      setFleet(getOptions);
      setSeltdFleetId(-1);
    }

    handleFleetSelect(-1);
  };

  const handleFleetSelect = async (fleetId) => {
    setSeltdFleetId(fleetId);
    if (fleetId !== -1) {
      if (isShowVeh === true) {
        const vehicleListPayload = {
          value: "orgId",
          paramValue: fleetId,
          searchText: "",
        };
        const { data: vehicleListResponse } =
          await dropdownServices.getFleetList(vehicleListPayload);
        const { content: vehicleList } = vehicleListResponse.data;

        setVehicles([]);
        setVehicles(getOptions(vehicleList));
        setSeltdVehId(-1);

        var data1 = [];
        for (let i = 0; i < vehicleList.length; i++) {
          var obj = {};
          obj.value = vehicleList[i].refVal;
          obj.key = vehicleList[i].refVal;
          data1.push(obj);
        }
        setVehiclesImei(getOptions(data1));
        setSeltdImei(data1.length > 0 ? data1[0].key : "-1");
      }
    } else {
      setVehicles([]);
      setVehicles(getOptions);
      setSeltdVehId(-1);
    }
  };

  const handleVehicleSelect = (vehicleId) => {
    setSeltdVehId(vehicleId);
    var selImei = -1;
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].key === vehicleId) {
        selImei = vehicles[i].refVal;
        break;
      }
    }
    setSeltdImei(selImei);
    // {
    //   vehicleId === -1 ? getYesderday() : getToday();
    // }
  };

  const handleIMEISelect = (imei) => {
    //setSeltdImei(imei);
    var selImei = -1;
    var selVeh = -1;
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].refVal === imei) {
        selImei = vehicles[i].refVal;
        selVeh = vehicles[i].key;
        break;
      }
    }
    setSeltdImei(selImei);
    setSeltdVehId(selVeh);
  };

  const handleSeveritySelect = (severityId) => {
    setSelectedSeverity(severityId);
    setSeverityVal(severityId);
  };

  const handleStatusSelect = (statusId) => {
    setSelectedStatus(statusId);
    setStatusVal(statusId);
  };

  const handleRegionSearch = async (searchText) => {
    if (searchText.length > 1 || searchText == "") {
      const regionListPayload = {
        value: "region",
        resellerId: seltdResellerId,
        searchText: searchText,
        dealerId: selectedDealerId,
      };
      const { data: regionListResponse } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionListResponse.data;
      setRegions(getOptions(regionList));
    }
  };

  const handleCustomerSearch = async (searchText) => {
    if (searchText.length > 1 || searchText == "") {
      const customersListPayload = {
        value: "customer",
        resellerId: seltdResellerId,
        dealerId: selectedDealerId,
        searchText,
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(getOptions(customersList));
    }
  };

  const handleDealerSearch = async (searchText) => {
    if (searchText.length > 1 || searchText == "") {
      const dealerListPayload = {
        value: "dealer",
        resellerId: seltdResellerId,
        searchText: searchText,
        regionId: selectedRegionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealers(getOptions(dealerList));
    }
  };

  const handleFleetSearch = async (searchText) => {
    if (searchText.length > 1) {
      const fleetListPayload = {
        value: "fleet",
        resellerId: seltdResellerId,
        dealerId: 0,
        customerId: seltdCustomerId,
        searchText: searchText,
      };
      const { data: fleetListResponse } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetList } = fleetListResponse.data;
      setFleet(getOptions(fleetList));
    }
  };

  const handleVehicleSearch = async (searchText) => {
    if (searchText.length > 1 || searchText == "") {
      const vehicleListPayload = {
        value: "vehicle",
        paramValue: seltdFleetId,
        resellerId: selectedResellerId,
        dealerId: 0,
        customerId: selectedCustomerId,
        fleetId: seltdFleetId,
        searchText: searchText,
      };
      const { data: vehicleListResponse } = await dropdownServices.getFleetList(
        vehicleListPayload
      );
      const { content: vehicleList } = vehicleListResponse.data;
      setVehicles(getOptions(vehicleList));
    }
  };

  const handleResellerSearch = async (searchText) => {
    if (searchText.length > 2) {
      const resellersListPayload = {
        value: "reseller",
        orgType: userData.userOrgType,
        roleId: userData.roleId,
        id: userData.resellerId,
        searchText,
      };
      const { data: responseBody } = await dropdownServices.getResellers(
        resellersListPayload
      );
      const { content: resellersList } = responseBody.data;
      setResellers(resellersList);
      setOrgListJSON(resellersList);
    }
  };

  const handleIMEISearch = () => {};
  const handleCustomerAssignedSearch = () => {};
  const handleCreatedDate = () => {};

  const newHandleSubmit = (e) => {
    e.preventDefault();
    // console.log("handlesubmit=>",selectedRegionId);
    onSubmit({
      reseller: seltdResellerId,
      region: selectedRegionId,
      dealer: selectedDealerId,
      customer: seltdCustomerId,
      fleet: seltdFleetId,
      vehicle: seltdVehId,
      from_date: fromDate,
      to_date: toDate,
      severityvalue: selectedSeverity,
      statusvalue: selectedStatus,
      selectedPeriod: selectedPeriod,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { target } = e;

    var commondata = {};
    commondata = Object.fromEntries(new FormData(target));

    var startDate;
    var endDate;

    if (
      refFromDate.current.innerHTML === undefined ||
      "" === refFromDate.current.innerHTML
    ) {
      startDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      endDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    } else {
      startDate = refFromDate.current.innerHTML;
      endDate = refToDate.current.innerHTML;
    }
  };

  const clickVal = (e) => {
    setFromDate(format(e[0], "yyyy-MM-dd HH:mm:ss"));
    setToDate(format(e[1], "yyyy-MM-dd HH:mm:ss"));
    handleClose();
  };
  const { afterToday, allowedRange, allowedMaxDays, combine } = RsuitePik;
  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 3));
  const clickValMon = (e) => {
    setFromDate(format(e[0], "yyyy-MM-dd HH:mm:ss"));
    setToDate(format(e[1], "yyyy-MM-dd HH:mm:ss"));

    if (getMonth(e[1]) === getMonth(new Date())) {
      setFromDate(format(e[0], "yyyy-MM-dd HH:mm:ss"));
      setToDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
    }
    setCustomMon(false);
  };

  //get month
  const [month, setMonth] = useState([]);
  const [monthLabel, setMonthLabel] = useState(
    moment().startOf("month").format("DD-MM-YYYY") ===
      moment().format("DD-MM-YYYY")
      ? moment().subtract(1, "month").format("MMMM-YYYY")
      : moment().format("MMMM-YYYY")
  );

  const getMonthCal = (e) => {
    setMonthLabel(moment(e).format("MMMM YYYY"));
    //moment().format(e, 'YYYY MM')
    setFromDate(moment(e).startOf("month").format("yyyy-MM-DD 00:00:00"));
    if (moment().format("MMMM") === moment(e).format("MMMM")) {
      setToDate(moment().format("yyyy-MM-DD HH:mm:ss"));
    } else {
      setToDate(moment(e).endOf("month").format("yyyy-MM-DD 23:59:59"));
    }
    setCustomMon(false);
  };

  const Locale = {
    1: "Jan",
    2: "Feb",
  };

  const disabledDate = (current) => {
    return (
      current &&
      current > moment().endOf("day") ===
        current > moment().subtract(3, "month")
    );
  };

  const selectMonth = (date) => {
    // console.log('date', date);
    setMonthLabel(moment(date).format("MMMM YYYY"));
    setFromDate(moment(date).startOf("month").format("yyyy-MM-DD 00:00:00"));
    if (moment().format("MMMM") === moment(date).format("MMMM")) {
      setToDate(moment().format("yyyy-MM-DD HH:mm:ss"));
    } else {
      setToDate(moment(date).endOf("month").format("yyyy-MM-DD 23:59:59"));
    }
    setCustomMon(false);
  };

  useEffect(() => {
    if (seltdVehId === -1) {
      setFromDate(moment().startOf("month").format("yyyy-MM-DD 00:00:00"));
      setFromDate(
        moment()
          .subtract(1, "days")
          .format("yyyy-MM-DD" + " " + "00:00:00")
      );
      setToDate(
        moment()
          .subtract(1, "days")
          .format("yyyy-MM-DD" + " " + "23:59:59")
      );
    }
  }, [seltdVehId]);

  return (
    <form>
      <StyledPaperShadow elevation={0}>
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={
            globalOrgType === 8 && globaldisplayview === true ? true : false
          }
          fieldName="Organization"
        />
        <DropDown
          label="Region"
          options={regions}
          onChange={handleRegionSelect}
          optionsDisplayKey="value"
          isShow={
            (globalOrgType === 8 || globalOrgType === 2) &&
            globaldisplayview === true
              ? true
              : false
          }
          onSearch={handleRegionSearch}
          fieldName={"regionId"}
          standardValue={"1"}
          ResellerId={globalResellerId}
        />
        <DropDown
          label="Dealer"
          options={dealers}
          optionsDisplayKey="value"
          isShow={
            (globalOrgType === 8 || globalOrgType === 2) &&
            globaldisplayview === true
              ? true
              : false
          }
          onChange={handleDealerSelect}
          fieldName={"dealerId"}
          onSearch={handleDealerSearch}
          standardValue={"113806"}
          ResellerId={globalResellerId}
        />
        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={
            globalOrgType === 8 || globalOrgType === 2 || globalOrgType === 5
              ? true
              : false
          }
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Fleet"
          options={fleet}
          onChange={handleFleetSelect}
          optionsDisplayKey="value"
          isShow={
            globalOrgType === 8 ||
            globalOrgType === 2 ||
            globalOrgType === 5 ||
            globalOrgType === 3
              ? true
              : false
          }
          onSearch={handleFleetSearch}
        />
        <DropDown
          label="Vehicle"
          options={vehicles}
          onChange={handleVehicleSelect}
          optionsDisplayKey="value"
          onSearch={handleVehicleSearch}
          isShow={typeof isShowVeh !== "undefined" ? isShowVeh : false}
          selValue={seltdVehId}
        />
        <DropDown
          label="IMEI"
          options={vehiclesImei}
          onChange={handleIMEISelect}
          optionsDisplayKey="value"
          isShow={typeof isIMEI !== "undefined" ? isIMEI : false}
          onSearch={handleIMEISearch}
          selValue={seltdImei}
        />
        <DropDown
          label="Severity"
          options={severity}
          onChange={handleSeveritySelect}
          optionsDisplayKey="value"
          isShow={typeof isSeverity !== "undefined" ? isSeverity : false}
          onSearch={handleIMEISearch}
          selValue={severityVal}
        />
        <DropDown
          label="Status"
          options={status}
          onChange={handleStatusSelect}
          optionsDisplayKey="value"
          isShow={typeof isStatus !== "undefined" ? isStatus : false}
          onSearch={handleIMEISearch}
          selValue={statusVal}
        />
        {displayMonth ? (
          <>
            <ClickAwayListener onClickAway={handleCloseMon}>
              <StyledCalandar elevation={0}>
                <StyledFormControlLabel>Select Month</StyledFormControlLabel>
                <OutlinedInput
                  size="small"
                  onClick={customMonthRange}
                  endAdornment={
                    <InputAdornment>
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={customMonthRange}
                      >
                        <FaCalendar />
                      </IconButton>
                    </InputAdornment>
                  }
                  //value={moment(toDate).format("MMMM YYYY")}
                  value={monthLabel}
                />
                <Anday
                  picker="month"
                  disabledDate={disabledDate}
                  open={customMon}
                  onChange={selectMonth}
                  defaultValue={
                    moment().startOf("month").format("DD-MM-YYYY") ===
                    moment().format("DD-MM-YYYY")
                      ? moment().subtract(1, "days")
                      : moment()
                  }
                />
                {/* <DatePicker to
                showOneCalendar
                disabledDate={combine(allowedRange(moment().subtract(3, 'month'), moment().toDate()), allowedMaxDays(1))}
                placement="bottomEnd"
                limitStartYear={0}
                limitEndYear={1}
                className="customCal"
                preventOverflow={true}
                open={customMon}
                //defaultValue={monthLabel}
                size="xs"
                format="yyyy-MM"
                onOk={(e) => getMonthCal(e)}
              /> */}
              </StyledCalandar>
            </ClickAwayListener>
            <Space></Space>
          </>
        ) : (
          <StyledCalandar elevation={0}>
            <StyledFormControlLabel>Date</StyledFormControlLabel>
            <OutlinedInput
              size="small"
              sx={
                fromDate === format(new Date(), "yyyy-MM-dd") + " 00:00:00"
                  ? ""
                  : fromDate ===
                    format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00"
                  ? ""
                  : fromDate ===
                    format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
                  ? ""
                  : fromDate ===
                    moment()
                      .subtract(1, "month")
                      .startOf("month")
                      .format("yyyy-MM-DD" + " 00:00:00")
                  ? ""
                  : fromDate ===
                    moment()
                      .startOf("month")
                      .format("yyyy-MM-DD" + " 00:00:00")
                  ? ""
                  : selectDropdown
              }
              onClick={handleClick}
              endAdornment={
                <InputAdornment>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <FaCalendar />
                  </IconButton>
                </InputAdornment>
              }
              value={
                fromDate === format(new Date(), "yyyy-MM-dd") + " 00:00:00"
                  ? "Today"
                  : fromDate ===
                      moment()
                        .subtract(1, "days")
                        .format("yyyy-MM-DD" + " " + "00:00:00") &&
                    toDate ===
                      moment()
                        .subtract(1, "days")
                        .format("yyyy-MM-DD" + " " + "23:59:59")
                  ? "Yesterday"
                  : fromDate ===
                    format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
                  ? "Last 7 Days"
                  : fromDate ===
                      moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("yyyy-MM-DD" + " 00:00:00") &&
                    toDate ===
                      moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("yyyy-MM-DD" + " 23:59:59")
                  ? "Last Month"
                  : moment(fromDate).format("yyyy-MM-DD" + " 00:00") ===
                      moment()
                        .startOf("month")
                        .format("yyyy-MM-DD" + " 00:00") &&
                    moment(toDate).format("yyyy-MM-DD h:mm") ===
                      moment(new Date()).format("yyyy-MM-DD h:mm")
                  ? "This Month"
                  : `${moment(fromDate).format(
                      "DD-MM-YYYY 00:00:00"
                    )} - ${moment(toDate).format("DD-MM-YYYY HH:mm:ss")}`
              }
            />
            <RsuitePik
              format="dd-MM-yyyy HH:mm:ss"
              disabledDate={combine(
                allowedMaxDays(allowDays),
                allowedRange(last3months, moment().subtract(1, "days"))
              )}
              placeholder="Select Date Range"
              defaultCalendarValue={[
                new Date(
                  moment().subtract(1, "days").format("YYYY-MM-DD, 00:00:00")
                ),
                new Date(
                  moment().subtract(1, "days").format("YYYY-MM-DD, 23:59:59")
                ),
              ]}
              onChange={(e) => {
                clickVal(e);
              }}
              placement="bottomEnd"
              className="customCal"
              preventOverflow={true}
              open={customCal}
              onOk={(e) => clickVal(e)}
              size="xs"
            />

            <StyledCalandarMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {seltdVehId !== -1 && (
                <ListItemButton
                  onClick={getToday}
                  dense={true}
                  selected={
                    fromDate ===
                      format(new Date(), "yyyy-MM-dd") + " 00:00:00" && true
                  }
                >
                  <ListItemText>Today</ListItemText>
                </ListItemButton>
              )}
              <ListItemButton
                onClick={getYesderday}
                dense={true}
                selected={
                  fromDate ===
                    moment()
                      .subtract(1, "days")
                      .format("yyyy-MM-DD" + " " + "00:00:00") &&
                  toDate ===
                    moment()
                      .subtract(1, "days")
                      .format("yyyy-MM-DD" + " " + "23:59:59") &&
                  true
                }
              >
                <ListItemText>Yesterday</ListItemText>
              </ListItemButton>
              {displayDate && (
                <ListItemButton
                  onClick={getLast7day}
                  dense={true}
                  selected={
                    fromDate ===
                    format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
                  }
                >
                  <ListItemText>Last 7 Days</ListItemText>
                </ListItemButton>
              )}

              {lastmonth && (
                <ListItemButton
                  onClick={getLastmonth}
                  dense={true}
                  selected={
                    fromDate ===
                      moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("yyyy-MM-DD" + " 00:00:00") &&
                    toDate ===
                      moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("yyyy-MM-DD" + " 23:59:59")
                  }
                >
                  <ListItemText>Last Month</ListItemText>
                </ListItemButton>
              )}

              {currentmonth && (
                <ListItemButton
                  onClick={getCurrentmonth}
                  dense={true}
                  selected={
                    moment(fromDate).format("yyyy-MM-DD" + "00:00") ===
                      moment()
                        .startOf("month")
                        .format("yyyy-MM-DD" + "00:00") &&
                    moment(toDate).format("yyyy-MM-DD h:mm") ===
                      moment().format("yyyy-MM-DD h:mm") &&
                    true
                  }
                >
                  <ListItemText>This Month</ListItemText>
                </ListItemButton>
              )}
              <ListItemButton
                onClick={customRage}
                dense={true}
                selected={
                  fromDate !==
                    format(subDays(new Date(), 1), "yyyy-MM-dd") +
                      " 00:00:00" &&
                  toDate !==
                    format(subDays(new Date(), 1), "yyyy-MM-dd") +
                      " 23:59:59" &&
                  true
                }
              >
                <ListItemText>Custom</ListItemText>
              </ListItemButton>
              <Divider />
            </StyledCalandarMenu>
          </StyledCalandar>
        )}
        <StyledFiterButton
          type="button"
          onClick={newHandleSubmit}
          variant="contained"
          size="small"
          color="success"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
