import React, { useState, useEffect } from "react";
import {Button,Grid,Box,Typography, FormControl, OutlinedInput, InputLabel, FormHelperText, Stack} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import DraggableDialog from "../shared/DailogDrag";
import logServices from "../../services/logService";
import { useAuditLogMutation, useChangePasswordMutation } from "../../store/slice/ApiSlice";
import { logout, snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
// import userServices from "../../services/userService";


const useStyles = makeStyles(() => ({
  input1: {
    height: 10,
  },
}));

const initialState = {
  password: "",
  newPassword: "",
  currentPassword: "",
  showPassword: false,
};

export default function ChangePassword() {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [passWordMsg, setPassWordMsg] = useState(null);

  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auditLog] = useAuditLogMutation();

  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({mode:'all'});


  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });
  const [values, setValues] = useState(initialState);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


// const globalJson1 =
// localStorage.getItem("userData") !== null
//   ? JSON.parse(localStorage.getItem("userData"))
//   : null;
// var globalOrgId = 1;
// var globaluserId = 1;
// var globalRoleId = 0;
// var globalJson = {};
// var globalResellerId = 2;
// var globalOrgType = 2;
// var globalUserName = "";
// var globalOrgName = "";
// if (
// "null" === localStorage.getItem("userData") ||
// null === localStorage.getItem("userData")
// ) {
// } else {
// globalJson =
//   localStorage.getItem("userData") !== null
//     ? JSON.parse(localStorage.getItem("userData"))
//     : 0;
// globalOrgId = globalJson.orgId;
// globalOrgType = globalJson.userOrgType;
// globaluserId = globalJson.id;
// globalRoleId = globalJson.roleId;
// globalResellerId = globalJson.resellerId;
// globalOrgName = globalJson.orgName;
// globalUserName = globalJson.userName;
// }

let userData = JSON.parse(localStorage.getItem("userData"));

let orgId = userData?.orgId;

let userId = userData?.id;

let orgName = userData?.orgName;
let userName = userData?.userName;

let auditPayload = {
  //Audit Common Payload
  orgId: orgId,
  userId: userId, //User ID
  orgName: orgName, //Org Name
  userName: userName,
};

  useEffect(async () => {
    var userLogginData = {};
    if (
      "null" === localStorage.getItem("userData") ||
      null === localStorage.getItem("userData")
    ) {
    } else {
      userLogginData = JSON.parse(localStorage.getItem("userData"));
    }
    setId(userLogginData.id);


    auditPayload.message = "ProfileDeatils > Password";
    auditPayload.action = "View";
    auditPayload.featureName = "Password";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
  

    auditLog(auditPayload);




  }, []);

  const handleSubmitPass = (data) => {
    ChangeUserPassword(data);
  };

  const [
    changePassword,
    { data: getResponseDetails, isLoading: changePasswordLoading, isSuccess: changePasswordSuccess },
  ] = useChangePasswordMutation();

//validate
var lowerCaseLetters = /[a-z]/g;
var upperCaseLetters = /[A-Z]/g;
var numbers = /[0-9]/g;
var space = /\s/g;
var specialChar = /[!@#$%^&*]/g;

const passwordValidate = (password) => {
// console.log(lowerCaseLetters.test(password) ,upperCaseLetters.test(password) , numbers.test(password) ,  space.test(password) , specialChar.test(password));

var lowervalid=0;var uppervalid=0;var numbersvalid=0;var spacevalid=0;var specialcharvalid=0;

if(upperCaseLetters.test(password)){
    // console.log("dgdgh",password)
    uppervalid=1;
   }if(lowerCaseLetters.test(password) ){
    lowervalid=1;
 }if(numbers.test(password) ){
    numbersvalid=1;
   }if(!space.test(password) ){
    spacevalid=1;
   }if(specialChar.test(password) ){
    specialcharvalid=1;
   }
//  console.log(lowervalid,uppervalid, numbersvalid, spacevalid ,specialcharvalid );
  if(lowervalid=== 1 && uppervalid=== 1 && numbersvalid === 1 && spacevalid === 1 && specialcharvalid === 1){
    return  "";
  }
  else{
    // console.log("test 1");
    return  "InValid Password";
  }
}
  const ChangeUserPassword = async (obj) => {
    changePassword(obj);
    // const responseData = await userService.ChangeUserPassword(obj);

    // var obj1 = {};
    // obj1.dialogButtonText = "change Status";
    // obj1.submitButtonText = "";
    // obj1.closeButtonText = "Close";
    // obj1.dialogText = responseData.data.resultText;
    // setDataStatus(true);
    // setDialogContent(obj1);
    // setDataStatusConfirm(false);
    // setOpen(true);
  };
  useEffect(() => {
    if (changePasswordSuccess) {
      handleCloseUserMenu1();
    }
  },[changePasswordSuccess]);
  
  

  const handleCloseUserMenu1 = async () => {
    navigate("#popup");
   const interval =  setInterval(() => {
      dispatch(logout());
      navigate(`/`);
      clearInterval(interval);
    },2000);
};

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false); 
  };
  return (
        <Box>
          <Typography variant="h6" gutterBottom sx={{mb:4}}>Change Password</Typography>
          <Box component='form' onSubmit={handleSubmit(handleSubmitPass)}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <FormControl fullWidth error={errors.currentPassword}>
                <InputLabel required>Password</InputLabel>
                <OutlinedInput
                {...register("currentPassword", {
                  required:{
                    value:'true',
                    message:'Please enter New Password'
                  },
                })}
                  label="Password"
                  required
                  InputProps={{ classes: { input: classes.input1 } }}
                  id="currentPassword"
                  type="password"
                  name="currentPassword"
                  value={values.currentPassword}
                  onChange={handlePasswordChange("currentPassword")}
                  autoComplete="current-password"
                />
                <FormHelperText>{errors.currentPassword && errors.currentPassword.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12}>
                <FormControl fullWidth error={errors.newPassword}>
                  <InputLabel required>
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    {...register("newPassword", {
                      required:{
                        value:'true',
                        message:'Please enter New Password'
                      },
                      minLength:{
                        value:5,
                        message:'Password cannot less than 5 characters'
                      },
                    })}
                    margin="dense"
                    required
                    label='New Password'
                    type={values.showPassword ? "text" : "password"}
                    name="newPassword"
                    onChange={handlePasswordChange("newPassword")}
                    value={values.newPassword}
                    id="newPassword"
                    autoComplete="new-password"
                    InputProps={{ classes: { input: classes.input1 } }}
                  />
                  <FormHelperText>{errors.newPassword && errors.newPassword.message}</FormHelperText>
                  </FormControl>
              </Grid>
              <Grid item lg={12}>
                <FormControl fullWidth error={errors.password}>
                <InputLabel required>
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  {...register("password", { required:{
                      value:'true',
                      message:'Please enter Password'
                    },
                    
                    validate: {
                      emailAvailable: async (fieldValue) => {
                      
                         var result=  passwordValidate(fieldValue.trim());
                        return (
                          result !==
                          "InValid Password" || "InValid Password"
                        );
                      },
                    },
                  }
                    )}
                  margin="dense"
                  label='Confirm New Password'
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="confirm-new-password"
                  InputProps={{ classes: { input: classes.input1 } }}
                  onChange={handlePasswordChange("password")}
                  value={values.password}
                  
                />
                <FormHelperText>{errors.password && errors.password.message}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
                
                
                {/* {errors?.newPassword?.type === "required" && (
                  <Typography>This field is required</Typography>
                )}
                {errors?.newPassword?.type === "minLength" && (
                  <Typography>
                    password cannot less than 5 characters
                  </Typography>
                )} */}

                {/* <Grid container justify="flex-end">
                  <Link
                    variant="body2"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      marginLeft: 140,
                    }}
                  >
                    {values.showPassword ? "HidePassword" : "ShowPassword"}
                  </Link>
                </Grid> */}
                
                {watch("password") !== watch("newPassword") &&
                getValues("password") ? (
                  <Typography>password not match</Typography>
                ) : null}
                <input type="hidden" name="id" value={id} />

                <Stack direction='row' justifyContent='flex-end'>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{mt:3}}
                  disableElevation
                >
                  Change Password
                </Button>
                </Stack>
          </Box>
       
        {dataStatusConfirm !== false && (
          <DraggableDialog
            open={open}
            dialogButtonText={dialogContentConfirm.dialogButtonText}
            handleClose={handleSbmtClose}
            submitButtonText={dialogContentConfirm.submitButtonText}
            dialogText={dialogContentConfirm.dialogText}
            closeButtonText={dialogContentConfirm.closeButtonText}
            handleClearCls={handleClearCls}
          />
        )}
        {dataStatus !== false && (
          <DraggableDialog
            open={open}
            dialogButtonText={dialogContent.dialogButtonText}
            handleClose={handleSbmtClose}
            submitButtonText={dialogContent.submitButtonText}
            dialogText={dialogContent.dialogText}
            closeButtonText={dialogContent.closeButtonText}
            handleClearCls={handleClearCls}
          />
        )}
        
        <FormDialog children={"Password Changed Succesfully . Kindly Login With New Password"} title={"Alert"} size={"sm"} close={false}> 
        </FormDialog>
     
         </Box>
  );
}
