import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import { Breadcrumb } from "../shared/Breadcrumbs";
// import CreateTicket from './createTicket'
import ViewTicket from "./ViewTicket";
import EditTicket from "./editticket";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useCreateTicketMutation, useGetDropDownMutation, useTicketListMutation } from "../../store/slice/Helpdesk/HelpDeskSlice";
import HelpDeskNew from "./HelpDesk";
import CreateTicket from "./createTicket";
import { useGetDropdownListMutation } from "../../store/slice/ApiSlice";

const HelpDesk = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let orgType = userData.userOrgType;
    let userName = userData.userName;

  const [editOpen, setEditOpen] = React.useState(true);
  const [ticketId, setTicketId] = React.useState(0);

  const hideListView = (e) => {
    // console.log(e.target.id);
    setTicketId(e.target.id);
    setEditOpen(!editOpen);
  };

  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 2000);
    }
  }, [isSuccess.isSuccess]);


  
  const [listTicket, {data:listTickettData, isLoading:listTicketLoading, isSuccess:listTicketSuccess}] = useTicketListMutation();
  


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="HelpDesk"></Breadcrumb>
      {/* <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} /> */}
      {/* {editOpen === true && 
        <ViewTicket fncallback={hideListView} 
        createTicketNew={createTicketNew} 
        createTicketData={createTicketData} 
        createTicketSuccess={createTicketSuccess} 
        createTicketLoading={createTicketLoading} 
        listTicket={listTicket} 
        listTickettData={listTickettData} 
        listTicketLoading={listTicketLoading} 
        listTicketSuccess={listTicketSuccess}/>
        } */}
      {/* {editOpen === false && (
        <EditTicket editticket={ticketId} fncallback={hideListView} />
      )} */}
      {/* <CreateTicket/> */}
      {/* {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )} */}
      <HelpDeskNew />
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default HelpDesk;
