import { Box, Divider, IconButton, Stack, Typography, alpha, useTheme } from '@mui/material'
import React from 'react'
import { StyledIconButton } from '../../theme/StyledEle'
import { IoMdClose } from 'react-icons/io'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from '@mui/lab'
import { HiLocationMarker } from 'react-icons/hi'
import Scrollbars from 'react-custom-scrollbars-2'
import { GrLocation } from "react-icons/gr";
import TimeLineItem from './TimeLineItem'
import moment from 'moment'

function RouteTimeline(props) {
    const { timeLineData, handleTimeline } = props;
    const theme = useTheme();
    // const TimelineData = [
    //     {
    //         "zoneName": "BHEL Sahibabad to Pikhuwa Tata steel",
    //         "type": "zone",
    //         "category": "charging",
    //         "startTime": "2024-02-12 09:00:00",
    //         "endTime": "2024-02-12 11:00:00",
    //         "stoppageDuration": "30",
    //         "chargingDuration": "10",
    //         "stoppageCount": "1",
    //         "chargingCount": "1",
    //         "chargingEnergyGained": "10",
    //         "stoppageEnergyConsumed": "5",
    //         "interEnergyConsumed": "30",
    //         "interDistanceTraveled": "10",
    //         "interDuration": "30",
    //         "maxSpeed": "55",
    //         "avgSpeed": "30",
    //         "startSOC": "45",
    //         "endSOC": "75"
    //       },
    //       {
    //         "zoneName": "NoZone",
    //         "type": "stoppage",
    //         "category": "-",
    //         "startTime": "2024-02-12 11:30:00",
    //         "endTime": "2024-02-12 12:00:00",
    //         "stoppageDuration": "30",
    //         "chargingDuration": "-",
    //         "stoppageCount": "1",
    //         "chargingCount": "-",
    //         "chargingEnergyGained": "-",
    //         "stoppageEnergyConsumed": "5",
    //         "interEnergyConsumed": "30",
    //         "interDistanceTraveled": "10",
    //         "interDuration": "30",
    //         "maxSpeed": "55",
    //         "avgSpeed": "30",
    //         "startSOC": "55",
    //         "endSOC": "48"
    //       },
    //       {
    //         "zoneName": "Pikhuwa Tata steel",
    //         "type": "zone",
    //         "category": "zone",
    //         "startTime": "2024-02-12 11:30:00",
    //         "endTime": "2024-02-12 13:30:00",
    //         "stoppageDuration": "30",
    //         "chargingDuration": "30",
    //         "stoppageCount": "1",
    //         "chargingCount": "2",
    //         "chargingEnergyGained": "10",
    //         "stoppageEnergyConsumed": "5",
    //         "interEnergyConsumed": "30",
    //         "interDistanceTraveled": "10",
    //         "interDuration": "30",
    //         "maxSpeed": "55",
    //         "avgSpeed": "30",
    //         "startSOC": "48",
    //         "endSOC": "60"
    //       },
    //       {
    //         "zoneName": "Delhi-meerut High way1",
    //         "type": "zone",
    //         "category": "zone",
    //         "startTime": "2024-02-12 13:30:00",
    //         "endTime": "2024-02-12 15:30:00",
    //         "stoppageDuration": "30",
    //         "chargingDuration": "-",
    //         "stoppageCount": "1",
    //         "chargingCount": "-",
    //         "chargingEnergyGained": "10",
    //         "stoppageEnergyConsumed": "5",
    //         "interEnergyConsumed": "30",
    //         "interDistanceTraveled": "10",
    //         "interDuration": "30",
    //         "maxSpeed": "55",
    //         "avgSpeed": "30",
    //         "startSOC": "60",
    //         "endSOC": "55"
    //       },
    //       {
    //         "zoneName": "BHEL Sahibabad to Pikhuwa Tata steel",
    //         "type": "zone",
    //         "category": "zone",
    //         "startTime": "2024-02-12 15:30:00",
    //         "endTime": "2024-02-12 16:00:00",
    //         "stoppageDuration": "30",
    //         "chargingDuration": "10",
    //         "stoppageCount": "1",
    //         "chargingCount": "-",
    //         "chargingEnergyGained": "-",
    //         "stoppageEnergyConsumed": "5",
    //         "interEnergyConsumed": "30",
    //         "interDistanceTraveled": "10",
    //         "interDuration": "30",
    //         "maxSpeed": "55",
    //         "avgSpeed": "30",
    //         "startSOC": "55",
    //         "endSOC": "48"
    //       }
    // ]
    
  return (
    timeLineData !== undefined &&
    <Box>
        <Box sx={{p:1, background:theme.palette.info.dark}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                <Typography variant='body1' sx={{color:'#fff'}}>Trip Summary</Typography>
                <Typography variant='body1' sx={{fontSize:'10px', color:'#fff'}}>{timeLineData?.data[0]?.startTime} to {timeLineData?.data[timeLineData?.data?.length-1]?.endTime}</Typography>
                </Box>
                <StyledIconButton size='small' sx={{color:'#fff'}} onClick={() => handleTimeline()}><IoMdClose /></StyledIconButton>
            </Stack>
        </Box>
        <Box sx={{pt:0, height:'60vh', overflowY:'auto'}}>
            {/* <Box sx={{px:5}}><Divider><Typography variant='caption'>Trip Start</Typography></Divider></Box> */}
            <Timeline sx={{p:0, mt:0}}>
                {
                    timeLineData?.data?.map((item, index) => {
                        return (
                            <>
                              {
                                item.sdate !== null &&
                                <Box sx={{py:.8,px:2, background:alpha(theme.palette.grey[50], 1), mb:1, position:'sticky', top:'0px', zIndex:999, boxShadow:'2px 0px 5px rgba(0,0,0,.1)'}}>
                                  <Typography variant='subtitle2'>{item?.sdate}</Typography>
                                </Box>
                              }
                                <TimeLineItem itemData={item} key={index} index={index} length={timeLineData?.data?.length}/>
                            </>
                        )
                    })
                }
            </Timeline>
        </Box>
    </Box>
  )
}

export default RouteTimeline