import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  CardHeader,
  CardContent,
  Button,
  Collapse,
  useTheme,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import {
  SoftButton,
  StyledCardShadow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledDropDown,
} from "../../theme/StyledEle";
import logServices from "../../services/logService";
import ReportServices from "../../services/reportServices";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import { useAuditLogMutation, useListDeviceOnBoardMutation, useGetDropdownListMutation, useMoveDeviceToResellerMutation, useVerifyResellerMutation, useDeleteDeviceOnBoardMutation } from "../../store/slice/ApiSlice";

import { MenuItem } from "@mui/material";
import StyledModel from "../../Utils/StyledModel";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import SingleDropdown from "../NewDropdown/SingleDropdown";

const ListDevice = ({ onEditDevice, menuUser, onViewDevice }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;
  let roleId = userData.roleId;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [arraySortOrder, setArraySortOrder] = useState({});
  const [pageStatus, setPageStatus] = useState([]);
  const [moveToReseller, setMoveToReseller] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [resellerList, setResellerList] = useState(null);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [commonFilter, setCommonFilter] = useState({
    excel: false,
    pdf: false,
  });
  const [deleteItem, setDeleteItem] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const columns = useMemo(
    () => [
     
      {
        Header: "Device IMEI Number",
        accessor: "imei",
      },
      {
        Header: "Device Serial Number",
        accessor: "deviceNumber",
      },
      {
        Header: "Device Model",
        accessor: "model",
      },
      {
        Header: "Device Status",
        accessor: "deviceStatusValue",
      },
      {
        Header: "SIM Number",
        accessor: "simNumber",
      },
      {
        Header: "First Mobile No",
        accessor: "simMobileNumber",
      },
      {
        Header: "SIM Status",
        accessor: "simStatusValue",
      },
      {
        Header: "Vehicle VIN",
        accessor: "vehicleNo",
      },
      {
        Header: "Last Connected",
        accessor: "gpsModifyDate",
      },
      // {
      //   Header: "Last Received Packet",
      //   accessor: "",
      // },
      
      // {
      //   Header: "Last Location",
      //   accessor: "deviceLocation",

      //   Cell: ({ row, column, cell }) => {
      //     const refAddres = React.useRef(row);
      //     var refLink = React.useRef(row);
      //     var getAddressBtn = React.useRef(row);
      //     var toggleAddressBtn = React.useRef(row);
      //     const getAddress = async (e) => {
      //       if (refAddres.current.innerText === "") {
      //         var request = {};
      //         request.latLng = e.target.value;
      //         // rowid = e.target.id;
      //         const responseData = (
      //           await ReportServices.getGeocodeAddress(request)
      //         ).data;
      //         refAddres.current.innerText = responseData;
      //         if (responseData !== "") {
      //           refLink.current.style.display = "none";
      //           refAddres.current.style.display = "block";
      //           toggleAddressBtn.current.style.display = "block";
      //           getAddressBtn.current.style.display = "none";
      //           toggleAddressBtn.current.innerText = "LatLng";
      //         }
      //       }
      //     };

      //     //Address toggle
      //     const ToggleAdderss = () => {
      //       if (refAddres.current.style.display === "block") {
      //         refAddres.current.style.display = "none";
      //         refLink.current.style.display = "block";
      //         toggleAddressBtn.current.innerText = "Address";
      //       } else {
      //         refAddres.current.style.display = "block";
      //         refLink.current.style.display = "none";
      //         toggleAddressBtn.current.innerText = "LatLng";
      //       }
      //     };
      //     return row.original.deviceLocation !== "Not applicable" ? (
      //       <Box sx={{ display: "flex", alignItems: "center" }}>
      //         <Box
      //           className="addresslink"
      //           id={row.original.deviceLocation}
      //           sx={{ display: "flex", alignItems: "center" }}
      //         >
      //           <Button
      //             onClick={(e) => getAddress(e)}
      //             value={row.original.deviceLocation}
      //             ref={getAddressBtn}
      //           >
      //             Address
      //           </Button>
      //           <Button
      //             onClick={(e) => ToggleAdderss(e)}
      //             ref={toggleAddressBtn}
      //             sx={{ display: "none" }}
      //           >
      //             LatLng
      //           </Button>
      //           <a
      //             href={`https://www.google.com/maps/search/?api=1&query=${row.original.deviceLocation}`}
      //             target="_blank"
      //             className="latlink"
      //             ref={refLink}
      //             id={row.original.deviceLocation}
      //           >
      //             {row.original.deviceLocation}
      //           </a>
      //           <a
      //             href={`https://www.google.com/maps/search/?api=1&query=${row.original.deviceLocation}`}
      //             target="_blank"
      //             className="address"
      //             ref={refAddres}
      //             id={row.original.deviceLocation}
      //             key={row.original.deviceLocation}
      //           ></a>
      //         </Box>
      //       </Box>
      //     ) : (
      //       <Box>
      //         <Typography>NA</Typography>
      //       </Box>
      //     );
      //   },
      // },
      {
        Header: "Last Received Packet",
        accessor: "vehGPSdate",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Modified Date",
        accessor: "modifiedDate",
      },
      {
        Header: "Firmware Version",
        accessor: "firmware",
      },
      {
        Header: "First Valid Location",
        accessor: "firstLocation",
      },
      {
        Header: "First Valid Feed Received Time",
        accessor: "firstFeedRecievedTime",
      },
      {
        Header: "Assigned To Reseller",
        accessor: "resellerName",
      },
      
      // {
      //   Header: "Location",
      //   accessor: "firstLocation",

      //   Cell: ({ row, column, cell }) => {
      //     const refAddres = React.useRef(row);
      //     var refLink = React.useRef(row);
      //     var getAddressBtn = React.useRef(row);
      //     var toggleAddressBtn = React.useRef(row);
      //     const getAddress = async (e) => {
      //       if (refAddres.current.innerText === "") {
      //         var request = {};
      //         request.latLng = e.target.value;
      //         // rowid = e.target.id;
      //         const responseData = (
      //           await ReportServices.getGeocodeAddress(request)
      //         ).data;
      //         refAddres.current.innerText = responseData;
      //         if (responseData !== "") {
      //           refLink.current.style.display = "none";
      //           refAddres.current.style.display = "block";
      //           toggleAddressBtn.current.style.display = "block";
      //           getAddressBtn.current.style.display = "none";
      //           toggleAddressBtn.current.innerText = "LatLng";
      //         }
      //       }
      //     };

      //     //Address toggle
      //     const ToggleAdderss = () => {
      //       if (refAddres.current.style.display === "block") {
      //         refAddres.current.style.display = "none";
      //         refLink.current.style.display = "block";
      //         toggleAddressBtn.current.innerText = "Address";
      //       } else {
      //         refAddres.current.style.display = "block";
      //         refLink.current.style.display = "none";
      //         toggleAddressBtn.current.innerText = "LatLng";
      //       }
      //     };

      //     return row.original.firstLocation !== "NA" ? (
      //       <Box sx={{ display: "flex", alignItems: "center" }}>
      //         <Box
      //           className="addresslink"
      //           id={row.original.firstLocation}
      //           sx={{ display: "flex", alignItems: "center" }}
      //         >
      //           <Button
      //             onClick={(e) => getAddress(e)}
      //             value={row.original.firstLocation}
      //             ref={getAddressBtn}
      //           >
      //             Address
      //           </Button>
      //           <Button
      //             onClick={(e) => ToggleAdderss(e)}
      //             ref={toggleAddressBtn}
      //             sx={{ display: "none" }}
      //           >
      //             LatLng
      //           </Button>

      //           <a
      //             href={`https://www.google.com/maps/search/?api=1&query=${row.original.firstLocation}`}
      //             target="_blank"
      //             className="latlink"
      //             ref={refLink}
      //             id={row.original.firstLocation}
      //           >
      //             {row.original.firstLocation}
      //           </a>
      //           <a
      //             href={`https://www.google.com/maps/search/?api=1&query=${row.original.firstLocation}`}
      //             target="_blank"
      //             className="address"
      //             ref={refAddres}
      //             id={row.original.firstLocation}
      //             key={row.original.firstLocation}
      //           ></a>
      //         </Box>
      //       </Box>
      //     ) : (
      //       <Box>
      //         <Typography>NA</Typography>
      //       </Box>
      //     );
      //   },
      // },
      // {
      //   Header: "Time",
      //   accessor: "firstFeedRecievedTime",
      // },
      // {
      //   Header: "Sim Activation Date",
      //   accessor: "simActivationDate",
      // },
      // {
      //   Header: "Lot Number",
      //   accessor: "lotNumber",
      // },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   var obj = { sortColumn };
  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "imei") {
  //     if (obj1.imei !== undefined && obj1.imei === "asc") {
  //       obj1.imei = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.imei = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "IMEI";
  //   } else if (obj.sortColumn.id === "simNumber") {
  //     if (obj1.simNumber !== undefined && obj1.simNumber === "asc") {
  //       obj1.simNumber = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.simNumber = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "SIM_NUMBER";
  //   }
  //   setArraySortOrder(obj1);
  //   var objSel = { ...pageStatus };
  //   fetchDeviceDetails(
  //     "",
  //     objSel.resellerId,
  //     objSel.orgId,
  //     seltdsrtngcolumn,
  //     orderdir
  //   );
  // };

  const onEdit = (itemEdited) => {
    onEditDevice(itemEdited);

    auditPayload.message = "Admin > Device > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onView = (itemViewed) => {
    onViewDevice(itemViewed);

    auditPayload.message = "Admin > Device > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };
  // const fetchDeviceDetails = async (
  //   searchText,
  //   seltdResellerId,
  //   seltdLastOrgId,
  //   columnName,
  //   orderdir
  // ) => {
  //   var request = {};
  //   request.orgId = seltdLastOrgId;
  //   request.resellerOrgId = seltdResellerId;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "String";
  //   request.customerId = pageStatus.customerId;
  //   request.fleetId = pageStatus.fleetId;
  //   request.vehicleId = pageStatus.vehicleId;
  //   request.imei = pageStatus.imei;
  //   request.devModel = pageStatus.devModel;
  //   request.statusId = pageStatus.statusId;
  //   request.search = searchText;
  //   request.countFrom = newPage;
  //   request.countTo = rowsPerPage;
  //   request.dealerId = pageStatus.dealerId;
  //   request.regionId = pageStatus.regionId;
  //   request.startDate = pageStatus.startDate;
  //   request.endDate = pageStatus.endDate;
  //   request.lastConnectStatus = pageStatus.devicelastconnectStatus;
  //   request.dateOnOrBefore = pageStatus.dateOnOrBefore;
  //   if (columnName === undefined) {
  //     request.columnName = "IMEI";
  //   } else {
  //     request.columnName = columnName;
  //   }
  //   if (orderdir === undefined) {
  //     request.orderDir = "desc";
  //   } else {
  //     request.orderDir = orderdir;
  //   }

  //   try {
  //     const responseData = (await DeviceService.getGridViewForDevice(request))
  //       .data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //   } catch (error) {}
  // };

  // const callbackLoadFn = async (
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdVehicleId,
  //   seltdLastOrgId,
  //   modelVal,
  //   statusVal,
  //   seltdRegionId,
  //   seltdDealerId,
  //   fromDate,
  //   toDate,
  //   selConnected,
  //   selCreatedDate
  // ) => {
  //   var dataStr = {};
  //   dataStr.edit = false;
  //   dataStr.delete = false;
  //   dataStr.view = false;
  //   dataStr.excel = false;
  //   dataStr.pdf = false;
  //   var strval;
  //   strval = menuUser.split(",");
  //   for (var i = 0; i < strval.length; i++) {
  //     if (parseInt(strval[i]) === 3) {
  //       dataStr.edit = true;
  //     }
  //     if (parseInt(strval[i]) === 4) {
  //       dataStr.delete = true;
  //     }
  //     if (parseInt(strval[i]) === 6) {
  //       dataStr.pdf = true;
  //     }
  //     if (parseInt(strval[i]) === 5) {
  //       dataStr.excel = true;
  //     }
  //   }
  //   setCommonFilter(dataStr);
  //   if (loadStatus === true) {
  //     var request = {};
  //     request.orgId = seltdLastOrgId;
  //     request.resellerOrgId = seltdResellerId;
  //     request.page = newPage;
  //     request.size = rowsPerPage;
  //     request.customerId = seltdCustomerId;
  //     request.fleetId = seltdFleetId;
  //     request.vehicleId = seltdVehicleId;
  //     request.imei = 1;
  //     request.devModel = modelVal;
  //     request.statusId = statusVal;
  //     request.search = "";
  //     request.columnName = "IMEI";
  //     request.orderDir = "desc";
  //     request.dealerId = seltdDealerId;
  //     request.regionId = seltdRegionId;
  //     request.startDate = fromDate;
  //     request.endDate = toDate;
  //     request.devicelastconnectStatus = selConnected;
  //     request.dateOnOrBefore = selCreatedDate;
  //     setPageStatus(request);

  //     const responseData = (await DeviceService.getGridViewForDevice(request))
  //       .data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   }
  //   setLoadStatus(false);
  // };

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate('#deviceDelte');
    //navigate(`#/id=${itemDeleted.id}`);
    
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "deviceOnBoard/deviceListOnboardXLS.xls?vendorOrgId=" +
        payload.vendorOrgId +
        "&devModel=" +
        payload.devModel +
        "&imei=" +
        payload.imei +
        "&orgId=" +
        payload.orgId +
        "&statusId=" +
        payload.statusId +
        "&lastConnectStatus=" +
        payload.lastConnectStatus +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&orgType=" +
        payload.orgType +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Device > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const onChangepdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "device/deviceListPDF.pdf?resellerOrgId=" +
        payload.resellerOrgId +
        "&devModel=" +
        payload.devModel +
        "&deviceImei=" +
        payload.deviceImei +
        "&vehicleId=" +
        payload.vehicleId +
        "&fleetId=" +
        payload.fleetId +
        "&customerId=" +
        payload.customerId +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&statusId=" +
        payload.statusId +
        "&devicelastconnectStatus=" +
        payload.devicelastconnectStatus +
        "&dateOnorBefore=" +
        payload.dateOnorBefore +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Device > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "deviceList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const [getDeviceList, {data:deviceListData, isLoading:deviceListLoading, isSuccess:deviceListSuccess} ] = useListDeviceOnBoardMutation();
  const [deleteDevice, {data:deviceDeleteData, isLoading:deviceDeleteLoading, isSuccess:deviceDeleteSuccess} ] = useDeleteDeviceOnBoardMutation();
  const [getResellerList, {data:resellerListData, isLoading:resellerLoading, isSuccess:resellerSuccess} ] = useGetDropdownListMutation();
  const [saveDeviceToReseller, {data:moveDeviceData, isLoading:deviceToResellerLoading, isSuccess:moveDeviceSuccess}] = useMoveDeviceToResellerMutation();
  const [verifyReseller ,{data:verifyData, isLoading:verifyDataLoading, isSuccess:verifyDataSuccess}]=useVerifyResellerMutation();


  const [payload, setPayload] = useState(null);
  let commonPayload = {};
  //get Data
  const getData = (data) => {
    //   var dataStr = {};
    // dataStr.edit = false;
    // dataStr.delete = false;
    // dataStr.view = false;
    // dataStr.excel = false;
    // dataStr.pdf = false;
    // var strval;
    // strval = menuUser.split(",");
    // for (var i = 0; i < strval.length; i++) {
    //   if (parseInt(strval[i]) === 3) {
    //     dataStr.edit = true;
    //   }
    //   if (parseInt(strval[i]) === 4) {
    //     dataStr.delete = true;
    //   }
    //   if (parseInt(strval[i]) === 6) {
    //     dataStr.pdf = true;
    //   }
    //   if (parseInt(strval[i]) === 5) {
    //     dataStr.excel = true;
    //   }
    // }
    // setCommonFilter(dataStr);
    commonPayload.devModel = data.DeviceModel;
    commonPayload.statusId = data.deviceStatus;
    commonPayload.orgType = orgType;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.dateOnOrbefore = data.createDate;
    commonPayload.lastConnectStatus = data.lastconnnect;
    commonPayload.columnName = "IMEI";
    commonPayload.orderDir = "desc";
    commonPayload.search = "";
    commonPayload.imei = data.imei;
    commonPayload.vendorOrgId = data.vendor!== null && data.vendor!== "-1" ? data.vendor:0;
    commonPayload.size = rowsPerPage;
    commonPayload.page = newPage;
    commonPayload.orgId = orgId;

    setPayload(commonPayload);
    getDeviceList(commonPayload);

    auditPayload.message = "Admin > Device > Device List";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
   

    let resellerPayload={
      value :"reseller",
      orgType:orgType,
      roleId: roleId,
      searchText:""
    }
  getResellerList(resellerPayload);

  };

  const handleSelectedReseller = (resellerId) => {
    setSelectedReseller(resellerId);
  };

  const dropDownSearch = (searchText, name) => {
    var CommonPayload={};
    CommonPayload.value = "reseller";
    CommonPayload.searchText = searchText;
    CommonPayload.orgType = orgType;
    CommonPayload.roleId = roleId;
   
      getResellerList(CommonPayload);
    
  };

  useEffect(() => {
    if (deviceListSuccess && resellerSuccess) {
      setResponseData([]);
      if (deviceListData.data !== null) {
        setResponseData(deviceListData.data.content);
        setTotalElements(deviceListData.data.totalElements);
        setTotalPages(deviceListData.data.totalPages);
        setResellerList(resellerListData?.data);

        setSelectedReseller(resellerListData?.data?.content[0]?.key);

        var dataStr = {};
        dataStr.edit = false;
        dataStr.delete = false;
        dataStr.view = false;
        dataStr.excel = false;
        dataStr.pdf = false;
        var strval;
        strval = menuUser.split(",");
        for (var i = 0; i < strval.length; i++) {
          if (parseInt(strval[i]) === 4) {
            dataStr.edit = true;
          }
          if (parseInt(strval[i]) === 2) {
            dataStr.view = true;
          }
          if (parseInt(strval[i]) === 3) {
            dataStr.delete = true;
          }
          if (parseInt(strval[i]) === 6) {
            dataStr.pdf = true;
          }
          if (parseInt(strval[i]) === 5) {
            dataStr.excel = true;
          }
        }
        setCommonFilter(dataStr);
      }
      auditPayload.message = "Admin > Device > Device List";
      auditPayload.action = "View";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }
  }, [deviceListSuccess,resellerSuccess]);

  //search
  // const handleSearch = (searchText) => {
  //   var selObj = { ...pageStatus };
  //   fetchDeviceDetails(searchText, selObj.resellerOrgId, selObj.orgId);
  // };
  const handleSearch = async (searchText) => {

    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getDeviceList(payload);
    } else if (payload.search.length === 0) {
      getDeviceList(payload);
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getDeviceList(payload);
  };

  //Delete
  const deleteItems = async() => {
    let payload = {
      id: deleteItem.id,
    };
    navigate(location.pathname);
    const deleteres = await deleteDevice(payload);

  };

  useEffect(() => {
    if (deviceDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deviceDeleteData?.resultMessage,
          deviceDeleteData?.resultText,
          deviceDeleteSuccess,
        ])
      );
      getDeviceList(payload);
    }
  }, [deviceDeleteSuccess]);

  useEffect(() => {
    if (deviceDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deviceDeleteData?.resultMessage,
          deviceDeleteData?.resultText,
          deviceDeleteSuccess,
        ])
      );
      getDeviceList(payload);
    }
  }, [deviceDeleteSuccess]);

  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);

  //get row data from data table
  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };

  const handleMoveToReseller = () => {
    setMoveToReseller(true);
    navigate('#move');
  };


  const handleVerifySeller = async() => {
    let mobArry = []; 
    let payloadSave = {

    }
    selectRows.forEach((key, index) => {
      let imeiSimList ={};
      imeiSimList.imei= key.original.imei;
      imeiSimList.simNumber=key.values.simNumber;
      imeiSimList.id=key.original.id;
      mobArry.push(imeiSimList);
    })
    
    payloadSave.deviceDetails = mobArry;
  const response= await  verifyReseller(payloadSave);

  var obj1 = {};
  
  obj1.dialogText =
  response.data.resultText === "Verified Succesfully"? "Device moved to system": "Not verified";

  setDataStatus(true);
  setDialogContent(obj1);
  setDataStatusConfirm(response.data.resultText === "Verified Succesfully"? "Success": "Failed");
  setOpen(true);

  if (responseData.status === 200) {
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  }   
  };

 

  useEffect(() => {
   
  }, []);

  const moveDeviceToReseller = async () => {
    let mobArry = []; 
    let payloadSave = {

    }
    selectRows.forEach((key, index) => {

      let imeiSimList ={};
      imeiSimList.imei= key.original.imei;
      imeiSimList.simNumber=key.values.simNumber;
      mobArry.push(imeiSimList);

    })
    
    payloadSave.imeiList = mobArry;
    payloadSave.vendorId = payload.vendorOrgId;
    payloadSave.resellerId = selectedReseller;
    navigate(location.pathname);
    const responseData=await saveDeviceToReseller(payloadSave);

    var obj1 = {};
  
    obj1.dialogText =
    responseData.data.resultText === "Success"? "Device moved to reseller successfully": "Device not moved to reseller";
  
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(responseData.data.resultText === "Success"? "Success": "Failed");
    setOpen(true);
  
    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }   


  }

  return (
    <>
      <Box>
        <AdminDropdown getData={getData} vendorDrop={true} />
        <StyledCardShadow sx={{ mt: 2 }}>
          <Box sx={{ padding: "15px" }}>
            <Collapse
              in={selectRows?.length > 0}
              sx={{
                border: `.5px solid ${theme.palette.divider}`,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ px: 2, py: 1, my: 0.5 }}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"end"}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"end"}
                    spacing={1.5}
                    sx={{ Width: "100%" }}
                  ></Stack>
                  <SoftButton
                    color="info"
                    className="info"
                    onClick={handleMoveToReseller}
                  >
                    Move To Reseller
                  </SoftButton>
                  <SoftButton
                    color="info"
                    className="info"
                    onClick={handleVerifySeller}
                  >
                    Verify
                  </SoftButton>
                </Stack>
              </Box>
            </Collapse>
          </Box>

          <CardContent sx={{ py: 0 }}>
            <DataTable
              onEdit={onEdit}
              onDelete={onDelete}
              onView={onView}
              //onCheckbox={onCheckbox}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangepdf}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              includeSearchBox={true}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              commonFilter={commonFilter}
              isLoading={deviceListLoading}
              checkbox={orgType === 7 ? false : true}
              selectRow={getRow}
            />
          </CardContent>
        </StyledCardShadow>
        {location.hash === '#deviceDelte' && (
          <StyledModel data={deleteItem} deleteItem={deleteItems}>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Device No: {deleteItem.imei}
              </Typography>
            </Box>
          </StyledModel>
        )}
        {location.hash === '#move'  && (
          <StyledModel moveToReseller={true} deleteItem={moveDeviceToReseller}>
            <Box sx={{ px: 2 }}>
              <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                <SingleDropdown
                  label={"Reseller"}
                  listItems={resellerList}
                  handleChange={handleSelectedReseller}
                  value={selectedReseller}
                  isSearch={dropDownSearch}
                />
              </StyledFormControl>
            </Box>
          </StyledModel>
        )}

        
      </Box>
      {open && (
        <StyledSnackBar
          open={open}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
      
    </>
  );
};

export default ListDevice;
