import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader } from "@mui/material";
import DriverService from "../../services/driverService";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "./driverDropdown";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
const ListDriver = ({ onEditDriver }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState("");
  const [loadStatus, setLoadStatus] = useState(true);

  useEffect(async () => {
    await fetchDriverDetails();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Driver ID",
        accessor: "id",
      },
      {
        Header: "ID File",
        accessor: "vehicleId",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Mobile Number",
        accessor: "mobile",
      },
      {
        Header: "License Number",
        accessor: "licenseNumber",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const handleSubmit = async (
    seltdOrgTypeId,
    seltdResellerId,
    seltdCustomerId,
    seltdFleetId,
    seltdLastOrgId,
    seltdDealerId,
    seltdRegionId
  ) => {
    var request = {};
    request.orgId = seltdLastOrgId;
    request.resellerId = seltdResellerId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.searchText = "String";
    try {
      const responseData = (await DriverService.getGridViewForDriver(request))
        .data;
      setResponseData(responseData.data.content);
      setLoading(responseData.resultText);
    } catch (error) {}
  };

  const onEdit = (itemEdited) => {
    onEditDriver(itemEdited.id);
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  const fetchDriverDetails = async (searchText) => {
    // var request = {};
    // request.resellerId = 10;
    // request.orgId = 2;
    // request.page = newPage;
    // request.size = rowsPerPage;
    // request.searchText = searchText;
    // const responseData = (await DriverService.getGridViewForDriver(request)).data;
    // setResponseData(responseData.data.content);
    // setTotalElements(responseData.data.totalElements);
    // setTotalPages(responseData.data.totalPages);
  };

  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(true);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContent(obj);
      setDataStatus(true);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const callbackLoadFn = async (orgId, resellerId) => {
    if (loadStatus === true) {
      var request = {};
      request.resellerId = resellerId;
      request.orgId = orgId;
      request.page = newPage;
      request.size = rowsPerPage;
      request.searchText = "";

      const responseData = (await DriverService.getGridViewForDriver(request))
        .data;
      setResponseData(responseData.data.content);
      setLoading(responseData.resultText);
      setTotalElements(responseData.data.totalElements);
      setTotalPages(responseData.data.totalPages);
    }
    setLoadStatus(false);
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await DriverService.deleteDriverById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
    setOpen(true);
  };
  const handleSearch = (searchText) => {
    fetchDriverDetails(searchText);
  };

  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);

    var request = {};
    request.resellerId = 1;
    request.orgId = 1;
    request.page = newPage;
    request.size = rowsPerPage;
    request.searchText = "String";
    try {
      const responseData = (await DriverService.getGridViewForDriver(request))
        .data;
      setResponseData([]);
      setResponseData(responseData.data.content);
      setLoading(responseData.resultText);
      setTotalElements(5);
      setTotalPages(0);
      setTotalElements(responseData.data.totalElements);
      setTotalPages(responseData.data.totalPages);
    } catch (error) {}
  };

  return (
    <Box>
      <CommonDropDown
        handleSmt={handleSubmit}
        callbackLoadFn={callbackLoadFn}
      />
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardHeader title={<StyledTableTitle>Driver List</StyledTableTitle>} />

        <CardContent sx={{ py: 0 }}>
          {responseData.length > 0 && (
            <DataTable
              loading={loading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onEdit={onEdit}
              onDelete={onDelete}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          )}
        </CardContent>
      </StyledCardShadow>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
};

export default ListDriver;
