import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { HiOutlineCog } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { StyledSidebarListBtn, StyledSwitch } from "../../../theme/StyledEle";
import { event } from "jquery";
function TreeviewControls(props) {
  const theme = useTheme();
  const { searchVehicle, searchKey, autoRef, autoRefresh, vehicleTrails, geoZone, activeFeature, handleFeatureChange, showTrails, handleTrails } = props;


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //search keyword
  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );
  return (
    <Box sx={{ borderBottom: `.2px solid ${theme.palette.divider}` }}>
      <Paper elevation={0} sx={{ px: 0.5, py: 0.5 }}>
        <Stack direction={"row"} divider={<Divider />}>
          <IconButton disabled>
            <CiSearch />
          </IconButton>
          <InputBase
            placeholder="Search vehicle"
            sx={{ width: "100%" }}
            value={isSearchKeyword}
            onChange={(event) => searchVehicle(event)}
          />
          <IconButton
            onClick={handleSettings}
            id="map_settings"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <HiOutlineCog />
          </IconButton>
        </Stack>
      </Paper>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "20px",
              left: "-6px",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiList-root": {
              padding: "0px",
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <ListItem dense={true} sx={{ px: 0, py: 0 }}>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Checkbox
                edge="start"
                inputProps={{ "aria-labelledby": "settings" }}
                size="small"
                sx={{ py: 1, px: 1 }}
                onChange={(event) => handleFeatureChange(event.target.checked, 0)}
                checked={activeFeature === 0 ? true : false}
              />
            </ListItemIcon>
            <ListItemText id={"settings"} primary="Auto Refresh 3 mins" />
          </ListItemButton>
        </ListItem>
        {
          vehicleTrails !== null && 
          <ListItem dense={true} sx={{px:0, py:0}}>
                <ListItemButton>
                    <ListItemIcon sx={{minWidth:0}}>
                        <Checkbox 
                            edge='start'
                            inputProps={{ 'aria-labelledby': 'geoZone' }}
                            size='small'
                            sx={{py:1, px:1,}}
                            onChange={(event) => handleFeatureChange(event.target.checked, 1)}
                            checked={activeFeature === 1 ? true : false}
                        />
                    </ListItemIcon>
                      <ListItemText id={'geoZone'} primary='Show Geo Zone'/>
                </ListItemButton>
            </ListItem>
        }
        {
          vehicleTrails !== null && resellerId === 110224 && (orgType === 8 || orgType === 2) &&
          <ListItem dense={true} sx={{px:0, py:0}}>
                <ListItemButton >
                    <ListItemIcon sx={{minWidth:0}}>
                        <Checkbox 
                            edge='start'
                            inputProps={{ 'aria-labelledby': 'routeTrip' }}
                            size='small'
                            sx={{py:1, px:1,}}
                            onChange={(event) => handleFeatureChange(event.target.checked, 2)}
                            checked={activeFeature === 2 ? true : false}
                        />
                    </ListItemIcon>
                      <ListItemText id={'routeTrip'} primary='Show Routes'/>
                </ListItemButton>
            </ListItem>
        }
        <Divider />
        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{px:0, py:0}}>
                <ListItemButton >
                    <ListItemIcon sx={{minWidth:0, ml:'-5px'}}>
                      <StyledSwitch 
                          onChange={(event) => handleTrails(!showTrails)}
                          checked={showTrails}
                          sx={{mr:1}}
                      />
                        {/* <Checkbox 
                            edge='start'
                            inputProps={{ 'aria-labelledby': 'routeTrip' }}
                            size='small'
                            sx={{py:1, px:1,}}
                            onChange={(event) => handleFeatureChange(event.target.checked, 2)}
                            checked={activeFeature === 2 ? true : false}
                        /> */}
                    </ListItemIcon>
                      <ListItemText id={'routeTrip'} primary='Show Trails'/>
                </ListItemButton>
            </ListItem>
        }
      </Menu>
    </Box>
  );
}

export default TreeviewControls;
