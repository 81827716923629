import { Box, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ListKycFeed from "./ListKycFeeds";
import ListKycProcessedFeed from "./ListKycProcessedFeed";
import CreateKycForm from "./CreateKyc";
import VinJourneyDtails from "./VinDetails";
import { StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import FormDialog from "../../Utils/FormDialog";
import KYCEdit from "./KYCEdit";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetKycDetailsQuery } from "../../store/slice/LogsSlice/LogsSlice";

const KycLog = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let RoleId = userData?.roleId;
  const [kycToEdit, setKycToEdit] = React.useState(null);
  const [id, setId] = useState();
  const [skip, setSkip] = useState(true);
  const [tabValue, setTabValue] = useState(RoleId !== 17?2:3);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  //const {data:getKycDetails, isLoading, isSuccess} = useGetKycDetailsQuery(id,{skip});
  const handleDevEdit = async(kycEditedId) => {
    //setKycToEdit(kycEditedId);
    //setTabValue(0);
    location.state={'id':kycEditedId.id}
    navigate('#edit');
    
    console.log('location', location);
    setId(kycEditedId.id);
    // setSkip(false);
  };
  const handleTabChange = (event, newValue) => {
    setKycToEdit(null);
    setTabValue(newValue);
  };

  const handleCancel = () => {
    setTabValue(RoleId !== 17?2:3);
  };

  const TABS = [
    {
      label: "Create KYC Form",
      children: (
        <CreateKycForm
          kycToEdit={kycToEdit}
          onCancel={handleCancel}
          tabValue={tabValue}
        />
      ),
    },
    
   
    {
      label: "Vin Journey Details",
      children: <VinJourneyDtails tabValue={tabValue} />,
    },
    {
      label: "KYC Processing",
      children: (
        <ListKycProcessedFeed tabValue={tabValue} onEditKyc={handleDevEdit} />
      ),
    },
    {
      label: "KYC Feeds",
      children: <ListKycFeed tabValue={tabValue} />,
    },
    // //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];
  const TABS1 = [
    {
      label: "Create KYC Form",
      children: (
        <CreateKycForm
          kycToEdit={kycToEdit}
          onCancel={handleCancel}
          tabValue={tabValue}
        />
      ),
    },
    {
      label: "KYC Processing",
      children: (
        <ListKycProcessedFeed tabValue={tabValue} onEditKyc={handleDevEdit} />
      ),
    },
    {
      label: "KYC Feeds",
      children: <ListKycFeed tabValue={tabValue} />,
    },
    // //  {
    //   label: 'Security',
    //   children: <SecurityFeature onEditFeature={handleDevEdit}  />
    // }
  ];

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Log" subDiv="KYC Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover
        text="Only valid KYC feeds are shown in KYC Details page."
        title={"KYC Log"}
      />
      <StyledTabs tabs={RoleId === 17?TABS:TABS1} handleChange={handleTabChange} value={tabValue} />

        {/* Edit Popup */}
        <FormDialog title={'Edit KYC Form'}>
            <KYCEdit data={editData} ids={id}/>
        </FormDialog>
    </StyledPageLayout>
  );
};

export default KycLog;
