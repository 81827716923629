import Sidebar from "./Sidebar";
import Header from "./Header";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import routes from "../../Route/routes";
import { AppBar, Toolbar, CssBaseline, Dialog, DialogContentText, DialogContent, CardMedia, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { StyledContentArea } from "../../theme/StyledEle";
import Scrollbars from "react-custom-scrollbars-2";
import Home from "../../components/page-not-found/PageNotFound";
import { Backdrop } from "@material-ui/core";
import useIdle from "../../components/IdleTimer/UseIdleTimer";
import Sessionout from "../../components/login/Sessionout";
import VerificationDialog from "../../components/verification";
import poweredBy from '../../assets/images/NeshPoeweLogo.svg'

const SidebarLayout = () => {


  
  let userData = JSON.parse(localStorage.getItem("userData"));
  let emailVerificationStatus = userData?.emailVerificationStatus;
  let mobileVerificationStatus = userData?.mobileVerificationStatus;
  let skipDialog =   localStorage?.getItem("skip");

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const drawerToggle = () => {
    setOpen(!open);
  };

  const compareArrays = (a, c) => {
    for (var i = 0; i < a.length; i++) {
      if (a[i] === c) {
        return true;
        break;
      }
    }
    return false;
  };

  // const MainContent = styled(Box)({
  //   // marginTop: '48px',
  //   width: "100%",
  // });

  const ContainerWrapper = styled(Box)({
    width: "100%",
    padding: "10px 0px",
  });

  const callfun = () => {
    var arry = false;
    var arrayFound = [];
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              for (let k = 0; k < routes[0].children.length; k++) {
                if (
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j]
                    .route === routes[0].children[k].path
                ) {
                  arrayFound.push(routes[0].children[k].path);
                }
              }
            }
          }
        }
      }
    }

    var data = window.location.href.split("#");
    if (data.length === 1) {
      if (true === window.localStorage.getItem("expjwToken")) {
        if (null !== window.localStorage.getItem("isRefreshToken")) {
          window.localStorage.removeItem("isRefreshToken");
          window.localStorage.removeItem("menuList");
        }
        navigate("/");
        arry = true;
      }
    } else {
      arry = false;
      for (let l = 0; l < routes[0].children.length; l++) {
        if (
          compareArrays(
            arrayFound,
            window.location.href.split("#")[1].split("?")[0].split("/")[1]
          ) === true ||
          "/" === window.location.href.split("#")[1].split("?")[0] ||
          "/" === window.location.href.split("#")[1].split("?")[0] ||
          "/userActivation" ===
            window.location.href.split("#")[1].split("?")[0] ||
          "/profileSettings" ===
            window.location.href.split("#")[1].split("?")[0] ||
          "/accountSettings" ===
            window.location.href.split("#")[1].split("?")[0] ||
            "/themeeditor" ===
              window.location.href.split("#")[1].split("?")[0]
        ) {
          arry = true;
        }
      }
    }

    return arry;
  };

  useEffect(() => {
    callfun();
    setInterval(() => {
      if (true === window.localStorage.getItem("expjwToken")) {
        if (null !== window.localStorage.getItem("isRefreshToken")) {
          window.localStorage.removeItem("isRefreshToken");
          window.localStorage.removeItem("menuList");
          window.localStorage.removeItem("userData");
        }
        //navigate("/login");
       //return <Sessionout/>
      }
    }, 1000);
    if (callfun() === false) {
      navigate("/");
    }
  }, [navigate]);

  // light mode dark mode
  const theme = useTheme();
  
    // {/* //   <CssBaseline />
    // //   <Header open={open} drawerToggle={drawerToggle} /> */}
    // {/* <Sidebar open={open} /> */}


    const [openModal, setOpenModal] = useState(false)

    const handleIdle = () => {
      setOpenModal(true);
    } 

    const { idleTimer } = useIdle({ onIdle: handleIdle, idleTime: 1800000, });
  return (
    <>
    {
      (skipDialog === null || skipDialog === undefined) && (emailVerificationStatus === 0 || mobileVerificationStatus === 0) && <VerificationDialog />
    }

    { 
      openModal && <Sessionout />
    }
      <StyledContentArea sx={{ background: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.background.default, height:'100vh', position:'relative' }} className="ContentArea">
        <Toolbar/>
          <Box sx={{ 
            background: theme.palette.background.default , 
            height:'calc(100% - 6%)',
            borderRadius:'15px 0 0 15px',
            overflow:'hidden',
            }}>
              
          <Scrollbars>
            {callfun() === true && <Outlet />}
            {callfun() === false && <Home />}
          </Scrollbars>
          </Box>
      </StyledContentArea>
      </>
  );
};

export default SidebarLayout;
