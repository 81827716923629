import React, { useEffect, useRef, useState } from 'react'
import { ControlsBox, StyledPageLayout } from '../../theme/StyledEle'
import { Breadcrumb } from '../shared/Breadcrumbs'
import { GOOGLE_MAP_KEY } from '../../constants/appConstant'
import { Avatar, Box, Button, ListItemAvatar, ListItemButton, ListItemText, Menu, Typography } from '@mui/material'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import MarkerImage from "../../assets/images/MovingBike.svg";
import { PlayBackMarker } from './PlayBackMarker'
import CommonDropDown from '../google-maps/CommonDropDown'
import playBackService from '../../services/playBackService'
import moment from 'moment';
import { Dark, Day } from "../../theme/gmapTheme";
import { useSelector } from 'react-redux'
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri'
import { FiLayers } from 'react-icons/fi'
//map Layer icon
import DefaultMap from '../../assets/images/roadamap.png'
import Terrain from '../../assets/images/terrain.png'
import Satellite from '../../assets/images/satellite.png'
import { DateRangePicker} from "rsuite";
import { format, getMonth, set } from "date-fns";
import logServices from "../../services/logService";


 
const globalJson1 =
localStorage.getItem("userData") !== null
  ? JSON.parse(localStorage.getItem("userData"))
  : null;
var globaldisplayview = "";
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;

if (
"null" === localStorage.getItem("userData") ||
null === localStorage.getItem("userData")
) {
} else {
globalJson =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : 0;
globalOrgId = globalJson.orgId;
globalOrgType = globalJson.userOrgType;
globaluserId = globalJson.id;
globalRoleId = globalJson.roleId;
globalResellerId = globalJson.resellerId;
globaldisplayview = globalJson.displayView;
}

function PlaybackAnimate() {

  //SetResponce Data
  const [playBackData, setPlayBackData] = useState(null);
  //Cor Data
  const [corrdinates, setCorrdinates] = useState([]);
  //Get All Data
  const [allData, setAllData] = useState([]);
  var TableArry = [...allData]

  // Date range pikcker

  //Date Range Picker
  const [state, setState] = useState({
    start: moment(),
    end: moment(),
    });
    const {start, end } = state;
    const handleCallback = (start, end) => {
    setState({ start, end });
    handleSubmit();
    };
    const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');

    const handleEvent = (event, picker) => {
        
    }

  const [fromDate, setFromDate] = useState(moment().format('yyyy-MM-DD 00:00:00'));
  const [toDate, setToDate] = useState(moment().format('yyyy-MM-DD hh:mm:ss'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customCal, setCustomCal] = useState(false);
  //date picker
  const open = Boolean(anchorEl);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleCloseCalc();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseCalc = () => {
    setCustomCal(false);
  }
  const getCustomDate = (e) => {
      setFromDate(format(e[0], "yyyy-MM-dd" +" "+ "00:00:00"));
      setToDate(format(e[1], "yyyy-MM-dd" +" "+ "23:59:59"));
      handleCloseCalc();
  };

  const customMonthRange = (event) => {
    setAnchorEl(true);
  };

  const getToday = () => {
    setFromDate(moment().format("yyyy-MM-DD") + " 00:00:00");
    setToDate(moment().format("yyyy-MM-DD HH:mm:ss"));
    handleClose();
  };

  const getYesderday = () => {
    setFromDate(moment().subtract(1, 'days').format("yyyy-MM-DD") +" "+ "00:00:00");
    setToDate(moment().subtract(1, 'days').format("yyyy-MM-DD") +" "+ "23:59:59");
    handleClose();
  };

  const customRange = () => {
    setCustomCal(true);
    handleClose();
  }

  useEffect(() => {
    console.log('fromDate', fromDate, 'toDate', toDate);
  },[fromDate])

  useEffect(async () => {
    var request = {};
    request.message = "Tracking > PlayBack";
    request.action = "View";
    request.featureName = "PlayBack";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;

    const responseData = await logServices.saveAuditLogDetails(request);
  },[])


  //Call Get Services
  const handleSubmit = async (formData) => {
    var request = {};
    request.truckId= formData.vehicle;
    request.orgId= formData.fleet;
    request.startDate= `${fromDate}`
    request.endDate= `${toDate}`
    request.startOdometer= 0
    request.endOdometer= 0;
    const data = (await playBackService.getPlayBackData(request)).data;
    setPlayBackData(data);
    var pathArry=[];
    for(let i=0; i < data.playBackList[0].playBackList.length; i++){
    var pathData={};
    pathData.lat=parseFloat(data.playBackList[0].playBackList[i].latlng.split(',')[0]);
    pathData.lng=parseFloat(data.playBackList[0].playBackList[i].latlng.split(',')[1]);
    pathArry.push(pathData);
  }
  setCorrdinates(pathArry);
  }

 

  //
var lineCoordinates = [
    {lng:80.67375,lat:16.51256},
    {lng:80.67375,lat:16.51256},
    {lng:80.67234,lat:16.51253},
    {lng:80.66956,lat:16.51496},
    {lng:80.66719,lat:16.51222},
    {lng:80.66447,lat:16.509},
    {lng:80.66231,lat:16.50631},
    {lng:80.66086,lat:16.50572},
    {lng:80.66109,lat:16.50562},
    {lng:80.66109,lat:16.50562},
    {lng:80.66093,lat:16.50567},
    {lng:80.66102,lat:16.5052},
    {lng:80.66416,lat:16.50411},
    {lng:80.66421,lat:16.50411},
    {lng:80.66471,lat:16.50393},
    {lng:80.66535,lat:16.50358},
    {lng:80.66536,lat:16.50359},
    {lng:80.66523,lat:16.50343},
    {lng:80.6638,lat:16.50023},
    {lng:80.66336,lat:16.49925},
    {lng:80.66319,lat:16.49898},
    {lng:80.6643,lat:16.49683},
    {lng:80.66577,lat:16.49699},
    {lng:80.66685,lat:16.49574},
    {lng:80.66596,lat:16.4946},
    {lng:80.66567,lat:16.49424},
    {lng:80.66594,lat:16.49462},
    {lng:80.66816,lat:16.49635},
    {lng:80.6694,lat:16.49899},
    {lng:80.67059,lat:16.50169},
    {lng:80.67183,lat:16.50424},
    {lng:80.67085,lat:16.50648},
    {lng:80.67206,lat:16.50996},
    {lng:80.67279,lat:16.51239},
    {lng:80.67436,lat:16.51628},
    {lng:80.67395,lat:16.51331},
    {lng:80.67343,lat:16.51338},
    {lng:80.67325,lat:16.51305},
    {lng:80.67393,lat:16.51301},
    {lng:80.67407,lat:16.51272},
    {lng:80.67407,lat:16.51272}
    ];
    
  // var lineCoordinates = [corrdinates];
  //isLoaded
  const [libraries] = useState(["drawing", "places", "geometry"])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    version:'weekly'
  })
  //State
  const [map, setMap] = React.useState(null);
  //Center Position
  const [center, setCenter] = useState({lat:20.5937, lng:78.9629});
  //Onload
  const gmode = useSelector((state) => state.controls.customizer.mapMode);
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // for (var i = 0; i < lineCoordinates.length; i++) {
    //   var pos = new window.google.maps.LatLng(corrdinates[i].lat, corrdinates[i].lng);
    //   bounds.extend(pos);
    // }
    map.fitBounds(bounds);
    setMap(map);


    //animatePolyline
    //animatePolyline()
  }, [gmode])

  //Unmount
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  //map style
  const containerStyle = {
    height: "85vh",
    width: "100%",
    borderRadius:'15px'
  };
  //Onload Polyline
  const onLoadPolyline = polyline => {
  setPolyIcon(polyline.get('icons'))
  };
  const [getPolyIcon, setPolyIcon] = useState(100);


  //Polyline Options
  var lineSymbol = {
    path: window.google?.maps.SymbolPath.CIRCLE,
    scale: 8,
    fillColor: '#566895',
    fillOpacity: 1,
    strokeColor: '#282c41',
    strokeOpacity: 1,
    strokeWeight: 3,
};


const [playPath, setPlayPath] = useState([]);
var linePartArr=[...playPath];
const [play, setPlay] = useState(false);
const tick = useRef();
const firstStart = useRef(true);
const [init, setInit] = useState(0);

const playAnything = () => {
  setPlay(!play);
}

useEffect(() => {
  if (firstStart.current) {
    firstStart.current = !firstStart.current;
    return;
  }
  if(play){
    animatePolyline()
  }else{
    clearInterval(tick.current)
  }
},[play])

// Stop Animation
const StopAnimatoin = () => {
  setPlayPath([]);
  setAllData([]);
  setPlay(false);
  clearInterval(tick.current);
  setInit(0);
}
//// //animate the drawing of the polyline


 const animatePolyline = () => {
  var i = init;
  //var play = true;
  var pauseLineRemove = 1500;
  var pauseRedrawLine = 1000;
  //experiment with the speed based on the total parts in the line
  var drawSpeed = newSpeed;
  if(play){
  setNewZoom(8);
  tick.current = setInterval(() => {
      //check if the end of the array is reached
      if (i + 1 == corrdinates.length && play) {
      }
    //create a line part between the current and next coordinate
        var part = [];
        part.push(corrdinates[i]);
        //part.push(lineCoordinates[i + 1]);
      if(corrdinates[i] !== undefined){
        linePartArr=[...linePartArr, ...part];
        setPlayPath(linePartArr);
      }else{
        clearInterval(tick.current)
      }

      //render All Data
      var singleData = []
      singleData.push(playBackData.playBackList[0].playBackList[i]);
      if(playBackData.playBackList[0].playBackList[i] !== undefined){
        TableArry=[...singleData, ...TableArry];
        setAllData(TableArry);
      }else{
        clearInterval(tick.current)
      }

      i++;
      setInit(i);

  }, drawSpeed);
  }else{
    clearInterval(tick.current);
  }
}

const animateIcon = () => {
  var lineOffset = 0;
  //experiment with the speed based on the length of the line
  var iconSpeed = 1.25;
  //move the icon
  if (linePartArr.length <= 40) {
    var aniIcon = setInterval(function () {
      lineOffset = (lineOffset + iconSpeed) % 200;
      var lineIcon = getPolyIcon[0];
      lineIcon.offset = lineOffset / 2 + '%';
      //lineIcon.offset = lineOffset;
      setPolyIcon(lineIcon.offset);
  }, 300);
  }else{
    clearInterval(aniIcon);
  }
}


  //Controls Start
  //setZoomFunction
  const [newZoom, setNewZoom] = useState(5);
  const handleZoom = (e,newZoom) => {
    if (newZoom !== null) {
      setNewZoom(newZoom);
    }
  }
  //SetBorward
  const moveBackword = () => {
    setPlay(false);
    var splicArry = playPath.splice(-1);
    var allDatas = allData.splice(-1);
    setPlayPath([...playPath]);
    setAllData([...allData]);
    setInit(init - 1);
  }
  //SetForward
  const moveForward = () => {
    setPlay(false);
    var nextCor = corrdinates[playPath.length]
    var nextData = corrdinates[allData.length]
    setPlayPath([...playPath, nextCor]);
    setAllData([...allData, nextData]);
    setInit(init + 1);
  }
  //setSpeed
  const [newSpeed, setNewSpeed] = useState(1000);
  const handleSpeed = (e, newSpeed) => {
    setNewSpeed(newSpeed)
  }

  var icons = {
      url:MarkerImage,
      scale: .1,
      rotation:0,
      fillOpacity: 1,
  }

//Map Controls
const [mapType, setMapType] = useState("roadmap");
const [openMapEl, setOpenMapEl] = useState(null);
const openMap = Boolean(openMapEl);
const handleMapLayer = (event) => {
  setOpenMapEl(event.currentTarget);
};
const handleMapLayerClose = () => {
  setOpenMapEl(null);
};
const handleMapType = (mapTypename) => {
  setMapType(mapTypename)
};


//Map Options
const options = {
  zoom:newZoom,
  center:center,
  minZoom:5,
  maxZoom:18,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false,
  disableDefaultUI: true,
  featureType: "poi.business",
  stylers: [{ visibility: "off" }],
  featureType: "transit",
  elementType: "labels.icon",
  stylers: [{ visibility: "off" }],
  featureType: "poi",
  stylers: [{ visibility: "off" }],
  tilt:47.5,
  heading: 320,
}




  return(
    <StyledPageLayout>
      <Breadcrumb mainDiv="Tracking" subDiv="Playback"></Breadcrumb>
      <CommonDropDown
        onSubmit={handleSubmit}
        //callbackLoadFn={callbackLoadFn}
        isShowVeh={true}
      />
      <Box sx={{mt:2}}>
      { isLoaded ? 
        <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        center= {corrdinates[0] !== '' ? corrdinates[0] : center}
        onUnmount={onUnmount}
        zoom={newZoom}
        options={options}
      >

        {/* Map Controls */}
      <ControlsBox className='zoom-control-wrapper' direction='row'
      sx={{
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'center',
      }}>
          <Button className="map-btn" onClick={() => setNewZoom(newZoom + 1)} disabled={newZoom === 20}><RiZoomInLine/></Button>
          <Button className="map-btn" onClick={() => setNewZoom(newZoom - 1)} disabled={newZoom === 5}><RiZoomOutLine/></Button>
          <Button className="map-btn"
          id="basicmaplayer-button"
          aria-controls={openMap ? 'maplayer-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMap ? 'true' : undefined}
          onClick={handleMapLayer}
          >
              <FiLayers />
          </Button>
          <Menu
        id="maplayer-menu"
        anchorEl={openMapEl}
        open={openMap}
        onClose={handleMapLayerClose}
        MenuListProps={{
          'aria-labelledby': 'maplayer-button',
        }}
        anchorOrigin={{
          vertical: '',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: '',
          horizontal: 'right',
        }}
      >
            <ListItemButton className="small-menuItem" selected={mapType === 'roadmap'} onClick={() => handleMapType('roadmap')}>
                <ListItemAvatar>
                    <Avatar src={DefaultMap} variant="rounded" sx={{width:'25px', height:'25px'}}/>
                </ListItemAvatar>
                <ListItemText>RoadMap</ListItemText>
            </ListItemButton>
            <ListItemButton className="small-menuItem" selected={mapType === 'terrain'} onClick={() => handleMapType('terrain')}>
                <ListItemAvatar>
                    <Avatar src={Terrain} variant="rounded" sx={{width:'25px', height:'25px'}}/>
                </ListItemAvatar>
                <ListItemText>Terrain</ListItemText>
            </ListItemButton>
            <ListItemButton className="small-menuItem" selected={mapType === 'satellite'} onClick={() => handleMapType('satellite')}>
                <ListItemAvatar>
                    <Avatar src={Satellite} variant="rounded" sx={{width:'25px', height:'25px'}}/>
                </ListItemAvatar>
                <ListItemText>Satellite</ListItemText>
            </ListItemButton>
      </Menu>
      </ControlsBox>

        {/* <Button onClick={playAnything}>Play</Button> */}
        {
          playPath.length === 0 && 
          <Polyline
          onLoad={onLoadPolyline}
          path={corrdinates}
          options={{
            strokeWeight:2,
            strokeColor:'0000ff',
          }}
        />
        }
        
        <Polyline
          onLoad={onLoadPolyline}
          path={playPath}
          options={{
            strokeWeight:2,
            strokeColor:'0000ff',
            // icons:[{
            //   url:MarkerImage,
            //   //offset:getPolyIcon,
            //   offset:getPolyIcon,
            // }]
          }}
        />

          <Marker
          position={corrdinates[corrdinates.length - 1]} 
          title= 'End Location'
          id='markerid'
          optimized={false}
        />
        <Marker
          position={corrdinates[0]} 
          title= 'Start Location'
          id='markerid'
          optimized={false}
        />
        

        {/* Controls Start */}
        {
          playBackData !== null &&
          <PlayBackMarker
            tripData={playBackData}
            handleZoom={handleZoom}
            newZoom={newZoom}
            playAnything={playAnything}
            play={play}
            StopAnimatoin={StopAnimatoin}
            moveBackword={moveBackword}
            moveForward={moveForward}
            playPath={playPath}
            allData={allData}
            handleSpeed={handleSpeed}
            newSpeed={newSpeed}
            handleCallback={handleCallback}
            handleEvent={handleEvent}
            label={label}
            handleClick={handleClick}
            handleCloseCalc={handleCloseCalc}
            getCustomDate={getCustomDate}
            getToday={getToday}
            getYesderday={getYesderday}
            customRange={customRange}
            fromDate={fromDate}
            toDate={toDate}
            anchorEl={anchorEl}
            customCal={customCal}
            handleClose={handleClose}
            open={open}
          />
        }
          
        {/* Controls End */}
        <Marker
          position={playPath[playPath.length - 1]} 
          //icon={MarkerImage}
          icon={icons}
          title= 'animated-marker'
          id='markerid'
          optimized={false}
          zIndex={3}
        />
      </GoogleMap> : ''
      }
      </Box>
    </StyledPageLayout>
  ) 
}

export default React.memo(PlaybackAnimate)
