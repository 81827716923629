import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Switch,
} from "@mui/material";
//import TextInput from '../shared/TextInput';
import DraggableDialog from "../shared/DailogDrag";
// import RadioInput from '../shared/RadioInput';
// import SelectInput from '../shared/SelectInput';
// import DatePicker from '../shared/DatePicker';
// import FeatureService from '../../services/featureService';
import RoleSetttingService from "../../services/roleSetttingService";

//import moment from 'moment';
import Checkbox from "@mui/material/Checkbox";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import MinimizeIcon from "@mui/icons-material/Minimize";

import { styled } from "@mui/material/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CommonDropDown from "./CommonDropDown";
import {
  StyledCardShadow,
  StyledFiterButton,
  StyledFormControlLabel,
  StyledSwitch,
  StyledTableTitle,
} from "../../theme/StyledEle";
import { Masonry } from "@mui/lab";
import { useTheme } from "@mui/material";
import RolesettinsDropdown from "./RoleSettingsDropdown";
//const RESTRICTED_ID = 1;
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin: "-0.1%",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(32,177,162,1)",
    flexDirection: "row-forward",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CreateRoleSettings() {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [listallfeature, setListallfeature] = useState([]);
  const [selectedExpertArr, setSelectedExpertArr] = useState([]);
  const [selectedButtonArr, setSelectedButtonArr] = useState([]);
  const [count, setCount] = useState(0);
  //const [expandVal, setExpandVal] = useState(false);
  //const [seltdResellerId, setSeltdResellerId] = useState("");
  const [payload, setPayload] = useState(null);

  const getData = (data) => {


    setCount(count + 1);

    var dataEdit = {};
    dataEdit.orgId = data.orgId;
    dataEdit.roleId = data.role;
    setPayload(dataEdit);
    var request = {};
    request.resellerId = data.orgnization;
    request.orgId = data.orgId;
    request.roleId = data.role;
    request.page = 0;
    request.size = 100;
    request.searchText = "";
    RoleSetttingService.getEditRoleFeature(dataEdit)
      .then((res1) => {
        var clonedExpertArr = [];
        var clonedExpertArrBtn = [];
        var sltdExt = [];
        sltdExt = res1.data.dataResponse;
        for (var i = 0; i < sltdExt.length; i++) {
          clonedExpertArr.push(sltdExt[i].linkfeatureId);
          if (
            null !== sltdExt[i].buttonoperation &&
            "" !== sltdExt[i].buttonoperation
          ) {
            for (
              var j = 0;
              j < sltdExt[i].buttonoperation.split(",").length;
              j++
            ) {
              clonedExpertArrBtn.push(
                sltdExt[i].linkfeatureId +
                  "_" +
                  sltdExt[i].buttonoperation.split(",")[j]
              );
            }
          }
        }
        setSelectedButtonArr(clonedExpertArrBtn);
        setSelectedExpertArr(clonedExpertArr);
        RoleSetttingService.getFeaturListAll(request)
          .then((res) => {
            setListallfeature(res.data.main);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  const theme = useTheme();
  // const buttonStyle = {
  //   fontSize: theme.text.fontSize,
  //   textTransform: "none",
  //   color: "#fff",
  //   m: 1,
  // };

  /*
  const FormLayout = ({ children, title }) => {
    return (
      <Box
        sx={{
          marginY: "20px",
          "& .MuiOutlinedInput-root": { height: "25px" },
          "& .MuiFormControlLabel-label": { fontSize: theme.text.fontSize },
          "& .MuiSvgIcon-root": { height: "15px", width: "15px" },
        }}
      >
        <fieldset style={{ border: "1px solid black" }}>
          <legend style={{ fontWeight: 600 }}> {title} </legend>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </fieldset>
      </Box>
    );
  };
  */

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState("panel0");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    //setExpandVal(newExpanded ? panel : false);
  };

  const handleSelectedButtons = (e, id) => {
    let clonedExpertArr = [...selectedButtonArr];
    if (e.target.checked === true) {
      clonedExpertArr.push(e.target.value);
      setSelectedButtonArr(clonedExpertArr);
    } else {
      let filtered = clonedExpertArr.filter((expert) => {
        return expert !== e.target.value;
      });
      setSelectedButtonArr(filtered);
      clonedExpertArr = [];
      for (var i = 0; i < filtered.length; i++) {
        clonedExpertArr.push(filtered[i]);
      }
    }
  };

  const handleSelectedExperts = (e) => {
    let clonedExpertArr = [...selectedExpertArr];
    if (e.target.checked === true) {
      clonedExpertArr.push(e.target.value);
      setSelectedExpertArr(clonedExpertArr);
    } else {
      let filtered = clonedExpertArr.filter((expert) => {
        return expert !== e.target.value;
      });
      setSelectedExpertArr(filtered);
    }
    e.preventDefault();
  };

  const saveCommon = (e, obj, submunu) => {
    // var globalJson =
    //   localStorage.getItem("userData") !== null
    //     ? JSON.parse(localStorage.getItem("userData"))
    //     : null;

    var array = [];
    var newMainObj1 = {};
    newMainObj1.linkfeatureId = obj.linkfeatureId;
    newMainObj1.id = obj.id;
    newMainObj1.status = obj.status;
    newMainObj1.roleId = payload.roleId;
    newMainObj1.orgId = payload.orgId;
    newMainObj1.featureName = obj.featureName;
    newMainObj1.link = obj.link;
    newMainObj1.buttonoperation = "";
    newMainObj1.status = "Active";
    if ("" === submunu) {
      submunu = [];
    }
    submunu.push(newMainObj1);
    var bntVal = [];
    for (var i = 0; i < submunu.length; i++) {
      array.push(submunu[i].linkfeatureId);
      if (
        null !== submunu[i].buttonoperation &&
        "" !== submunu[i].buttonoperation &&
        undefined !== submunu[i].buttonoperation
      ) {
        for (var j = 0; j < submunu[i].buttonoperation.split(",").length; j++) {
          bntVal.push(
            submunu[i].linkfeatureId +
              "_" +
              submunu[i].buttonoperation.split(",")[j]
          );
        }
      }
    }
    let clonedExpertArr = [...selectedExpertArr];
    let newArray = [];
    for (var jone = 0; jone < clonedExpertArr.length; jone++) {
      for (var ione = 0; ione < array.length; ione++) {
        if (array[ione] === clonedExpertArr[jone]) {
          newArray.push(array[ione]);
        }
      }
    }

    let clonedExpertArrBtn = [...selectedButtonArr];
    let newArrayBtn = [];
    for (var jtwo = 0; jtwo < clonedExpertArrBtn.length; jtwo++) {
      for (var itwo = 0; itwo < bntVal.length; itwo++) {
        if (bntVal[itwo] === clonedExpertArrBtn[jtwo]) {
          newArrayBtn.push(bntVal[itwo]);
        }
      }
    }
    var fnlSendArry = [];
    for (var ithree = 0; ithree < submunu.length; ithree++) {
      submunu[ithree].status = 0;
      for (var jthree = 0; jthree < newArray.length; jthree++) {
        if (submunu[ithree].linkfeatureId === newArray[jthree]) {
          submunu[ithree].status = 1;
        }
      }
      fnlSendArry.push(submunu[ithree]);
    }

    for (var ifour = 0; ifour < fnlSendArry.length; ifour++) {
      var bntnAdd = "";
      for (var jfour = 0; jfour < newArrayBtn.length; jfour++) {
        if (
          newArrayBtn[jfour].split("_")[0] === fnlSendArry[ifour].linkfeatureId
        ) {
          if (jfour === 0) {
            bntnAdd = newArrayBtn[jfour].split("_")[1];
          } else {
            if ("" === bntnAdd) {
              bntnAdd = newArrayBtn[jfour].split("_")[1];
            } else {
              bntnAdd = bntnAdd + "," + newArrayBtn[jfour].split("_")[1];
            }
          }
        }
      }
      fnlSendArry[ifour].buttonoperation = bntnAdd;
    }

    var fnlSendArryObj = [];
    for (var ifive = 0; ifive < fnlSendArry.length; ifive++) {
      var newMainObj = {};
      newMainObj.linkfeatureId = fnlSendArry[ifive].linkfeatureId;
      newMainObj.id = fnlSendArry[ifive].id;
      newMainObj.status = fnlSendArry[ifive].status;
      newMainObj.roleid = payload.roleId;
      newMainObj.orgid = payload.orgId;
      newMainObj.featureName = fnlSendArry[ifive].featureName;
      newMainObj.link = fnlSendArry[ifive].link;
      newMainObj.buttonoperation = fnlSendArry[ifive].buttonoperation;
      fnlSendArryObj.push(newMainObj);
    }
    var jsonobj = {};
    jsonobj.featureForm = fnlSendArryObj;

    RoleSetttingService.getSaveRoleFeature(jsonobj)
      .then((res1) => {
        var obj1 = {};
        if (res1.data.resultText === "Success") {
          obj1.dialogButtonText = "Saved Status";
          obj1.submitButtonText = "";
          obj1.closeButtonText = "Close";
          obj1.dialogText = "Data Saved Successfully";
        } else {
          obj1.dialogButtonText = "Saved Status";
          obj1.submitButtonText = "";
          obj1.closeButtonText = "Close";
          obj1.dialogText = "Data Not Saved Properly";
        }
        setDataStatus(true);
        setDialogContent(obj1);
        setDataStatusConfirm(false);
        setOpen(true);
        // if (res1.status === 200) {
        //   setTimeout(() => onCancel(), 2000);
        // }
      })
      .catch((err) => {});
  };
  function renderSwitch(param) {
    switch (param) {
      case "1":
        return "create";
      case "2":
        return "view";
      case "3":
        return "delete";
      case "4":
        return "edit";
      case "5":
        return "excel";
      case "6":
        return "pdf";
      case "7":
        return "assign";
      case "8":
        return "unassign";
      case "9":
        return "importfunction";
      case "10":
        return "actionsimstatus";
      case "12":
        return "changeplan";
      case "13":
        return "drivertoggle";
      case "14":
        return "createvehicle";
      case "15":
        return "firmwareupdate";
      case "16":
        return "deviceConfigUpdate";
      case "17":
        return "deviceRegistration";
      case "18":
        return "recentActivation";
      case "19":
        return "CancelledLicence";
      case "20":
        return "DownloadLicence";
      case "21":
        return "ImportDevicewithLicence";
      case "22":
        return "DealerOperator";
      case "23":
        return "helpdeskDashboard";
      case "24":
        return "UserActivation";
      case "25":
        return "MultipleOTA";
      case "31":
        return "CreateCCO";
      case "34":
        return "oneYearReport";
      case "37":
        return "vehicleRating";
      case "38":
        return "ResendActivation";
      case "39":
        return "LastConnected";
      case "40":
        return "VehicleSummary";
      case "41":
        return "BatteryDetails";
      case "42":
        return "MotorProfile";
      case "43":
        return "Device Raw Feed";
      case "44":
        return "Rejected Raw Feed";
      case "45":
        return "Rejected Staging Feed";
      case "46":
        return "Staging Feed";
      case "47":
        return "Raw Feed";  
      case "48":
        return "Charging Report";
      case "49":
        return "Charging Temperature Behaviour";
      case "50":
        return "DisCharging Temperature Behaviour";
      case "51":
        return "Vehicle Last Connected";
      case "52":
        return "Voltage Difference";
      case "53":
        return "Degradation";
      case "54":
          return "Deep Discharge";
      default:
        return "create";
    }
  }
  const themes = useTheme();

  return (
    <Box>
      {/* <CommonDropDown handleSmt={handleSubmit} /> */}
      <RolesettinsDropdown getData={getData}/>
      <Box sx={{ my: 2 }}>
        <Masonry columns={2} spacing={1}>
          {listallfeature.length > 0 &&
            listallfeature.map((dataObj, index) => (
              <Grid item lg={6}>
                {dataObj.featureName !== undefined && (
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle>
                          {dataObj.featureName.toUpperCase()}
                        </StyledTableTitle>
                      }
                      subheader={
                        <Typography variant="caption">Permissions</Typography>
                      }
                      action={
                        <FormControl>
                          {/* <StyledFormControlLabel>{dataObj.featureName}</StyledFormControlLabel> */}
                          <StyledSwitch
                            checked={
                              selectedExpertArr.length > 0
                                ? selectedExpertArr.indexOf(
                                    dataObj.linkfeatureId
                                  ) > -1
                                  ? true
                                  : false
                                : false
                            }
                            name={"checkbox" + dataObj.linkfeatureId}
                            onChange={(e) => handleSelectedExperts(e)}
                            value={dataObj.linkfeatureId || ""}
                          />
                        </FormControl>
                      }
                    />

                    <CardContent sx={{ py: 0 }}>
                      {dataObj.sub.length > 0 &&
                        dataObj.sub.map((obj, index) => (
                          // <div key={obj.id}>
                          <div>
                            {obj.name !== undefined && (
                              <Grid
                                container
                                sx={{
                                  border: `1px solid ${themes.palette.divider}`,
                                  my: 1,
                                }}
                              >
                                <Grid item lg={3}>
                                  <Checkbox
                                    checked={
                                      selectedExpertArr.length > 0
                                        ? selectedExpertArr.indexOf(
                                            obj.linkfeatureId
                                          ) > -1
                                          ? true
                                          : false
                                        : false
                                    }
                                    name={"checkbox" + obj.linkfeatureId}
                                    onChange={(e) => handleSelectedExperts(e)}
                                    value={obj.linkfeatureId || ""}
                                  />
                                  {obj.name + " "}
                                </Grid>
                                <Grid item lg={9}>
                                  <Grid container>
                                    {undefined !== obj.buttonoperation &&
                                      "" !== obj.buttonoperation &&
                                      null !== obj.buttonoperation &&
                                      obj.buttonoperation
                                        .split(",")
                                        .map((ob, i) => (
                                          <Grid item lg={4} key={i}>
                                            <Checkbox
                                              checked={
                                                selectedButtonArr.length > 0
                                                  ? selectedButtonArr.indexOf(
                                                      obj.linkfeatureId +
                                                        "_" +
                                                        ob
                                                    ) > -1
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              name={"checkbox" + obj.name + ob}
                                              onChange={(e) =>
                                                handleSelectedButtons(
                                                  e,
                                                  obj.linkfeatureId + "_" + ob
                                                )
                                              }
                                              value={
                                                obj.linkfeatureId + "_" + ob ||
                                                ""
                                              }
                                            />{" "}
                                            {renderSwitch(ob)}
                                          </Grid>
                                        ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        ))}
                    </CardContent>
                    <CardActions>
                      <StyledFiterButton
                        type="button"
                        variant="contained"
                        color="success"
                        disableElevation
                        onClick={(e) => {
                          saveCommon(e, dataObj, dataObj.sub);
                        }}
                      >
                        Save
                      </StyledFiterButton>
                    </CardActions>
                  </StyledCardShadow>
                )}
              </Grid>
            ))}
        </Masonry>

        {/* {listallfeature.length > 0 &&
          listallfeature.map((dataObj, index) => (
            <div className="text" key={dataObj.id}>
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange("panel" + index)}
                sx={{ paddingBottom: "0.10%", lineHeight: "1.2px" }}
              >
                <AccordionSummary
                  aria-controls={"panel" + index}
                  id={"panel" + index}
                  sx={{
                    color: "white",
                    minHeight: "18px !important",
                    borderRadius: "4px",
                    paddingLeft: "3px !important",
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                  }}
                >
                  <Typography sx={{ fontWeight: "200", lineHeight: "1.2" }}>
                    {" "}
                    {dataObj.featureName.toUpperCase()}
                  </Typography>
                  {expanded === "panel" + index && (
                    <MinimizeIcon
                      sx={{
                        marginLeft: "auto",
                        marginTop: "-0.3%",
                        color: "#f0dfdf",
                        transform: "scale(1.6)",
                        fontSize: "90",
                        fontWeight: "bold",
                        strokeWidth: 3,
                      }}
                      // style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" )) ? "none" : 'block' }}
                    />
                  )}
                  {expanded !== "panel" + index && (
                    <AddTwoToneIcon
                      sx={{
                        marginLeft: "auto",
                        marginTop: "0.1%",
                        pointerEvents: "auto",
                        transform: "scale(1.6)",
                        color: "#f0dfdf",
                        fontSize: "90",
                        fontWeight: "bolder !important",
                        strokeWidth: 3,
                      }}
                      //style={{ display: (expandVal === true && (expandedInsdL1 === "panelInside" )) ? "none" : 'block' }}
                    />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid
                      container
                      sx={{ marginLeft: "1%", marginTop: "0.5%" }}
                    >
                      <Grid item xs={11}>
                        <Checkbox
                          checked={
                            selectedExpertArr.length > 0
                              ? selectedExpertArr.indexOf(
                                  dataObj.linkfeatureId
                                ) > -1
                                ? true
                                : false
                              : false
                          }
                          name={"checkbox" + dataObj.linkfeatureId}
                          onChange={(e) => handleSelectedExperts(e)}
                          value={dataObj.linkfeatureId || ""}
                        />
                        {dataObj.featureName}
                      </Grid>
                      <Grid item xs={1} sx={{ paddingTop: "0.4%" }}>
                        <button
                          style={{
                            background: "#1D8C9F",
                            borderColor: "#1D8C9F",
                            color: "white",
                            fontSize: "87%",
                          }}
                          type="button"
                          onClick={(e) => {
                            saveCommon(e, dataObj, dataObj.sub);
                          }}
                        >
                          Save
                        </button>
                      </Grid>
                    </Grid>
                    {dataObj.sub.length > 0 &&
                      dataObj.sub.map((obj, index) => (
                        <div className="text" key={obj.id}>
                          <Grid
                            container
                            sx={{ marginLeft: "1%", marginTop: "0.5%" }}
                            >
                            <Grid item xs={2}>
                              <Checkbox
                                checked={
                                  selectedExpertArr.length > 0
                                    ? selectedExpertArr.indexOf(
                                        obj.linkfeatureId
                                      ) > -1
                                      ? true
                                      : false
                                    : false
                                }
                                name={"checkbox" + obj.linkfeatureId}
                                onChange={(e) => handleSelectedExperts(e)}
                                value={obj.linkfeatureId || ""}
                              />
                              {obj.name + " "}
                            </Grid>
                            {undefined !== obj.buttonoperation &&
                              "" !== obj.buttonoperation &&
                              null !== obj.buttonoperation &&
                              obj.buttonoperation.split(",").map((ob, i) => (
                                <Grid item xs={2}>
                                  <div className="text" key={i}>
                                    <Checkbox
                                      checked={
                                        selectedButtonArr.length > 0
                                          ? selectedButtonArr.indexOf(
                                              obj.linkfeatureId + "_" + ob
                                            ) > -1
                                            ? true
                                            : false
                                          : false
                                      }
                                      name={"checkbox" + obj.name + ob}
                                      onChange={(e) =>
                                        handleSelectedButtons(
                                          e,
                                          obj.linkfeatureId + "_" + ob
                                        )
                                      }
                                      value={obj.linkfeatureId + "_" + ob || ""}
                                    />{" "}
                                    {renderSwitch(ob)}
                                  </div>
                                </Grid>
                              ))}
                          </Grid>
                        </div>
                      ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))} */}
      </Box>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          dialogText={dialogContentConfirm.dialogText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </Box>
  );
}
