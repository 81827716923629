import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent,Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import VDRDropDown from "./VDRDropDown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import logServices from "../../services/logService";

import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import { useAuditLogMutation, useEvdataMutation } from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";

const text =
  "Note: EV Data Report shows data about the machine for each minute for a selected time period.";

const EVDataReport = () => {
  const [responseData, setResponseData] = useState([]);
  const theme = useTheme();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }
  useEffect(async () => {
    // var request = {};
    // request.message = "Reports > EvData";
    // request.action = "View";
    // request.featureName = "EvData";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName= globalUserName;
    // const responseData = await logServices.saveAuditLogDetails(request);
    auditPayload.message = 'Reports > EV Data';
    auditPayload.action = 'View';
    auditPayload.featureName = 'EV Data';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditPayload.orgId = orgId;
    auditPayload.userId = userId;
    auditPayload.orgName = orgName;
    auditPayload.userName= userName;

    auditLog(auditPayload);

  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      // {
      //   Header: "Vehicle",
      //   accessor: "vehicleno",
      // },
      {
        Header: "Portal Received Time",
        accessor: "receivedDate",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "GPS Location",
        accessor: "gpsLocation",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpsLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.gpsLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpsLocation}
                >
                  {row.original.gpsLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpsLocation}
                  key={row.original.gpsLocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "GNSS Status",
        accessor: "gnssStatus",
      },
      {
        Header: "GNSS PDOP",
        accessor: "gnssPdop",
      },
      {
        Header: "GNSS HDOP",
        accessor: "gnssHdop",
      },
      {
        Header: "Gps Odometer",
        accessor: "startOdometer",
      },
      {
        Header: "CAN Odometer",
        accessor: "evCanOdometer",
      },
      {
        Header: "Trip Odometer",
        accessor: "odometer",
      },
      {
        Header: "Ignition",
        accessor: "ignition",
      },
      {
        Header: "Speed",
        accessor: "speed",
      },
      {
        Header: "Movement",
        accessor: "movement",
      },
      {
        Header: "Altitude",
        accessor: "altitude",
      },
      {
        Header: "Direction",
        accessor: "direction",
      },
      {
        Header: "GSM Signal",
        accessor: "gsmSignal",
      },
      {
        Header: "Active GSM Operator",
        accessor: "activeGsmOprtr",
      },
      {
        Header: "GSM Cell ID",
        accessor: "cellId",
      },
      {
        Header: "GSM Area Code",
        accessor: "mobileCountryCode",
      },
      {
        Header: "External Voltage",
        accessor: "externalBatteryVolt",
      },
      {
        Header: "Extended External Voltage",
        accessor: "extendedExternalBattery",
      },
      {
        Header: "TCU Battery Voltage",
        accessor: "intBatVoltPercentage",
      },
      {
        Header: "TCU Battery Current",
        accessor: "ac",
      },
      {
        Header: "TCU Battery Level",
        accessor: "batteryLevel",
      },
      {
        Header: "Digital Input 1",
        accessor: "digitalInput1",
      },
      {
        Header: "Digital Input 2",
        accessor: "digitalInput2",
      },
      {
        Header: "Digital Input 3",
        accessor: "digitalInput3",
      },
      {
        Header: "Digital Input 4",
        accessor: "digitalInput4",
      },
      {
        Header: "Analog Input 1",
        accessor: "analogInput1",
      },
      {
        Header: "Analog Input 2",
        accessor: "analogInput2",
      },
      {
        Header: "Digital Output 1",
        accessor: "digitalOutput1",
      },

      {
        Header: "Digital Output 2",
        accessor: "digitalOutput2",
      },
      {
        Header: "Extended Analog Input 1",
        accessor: "extAnalogInput1",
      },

      {
        Header: "Extended Analog Input 2",
        accessor: "extAnalogInput2",
      },
      {
        Header: "Data Mode",
        accessor: "dataMode",
      },
      {
        Header: "Sleep Mode",
        accessor: "sleepMode",
      },
      {
        Header: "Instant Movement",
        accessor: "instMovement",
      },
      {
        Header: "Axis X",
        accessor: "axisX",
      },
      {
        Header: "Axis Y",
        accessor: "axisY",
      },
      {
        Header: "Axis Z",
        accessor: "axisZ",
      },
      {
        Header: "GND Sense",
        accessor: "gndSense",
      },
      {
        Header: "Dallas Tempertature 1",
        accessor: "dallasTemp1",
      },
      {
        Header: "Dallas Tempertature 2",
        accessor: "dallasTemp2",
      },
      {
        Header: "Dallas Tempertature 3",
        accessor: "dallasTemp3",
      },
      {
        Header: "Dallas Tempertature 4",
        accessor: "dallasTemp4",
      },
      {
        Header: "Dallas Tempertature ID 1",
        accessor: "dallasTempId1",
      },
      {
        Header: "Dallas Tempertature ID 2",
        accessor: "dallasTempId2",
      },
      {
        Header: "Dallas Tempertature ID 3",
        accessor: "dallasTempId3",
      },
      {
        Header: "Dallas Tempertature ID 4",
        accessor: "dallasTempId4",
      },
      {
        Header: "DOUT 1 OverCurrent",
        accessor: "dout1OverCurrent",
      },
      {
        Header: "DOUT 2 OverCurrent",
        accessor: "dout2OverCurrent",
      },
      {
        Header: "BT Status",
        accessor: "btStatus",
      },
      {
        Header: "Eco Score",
        accessor: "ecoScore",
      },
      {
        Header: "ICCID",
        accessor: "iccd",
      },
      {
        Header: "Motor",

        columns: [
          {
            Header: "CAN 0",
            accessor: "avl900",
          },
          {
            Header: "CAN 1",
            accessor: "avl901",
          },
          {
            Header: "CAN 2",
            accessor: "avl902",
          },
          {
            Header: "CAN 3",
            accessor: "avl903",
          },
          {
            Header: "CAN 4",
            accessor: "avl904",
          },
          {
            Header: "CAN 5",
            accessor: "avl905",
          },
          {
            Header: "CAN 6",
            accessor: "avl906",
          },
          {
            Header: "CAN 7",
            accessor: "avl907",
          },
          {
            Header: "CAN 8",
            accessor: "avl908",
          },
          {
            Header: "CAN 9",
            accessor: "tempSensor1",
          },
        ],
      },
      {
        Header: "Battery",

        columns: [
          {
            Header: "CAN 10",
            accessor: "tempSensor2",
          },
          {
            Header: "CAN 11",
            accessor: "tempSensor3",
          },
          {
            Header: "CAN 12",
            accessor: "cellBalancing",
          },
          {
            Header: "CAN 13",
            accessor: "avl913",
          },
          {
            Header: "CAN 14",
            accessor: "avl914",
          },
          {
            Header: "CAN 15",
            accessor: "avl915",
          },
          {
            Header: "CAN 16",
            accessor: "avl916",
          },
          {
            Header: "CAN 17",
            accessor: "avl917",
          },
          {
            Header: "CAN 18",
            accessor: "avl918",
          },
          {
            Header: "CAN 19",
            accessor: "avl919",
          },
          {
            Header: "CAN 20",
            accessor: "avl920",
          },
        ],
      },
      {
        Header: "CAN 21",
        accessor: "avl921",
      },
      {
        Header: "CAN 22",
        accessor: "avl922",
      },
      {
        Header: "CAN 23",
        accessor: "avl923",
      },
      {
        Header: "CAN 24",
        accessor: "avl924",
      },
      {
        Header: "CAN 25",
        accessor: "avl925",
      },
      {
        Header: "CAN 26",
        accessor: "avl926",
      },
      {
        Header: "CAN 27",
        accessor: "avl927",
      },
      {
        Header: "CAN 28",
        accessor: "avl928",
      },
      {
        Header: "CAN 29",
        accessor: "avl929",
      },
      {
        Header: "CAN 30",
        accessor: "avl930",
      },
      {
        Header: "CAN 31",
        accessor: "avl931",
      },
      {
        Header: "CAN 32",
        accessor: "avl932",
      },
      {
        Header: "CAN 33",
        accessor: "avl933",
      },
      {
        Header: "CAN 34",
        accessor: "avl934",
      },
      {
        Header: "CAN 35",
        accessor: "avl935",
      },
      {
        Header: "CAN 36",
        accessor: "avl936",
      },
      {
        Header: "CAN 37",
        accessor: "avl937",
      },
      {
        Header: "CAN 38",
        accessor: "avl938",
      },
      {
        Header: "CAN 39",
        accessor: "avl939",
      },
      {
        Header: "CAN 40",
        accessor: "avl940",
      },
      {
        Header: "CAN 41",
        accessor: "avl941",
      },
      {
        Header: "CAN 42",
        accessor: "avl942",
      },
    ],
    []
  );
  
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  // const onSubmit = async (data) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.sortBy = "desc";
  //   loadrequest.search = searchval;
  //   loadrequest.orderCol = "IMEI";
  //   console.log("check" + loadrequest);
  //   setAssignedval(loadrequest);

  //   if(loadrequest.vehId!=-1 && loadrequest.vehId!=0){
  //    const responseData = (await ReportServices.EVDataReport(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   }else{    
  //   setTotalElements(0);
  //   setTotalPages(0)
  //   }
  // };

  // const fetchVehicleDataDetails = async (searchText, columnName) => {
  //   loadrequest = assignedval;

  //   loadrequest.search = searchText;
  //   if (columnName === undefined) {
  //     loadrequest.columnName = "MESSAGE";
  //   } else {
  //     loadrequest.columnName = columnName;
  //   }
  //   const responseData = (await ReportServices.EVDataReport(loadrequest)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  //   setTotalElements(5);
  //   setTotalPages(0);
  // };

  // const handleSearch = (searchText) => {
  //   fetchVehicleDataDetails(searchText);
  //   setSearchval(searchText);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (await ReportServices.EVDataReport(loadrequest))
  //       .data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "EVDataReport/evDataXLS.xls?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehId=" +
  //       loadrequest.vehId;

  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "EVData";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;
  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };

  // const onChangePdf = async () => {
  //   loadrequest = assignedval;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);
  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "EVDataReport/evDataPDF.pdf?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehId=" +
  //       loadrequest.vehId;

  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > PDF";
  //     request.action = "View";
  //     request.featureName = "EVData";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;
  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };


  // Get Data from New Dropdown
  const [ getEvData, {data:evData, isLoading, isSuccess, isFetching} ] = useEvdataMutation();
  let commonPayload = {}
  const onSubmitForm = (data) => {
        setNewPage(0);
        setRowsPerPage(25);
        commonPayload.resellerId = resellerId;
        commonPayload.regionId = data.regionId;
        commonPayload.dealerId = data.dealerId;
        commonPayload.customerId = data.customerId;
        commonPayload.orgId = data.fleetId;
        commonPayload.vehId = data.vehicleId;
        commonPayload.search = "";
        commonPayload.startDate = data.fromDate;
        commonPayload.endDate = data.toDate;
        commonPayload.sortBy = "desc";
        commonPayload.page = newPage;
        commonPayload.size = rowsPerPage;
        commonPayload.orderCol = "IMEI";
        //Service Call
        setPayload(commonPayload);
        getEvData(commonPayload);

        auditPayload.message = "Reports > EV Data Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
        auditPayload.action = "Submit";
        auditPayload.featureName = "EV Data Report";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = userName;
        auditLog(auditPayload);
  }
   //Export PDF & Excel
   const [payload, setPayload] = useState(null);
   const onChangeXcl = async () => {
     try {
        var url =
        process.env.REACT_APP_API_BASE_URL +
        "EVDataReport/evDataXLS.xls?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orgId=" +
        payload.orgId +
        "&vehId=" +
        payload.vehId;
        window.open(url);
 
        auditPayload.message = 'Reports > EV Data > XLS';
        auditPayload.action = 'View';
        auditPayload.featureName = 'EV Data';
        auditPayload.customerName = 'All';
        auditPayload.fleetName = 'All';
        auditPayload.result = 'Success';
        auditPayload.access = 'Portal';
        auditPayload.severity = 'INFO';
        auditPayload.orgId = orgId;
        auditPayload.userId = userId;
        auditPayload.orgName = orgName;
        auditPayload.userName= userName;
        auditLog(auditPayload);
       fetch(url, {
         headers: {
           Authorization:
             "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
         },
       }) // FETCH BLOB FROM IT
         .then((response) => {
           response.blob();
         })
         .then((blob) => {})
         .catch((err) => {});
     } catch (error) {}
   };
   const onChangePDF = async () => {
 
     try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "EVDataReport/evDataPDF.pdf?startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&orgId=" +
          payload.orgId +
          "&vehId=" +
          payload.vehId;
          window.open(url);
 
          auditPayload.message = 'Reports > EV Data > PDF ';
          auditPayload.action = 'View';
          auditPayload.featureName = 'EV Data';
          auditPayload.customerName = 'All';
          auditPayload.fleetName = 'All';
          auditPayload.result = 'Success';
          auditPayload.access = 'Portal';
          auditPayload.severity = 'INFO';
          auditPayload.orgId = orgId;
          auditPayload.userId = userId;
          auditPayload.orgName = orgName;
          auditPayload.userName= userName;
          auditLog(auditPayload);
          
     } catch (error) {}
   };
  //Search Text
  const handleSearch = async (searchText) => {
      payload.search = searchText;
      setNewPage(0);
      setRowsPerPage(25);
      payload.page = 0;
      payload.rowsPerPage = 25;
      if(payload.search.length >= 3){
        getEvData(payload);
      }else if(payload.search.length === 0){
        getEvData(payload);
      }
      
  };
  //Page Change

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size=rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getEvData(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getEvData(payload);
    }
  };

  useEffect(() => {
    console.log('isSuccess', evData);
    if(isSuccess){
      setResponseData([]);
      if(evData.data !== null){
        setResponseData(evData?.data?.content);
        setTotalElements(evData?.data?.totalElements);
        setTotalPages(evData?.data?.totalPages);
      }
    }
  },[isSuccess])

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="EV Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"EV Data Report"} />
      <Box>
        {/* <VDRDropDown
          onSubmit={onSubmit}
          callbackLoadFn={callbackLoadFn}
          displayDate={true}
          allowDays={7}
        /> */}
        {/* <FilterDropdown getData={onSubmitForm} status={false} allowDays={7} allowToday={true} isShowAll={false} setToday={true}/> */}
      
        <FilterDropdown getData={onSubmitForm} time={true} status={false}  isShowAll={false} allowDays = {resellerId === 110224 ? 1 : 1} showTime = {true}  last7={false} last6Month={true}  allowedMonth = {resellerId === 110224 ? 6 : 3}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              includeExportButton={true}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              handleChange={handleSearch}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default EVDataReport;
