import { useTheme } from '@mui/material';
import { Circle, Marker } from '@react-google-maps/api';
import React from 'react'

export default function RouteMarkers(props) {
    const { routeData } = props;
    const theme = useTheme();
    const circleOptions = {
        fillColor:theme.palette.secondary.main,
        fillOpacity:.5,
        strokeWeight:1.2,
        strokeColor:theme.palette.secondary.main,
    }
  return (
            routeData?.map((item, index) => {
            let number = item.sequenceNo.toString();
            let lat = parseFloat(item.geoZoneLatLong.split(',')[0])
            let lng = parseFloat(item.geoZoneLatLong.split(',')[1])
            return(
                <>
                <Marker 
                    position={{lat:lat,lng:lng}}
                    options={{
                        label:{
                            text:number,
                            color:'#fff'
                        } 
                    }}
                />
                {/* Circle */}
                <Circle 
                    center={{lat:lat, lng:lng}}
                    radius={item.radius}
                    options={circleOptions}
                />
            </> 
            )   
        })
  )
}

