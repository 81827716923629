import Service from "./service";

const helpDeskServices = {
  getResellerdetails: (data) => {
    return Service.post("/helpdesk/getUserById ", data, null);
  },
  listcategory() {
    return Service.get("/helpdesk/listReferenceByCategory");
  },
  getvehiclelist: (data) => {
    return Service.post("/helpdesk/getSearchVehicle ", data, null);
  },
  createTicket: function (data) {
    return Service.post("/helpdesk/createTicket", data, null, {
      "Content-Type": "multipart/form-data",
    });
  },
  listticketdet: function (data) {
    return Service.post("/helpdesk/listServiceRequestByMultiSel", data, null);
  },
  listOrgUsers: function (data) {
    return Service.post("/helpdesk/listOrgUsers", data, null);
  },
  getVehicleDeviceByOrgId: function (data) {
    return Service.post("/helpdesk/getVehicleDeviceByOrgId", data, null);
  },
  getTicketDetails: function (id) {
    return Service.post("/helpdesk/getTicketdetails", { id });
  },
  saveComments: function (data) {
    return Service.post("/helpdesk/serviceRequestCommentEntry", data, null);
  },
  getCommentList: function (id) {
    return Service.post("/helpdesk/getCommentsList", { id });
  },
  listRecentActivities: function (id) {
    return Service.post("/helpdesk/getServiceHistory", { id });
  },
  updateTicket: function (data) {
    return Service.post("/helpdesk/updateTicket", data, null);
  },
  getAssignedList: function (data) {
    return Service.post("/helpdesk/getAssignedTo", data, null);
  },
  getUserList: function (data) {
    return Service.post("/helpdesk/getUsers", data, null);
  },
  getSettings: function (data) {
    return Service.post("/helpdesk/getSettings", data, null);
  },
  saveSettings: function (data) {
    return Service.post("/helpdesk/saveHelpDeskSettingsCreate", data, null);
  },
  uploadImageList: function (data) {
    return Service.post("/helpdesk/uploadFileList", data, null);
  },
};

export default helpDeskServices;
