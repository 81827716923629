import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { CardContent, CardHeader } from "@mui/material";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useFeedLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";

const text =
  "Note: RAW feeds MUST have VALID Header. This is for security reasons. Feeds with Invalid Header will be moved to RAW REJECTED. All other feeds will be stored and displayed here.From RAW, feeds are validated and then moved to STAGING if validation is successful.";

const RawFeed = ({ data, payload, isLoadingOut }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Logs > FeedLog > Raw Feed";
    auditPayload.action = "View";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Device Captured Date",
        accessor: "gpsDate",
        width:150,
      },

      {
        Header: "GpsFeed",
        accessor: "gpsFeed",
        width:800,
      },

      {
        Header: "Portal Received Date",
        accessor: "activityDate",
      },
      {
        Header: "Description",
        accessor: "message",
      },
    ],
    []
  );

  const [getFeedList, { data: rawFeedListdata, isLoading, isSuccess }] =
    useFeedLogListMutation();

  useEffect(() => {
    if (data !== undefined) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      } else {
        setResponseData([]);
        setTotalElements(0);
        setTotalPages(0);
      }
    }
  }, [data]);

  const onChangeXcl = async () => {
    let xlsfeed = "rawfeed";
    try {
      payload.feedStage = xlsfeed;
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FeedLog/FeedlogXLS.csv?vehicleId=" +
        payload.vehicleId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&imei=" +
        payload.imei +
        "&resellerId=" +
        payload.resellerId +
        "&xlsfeed=" +
        xlsfeed +
        "&url=" +
        payload.url;

      window.open(url);

    auditPayload.message = "Logs > FeedLog > Raw Feed > Export >Excel";
    auditPayload.action = "Download";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getFeedList(payload);
    } else if (payload.search.length === 0) {
      getFeedList(payload);
    }
  };
  //Page Change

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getFeedList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getFeedList(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (rawFeedListdata.data !== null) {
        setResponseData(rawFeedListdata.data.content);

        setTotalElements(rawFeedListdata.data.totalElements);
        setTotalPages(rawFeedListdata.data.totalPages);
      }
    }
  }, [isSuccess]);

  // const [responseData, setResponseData] = useState(rawFeedData);
  // const [statusCon, setStatus] = useState(status[0]);
  // const [newRawfeed, setNewRawfeed] = useState("");
  // const [open, setOpen] = useState(false);
  // const [loadStatus, setLoadStatus] = useState(true);
  // const [loading, setLoading] = useState("");

  //   var loadrequest = [];

  //   const globalJson1 =
  //   localStorage.getItem("userData") !== null
  //     ? JSON.parse(localStorage.getItem("userData"))
  //     : null;
  // var globalOrgId = 1;
  // var globaluserId = 1;
  // var globalRoleId = 0;
  // var globalJson = {};
  // var globalResellerId = 2;
  // var globalOrgType = 2;
  // var globalCustomerId = 0;
  // var globalUserName = "";
  // var globalOrgName = "";
  // if (
  //   "null" === localStorage.getItem("userData") ||
  //   null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   globalJson =
  //     localStorage.getItem("userData") !== null
  //       ? JSON.parse(localStorage.getItem("userData"))
  //       : 0;
  //   globalOrgId = globalJson.orgId;
  //   globalOrgType = globalJson.userOrgType;
  //   globaluserId = globalJson.id;
  //   globalRoleId = globalJson.roleId;
  //   globalResellerId = globalJson.resellerId;
  //   globalCustomerId = globalJson.customerId;
  //   globalOrgName = globalJson.orgName;
  //   globalUserName = globalJson.userName;
  // }

  // useEffect(async () => {

  //   var request = {};
  //   request.message = "Logs > FeedLog > Raw Feed";
  //   request.action = "View";
  //   request.featureName = "FeedLog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName= globalUserName;

  //   const responseData = await logServices.saveAuditLogDetails(request);

  // }, []);

  //  useEffect(async () => {
  //    await callbackLoadFn();
  //    console.log("strin");
  //  }, []);

  // {
  //   "vehicleId": 26202,
  //   "startDate": "2023-02-01 04:56:22",
  //   "endDate": "2023-02-22 04:56:22",
  //   "orderdir": "desc",
  //   "search": "",
  //   "imei": "091738747854563",
  //   "page": 0,
  //   "size": 10,
  //   "resellerId": 0,
  //   "url": 1
  // }

  // const callbackLoadFn = async (
  //   selResellerId,
  //   selRegionId,
  //   selDealerId,
  //   selCustomerId,
  //   selFleetId,
  //   selVehicleId,
  //   selimei
  // ) => {
  //   loadrequest = assignedval;
  //   loadrequest.vehicleId = selVehicleId;
  //   const date = format(new Date(), "yyyy-MM-dd") + " 00:00:00";
  //   const date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  //   loadrequest.startDate = date;
  //   loadrequest.endDate = date1;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.search = "";
  //  // loadrequest.page = newPage;
  //   //loadrequest.size = rowsPerPage;
  //   loadrequest.imei = selimei;
  //   loadrequest.orgId = selFleetId;
  //   loadrequest.resellerId = selResellerId;
  //   loadrequest.url = 1;
  //   setAssignedval(loadrequest);

  //   if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //    // setTotalElements(responseData.data.totalElements);
  //     //setTotalPages(responseData.data.totalPages);
  //     //setResponseData(responseData.data.content);
  //   }else{
  //   setLoading("Success");
  //   // setTotalElements(0);
  //   //setTotalPages(0)
  //   }
  //   // const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //   // setTotalElements(responseData.data.totalElements);
  //   // setTotalPages(responseData.data.totalPages);
  //   // setResponseData(responseData.data.content);

  //   fetchRawFeedDetails();
  // };

  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  // const labelStyle = {
  //   fontSize: theme.text.fontSize,
  //   color: "black",
  //   fontWeight: 600,
  // };
  // const menuStyles = {
  //   fontSize: theme.text.fontSize,
  //   lineHeight: "1",
  // };
  // const selectDropdown = {
  //   ".MuiOutlinedInput-input": {
  //     fontSize: theme.text.fontSize,
  //     padding: "1.5px 4px",
  //   },
  // };

  //const [newPage, setNewPage] = useState(0);
  //const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [totalElements, setTotalElements] = useState(5);
  //const [totalPages, setTotalPages] = useState(1);
  // const [selectedDropdownAry, setSelectedDropdownAry] = useState([]);
  // const [assignedval, setAssignedval] = useState({});
  // const [searchval, setSearchval] = useState("");

  // // const onSubmit = async (data) => {
  // //   console.log(data);
  //   //setNewPage(newPage);
  //   //setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   //sloadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   //loadrequest.page = newPage;
  //   loadrequest.imei = data.imei;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.sortBy = "desc";
  //   loadrequest.search = searchval;
  //   console.log("check" + loadrequest);
  //   setAssignedval(loadrequest);
  //   if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //    // setTotalElements(responseData.data.totalElements);
  //     //setTotalPages(responseData.data.totalPages);
  //     //setResponseData(responseData.data.content);
  //   }else{
  //   setLoading("Success");
  //   // setTotalElements(0);
  //  // setTotalPages(0)
  //   }
  // };

  // const handleSearch = async(searchText) => {
  //   setSearchVal(searchText);
  //   loadrequest=assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.IdleReport(loadrequest)).data;
  //   setResponseData(responseData.data.content);

  // }

  // const fetchRawFeedDetails = async (searchText, columnName) => {
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;

  //   if (columnName === undefined) {
  //     loadrequest.columnName = "MESSAGE";
  //   } else {
  //     loadrequest.columnName = columnName;
  //   }

  //   console.log(loadrequest);

  //   const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //  // setTotalElements(responseData.data.totalElements);
  //   //setTotalPages(responseData.data.totalPages);
  // };

  // const handleSearch = (searchText) => {
  //   fetchRawFeedDetails(searchText);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);

  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };
  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;
  //   loadrequest.feedStage = "rawfeed";
  //   //loadrequest.resellerId = selResellerId;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "FeedLog/FeedlogXLS.xls?vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&imei=" +
  //       loadrequest.imei +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&feedStage=" +
  //       loadrequest.feedStage +
  //       "&url=" +
  //       loadrequest.url;

  //     window.open(url);
  //   } catch (error) {}
  // };
  // const onChangePdf=async ()=>{

  //   loadrequest=assignedval;

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);
  //   try {

  //     var url=process.env.REACT_APP_API_BASE_URL+"ManualCanDataReport/vehicledataPDF.pdf?startDate="+loadrequest.startDate+"&endDate="+loadrequest.endDate+"&orderDir="+loadrequest.orderDir+"&orgId="+loadrequest.orgId+
  //    "&vehicleId="+loadrequest.vehicleId;

  //      window.open(url);

  //   } catch (error) {}
  // }

  //var TableData = rawFeedData.content;
  // console.log(TableData);
  return (
    <>
      <MouseOverPopover text={text} title={"Raw Feed"} />
      <Box>
        {/* <CanFeedDropDown
          onSubmit={onSubmit}
          isshowimei={true}
          callbackLoadFn={callbackLoadFn}
        />  */}

        <StyledCardShadow>
          <CardHeader title={<StyledTableTitle>Raw Feed</StyledTableTitle>} />
          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={isLoading || isLoadingOut}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </>
  );
};

export default RawFeed;
