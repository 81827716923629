import React, { useState, useEffect, useMemo, useRef } from "react";
import dropdownServices from "../../services/dropdownServices";
import {
  OutlinedInput,
  Divider,
  InputAdornment,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/styles";
import { DateRangePicker, IconButton } from "rsuite";
import { format } from "date-fns";
import { addDays, subDays } from "date-fns";
import {
  StyledFiterButton,
  StyledPaperShadow,
  StyledCalandar,
  StyledCalandarMenu,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import { FaCalendar } from "react-icons/fa";
import moment from "moment";

const CommonDropDown = ({ handleSmt, callbackLoadFn }) => {
  const theme = useTheme();
  const dropdownStyle = {
    //boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px",
  };
  const labelStyle = {
    fontSize: theme.text.fontSize,
    color: "black",
    fontWeight: 600,
  };
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;
  var selectedAccess = null;

  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true;
  const [orgListJSON, setOrgListJSON] = useState([]);
  const [access, setAccess] = useState([]);
  //const[access, setAccess]=useState([]);

  const [seltdOrgTypeId, setSeltdOrgTypeId] = useState("0");
  const [seltdResellerId, setSeltdResellerId] = useState("0");
  const [seltdCustomerId, setSeltdCustomerId] = useState("0");
  const [seltdFleetId, setSeltdFleetId] = useState("0");
  const [seltdAccess, setSeltdAccess] = useState("All");

  const [seltdLastOrgId, setSeltdLastOrgId] = useState("0");

  const [displayDate, setDisplayDate] = useState(false);
  const refFromDate = useRef("");
  const refToDate = useRef("");

  const [isReseller, setIsReseller] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  const [isFleet, setIsFleet] = useState(true);
  const [isShowVeh, setIsShowVeh] = useState(true);
  const [isModel, setIsModel] = useState(true);
  const [isStatus, setIsStatus] = useState(true);
  const [isLastConnected, setIsLastConnected] = useState(true);
  const [iscreatedDate, setIsCreatedDate] = useState(true);

  // const access = [{"value":"All","key":"All"},{"value":"Portal","key":"Portal"},{"value":"Mobile","key":"Mobile"}];
  const [createdDateList, setCreatedDateList] = useState([
    { key: 0, value: "Today" },
    { key: 1, value: "Yesterday" },
    { key: 2, value: "Custom Range" },
  ]);
  const [selCreatedDate, setSelCreatedDate] = useState(0);
  const [fromDate, setFromDate] = useState(
    format(
      new Date(format(new Date(), "yyyy-MM-dd") + " 00:00:00"),
      "yyyy-MM-dd HH:mm:ss"
    )
  );
  const [toDate, setToDate] = useState(
    format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );
  const { afterToday } = DateRangePicker;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customCal, setCustomCal] = useState(false);
  const open = Boolean(anchorEl);

  //const [date, setDate] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCustomCal(false);
  };

  //Today
  const getToday = () => {
    setFromDate(format(new Date(), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
    handleClose();
    setSelCreatedDate(1);
  };

  const getYesderday = () => {
    setFromDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59");
    handleClose();
    setSelCreatedDate(2);
  };
  const getLast7day = () => {
    setFromDate(format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00");
    setToDate(format(subDays(new Date(), 1), "yyyy-MM-dd") + " 23:59:59");
    handleClose();
    setSelCreatedDate(3);
  };
  const customRage = () => {
    //setDate('Custom');
    handleClose();
    setCustomCal(true);
    setSelCreatedDate(4);
  };
  useEffect(() => {
    getToday();
  }, []);

  const selectDropdown = {
    "&.MuiInputBase-root": { width: "320px" },
  };

  const getResellerId = (listOfResellers) => {
    return listOfResellers[0].key;
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };

  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
  }

  useEffect(async () => {
    if (2 === parseInt(globalOrgType)) {
      // setIsOrgType(true);
      setIsReseller(false);

      setIsCustomer(true);
      setIsFleet(true);
      setSeltdResellerId(globalResellerId);
      setSeltdOrgTypeId(globalOrgType);
      selectedResellerId = globalResellerId;

      const customersListPayload = {
        value: "customer",
        resellerId: selectedResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const userListPayload = {
        value: "user",
        orgId: globalOrgId,
        resellerId: globalResellerId,
        customerId: selectedCustomerId,
        userOrgType: globalOrgType,
        searchText: "",
      };
      const { data: userListResp } = await dropdownServices.getUserlist(
        userListPayload
      );
      // const { content: userListResponse } = userListResp.data;
      const fleets = getOptions(userListResp);
      if (userListResp.length === 0) {
        setFleet(fleets);
        selectedFleetId = fleets[0].key;
        setSeltdFleetId(fleets[0].key);
      } else {
        setFleet(fleets);
        selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
        setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
      }
      selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
      setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    } else if (1 === parseInt(globalOrgType)) {
      if (1 === parseInt(globalOrgType) && 1 === parseInt(globalRoleId)) {
        setIsReseller(true);
        setIsCustomer(true);
        // setIsFleet(true);

        const resellerPayload = {
          value: "reseller",
          orgType: "2",
          resellerId: globalResellerId,
          dealerId: 0,
          roleId: globalRoleId,
          id: globalResellerId,
          searchText: "",
        };
        const { data: resellerResponseNew } =
          await dropdownServices.getResellers(resellerPayload);
        const { content: orgnListJSON } = resellerResponseNew.data;

        setOrgListJSON(orgnListJSON);
        selectedResellerId =
          orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1";
        setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "0");

        const customersListPayload = {
          value: "customer",
          resellerId: selectedResellerId,
          dealerId: 0,
          searchText: "",
        };
        const { data: customerListResponse } =
          await dropdownServices.getResellers(customersListPayload);
        const { content: customersList } = customerListResponse.data;
        const customerAll = getOptions(customersList);
        setCustomers(customerAll);
        selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
        setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

        const userListPayload = {
          value: "user",
          orgId: globalOrgId,
          resellerId: globalResellerId,
          customerId: selectedCustomerId,
          userOrgType: globalOrgType,
          searchText: "",
        };
        const { data: fleetListResp } = await dropdownServices.getUserlist(
          userListPayload
        );
        // const {content: fleetListResponse} = fleetListResp.data
        const fleets = getOptions(fleetListResp);
        if (fleetListResp.length === 0) {
          setFleet(fleets);
          selectedFleetId = fleets[0].key;
          setSeltdFleetId(fleets[0].key);
        } else {
          setFleet(fleets);
          selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
          setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
        }
        selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
        setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
      } else {
        // setIsOrgType(false);
        setIsReseller(false);
        setIsCustomer(false);
        setIsFleet(false);
        // setIsDealer(false);
        //setIsAgency(false);
      }
    } else {
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsShowVeh(false);
      setIsStatus(false);
      setIsModel(true);
      setIsCreatedDate(true);
      setIsLastConnected(true);

      const resellerPayload = {
        value: "reseller",
        orgType: "2",
        resellerId: globalResellerId,
        dealerId: 0,
        roleId: globalRoleId,
        id: globalResellerId,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;
      const resellerAll = getOptions(orgnListJSON);
      setOrgListJSON(resellerAll);
      selectedResellerId = resellerAll.length > 0 ? resellerAll[0].key : "-1";
      setSeltdResellerId(resellerAll.length > 0 ? resellerAll[0].key : "0");

      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const userListPayload = {
        value: "user",
        orgId: globalOrgId,
        resellerId: globalResellerId,
        customerId: selectedCustomerId,
        userOrgType: globalOrgType,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getUserlist(
        userListPayload
      );
      // const {content: fleetListResponse} = fleetListResp.data
      const fleets = getOptions(fleetListResp);
      if (fleetListResp.length === 0) {
        setFleet(fleets);
        selectedFleetId = fleets[0].key;
        setSeltdFleetId(fleets[0].key);
      } else {
        setFleet(fleets);
        selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
        setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
      }
      selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
      setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    }

    setAccess([
      { value: "All", key: "All" },
      { value: "Portal", key: "Portal" },
      { value: "Mobile", key: "Mobile" },
    ]);

    callbackLoadFn(fromDate, toDate);
  }, []);

  const handleResellerSelect = async (resellerId) => {
    // if(seltdOrgTypeId==="1"){
    setSeltdResellerId(resellerId);
    //selectedResellerId(resellerId);
    setSeltdLastOrgId(resellerId);
    const customersListPayload = {
      value: "customer",
      resellerId: resellerId,
      dealerId: 0,
      searchText: "",
    };

    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const _customers = getOptions(customersList);
    if (customersList.length === 0) {
      setCustomers(_customers);
      selectedCustomerId = _customers[0].key;
      setSeltdCustomerId(_customers[0].key);
    } else {
      setCustomers(_customers);
      selectedCustomerId = _customers.length > 0 ? _customers[0].key : "-1";
      setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");
    }

    selectedCustomerId = _customers.length > 0 ? _customers[0].key : "-1";
    setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");

    // const fleetListPayload = {
    //   value: "fleet",
    //   customerId:customersList.length>0?customersList[0].key:"",
    //   dealerId: 0,
    //   searchText: ""
    // }
    // const {data: fleetListResp} = await dropdownServices.getResellers(fleetListPayload)
    // const {content: fleetListResponse} = fleetListResp.data
    // const fleets = getOptions(fleetListResponse)
    // if(fleetListResponse.length===0){

    //   setFleet(fleets)
    //   selectedFleetId = fleets[0].key;
    //   setSeltdFleetId(fleets[0].key);
    // }else{

    //   setFleet(fleets)
    //   selectedFleetId = fleets.length>0?fleets[0].key:"-1"
    //   setSeltdFleetId(fleets.length>0?fleets[0].key:"-1");
    // }

    // selectedFleetId = fleets.length>0?fleets[0].key:"-1"
    // setSeltdFleetId(fleets.length>0?fleets[0].key:"-1");

    //     }else if(seltdOrgTypeId==="2"){
    //       setSeltdResellerId(resellerId);
    //       setSeltdLastOrgId(resellerId);

    //     }else{
    //         setSeltdResellerId(resellerId);
    //         const customersListPayload = {
    //           value: "customer",
    //           resellerId:resellerId,
    //           dealerId: 0,
    //           searchText: ""
    //         }
    //       const {data: customerListResponse} = await dropdownServices.getResellers(customersListPayload)
    //       const {content: customersList} = customerListResponse.data
    //       const _customers = getOptions(customersList)
    //       setCustomers(customersList)
    //       selectedCustomerId = customersList.length>0?customersList[0].key:"-1"
    //       setSeltdCustomerId(customersList.length>0?customersList[0].key:"-1");
    //       setSeltdLastOrgId(customersList[0].key);
    //       setSeltdResellerId(resellerId);

    //       setSeltdResellerId(resellerId);
    //       setSeltdLastOrgId(resellerId);

    //     }
  };

  // const handleOrgTypeSearch = async (searchText) => {
  //   const customersListPayload = {
  //     value: "customer",
  //     resellerId: selectedResellerId,
  //     dealerId: 0,
  //     searchText
  //   }
  //   const {data: customerListResponse} = await dropdownServices.getResellers(customersListPayload)
  //   const {content: customersList} = customerListResponse.data
  //   setCustomers(getOptions(customersList))
  // }

  const handleCustomerSelect = async (customerId) => {
    selectedCustomerId = customerId;
    setSeltdCustomerId(customerId);
    setSeltdLastOrgId(customerId);

    // if(seltdOrgTypeId==="1"){

    const fleetListPayload = {
      value: "user",
      orgId: customerId,
      resellerId: globalResellerId,
      customerId: customerId,
      userOrgType: globalOrgType,
      userId: globaluserId,
      searchText: "",
    };
    const { data: fleetListResp } = await dropdownServices.getUserlist(
      fleetListPayload
    );
    // const {content: fleetListResponse} = fleetListResp.data
    const fleets = getOptions(fleetListResp);
    if (fleetListResp.length === 0) {
      setFleet(fleets);
      selectedFleetId = fleets[0].key;
      setSeltdFleetId(fleets[0].key);
    } else {
      setFleet(fleets);
      selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
      setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    }
    selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
    setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");

    // }else{
    //   selectedCustomerId = customerId
    //   setSeltdCustomerId(customerId);
    //   setSeltdLastOrgId(customerId);

    // }
  };

  const handleUserSelect = (userId) => {
    selectedFleetId = userId;
    setSeltdFleetId(userId);
    setSeltdLastOrgId(userId);
    // seltdAccess("-1");
  };

  const handleSubmit = (e) => {
    handleSmt(
      seltdOrgTypeId,
      seltdResellerId,
      seltdCustomerId,
      seltdFleetId,
      seltdLastOrgId,
      globaluserId,
      fromDate,
      toDate,
      seltdAccess
    );
  };

  const handleCustomerSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
  };

  const handleResellerSearch = async (searchText) => {
    const resellersListPayload = {
      value: "reseller",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
      searchText,
    };

    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
  };

  const clickVal = (e) => {
    setFromDate(format(e[0], "yyyy-MM-dd HH:mm:ss"));
    setToDate(format(e[1], "yyyy-MM-dd HH:mm:ss"));
    handleClose();
  };

  const handlePortalSelect = (accessId) => {
    selectedAccess = accessId;
    setSeltdAccess(accessId);
  };

  return (
    <form>
      <StyledPaperShadow>
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={isReseller}
          fieldName="Organization"
        />

        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isCustomer}
          onSearch={handleCustomerSearch}
        />

        <DropDown
          label="User"
          options={fleet}
          onChange={handleUserSelect}
          optionsDisplayKey="value"
          isShow={isFleet}
          //onSearch={handleFleetSearch}
        />

        <DropDown
          label="Access"
          options={access}
          onChange={handlePortalSelect}
          optionsDisplayKey="value"
          //isShow={isFleet}
          //onSearch={handleFleetSearch}
        />

        <StyledCalandar elevation={0}>
          <StyledFormControlLabel>Date</StyledFormControlLabel>
          <OutlinedInput
            size="small"
            sx={
              fromDate === format(new Date(), "yyyy-MM-dd") + " 00:00:00"
                ? ""
                : fromDate ===
                  format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00"
                ? ""
                : fromDate ===
                  format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
                ? ""
                : selectDropdown
            }
            onClick={handleClick}
            endAdornment={
              <InputAdornment>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <FaCalendar />
                </IconButton>
              </InputAdornment>
            }
            value={
              fromDate === format(new Date(), "yyyy-MM-dd") + " 00:00:00"
                ? "Today"
                : fromDate ===
                  format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00"
                ? "Yesterday"
                : fromDate ===
                  format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
                ? "Last 7 Days"
                : `${moment(fromDate).format("DD-MM-YYYY 00:00")} - ${moment(
                    toDate
                  ).format("DD-MM-YYYY HH:mm")}`
            }
          />
          <DateRangePicker
            //ranges={predefinedBottomRanges}
            format="dd-MM-yyyy HH:mm"
            //style={{ width: 300}}
            disabledDate={afterToday()}
            placeholder="Select Date Range"
            defaultValue={[
              new Date(format(new Date(), "yyyy-MM-dd") + " 00:00:00"),
              new Date(),
            ]}
            onChange={(e) => {
              clickVal(e);
            }}
            placement="bottomEnd"
            className="customCal"
            preventOverflow={true}
            open={customCal}
            onOk={(e) => clickVal(e)}
            //onClose={closeCal}
            size="xs"
          />
          <StyledCalandarMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <ListItemButton
              onClick={getToday}
              dense={false}
              selected={
                fromDate === format(new Date(), "yyyy-MM-dd") + " 00:00:00" &&
                true
              }
            >
              <ListItemText>Today</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={getYesderday}
              dense={false}
              selected={
                fromDate ===
                  format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00" &&
                true
              }
            >
              <ListItemText>Yesterday</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={getLast7day}
              dense={false}
              selected={
                fromDate ===
                format(subDays(new Date(), 7), "yyyy-MM-dd") + " 00:00:00"
              }
            >
              <ListItemText>Last 7 Days</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={customRage}
              dense={false}
              selected={selCreatedDate === 4 && true}
            >
              <ListItemText>Custom</ListItemText>
            </ListItemButton>
            <Divider />
          </StyledCalandarMenu>
        </StyledCalandar>

        <StyledFiterButton
          type="button"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
