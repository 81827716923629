import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Alert,
  Grid,
  FormLabel,
  Stack,
  InputAdornment,
  Icon,
  IconButton,
  Tooltip,
  LinearProgress,
  Snackbar,
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Radio,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { AUTH_LOGIN } from "../../constants/actionType";
import loginService from "../../services/loginService.js";
import { saveToken } from "../../Utils";
import service from "../../services/service";
import userServices from "../../services/userServices";
import Captcha from "captcha-image";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScaleLoader from "react-spinners/ScaleLoader";
import menuListService from "../../services/menuListService";
import { useGetLogoApiMutation,useGetResellerListMutation,useGetThemeMutation, useGetUserDataMutation, useLoginDetailsMutation } from "../../store/slice/ApiSlice";
import { StyledFormControlLabel, StyledTextField } from "../../theme/StyledEle";
import { AiFillEye, AiFillEyeInvisible, AiOutlineReload } from "react-icons/ai";
import StyledSnackBar from "../../Utils/StyledSnackBar.js";
import { useLocation } from 'react-router-dom';
import { getAuth, setTheme } from "../../store/slice/ControlsSlice.js";
import { FaCircleCheck } from "react-icons/fa6";
import { useGetMenusMutation, useGetUserdetailsMutation, useSwitchAccountMutation } from "../../store/slice/NewAuthSlice/AuthSlice.js";
import { useSelector } from "react-redux";

export const Loginform = ({ showForgot }) => {
  const theme = useTheme();
  const [auth, {data:authData, isLoading:authLoading, isSuccess:authSuccess, }] = useLoginDetailsMutation();
  const [getReseller, {data:resellerList, isLoading:resellerLoading, isSuccess:resellerSuccess}] = useGetResellerListMutation();
  const [ switchAcc, {data:switchAccData, isLoading:switchAccLoading, isSuccess:switchAccSuccess} ] = useSwitchAccountMutation();
  const [ getUserDetails, {data:getUserDetailsData, isLoading:getUserDetailsLoading, isSuccess:getUserDetailsSuccess} ] = useGetUserdetailsMutation();
  const [ getNewMenu, {data:menuListData, isLoading:menuListLoading, isSuccess:menuListSuccess} ] = useGetMenusMutation();
  
  const navigate = useNavigate();
  const location = useLocation();
  const authTok = useSelector((state) => state.controls.authToken);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      name: null,
      password: null,
      captcha: null,
    },
  });
  const [validation, setValidation] = useState("");
  const [msg, setMsg] = useState();
  const [error, setError] = useState(false);
  const [elementData, setElementData] = useState();
  const [circularOpen, setCircularOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const [captcha, setCaptcha] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userLoaction,setUserLocation] = useState("");
  const [resellers, setResellers] = useState(false);
  const [selectReseller, setSelectResller] = useState(null);
//User Location 

const getUserLocation = () =>{

  // Check if geolocation is supported by the browser
  if ("geolocation" in navigator) {
    // Prompt user for permission to access their location
    navigator.geolocation.getCurrentPosition(
      // Success callback function
    
      function(position) {
        // Get the user's latitude and longitude coordinates
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setUserLocation(`${lat},${lng}`)
        // Update the map with the user's new location
      },
      // Error callback function
      function(error) {
        // Handle errors, e.g. user denied location sharing permissions
        console.error("Error getting user location:", error);
      }
    );
  } else {
    // Geolocation is not supported by the browser
    console.error("Geolocation is not supported by this browser.");
  }
  }

  useEffect(()=>{
    getUserLocation()
  
  },[location.pathname])
 
  //Login Start
  const onSubmit = (data) => {
    setCircularOpen(true);
    window.localStorage.removeItem("menuList");
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("isRefreshToken");
    window.localStorage.removeItem("jwToken");
    window.localStorage.removeItem("expjwToken");
    window.localStorage.removeItem("rshjwToken");

    let userName = data.userName.trim();
    let payload = {
      'userName':userName,
      'password':data.password,
      'captcha':data,captcha,
      'name':null,
      'latLong':userLoaction,
    }

    loginAuth(payload);

  };
  const payloadRef = useRef();

  const handleSelectReseller = (resellerId, type, url, segment) => {
    setSelectResller({resellerId:resellerId, type:type, url:url, segment:segment});
  }
  const loginAuth = async (payload) => {
    const authResponce = await auth(payload);
    if(!authLoading && authResponce?.data){
      payloadRef.current = {payload};
      //saveToken(authResponce.data?.accessToken, authResponce.data?.refreshToken);
      dispatch(getAuth([authResponce.data?.accessToken, authResponce.data?.refreshToken]));
      getResllerList(payload, authResponce?.data);
    }else if(!authLoading && authResponce?.error){
      setError(true);
      setMsg(authResponce?.error?.data?.resultMessage);
      setTimeout(() => {
        setError(false);
      },2000);
    }
    setCircularOpen(authLoading);
  }

  const getResllerList = async(payload, authData) => {
      let resellerPayload = {}
      resellerPayload.userName = payload.userName;
      resellerPayload.url = 'montra';
      const resellerList = await getReseller(resellerPayload);
      if(resellerList.data.data.length > 0){
          if(resellerList.data.data.length > 1){
            setSelectResller({resellerId:resellerList.data.data[0].key, type:resellerList.data.data[0].vehicleType, url:resellerList.data.data[0].url, segment:resellerList.data.data[0].segment});
            window.localStorage.removeItem("resellerList");
            window.localStorage.setItem("resellerList", null);
            window.localStorage.setItem(
            "resellerList",
            JSON.stringify(resellerList.data.data)
            );
            setResellers(true);
          }else{
            setSelectResller({resellerId:resellerList.data.data[0].key, type:resellerList.data.data[0].vehicleType, url:resellerList.data.data[0].url});
            window.location.href = `${resellerList.data.data[0].url}#/sign-verify#${authData?.accessToken}?${resellerList.data.data[0].segment}$${resellerList.data.data[0].key}`;
            //window.location.href = `http://localhost:3000/#/sign-verify#${authData?.accessToken}?${resellerList.data.data[0].segment}$${resellerList.data.data[0].key}`;
          }
      }
  }





  const getData = async() => {
      //getUserData();
      accountChange(selectReseller);
  }
  
  //set LocalStorage
  const setLocalStorage = (authToken, refToken) => {
    window.localStorage.setItem('jwToken',JSON.stringify(authToken));
    window.localStorage.setItem('rshjwToken',refToken);
    window.localStorage.setItem('expjwToken',null);
  }

  

  
  const accountChange = async (selectReseller) => {
    //let url = `${selectReseller.url}#/sign-verify#${authData?.accessToken}?${selectReseller.segment}$${selectReseller.resellerId}`
      window.location.href = `${selectReseller.url}/#/sign-verify#${authData?.accessToken}?${selectReseller.segment}$${selectReseller.resellerId}`;

      
      //const switchAccount = await switchAcc(payload);
      // if(switchAccount.data !== undefined ){
      //   setLocalStorage(switchAccount.data.accessToken, switchAccount.data.refreshToken);
      //   dispatch(getAuth([switchAccount.data.accessToken, switchAccount.data.refreshToken]));
      //   setTimeout(() => {
      //     getUserInfo();
      //   },0)
      // }else{
      //     window.reload();
      // }
    }
  
  
    const getUserInfo = async () => {
      const getUserDetail = await getUserDetails();
      localStorage.setItem("userData", JSON.stringify(getUserDetail.data.data));
      getMenus(getUserDetail.data.data);
    }
    
  
        //getMenu
        const getMenus = async (data) => {
          let payload = {
            "resellerId": data.resellerId,
            "orgId": data.orgId,
            "page": 0,
            "size": 100,
            "searchText": "",
            "id": 0,
            "roleId":  data.roleId,
            "orgType": data.userOrgType
        }
            const menusList = await getNewMenu(payload);
            window.localStorage.removeItem("menuList");
            window.localStorage.setItem("menuList", null);
            window.localStorage.setItem("menuList",JSON.stringify(menusList.data.menus));
            //window.localStorage.setItem("accountList",JSON.stringify(validateUserData.data));
            navigate(menusList.data.menus[0]?.sub[0]?.route !== undefined ? `/${menusList.data.menus[0]?.sub[0]?.route}` : `/${menusList.data.menus[1]?.sub[1]?.route}`);
            //window.location.reload();
        }




  function createMarkup(source) {
    return { __html: source };
  }

  function MyCaptcha() {
    const captchaImage = new Captcha(
      "20px Noto Sans SC",
      "center",
      "middle",
      100,
      37,
      "transparent",
      "black",
      4
    ).createImage();

    return <div dangerouslySetInnerHTML={createMarkup(captchaImage)} />;
  }
  useEffect(() => {
    setElementData(returnData(<MyCaptcha />));
  }, []);
  const returnData = () => {
    setCaptcha(true);
    return <MyCaptcha />;
  };
  const reloadcaptcha = () => {
    setElementData(returnData(<MyCaptcha />));
  };

  const submitButtonRef = useRef();

  const getCaptcha = (e) => {
    if (document.images.item(0).getAttribute("data-key") === e.target.value) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  };

  useEffect(() => {
    if (captcha !== null) {
      submitButtonRef?.current?.focus();
    }
  }, [captcha]);

  const showpassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      {circularOpen && (
        <Box
          sx={{ position: "absolute", width: "100%", top: "0px", left: "0px" }}
        >
          <LinearProgress
            sx={{
              "&": {
                background: "#fff",
              },
              "& .MuiLinearProgress-bar": {
                background:
                  "linear-gradient(90deg, #cc5021 20%, #a50156 33%, #ffb900 70% )",
              },
            }}
          />
        </Box>
      )}
      {
        !resellers ? (
          <>
            <Box sx={{ mb: 5 }}>
        <Typography variant="h5">Sign In</Typography>
        <Typography variant="body1">Access to Telematics Portal</Typography>
      </Box>
      {error === true && (
        <Snackbar open={error} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
           <Alert
            severity={error ? "error" : "success"}
          >
            {msg}
        </Alert>
        </Snackbar>
       
      )
      }
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <Stack direction="column" spacing={2}>
          <StyledFormControlLabel>
            <FormLabel>Username</FormLabel>
            <StyledTextField
              size="small"
              id="userName"
              type="text"
              fullWidth
              error={errors.userName && true}
              {...register("userName", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
              })}
              inputProps={{
                autoComplete:'username'
              }}
              onChange={(e) => setValue("userName", e.target.value)}
              helperText={errors.userName && errors.userName.message}
            />
          </StyledFormControlLabel>
          <StyledFormControlLabel>
            <FormLabel>Password</FormLabel>
            <StyledTextField
              
              size="small"
              error={errors.password && true}
              {...register("password", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
              })}
              onChange={(e) => setValue("password", e.target.value)}
              helperText={errors.password && errors.password.message}
              type={showPassword ? "text" : "password"}
              id="password"
              fullWidth
              inputProps={{
                  autoComplete:'current-password'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={showpassword} size="small">
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledFormControlLabel>
          <StyledFormControlLabel id="setid" variant="outlined">
            <FormLabel>Captcha</FormLabel>
            <StyledTextField
              className="captcha_img"
              fullWidth
              size="small"
              id="captcha"
              type="text"
              name="captcha"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {elementData}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Reload Captcha" arrow>
                      <IconButton onClick={reloadcaptcha} size="small">
                        <AiOutlineReload />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={errors.captcha && true}
              {...register("captcha", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
                validate: (fieldValue) => {
                  return (
                    fieldValue ===
                      document.images.item(0).getAttribute("data-key") ||
                    "Invalid Captcha"
                  );
                },
              })}
              onChange={(e) => getCaptcha(e)}
              helperText={errors.captcha && errors.captcha.message}
            />
          </StyledFormControlLabel>
        </Stack>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" size="small" />}
          label="Remember me"
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          color="primary"
          disabled={captcha || authLoading}
          ref={submitButtonRef}
        >
          {circularOpen ? "Loading..." : "Login"}
        </Button>
        {/* <ScaleLoader color="#fff" />  */}
        <Button onClick={() => showForgot(true)} color="primary" fullWidth>
          Forgot password?
        </Button>
      </Box>
          </>
        ) : <ResellerList refPayload={payloadRef.current} resellers={resellerList} selectId={selectReseller} handleReseller={handleSelectReseller} getData={getData}/>
      }
      
    </Box>
  );
};

export const Forgotpassword = ({ showForgot }) => {

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const [dialogContent, setDialogContent] = useState({});
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [open, setOpen] = useState(false);
const[successPop,setSuccessPop] = useState(false);
const[forgetPop,setForgetPop] = useState(true);


  //Login End

  //Fotgot Password Start
  let url = window.location.origin;

 
 

  const onSubmit = async (data) => {
    var request = {};
    request.userName = data.userName;
    request.path = url;
    request.email = data.userName

    const responseData1 = (await userServices.getForgetPassword(request)).data;
    var obj1 = {};
    obj1.dialogText = responseData1 === "/fpwdsuccessHyundai"?"Mail sent successfully":"Mail sent Failed";
    setDialogContent(obj1);
    setDataStatusConfirm(responseData1 === "/fpwdsuccessHyundai"?"Success":"Failed");
    setOpen(true);
    if (responseData1.status === 200) {
      
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
    if(responseData1 === "/fpwdsuccessHyundai"){
      setSuccessPop(true)
      setForgetPop(false)
    }else{
      setSuccessPop(false)
      setForgetPop(true)

    }
  };

  return (
    <>
    {(successPop === false && forgetPop === true) &&
    <Box>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5">Forgot Password</Typography>
        <Typography variant="body1">Change password here!</Typography>
      </Box>
      <Alert icon={false} color="info">
        To reset your password, enter your user name. Password reset details
        will be sent to your registered Email.
      </Alert>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <TextField
          size="medium"
          margin="normal"
          // id="userName"
          label="Username"
          type="text"
          fullWidth
          error={errors.userName && true}
          {...register("userName", {
            required: {
              value: true,
              message: "Required Field ",
            },
          })}
          onChange={(e) => setValue("userName", e.target.value)}
          helperText={errors.userName && errors.userName.message}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          color="primary"
        >
          Change Password
        </Button>
        <Button color="primary" fullWidth onClick={showForgot}>
          Back to Login
        </Button>
      </Box>
      
    </Box>}
    {(successPop === true && forgetPop === false) &&
  <Box
  sx={{ width: "100%" }}
  >
  <Typography component="h5" variant="h5" sx={{ mt: 1 }}>
    Your Change Password link has been sent successfully to your entered email address.
  </Typography>
  <Button color="primary" fullWidth onClick={showForgot}>
          Back to Login
        </Button>
</Box>  }
    {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
};


export const ResellerList = ({resellers, selectId, handleReseller, getData}) => {
  let theme = useTheme();
  return(
    <Box>
         <Box sx={{ mb: 5 }}>
            <Typography variant="h5">Manage Account</Typography>
            <Typography variant="body1">Select Reseller</Typography>
          </Box>
          <Stack>
              <List disablePadding>
                {
                  resellers?.data !== undefined && resellers?.data.length > 0 && resellers?.data.map((item, index) => {
                      return(
                              <ListItem dense key={index}
                              secondaryAction={
                                selectId.segment === item.segment &&
                                <Icon color="success" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <FaCircleCheck />
                                </Icon>
                              }
                              sx={{border:'.5px solid #ddd',p:0, mb:1, '&:hover':{background:theme.palette.action.hover,}}}
                              >
                                <ListItemButton dense onClick={() => handleReseller(item.key, item.vehicleType, item.url, item.segment)}> 
                                  <ListItemAvatar>
                                    <Avatar />
                                  </ListItemAvatar>
                                  <ListItemText 
                                    primary={item.title}
                                    secondary={item.roleName}
                                  />
                                </ListItemButton>
                              </ListItem>
                      )
                  })
                }
                  
              </List>
          </Stack>
          <Box sx={{mt:1}}>
              <Button variant="contained" fullWidth onClick={getData}>Continue</Button>
          </Box>
    </Box>
  )
}