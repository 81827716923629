import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { StyledFiterButton, StyledFormControl, StyledPaperShadow } from '../../theme/StyledEle'
import DatepickerDropdown from '../AdminDropdown/DatepickerDropdown'
import moment from 'moment';

function TestDriveDropdown(props) {
    const {getData} = props;
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD hh:mm:ss"));
    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
    const [activeLabel, setActiveLabel] = useState("today");
    const [label, setLabel] = useState("Today");
    const GetDate = (fromDate, toDate, name) => {
        setFromDate(fromDate);
        setToDate(toDate);
        setLabel(name);
      };

      const onSubmit = () => {
        let formdata = {
          fromDate: fromDate,
          toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
        };
        getData(formdata);
      };

  return (
    <StyledPaperShadow>
        <StyledFormControl>
            <DatepickerDropdown getDate={GetDate} activeLabel={activeLabel} title={'Booking Date'} showAll={false} thismonth={false} allowFutre={true}/>
        </StyledFormControl>
        <StyledFiterButton
          type="button"
          onClick={onSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </StyledFiterButton>
    </StyledPaperShadow>
  )
}

export default TestDriveDropdown
