import { Box, useTheme } from '@mui/material';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { GOOGLE_MAP_KEY } from '../../constants/appConstant';
import { Dark, Day } from '../../theme/gmapTheme';
import RouteMarkers from './RouteMarkers';

function RouteViewer(props) {
    const { routeData } = props;
    const theme = useTheme();
    const [defaultCenter, setDefaultCenter] = useState({
        lat: 25.9124,
        lng: 74.7873,
    });

    useEffect(() => {
      if(routeData !== null && routeData !== undefined){
        let lat = parseFloat(routeData[0].geoZoneLatLong.split(',')[0])
        let lng = parseFloat(routeData[0].geoZoneLatLong.split(',')[1])
        setDefaultCenter({lat:lat, lng:lng});
      }
    },[])

    const [map, setMap] = React.useState(null);
    const [mapzoom, setMapzoom] = useState(10);
    const [libraries] = useState(["drawing", "places", "geometry"]);
    const [mapType, setMapType] = useState("roadmap");
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        //googleMapsApiKey: "AIzaSyBx1k2yHWgbv2RS0NUVcYrqhcHD8sWr9eA",
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries,
        //version:'beta'
    });
    //map ref
    const mapRef = useRef(map);
    //map style
    const mapContainerStyle = {
        height: "100%",
        position: "relative",
    };
    const gmode = useSelector((state) => state.controls.customizer.mapMode);
    const onLoad = useCallback(
        function callback(map) {
          const bounds = new window.google.maps.LatLngBounds(defaultCenter);
          map.fitBounds(bounds);
          setMap(map);
          setMapzoom(5);
          //setZoomLevel(map.getZoom());
        },
        [gmode]
      );
      const options = {
        zoom: mapzoom,
        center: defaultCenter,
        minZoom: 2,
        maxZoom: 22,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
        featureType: "poi",
        stylers: [{ visibility: "off" }],
        tilt: 90,
        heading: 320,
      };


      //Route Operation







    //Google Map
    const mapMeno = useMemo(() => {
        return isLoaded ? (
            <GoogleMap
            options={{
                mapTypeId: mapType,
                ...options,
                ...(gmode !== 1 ? Dark : Day),
            }}
            mapContainerStyle={mapContainerStyle}
            >

              {/* Zone Marker */}
              <RouteMarkers routeData={routeData}/>

            </GoogleMap>
        ) : 'Loading'
    })


  return (
    <Box sx={{height:'100%'}}>
        {mapMeno}
    </Box>
  )
}

export default RouteViewer