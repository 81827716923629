import {  Avatar, Box,  Grid, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormControl } from '@mui/material'
import { RiUploadCloud2Fill } from 'react-icons/ri'
import { AiOutlineDelete } from 'react-icons/ai'
import { FileUploadBox, SoftButton, StyledFormControlLabel, StyledTextField } from '../../theme/StyledEle'
import { FaAndroid } from "react-icons/fa";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useSaveBannerMutation } from '../../store/slice/BannerSlice/bannerSlice'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { snackService } from '../../store/slice/ControlsSlice'
import { ScaleLoader } from 'react-spinners'

function CreateBanner(props) {
  const {reload } = props
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let userId = userData.id;

    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const TYPE = [
      {
        id: 0,
        value: "Select",
        key: 0,
      },
      {
        id: 1,
        value: "Constant",
        key: "Constant",
      },
      {
        id: 2,
        value: "Seasonal",
        key: "Seasonal",
      },

    ];
 //Slice Mution
 const [ saveBanner , {data:saveBannerData, isLoading:saveBannerLoading, isSuccess:saveBannerSuccess}] = useSaveBannerMutation();

    //Create State
    const [fileName, setFileName] = useState(null);
    const [validTill, setValidTill] = useState();
    const [file, setFile] = useState(null);
    const [type, setType] = useState(0);
    //Image Upload array
    const [upload, setUpload] = useState([]);

    const conver2base64 = (file, id) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (id === 1) {
          setFile(reader.result.toString());
        }
        if (id === 2) {
          setFile(reader.result.toString());
        }
        if (id === 3) {
          setFile(reader.result.toString());
        }
        if (id === 4) {
          setFile(reader.result.toString());
        }
        if (id === 5) {
          setFile(reader.result.toString());
        }

      };
      if (file) {
        reader.readAsDataURL(file);
      }
    };
    //Image Upload
    const appendImageName = async(e) => {
        if (upload.length < 1) {
          const uploaded = [...upload];
          if (uploaded.findIndex((f) => f.name === e.target.files[0].name) === -1) {
            let file = e.target.files[0];
            const {width, height } = await getImageDimensions(file)
            console.log("file",width,"height",height)
            if (file.size > 1000000) {
              dispatch(snackService(['Please upload a file smaller than 1 MB', 1, true]));
            }else if (width!==400 || height!==150){
              dispatch(snackService(['Please upload a 400 x 150 dimension image', 1, true]));
            }else{
              conver2base64(e.target.files[0],1);
              uploaded.push(e.target.files[0]);
            }

          }
          setUpload(uploaded);
        }

    };

    const deleteImageName = (e, imagename) => {
        const remainImages = upload.filter((item, index) => item.name !== imagename);
        setUpload(remainImages);
    };
    //Save Banner
    const handleSubmit = async (event,data) => {
      data.userId=userId;
      data.resellerId = resellerId;
      data.file = file;
      saveBanner(data)
  }


  const Validation = (event) => {
      event.preventDefault();
    //   if(fileName === null || fileName === ''){
    //     // dispatch(snackService(['File Name Required', 1, true]));
    // }else 
    if( upload.length === 0){
      dispatch(snackService(['Upload Required', 1, true]));
  }else if( type === 0 || type === null){
    dispatch(snackService(['Type Required', 1, true]));
}else{
      var formData = new FormData(event.target);
      var data = Object.fromEntries(formData);
      handleSubmit(event,data)
    }

  
  }
  useEffect(() => {
    if(saveBannerSuccess){
        if(saveBannerData.resultCode === 0){
          dispatch(snackService(['Uploaded Successfully', 0, true]));
          setFileName("");
          setUpload([]);
          setValidTill();
navigate(location.pathname);
reload()
        }else{
          dispatch(snackService(['Upload Failed', 1, true]));
        }
    }
  },[saveBannerSuccess])


    //resetTicket
    const resetTicket = () => {
        setFileName("");
setUpload([]);
setValidTill();
setType(0);
    }

    const getImageDimensions = async(file) => {
      let img = new Image();
      img.src = URL.createObjectURL(file);
      await img.decode();
      let width = img.width;
      let height = img.height;
      return {
        width,
        height,
    }
   
    }



  return (
    <>
    <form component={'form'} onSubmit={Validation} enctype="multipart/form-data">
    <Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item lg={6}>
            <FormControl fullWidth>
               <StyledFormControlLabel >File Name</StyledFormControlLabel>
               <StyledTextField name={'fileName'} size='small' placeholder='Enter File Name' value={fileName} fullWidth onChange={(event) => setFileName(event.target.value.replace(/^[0-9\b]+$/, ''))} inputProps={{ maxLength: "50" }}/>
           </FormControl>
            </Grid>
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Type</StyledFormControlLabel>
                    <StyledTextField size='small' name={'type'} fullWidth select value={type} onChange={(event) => setType(event.target.value)}>
                        {
                            TYPE !== undefined &&
                            TYPE.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            {type === 'Seasonal' && 
            <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel required>
                   Valid Till
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={validTill}
                      onChange={(event) => setValidTill(event)}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          size="small"
                          name='validTill'
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
}
              <Grid item lg={12}>

              </Grid>
            {/* Start Image Upload */}
            <Grid item lg={12}>
                <FileUploadBox sx={{mt:1}}>
                <Icon sx={{fontSize:'55px', mb:2}} color='primary'><RiUploadCloud2Fill  /></Icon>
                <Typography variant="body2" > Upload Attachment</Typography>
                        <input
                        id="myInput"
                        className="fileupload"
                        type={"file"}
                        name="file"
                        onChange={appendImageName}
                        accept='image/png,image/jpeg'
                    />
                    <Typography variant="caption">400 X 150</Typography>
                    <Typography variant="caption">JPEG , PNG </Typography>
                </FileUploadBox>
                <Box>
                    <List sx={{ width: "100%" }} dense={true}>
                        {upload.map((item, index) => {
                             return (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                      <IconButton
                                        type="button"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => deleteImageName(e, item.name)}
                                      >
                                        <AiOutlineDelete />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar variant="circle">
                                      <FaAndroid />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText

                                      primary={<Typography sx={{fontSize:"16px"}}>{item?.name}</Typography>}
                                      //
                                    />
                                  </ListItem>
                             )
                        })}
                    </List>
                </Box>
            </Grid>

        {

        }
            
        </Grid>
        <Stack direction={'row'} spacing={2} sx={{mt:2, width:"100%"}} justifyContent={'center'}>

            <SoftButton variant='contained' color='error' onClick={resetTicket} className='error'>Reset</SoftButton>
            <SoftButton variant='contained' color='success' className='success' type='submit'>
                
                {
                        saveBannerLoading ? <ScaleLoader height={12} color='#fff'/> : 'Save'
                    }
                    
                </SoftButton>
        </Stack>
    </Box>
    </form>

    </>
  )
}

export default CreateBanner