import {  AccordionDetails, AccordionSummary, Box, FormLabel, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {  StyledAccordion, StyledFormControl, StyledPaperShadow } from '../../theme/StyledEle'
import { MdOutlineExpandCircleDown } from 'react-icons/md'
import { useLocation } from 'react-router-dom';
import DeviceOnBoardServices from '../../services/deviceOnBoardService';
function DeviceView(props) {
    const theme = useTheme();
    const location = useLocation();
  
    const [editData, setEditData] = useState(null)
    const { data, ids } = props
       

    useEffect(async() => {

        const responseData = (await DeviceOnBoardServices.getDeviceView(ids)).data;
            setEditData(responseData);    
    },[])

  return (
    <Box>
        <StyledPaperShadow sx={{mt:2, overflow:'hidden'}}>
            <Grid container spacing={2} sx={{ maxHeight:'calc(100vh - 45vh)', overflowY:'scroll', height:'35%', minHeight:'calc(100vh - 50vh)'}}>
                <Grid item lg={8}>
                <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            Device Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Device IMEI Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.imei !== null ? editData?.formData?.imei : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Device Serial Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.deviceSerialNo !== null ? editData?.formData?.deviceSerialNo : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Device Status</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.deviceStatusName !== null ? editData?.formData?.deviceStatusName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Device Plan</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.devicePlanName !== null ? editData?.formData?.devicePlanName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Device Model</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.deviceModelName !== null ? editData?.formData?.deviceModelName : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Firmware</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.fameWare !== null ? editData?.formData?.fameWare : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Hardware</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.hardware !== null ? editData?.formData?.hardware : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Year</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.year !== null ? editData?.formData?.year : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            
                            
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                    
                    <StyledAccordion disableGutters elevation={0} defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandCircleDown />}
                        >
                            Sim Details
                        </AccordionSummary>
                        <AccordionDetails>
                        <Box>
                        <Grid container rowSpacing={2}>
                        <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>SIM Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simNumber !== null ? editData?.formData?.simNumber : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>SIM Status</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.simStatusValue !== null ? editData?.formData?.simStatusValue : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>First Mobile Number</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.firstMobileNum !== null ? editData?.formData?.firstMobileNum : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl sx={{display:''}}>
                                <FormLabel>Service Provider(APN)</FormLabel>
                                <Typography sx={{mt:.5}} variant='body2'>{editData?.formData?.firstServiceProvider !== null ? editData?.formData?.firstServiceProvider : '-'}</Typography>
                                </StyledFormControl>
                            </Grid>
                        </Grid>
                    </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                </Grid>
            </Grid>
        </StyledPaperShadow>
    </Box>
  )
}

export default DeviceView