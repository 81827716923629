import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import LogServices from "../../services/logService";
import { Breadcrumb } from "../shared/Breadcrumbs";
import logServices from "../../services/logService";
import LogsDropdown from "./LogsDropdown";
import {
  SoftButton,
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import LogDropdown from "../LogDropdown/LogDropdown";
import {
  useAirtelLogListMutation,
  useRefreshOrderMutation,
} from "../../store/slice/LogsSlice/LogsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const AirtelLog = () => {
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;
  let userId = userData.id;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);

  //refresh API Service
  const [
    RefreshOrders,
    {
      data: simRefreshOrdersdata,
      isLoading: getSimRefreshOrdersLoading,
      isSuccess: getSimRefreshOrdersSuccess,
    },
  ] = useRefreshOrderMutation();

  const [
    AirtelList,
    {
      data: airtelListdata,
      isLoading: getAirtelListLoading,
      isSuccess: getAirtelListSuccess,
    },
  ] = useAirtelLogListMutation();

  const columns = useMemo(
    () => [
      {
        Header: "Job Id",
        accessor: "jobId",
      },
      {
        Header: "Job Type",
        accessor: "jobType",
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
      },
      {
        Header: "Order Count",
        accessor: "orderCount",
      },
      {
        Header: "Success Count",
        accessor: "successCount",
      },
      {
        Header: "Failure Count",
        accessor: "failureCount",
      },
      {
        Header: "InProgress Count",
        accessor: "inProgressCount",
      },
      {
        Header: "Rejected Count",
        accessor: "rejectedCount",
      },
      {
        Header: "Order Initiate Date",
        accessor: "orderInitiateDate",
      },
      {
        Header: "Status Message",
        accessor: "statusMessage",
      },
      {
        Header: "Status Code",
        accessor: "statusCode",
      },
      {
        Header: "Error Items Count",
        accessor: "errorItemsCount",
      },
      {
        Header: "Requested Order Items",
        accessor: "requestedOrderItems",
      },
      {
        Header: "Modified Date",
        accessor: "modifiedDate",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "Device Serial No",
        accessor: "deviceSerialNo",
      },
      {
        Header: "Sim No",
        accessor: "simNo",
      },
      {
        Header: "Plan",
        accessor: "plan",
      },
      {
        Header: "Error",
        accessor: "errorCause",
      },
      {
        Header: "Error Code",
        accessor: "errorCode",
      },
      {
        Header: "Error Date",
        accessor: "errorDate",
      },
      {
        Header: "Error Transaction Id",
        accessor: "errorTransactionId",
      },
      {
        Header: "Sim Action",
        accessor: "action",
      },
      {
        Header: "Order Id",
        accessor: "orderId",
      },
      {
        Header: "Order Status",
        accessor: "orderStatus",
      },
      {
        Header: "Order Remarks",
        accessor: "orderRemarks",
      },
      {
        Header: "Order Completion Date",
        accessor: "orderCompletionDate",
      },
    ],
    []
  );
  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  // Onload and Submit
  const getData = (data) => {
   
    commonPayload.startDate = data.searchbyId !== "0" ? data.fromDate : "";
    commonPayload.endDate = data.searchbyId !== "0" ? data.toDate : "";
    commonPayload.action = data.actionId;
    commonPayload.searchText = "";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.resellerId = resellerId;
    setPayload(commonPayload);
    AirtelList(commonPayload);
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      AirtelList(payload);
    } else if (payload.searchText.length === 0) {
      AirtelList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      AirtelList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      AirtelList(payload);
    }
  };

  const handleRefresh = () => {
    var payload1 = {};
    payload1.userId = userId;
    RefreshOrders(payload1);
    
 
  };

  useEffect(() => {
    if (getAirtelListSuccess) {
      setResponseData([]);
      if (airtelListdata.data !== null) {
        setResponseData(airtelListdata.data.content);

        setTotalElements(airtelListdata.data.totalElements);
        setTotalPages(airtelListdata.data.totalPages);
      }
    }
   
  }, [getAirtelListSuccess]);
 

  useEffect(() => {
     if(getSimRefreshOrdersSuccess){

      var obj1 = {};

      obj1.dialogText = "Refreshed Successfully";
      setDialogContent(obj1);
      setDataStatusConfirm("Success");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        AirtelList(payload);
      }, 2000);
    }
  }, [getSimRefreshOrdersSuccess]);
 

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="Airtel Log"></Breadcrumb>
      <Box>
        <LogDropdown getData={getData} allowDays={1}
        allowedMonth={3}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader
            title={
              <SoftButton color="info" className="info" onClick={handleRefresh}>
                Refresh
              </SoftButton>
            }
          />

          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={getAirtelListLoading}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              includeExportButton={false}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </StyledPageLayout>
  );
};
export default AirtelLog;
