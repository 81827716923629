import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader, Grid, Paper } from "@mui/material";
import customerService from "../../services/customerService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel, Button, Typography, InputLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectWithSearch from "../shared/SelectWithSearch";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";
import DraggableDialog from "../shared/DailogDrag";
import dealerServices from "../../services/dealerService";
import { styled } from "@mui/material/styles";
import FilterButton from "../shared/FilterButton";
import KycDropDown from "./KycLogDropdown";
import customerKYCServices from "../../services/customerKYCService";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import logServices from "../../services/logService";
import KycLogDropdown from "../LogDropdown/KYCDropdown";
import { useVinJourneyListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


const Input = styled("input")({
  display: "none",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const containsText = (text, searchText) =>
  text.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) >
  -1;

const VinJourneyDtails = ({ tabValue }) => {

  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalCustomerId = 0;
  var globalUserName = "";
  var globalOrgName = "";
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globalCustomerId = globalJson.customerId;
    globalOrgName = globalJson.orgName;
    globalUserName = globalJson.userName;
  }
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [pageStatus, setPageStatus] = useState();
  const [loading, setLoading] = useState("");

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };

  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        Header: "VIN",
        accessor: "vin",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "Retail Invoice No",
        accessor: "retailInvoiceNo",
      },
      {
        Header: "Device Status",
        accessor: "deviceStatus",
      },
      {
        Header: "VIN IMEI Mapping Time",
        accessor: "customerApprovalTime",
      },
      {
        Header: "Customer Id",
        accessor: "customerId",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "KYC Received Time",
        accessor: "kycReceivedTime",
      },
      {
        Header: "KYC Approved Status",
        accessor: "kycApprovedStatus",
      },
      {
        Header: "KYC Approved Time",
        accessor: "kycApprovedTime",
      },
      {
        Header: "KYC Rejected Count",
        accessor: "",
      },
      {
        Header: "Last KYC Rejected Time",
        accessor: "lastKycRejectedTime",
      },
      {
        Header: "Vehicle Activation Time",
        accessor: "vehicleActivationTime",
      },
      {
        Header: "Consent Form Request Time",
        accessor: "consentFromRequestTime",
      },
      {
        Header: "Consent Form Approval Status",
        accessor: "",
      },
      {
        Header: "Consent Form Accepted Mode",
        accessor: "consentAcceptMode",
      },
      {
        Header: "Consent Form Approval Time",
        accessor: "consentFromApprovalTime",
      },
      {
        Header: "Post Consent Login",
        accessor: "consentFromApprovalStatus",
      },
      {
        Header: "Ticket Created",
        accessor: "createTicket",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Customer Assinged",
        accessor: "customerAssigned",
      },
      {
        Header: "KYC Mode",
        accessor: "kycType",
      },
      {
        Header: "Rejection Reason",
        accessor: "rejectedReason",
      },
      {
        Header: "Active Status",
        accessor: "kycStatus",
      },
    ],
    []
  );

  //   const onView = (itemViewed) => {
  //     onViewBat(itemViewed.batteryId);
  //   };

  // const callbackLoadFn = async (request1) => {
  //   var request = {};
  //   request.startDate = request1.defaultFrom;
  //   request.endDate = request1.defaultTo;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = request1.defaultSearch;
  //   request.vinVal = request1.defaultVin;
  //   request.retailVal = request1.defaultRetailVal;
  //   request.state = request1.defaultStatus;
  //   request.kycStatus = request1.defaultKycStatus;
  //   request.consentStatus = request1.defaultConsent;
  //   request.customerStatus = request1.defaultCustomer;
  //   const responseData = (
  //     await customerKYCServices.getGridViewForVinDetails(request)
  //   ).data.data;
  //   setResponseData(responseData.content);


  //   var request = {};
  //   request.message = "Logs > KYCLog > VinDetails";
  //   request.action = "View";
  //   request.featureName = "VinDetails";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName = globalUserName;

  //   const responseData1 = await logServices.saveAuditLogDetails(request);

  //   console.log(responseData.content);
  // };

  // const handleSubmit = async (selectSearch,selectedVin, selectedStatus,selectedKycStatus,startDate,endDate,selectedRetailVal,selectedConsent,selectedCustomer) => {
  //   var request = {};
  //   request.startDate = startDate;
  //   request.endDate = endDate;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = selectSearch;
  //   request.vinVal = selectedVin;
  //   request.state = selectedStatus;
  //   request.kycStatus = selectedKycStatus;
  //   request.retailVal=selectedRetailVal;
  //   request.consentStatus = selectedConsent;
  //   request.customerStatus = selectedCustomer;
  //   setPageStatus(request);
  //   try {
  //     const responseData = (await customerKYCServices.getGridViewForVinDetails(request)).data.data;
  //     setResponseData(responseData.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  //   const callbackChangePage = async (newPage, rowsPerPage) => {
  //     setNewPage(newPage);
  //     setRowsPerPage(rowsPerPage);
  //     var request = {};

  //     request.batteryId = 2;
  //     request.page = newPage;
  //     request.sortBy = "desc";
  //     request.batteryModel = "All";
  //     request.batteryType = "All";
  //     request.size = rowsPerPage;

  //     const responseData = (await BatteryServices.getGridViewForBat(request)).data
  //       .data;
  //     setResponseData([]);
  //     setResponseData(responseData.content);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.totalElements);
  //     setTotalPages(responseData.totalPages);
  //   };


  const [ getVinJourney, { data, isLoading, isSuccess} ] = useVinJourneyListMutation();
  let commonPayload = { }
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortBy = 'desc';
    commonPayload.selectedType = data.searchType;
    commonPayload.vinVal = 0;
    commonPayload.state = data.statusId;
    commonPayload.kycStatus = data.kycStatus;
    commonPayload.retailVal = data.invoiceNo;
    commonPayload.customerStatus = data.customerAssigned;
    commonPayload.consentStatus = data.consentId;
    commonPayload.search = '';
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    setPayload(commonPayload);
    getVinJourney(commonPayload);
  }

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      }
    }

    auditPayload.message = "Reports > KYC Log >Vin Journey Details";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;
    auditLog(auditPayload);
    
  }, [isSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getVinJourney(payload);
    } else if (payload.search.length === 0) {
      getVinJourney(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getVinJourney(payload);
  };


  return (
    <Box>
      {/* <KycDropDown tabValue={tabValue} handleSmt={handleSubmit}  callbackLoadFn={callbackLoadFn}/> */}
      <KycLogDropdown getData={getData} isShowAll={false} showCalendar={false} tabValue={tabValue}/>

      <StyledCardShadow sx={{my:2}}>
        <CardContent>
        <DataTable
          isLoading={isLoading}
          includeExportButton={false}
          includeSearchBox={true}
          handleChange={handleSearch}
          //   onView={onView}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={pageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          
        />
        </CardContent>
      </StyledCardShadow>
      {/* <UploadButtons /> */}
    </Box>
  );
};

export default VinJourneyDtails;

// function UploadButtons() {
//   return (
//     <Stack direction="row" alignItems="center" spacing={2}>
//       <label htmlFor="contained-button-file">
//         <Input
//           accept="image/*"
//           id="contained-button-file"
//           multiple
//           type="file"
//         />
//         <Button variant="contained" component="span">
//           Upload
//         </Button>
//       </label>
//       <label htmlFor="icon-button-file">
//         <Input accept="image/*" id="icon-button-file" type="file" />
//         <IconButton
//           color="primary"
//           aria-label="upload picture"
//           component="span"
//         >
//           <PhotoCamera />
//         </IconButton>
//       </label>
//     </Stack>
//   );
// }
