import { ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'

function GeoZoneMap() {




   

  return (
    <>
        <Select
            MenuProps={{
                MenuListProps:{
                    autoFocus:false
                }
            }} 
        >   
            <ListSubheader>
                <TextField 
                    onKeyDown={(e) => e.stopPropagation()}
                />
            </ListSubheader>
            <MenuItem value={1}>One</MenuItem>
            <MenuItem value={2}>Two</MenuItem>
            <MenuItem value={3}>Three</MenuItem>
        </Select>
    </>
  )
}

export default GeoZoneMap