"use client";
import React, { Suspense, useEffect, useState } from 'react';
import './assets/css/style.scss';
import ThemeProvider from './theme/ThemeProvider';
//import store from "./store";
import RouterView from "./Route/RouterView";
import './App.css'
import { Backdrop, Box, Dialog, DialogContent, Toolbar, Typography } from '@mui/material';
import RouteNew from './Route/RouteNew';
import { SoftButton } from './theme/StyledEle';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';


export default function App(){
  const [errorState, setErrorState] = useState(null);
  const location = useLocation();
  const resetError = (data) => {
    setErrorState(data);
  }
  //Tesst Error Bound
  const errorRender = ({error, resetErrorBoundary }) => {
    return (
        <Box sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Box maxWidth={'md'} sx={{textAlign:'center'}}>
                <Typography variant='h4' gutterBottom sx={{fontWeight:700}}>Something went wrong</Typography>
                <Typography gutterBottom variant='body2'>Don't logout, Click retry button in another page </Typography>
                <SoftButton onClick={() => resetError(resetErrorBoundary())} className='error' sx={{mt:2}}>Retry</SoftButton>
            </Box>
        </Box>
    )
  }

  const reset = (data) => {
    setErrorState(data);
  }
  return(
    <ErrorBoundary fallbackRender={errorRender} onReset={(details) => { reset(details) }}>
      <RouterView></RouterView>
    </ErrorBoundary>
  )
}

