import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { NewStore } from './store/NewStore';
import { Box, CssBaseline } from '@mui/material';
import HeaderNew from './layout/SidebarLayout/Header/HeaderNew';
import ThemeProvider from './theme/ThemeProvider';
import SidebarNew from './layout/SidebarLayout/Sidebar/SidebarNew';
import { BrowserRouter as Router, HashRouter, BrowserRouter } from 'react-router-dom';
import VerificationDialog from './components/verification';




ReactDOM.render(
  <React.StrictMode>
    <Provider store={NewStore}>
      <ThemeProvider>
      <Box sx={{ display: "flex", padding:"0px 0px"}} >
      <CssBaseline />
      <HashRouter>
        <HeaderNew/>
        <SidebarNew />
          <App />
        </HashRouter>
      </Box>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);