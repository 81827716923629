import { AppBar, Backdrop, Box, Card, CircularProgress, Divider, Drawer, Paper, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetMenusMutation, useGetUserdetailsMutation, useSwitchAccountMutation } from '../../store/slice/NewAuthSlice/AuthSlice';
import { getAuth } from '../../store/slice/ControlsSlice';
import { CircleLoader } from 'react-spinners';

function CheckAuthValidation() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ switchAcc, {data:switchAccData, isLoading:switchAccLoading, isSuccess:switchAccSuccess} ] = useSwitchAccountMutation();
    const [ getUserDetails, {data:getUserDetailsData, isLoading:getUserDetailsLoading, isSuccess:getUserDetailsSuccess} ] = useGetUserdetailsMutation();
    const [ getNewMenu, {data:menuListData, isLoading:menuListLoading, isSuccess:menuListSuccess} ] = useGetMenusMutation();

    let values = location?.hash?.split('#')[1];
    let token = values.split('?')[0];
    let segment = values.split('?')[1].split('$')[0];
    let resellerId = values.split('$')[1]
    const setTokenAuth = () => {
        dispatch(getAuth([token, null]));
        setTimeout(() => {
            accountChange();
        },0)
    }
    
    
    useEffect(() => {
        if(location.hash !== null && location.hash !== undefined){
            setTokenAuth();
        }
    },[location.hash])

    const accountChange = async () => {
        let payload = {
          //"userName": 'testmultilogintruck@gmail.com',
          "password": "",
          "segment": segment,
          "resellerId":resellerId
        }

        const switchAccount = await switchAcc(payload);
        if(switchAccount.data !== undefined ){
          setLocalStorage(switchAccount.data.accessToken, switchAccount.data.refreshToken);
          dispatch(getAuth([switchAccount.data.accessToken, switchAccount.data.refreshToken]));
          setTimeout(() => {
            getUserInfo();
          },0)
        }else{
            navigate('/');
        }
      }

      const setLocalStorage = (authToken, refToken) => {
        window.localStorage.setItem('jwToken',JSON.stringify(authToken));
        window.localStorage.setItem('rshjwToken',refToken);
        window.localStorage.setItem('expjwToken',null);
      }


      const getUserInfo = async () => {
        const getUserDetail = await getUserDetails();
        localStorage.setItem("userData", JSON.stringify(getUserDetail.data.data));
        getMenus(getUserDetail.data.data);
      }


      const getMenus = async (data) => {
        let payload = {
          "resellerId": data.resellerId,
          "orgId": data.orgId,
          "page": 0,
          "size": 100,
          "searchText": "",
          "id": 0,
          "roleId":  data.roleId,
          "orgType": data.userOrgType
      }
          const menusList = await getNewMenu(payload);
          window.localStorage.removeItem("menuList");
          window.localStorage.setItem("menuList", null);
          window.localStorage.setItem("menuList",JSON.stringify(menusList.data.menus));
          navigate(menusList.data.menus[0]?.sub[0]?.route !== undefined ? `/${menusList.data.menus[0]?.sub[0]?.route}` : `/${menusList.data.menus[1]?.sub[1]?.route}`);
          
      }

      const loaders = {
            aspectRatio: 1,
            animation: 
              'l2-1 2s infinite linear',
              
      }

          
          // @keyframes l2-2 {
          //   0% {background:#ffa516}
          //   50% {background:#f03355}
          // }

  return (
    <Box sx={{display:'flex', width:'100%'}}>
        <AppBar sx={{background:'#fff', zIndex:99999}} elevation={0}>
            <Toolbar>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'100%'}}>
                    <Box sx={{flex:1}}>
                        <Stack direction={'row'}  spacing={2}>
                            <Skeleton sx={{width:'50px'}}/>
                            <Skeleton sx={{width:'145px'}}/>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction={'row'}  spacing={2}>
                            <Skeleton variant="circular" width={30} height={30} />
                            <Skeleton sx={{width:'145px'}}/>
                        </Stack>
                    </Box>
                </Stack>
              
            </Toolbar>
            <Divider />
        </AppBar>
        <Drawer variant='permanent' open={true} sx={{
            "& .MuiDrawer-paper": {
            // background:
            // theme.palette.mode === "light"
            // ? theme.palette.primary.dark
            // : theme.sidebar.background,
            width:'10%',
            borderStyle: "none",
            transition:
            "all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s !important",
            },
            padding:'8px 12px'
        }}>
          <Toolbar />
          <Box sx={{height:'100%',  padding:'8px 12px'}}>
            {
              Array.from(Array(6).keys()).map(item =>  <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{my:1}}>
                <Skeleton variant="rounded" width={25} height={25} />
                <Skeleton sx={{width:'100%', height:'25px'}}/>  
              </Stack> )
            }
           
          
          </Box>
        </Drawer>
        <Box
          component={'main'}
          sx={{ flexGrow: 1, marginLeft:'8.7%', width:'90%'}}
        >
          <Toolbar />
            <Box sx={{px:3, py:2}}>
                <Skeleton variant='text' sx={{fontSize:'.8rem'}} width={150}/>
                <Skeleton variant='text' sx={{fontSize:'1.5rem'}} width={250}/>
                <Skeleton variant='rounded' height={80} sx={{mt:1}}/>
            </Box>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <Paper sx={{p:2,...loaders, '@keyframes l2-1': { 
                  '0%' : {transform: 'perspective(150px) rotateX(  0deg)'}, 
                  '100%': {transform: 'perspective(150px) rotateX(360deg)'},
                },
                }}>
                </Paper>
                <Typography variant='caption' >{
                  switchAccLoading ? 'Please Wait...' : getUserDetailsLoading ? 'Find your details' : menuListLoading ? 'Almost Done!' : 'Redirecting...' 
                  }</Typography>
            </Box>
        </Box>
    </Box>
       
  )
}

export default CheckAuthValidation