import { Alert, Backdrop, Box, Button, CardMedia, Chip, Fade, Grid, Grow, Snackbar, Stack, TextField, Typography, useTheme } from "@mui/material"
import React, { createRef, useEffect, useState } from "react"
import { FileUploadBox, StyledFilterFormControl, StyledFormControlLabel } from "../../theme/StyledEle"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { useGetLogoApiMutation, useSetLogoApiMutation } from "../../store/slice/ApiSlice" 
import Favicon from "react-favicon"
import { chanageSiteIdentity } from "../../store/slice/ControlsSlice"
import PulseLoader  from "react-spinners/PulseLoader";
import logoPlaceHolder from '../../assets/images/logoPlaceHolder.svg'
//import fav from '%PUBLIC_URL%/favicon.ico'

export const SiteIdentity = () => {
    const siteTitle = useSelector((state) => state.controls.siteIdentity.title);
    const siteTagLine = useSelector((state) => state.controls.siteIdentity.tagLine);
    const description = useSelector((state) => state.controls.siteIdentity.descriptions);
    const [image, setImage] = useState(null);
    const [fav, setFav] = useState(null);
    const theme = useTheme();
        const resellerID = JSON.parse(localStorage.getItem("userData"))?.resellerId;
        const body = {
            "resellerId": resellerID,
        }
        const [ getLogoApi, { data, isSuccess:isSuccessGetetLogo, isLoading:isLoadingGetLogo} ] = useGetLogoApiMutation();
        useEffect(() => {
            getLogoApi(body);
        },[resellerID])


        const logoURLs = useSelector( (state) => state.controls.siteIdentity.logoURL);
        const favIconURLs = useSelector( (state) => state.controls.siteIdentity.favIconURL);
        const title = useSelector( (state) => state.controls.siteIdentity.title);

        const { register, watch, handleSubmit, formState:{errors}, reset, setValue, getValues } = useForm({mode:'all'});
        useEffect(() => {
            setValue('siteTitle', data?.formData?.title);
            setValue('siteTagLine', data?.formData?.tagLine);
            setValue('description', data?.formData?.desc);
        },[data])


        //64Bite Conversion logo
        const conver2base64 = file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result.toString())
            };
            if(file) {
            reader.readAsDataURL(file);
            }
        }

        //64Bite Conversion Fav
        const conver2base64Fav = file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFav(reader.result.toString())
            };
            if(file) {
            reader.readAsDataURL(file);
            }
        }

        // Upload Logo
        const getLogo = (e) => {
            conver2base64(e.target.files[0]);
            setValue('logoFiles',e.target.files[0]);
        }
        // Upload Fav
        const getFavIcon = (e) => {
            conver2base64Fav(e.target.files[0]);
            setValue('favIcon',e.target.files[0])
        }


        //datas
        const rID = JSON.parse(localStorage.getItem("userData"))?.resellerId;
        const [ setLogoApi, { isLoading:isLoadingSetLogo, isSuccess:isSuccessSetLogo}] = useSetLogoApiMutation();
        const dispatch = useDispatch();
        const submitForms = (data) => {
            const siteData = {
                "resellerId": rID,
                "logoUrl":image,
                "logoFavicon":fav,
                "logoTitle":getValues('siteTitle'),
                "logoDesc":getValues('siteTagLine'),
                "logoTagLine":getValues('description'),
            }
            setLogoApi(siteData);
        }
    
        // useEffect(() => {
        //     if(isSuccessSetLogo){
        //         getLogoApi.refetch();
        //     }
        // },[isSuccessSetLogo])

    //    const isLoading = isLoadingSetLogo || isLoadingGetLogo
    //    const isSuccess = isSuccessSetLogo || isSuccessGetetLogo
       useEffect(() => {
        isSuccessGetetLogo &&  dispatch(chanageSiteIdentity(data.formData));
        },[isSuccessGetetLogo])

        
    return(
        <Fade 
        in
        timeout={{ enter: theme.transitions.duration.complex, exit: theme.transitions.duration.leavingScreen, }}
        >
        <Grid container spacing={2}>
            {
                isSuccessSetLogo && <Snackbar
                anchorOrigin={{ vertical: 'top',horizontal: 'center'}} 
                open={true}
                autoHideDuration={6000}
                message="Logo successfully updated"
                color="success"
                >
                    <Alert severity="success">
                         Succefully updated
                    </Alert>
                </Snackbar>
            }
            
            <Grid item lg={12} component={'form'} onSubmit={handleSubmit(submitForms)}>
            <Typography variant="h6" gutterBottom>General</Typography>
                
                <Stack spacing={2}>
                    <Grow in timeout={200}>
                    <Box>
                        <Typography gutterBottom>Logo</Typography>
                        <Grid container spacing={2}>
                            <Grid item lg={3}>
                                <FileUploadBox className="settingsUpload">
                                <CardMedia
                                        component='img'
                                        //src={image ? image : `https://${data?.formData.logoUrl}`}
                                        src={image ? image : logoURLs !== '' ? `https://${logoURLs}` : logoPlaceHolder }
                                        sx={{width:'120px !important', height:'120px !important', objectFit:'contain'}}
                                     /> 
                                </FileUploadBox>
                            </Grid>
                            <Grid item lg={9}>
                                <FileUploadBox>
                                    <input className="fileupload" type="file" accept='image/*' {...register('logoFiles')} onChange={(e) => getLogo(e)}/>
                                    <CardMedia
                                        component='img'
                                        src="https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                                        sx={{width:'65px', height:'65px'}}
                                     />
                                    <Typography>Upload logo</Typography>
                                        {/* {image &&  <Chip label={watch('logoFiles').name} size="small"/> } */}
                                    <Typography variant="caption">Size 120px X 22px</Typography>
                                </FileUploadBox>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grow>
                    <Grow in timeout={300}>
                    <Box>
                    <Typography>Fav Icon</Typography>
                        <Grid container spacing={2}>
                            <Grid item lg={3}>
                                <FileUploadBox className="settingsUpload">
                                    <CardMedia
                                        component='img'
                                        //src={fav ? fav : `https://${data?.formData.favIcon}`}
                                        src={fav ? fav : `https://${favIconURLs}`}
                                        className="favIcon"
                                     /> 
                                    <CardMedia
                                        component='img'
                                        src="https://assetmatics.live/wp-admin/images/browser.png"
                                     /> 
                                </FileUploadBox>
                            </Grid>
                            <Grid item lg={9}>
                                <FileUploadBox>
                                    <input className="fileupload" type="file" accept='image/*' {...register('favIcon')} onChange={(e) => getFavIcon(e)}/>
                                    <CardMedia
                                        component='img'
                                        src="https://cdn-icons-png.flaticon.com/512/8191/8191581.png"
                                        sx={{width:'65px', height:'65px'}}
                                     />
                                    <Typography>Upload Fav icon</Typography>
                                    { fav &&  <Chip label={watch('favIcon').name} size="small"/> }
                                    <Typography variant="caption">Size 60px X 60px</Typography>
                                </FileUploadBox>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grow>
                    <Grow in timeout={400}>
                <StyledFilterFormControl sx={{minWidth:'100%'}} error={errors.siteTitle}>
                    <StyledFormControlLabel required>Site Title</StyledFormControlLabel>
                    <TextField
                        size="small"
                        error={errors.siteTitle}
                        fullWidth
                        // defaultValue={}
                        {...register('siteTitle',{
                            required:{
                                value:true,
                                message:'Site title required'
                            }
                        })}
                        helperText={errors.siteTitle && errors.siteTitle.message}
                    />
                </StyledFilterFormControl>
                </Grow>
                <Grow in timeout={400}>
                <StyledFilterFormControl sx={{minWidth:'100%'}} error={errors.siteTagLine}>
                    <StyledFormControlLabel>Tag line</StyledFormControlLabel>
                    <TextField
                        error={errors.siteTagLine}
                        size="small"
                        fullWidth
                        defaultValue={data?.formData?.tagLine}
                        onChange={(e) => setValue(e.target.value)}
                        multiline
                        rows={1}
                        {...register('siteTagLine', {
                            keepTouched:true,
                        })}
                        helperText={errors.siteTagLine && errors.siteTagLine.message}
                    />
                </StyledFilterFormControl>
                </Grow>
                <Grow in timeout={400}>
                <StyledFilterFormControl sx={{minWidth:'100%'}} error={errors.description}>
                    <Stack direction='row' spacing={2}>
                        <StyledFormControlLabel>Descriptions</StyledFormControlLabel>
                        <Typography variant="caption">{watch('description') ? watch('description').length: ''} / 160</Typography>
                    </Stack>
                    <TextField
                        error={errors.description}
                        size="small"
                        fullWidth
                        multiline
                        rows={5}
                        defaultValue={data?.formData?.desc}
                        {...register('description',{
                            keepTouched:true,
                            maxLength:{
                                value:160,
                                message:'Reach maximum limit'
                            }
                        })}
                        helperText={errors.description && errors.description.message}
                    />
                </StyledFilterFormControl>
                </Grow>
                </Stack>
                <Stack direction='row' spacing={2} sx={{mt:2}} justifyContent='flex-end'>
                    <Button size="small" color="error">Cancel</Button>
                    <Button variant="contained" type="submit" size="small" disableElevation>
                        
                    { isLoadingSetLogo ? <PulseLoader color="#fff" size={10}/> : 'Save' }
                    {/* <PulseLoader color="#fff" /> */}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
        </Fade>
    )
}

export const Test = () => {
    const { register, handleSubmit, watch, setValue } = useForm();

    const conver2base64 = file => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setValue('pic',reader.result.toString())
        };
        if(file) {
        reader.readAsDataURL(file);
        }
    }
  const onSubmit = data => {
    conver2base64(data.pic[0])
  };

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <input {...register('pic')} type="file" accept='image/*'/>
            <input {...register('test')} type="test" accept='image/*'/>
            <button>Submit</button>
    </form>
    )
}