import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';
import zoneMarker from '../../assets/images/geoZoneMarker.png'; 

const CreateLeafletRouteTrip = (props) => {
  const { waypointList, geoZoneValue } = props;
  const mapRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [initialCoords, setInitialCoords] = useState([23.5120, 80.3290]);
  const theme = useTheme();
  console.log("waypointList",waypointList)
  console.log("geoZoneValue",geoZoneValue)

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('map', {
        center: initialCoords,
        zoom: zoomLevel,
        zoomControl: false
      }).setView(initialCoords, zoomLevel);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map);
      mapRef.current = map;
    } else {
      mapRef.current.setView(initialCoords, zoomLevel);
    }

    if (mapRef.current) {
      mapRef.current.eachLayer(layer => {
          if (!layer._url) mapRef.current.removeLayer(layer);
      });
    }

    if (geoZoneValue.length >= 2) {  
     
        const waypoints = geoZoneValue.map(zone => {
          if(zone.zoneType === "Circles" || zone.geoZoneType=== "Circles"){
          const coordinates = zone.cordinates.split(',').map(parseFloat);
          return L.latLng(coordinates[0], coordinates[1]);
        } else if (zone.zoneType === "Polygons" || zone.geoZoneType===  "Polygons") {
          const coordinates = JSON.parse(zone.cordinates);
          // Taking the first set of coordinates from the array
          const firstCoordinate = coordinates[0];
          return L.latLng(firstCoordinate.lat, firstCoordinate.lng);
        }
      });
          console.log("waypoints",waypoints)                     
    
      const customIcon = L.icon({
        iconUrl: zoneMarker,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -32]
      });

      L.Routing.control({
        waypoints,
        routeWhileDragging: false,
        lineOptions: {
          styles: [{color: '#007bff', opacity: 0.7, weight: 5}]
        },
        createMarker: () => {} 
      }).addTo(mapRef.current);

      geoZoneValue.forEach(zone => {
        if (zone.zoneType === "Circles" || zone.geoZoneType=== "Circles") {
          const coordinates = zone.cordinates.split(',').map(parseFloat);
          L.marker([coordinates[0], coordinates[1]], { icon: customIcon }).addTo(mapRef.current);
        } else if (zone.zoneType === "Polygons" || zone.geoZoneType===  "Polygons") {
          const coordinates = JSON.parse(zone.cordinates);
          const firstCoordinate = coordinates[0];
          L.marker([firstCoordinate.lat, firstCoordinate.lng], { icon: customIcon }).addTo(mapRef.current);
        }
      });
      
    
    }
  }, [initialCoords, zoomLevel, geoZoneValue]);

  return (
    <Box id="map" sx={{ width: '100%', height: '80vh' }}></Box>
  );
}

export default CreateLeafletRouteTrip;
