import { dashboardApi } from "../ApiSlice";

const DeviceApiSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        //Upload Validate
        deviceValidate: builder.mutation({
            query: (body) => {
            return {
                url: "/device/validateExcel",
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: body,
            }},
        }),
        //Device OnBoard
        deviceOnBoardValidate: builder.mutation({
            query: (body) => {
            return {
                url: "/deviceOnBoard/validateExcel",
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                body: body,
            }},
        }),
        //Deive Activity
        deviceAvtivity: builder.mutation({
            query: (body) => {
            return {
                url: "/device/getSingleDeviceHistory",
                method: "POST",
                body: body,
            }},
        }),
    })
})

export const { useDeviceValidateMutation, useDeviceOnBoardValidateMutation, useDeviceAvtivityMutation } = DeviceApiSlice;