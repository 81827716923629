import React, { useState, useEffect } from "react";
import dropdownServices from "../../services/dropdownServices";
import DropDown from "../shared/DropDown";
import {StyledFiterButton,StyledPaperShadow,} from "../../theme/StyledEle";
import "rsuite/dist/rsuite.min.css";


const CommonDropDown = (props) => {
  const { handleSmt, callbackLoadFn  } = props;
  
  var selectedOrgTypeId = null;
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;
  var globalDisplayView = 0;
  var selectedRegionId = null;
  var selectedDealerId = null;
  var selectedVehicleId = null;
  var selectedLastOrgId = null;


  const [regions, setRegions] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true;
  const [orgListJSON, setOrgListJSON] = useState([]);


  const [seltdOrgTypeId, setSeltdOrgTypeId] = useState("0");
  const [seltdResellerId, setSeltdResellerId] = useState("0");
  const [seltdCustomerId, setSeltdCustomerId] = useState("0");
  const [seltdFleetId, setSeltdFleetId] = useState("0");
  const [seltdDealerId, setSeltdDealerId] = useState("0");
  const [seltdVehicleId, setSeltdVehicleId] = useState("0");
  const [seltdLastOrgId, setSeltdLastOrgId] = useState("0");
  const [isOrgType, setIsOrgType] = useState(true);
  const [isReseller, setIsReseller] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  const [isFleet, setIsFleet] = useState(true);
  const [isShowVeh, setIsShowVeh] = useState(true);
  const [seltdRegionId, setSeltdRegionId] = useState("5");
 
  
  
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };
  const [select, setSelect] = useState([{ key: -1, value: "select" }]);


  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globalDisplayView = globalJson.displayView;
  }

  useEffect(async () => {
    if (2 === parseInt(globalOrgType)) {
      setIsOrgType(false);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsShowVeh(true);
      
      setSeltdOrgTypeId(globalOrgType);
      setSeltdResellerId(globalResellerId);
      selectedOrgTypeId=globalOrgType;
      selectedResellerId = globalResellerId;
      const regionListPayload = {
        value: "region",
        searchText:""
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionResponseBody.data;
      const _regions =regionList;
      setRegions(_regions);
      setSeltdRegionId(_regions.length > 0 ? _regions[5].key : "-1");
      selectedRegionId = _regions.length > 0 ? _regions[5].key : "-1";

      const dealerListPayload = {
        value: "dealer",
        resellerId:userData.userOrgType === 2 ? selectedResellerId : globalResellerId,
        searchText: "",
        regionId: selectedRegionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;

      const _dealers = dealerList;
      setDealers(_dealers.length>0?_dealers:select);
      setSeltdDealerId(_dealers.length > 0 ? _dealers[0].key : "-1");
      selectedDealerId = _dealers.length > 0 ? _dealers[0].key : "-1";

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: globalDisplayView === true ? _dealers[0].key : 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = customersList;
      setCustomers(customerAll.length>0?customerAll:select);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customerAll.length > 0 ? customersList[0].key : "-1",
        dealerId: globalDisplayView === true ? _dealers[0].key : 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = fleetListResponse;
      setFleet(fleetAll.length > 0 ? fleetAll: select);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");

      const vehicleListPayload = {
        paramValue: selectedFleetId,
        searchText: "",
        value: "orgId"
      };
      const { data: vehicleListResponse } = await dropdownServices.getFleetList(
        vehicleListPayload
      );
      const { content: vehicleList } = vehicleListResponse.data;
      const vehilceAll = vehicleList;
      setVehicles(vehilceAll.length > 0 ? vehilceAll : select);
      selectedVehicleId=vehilceAll.length > 0 ? vehilceAll[0].key : "-1";
      setSeltdVehicleId(vehilceAll.length > 0 ? vehilceAll[0].key : "-1");
      setSeltdLastOrgId(vehilceAll.length > 0 ? vehilceAll[0].key : "-1");
      selectedLastOrgId=vehilceAll.length > 0 ? vehilceAll[0].key : "-1";
    
    } else if (8 === parseInt(globalOrgType) && 1 === parseInt(globalRoleId)) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsShowVeh(true);
    

      const resellerPayload = {
        value: "reseller",
        orgType: "2",
        resellerId: globalResellerId,
        dealerId: 0,
        id: globalResellerId,
        roleId:1,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;
      setOrgListJSON(orgnListJSON);
      selectedResellerId = orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1";
      setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");

      const regionListPayload = {
        value: "region",
        searchText: ""
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionResponseBody.data;
      const _regions = regionList
      setRegions(_regions);
      setSeltdRegionId(_regions.length > 0 ? _regions[0].key : "-1");
      selectedRegionId = _regions.length > 0 ? _regions[0].key : "-1";

      const dealerListPayload = {
        value: "dealer",
        resellerId: seltdResellerId,
        searchText: "",
        regionId: selectedRegionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      const _dealers = dealerList;
      setDealers(_dealers.length>0?_dealers:select);
      setSeltdDealerId(_dealers.length > 0 ? _dealers[0].key : "-1");
      selectedDealerId = _dealers.length > 0 ? _dealers[0].key : "-1";

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: globalDisplayView === true ? seltdDealerId : 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = customersList;
      setCustomers(customerAll.length>0?customerAll:select);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customerAll.length > 0 ? customerAll[0].key : "-1",
        dealerId: globalDisplayView === true ? seltdDealerId : 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = fleetListResponse
      setFleet(fleetAll.length > 0 ? fleetAll: select);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");

      const vehicleListPayload = {
        value: "vehicle",
        resellerId: selectedResellerId,
        dealerId: globalDisplayView === true ? seltdDealerId : 0,
        customerId: selectedCustomerId,
        paramValue: selectedFleetId,
        searchText: "",

      };
      const { data: vehicleListResponse } = await dropdownServices.getFleetList(
        vehicleListPayload
      );
      const { content: vehicleList } = vehicleListResponse.data;
      const vehilceAll = vehicleList;
      setVehicles(vehilceAll.length > 0 ? vehilceAll : select);
      setSeltdVehicleId(vehilceAll.length > 0 ? vehilceAll[0].key : "-1");
      selectedVehicleId = vehilceAll.length > 0 ? vehilceAll[0].key : "-1";
      setSeltdLastOrgId(vehilceAll.length > 0 ? vehilceAll[0].key : "-1");

    } else if (1 === parseInt(globalOrgType)) {
      {
        setIsOrgType(false);
        setIsReseller(false);
        setIsCustomer(false);
        setIsFleet(false);
        setIsShowVeh(true);

        const vehicleListPayload = {
          value: "orgId",
          paramValue: selectedFleetId,
          searchText: "",
        };
        const { data: vehicleListResponse } =
          await dropdownServices.getFleetList(vehicleListPayload);
        const { content: vehicleList } = vehicleListResponse.data;
        setVehicles(getOptions(vehicleList));
      }
    } else if (3 === parseInt(globalOrgType)) {
      setIsOrgType(false);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(true);
      setIsShowVeh(false);
     

      const fleetListPayload = {
        value: "fleet",
        customerId: globalOrgId,
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdLastOrgId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdResellerId(globalResellerId);
    
    } else if (7 === parseInt(globalOrgType)) {
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsShowVeh(false);

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdLastOrgId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
   
    }
    callbackLoadFn(
      selectedOrgTypeId,
      selectedResellerId,
      selectedCustomerId,
      selectedFleetId,
      selectedVehicleId,
      selectedLastOrgId,
      selectedRegionId,
      selectedDealerId
    );
  }, []);

  const handleResellerSelect = async (resellerId) => {
    selectedResellerId = resellerId;
    setSeltdResellerId(resellerId);
    setSeltdLastOrgId(resellerId);
    if (globalDisplayView === true) {
      const regionListPayload = {
        value: "region",
        searchText:""
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionResponseBody.data;
      const _regions = regionList
      setRegions(_regions);
      setSeltdRegionId(_regions[0]?.key);
    } else {
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const _customers = customersList
      setCustomers(_customers);
      selectedCustomerId = _customers.length > 0 ? _customers[0].key : "-1";
      setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");
    }
  };

  const handleRegionSelect = async (regionId) => {
    setSeltdRegionId(regionId);
    if (globalOrgType === 8) {
      if (regionId === "-1") {
        setDealers([]);
        setDealers(select);
      } else {
        const dealerListPayload = {
          value: "dealer",
          // resellerId: userData.userOrgType === 8 ? seltdResellerId : globalResellerId,
          resellerId: seltdResellerId,
          dealerId: 0,
          searchText: "",
          regionId: regionId,
        };
        const { data: dealerListResponse } =
          await dropdownServices.getResellers(dealerListPayload);
        const { content: dealerList } = dealerListResponse.data;
        const _dealers = dealerList;
        setDealers(_dealers);
        setSeltdRegionId(regionId);
        setSeltdDealerId(_dealers[0].key);
        selectedDealerId = _dealers[0].key;
      }
    } else {
      if (regionId === "-1") {
        setDealers([]);
        setDealers(getOptions);
      } else {
        const dealerListPayload = {
          value: "dealer",
          resellerId:
            userData.userOrgType === 2 ? seltdResellerId : globalResellerId,
          dealerId: 0,
          searchText: "",
          regionId: regionId,
        };
        const { data: dealerListResponse } =
          await dropdownServices.getResellers(dealerListPayload);
        const { content: dealerList } = dealerListResponse.data;

        const _dealers = dealerList;
        setDealers(_dealers.length>0?_dealers:select);
        setSeltdRegionId(regionId);
        setSeltdDealerId(_dealers[0].key);
      }
    }
  };
  const handleDealerSelect = async (dealerId) => {
    setSeltdDealerId(dealerId);
    const customersListPayload = {
      value: "customer",
      orgType: "3",
      id: seltdResellerId,
      resellerId: userData.userOrgType === 8 ? seltdResellerId : globalResellerId,
      dealerId: dealerId,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const customerAll = customersList;
    setCustomers(customerAll.length>0?customerAll:select);
    setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
    handleCustomerSelect(customerAll.length > 0 ? customerAll[0].key : "-1");
  };  
  const handleCustomerSelect = async (customerId) => {
    selectedCustomerId = customerId;
    setSeltdCustomerId(customerId);
    setSeltdLastOrgId(customerId);

    const fleetListPayload = {
      value: "fleet",
      customerId: customerId,
      resellerId: globalOrgType === 8 ? selectedResellerId : globalResellerId,
      dealerId: 0,
      searchText: "",
    };
    const { data: fleetListResp } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetListResponse } = fleetListResp.data;

    const fleets = fleetListResponse;
    setFleet(fleets.length>0?fleets:select);
    selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
    setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    handleFleetSelect(fleets.length > 0 ? fleets[0].key : "-1");


    // if (fleetListResponse.length === 0) {
    //   const fleets = fleetListResponse;
    //   setFleet(fleets.length>0?fleets:select);
    //   selectedFleetId = fleets[0].key;
    //   setSeltdFleetId(fleets[0].key);
    // } else {
    //   const fleets = getOptions(fleetListResponse);
    //   setFleet(fleets);
    //   selectedFleetId = fleets.length > 0 ? fleets[0].key : "-1";
    //   setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    // }
  };

  const handleFleetSelect = async (fleetId) => {
    selectedFleetId = fleetId;
    setSeltdFleetId(fleetId);
    setSeltdLastOrgId(fleetId);
    setSeltdResellerId(globalResellerId);
    const vehicleListPayload = {
      value: "orgId",
      paramValue: fleetId,
      searchText: "",
    };
    const { data: vehicleListResponse } = await dropdownServices.getFleetList(
      vehicleListPayload
    );
    const { content: vehicleList } = vehicleListResponse.data;
    setVehicles(vehicleList.length>0?vehicleList:select);
    setSeltdVehicleId(vehicleList.length>0?vehicleList[0].key:"-1");
  };


  const handleVehicleSelect = (vehicleId) => {
    setSeltdVehicleId(vehicleId);
  };

  const handleSubmit = (e) => {
    if (globalDisplayView === true) {
     
      handleSmt(seltdOrgTypeId,seltdResellerId,seltdCustomerId,seltdFleetId,seltdVehicleId,seltdLastOrgId,seltdRegionId,seltdDealerId);
    } else {
      
      handleSmt(seltdOrgTypeId,seltdResellerId,seltdCustomerId,seltdFleetId,seltdVehicleId,seltdLastOrgId,seltdRegionId,seltdDealerId);
    }
  };

  const handleOrgTypeSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
  };

  const handleResellerSearch = async (searchText) => {
    const resellersListPayload = {
      value: "reseller",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
      searchText,
    };

    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
  };

  const handleCustomerSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: seltdResellerId,
      dealerId:  globalDisplayView?seltdDealerId:0,
      searchText: searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const customerall = getOptions(customersList);
    setCustomers(customerall);
  };

  const handleFleetSearch = async (searchText) => {
    const fleetListPayload = {
      value: "fleet",
      resellerId: seltdResellerId,
      dealerId: globalDisplayView?seltdDealerId:0,
      customerId: seltdCustomerId,
      searchText: searchText,
    };
    const { data: fleetListResponse } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetList } = fleetListResponse.data;
    setFleet(getOptions(fleetList));
  };

  const handleVehicleSearch = async (searchText) => {
    const vehicleListPayload = {
      value: "vehicle",
      resellerId: seltdResellerId,
      dealerId: globalDisplayView?seltdDealerId:0,
      customerId: seltdCustomerId,
      fleetId: seltdFleetId,
      searchText: searchText,
    };
    const { data: vehicleListResponse } = await dropdownServices.getFleetList(
      vehicleListPayload
    );
    const { content: vehicleList } = vehicleListResponse.data;
    setVehicles(getOptions(vehicleList));
  };



  return (
    <form>
      <StyledPaperShadow>
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={isReseller}
          fieldName="Organization"
        />

        <DropDown
          label="Region"
          options={regions}
          onChange={handleRegionSelect}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : false}
          // onSearch={handleRegionSearch}
          fieldName={"regionId"}
          selValue={seltdRegionId}
        />
        <DropDown
          label="Dealer"
          options={dealers}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : false}
          onChange={handleDealerSelect}
          fieldName={"dealerId"}
        />

        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isCustomer}
          onSearch={handleCustomerSearch}
        />

        <DropDown
          label="Fleet"
          options={fleet}
          onChange={handleFleetSelect}
          optionsDisplayKey="value"
          isShow={isFleet}
          onSearch={handleFleetSearch}
        />

        <DropDown
          label="Vehicle"
          options={vehicles}
          onChange={handleVehicleSelect}
          optionsDisplayKey="value"
          onSearch={handleVehicleSearch}
          isShow={isShowVeh}
        />

        <StyledFiterButton
          type="button"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
