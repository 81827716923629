import Service from "./service";
const logServices = {
  AuditLog: function (resource) {
    return Service.post("/AuditLog/gridView", resource, null);
  },
  UserLog: function (resource) {
    return Service.post("/UserLog/gridView", resource, null);
  },
  SendLog: function (resource) {
    return Service.post("/SendLog/gridView", resource, null);
  },
  VinLog: function (resource) {
    return Service.post("/vehicle/VinLogData", resource, null);
  },

  FeedLog: function (resource) {
    return Service.post("/FeedLog/gridView", resource, null);
  },
  DeviceDropdownWithPagination: function (resource) {
    return Service.post("/device/DeviceDropdownWithPagination", resource, null);
  },

  viewAlertLog: function (resource) {
    return Service.post("/Alerts/getReportList", resource, null);
  },
  saveAuditLogDetails: function (payload) {
    return Service.post("/AuditLog/saveAuditLogDetails", payload, null);
  },
};
export default logServices;
