import React, { useEffect, useMemo, useState} from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader, Grid, Paper } from "@mui/material";
import customerService from "../../services/customerService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel, Button, Typography, InputLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectWithSearch from "../shared/SelectWithSearch";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";
import DraggableDialog from "../shared/DailogDrag";
import customerKYCServices from "../../services/customerKYCService";
import KycDropDown from "./KycLogDropdown";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import logServices from "../../services/logService";
import KycLogDropdown from "../LogDropdown/KYCDropdown";
import { useListFeedProcessMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


const ListKycProcessedFeed = ({ tabValue, onEditKyc }) => {

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [pageStatus, setPageStatus] = useState();
  const [loading, setLoading] = useState("");

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });

  const theme = useTheme();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  // useEffect(async () => {
  //   await fetchProcessingDetails();
  // }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date & Time",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "VIN",
            accessor: "vin",
          },
          {
            Header: "Vehicle Number",
            accessor: "engineNumber",
          },
          {
            Header: "Vehicle Model",
            accessor: "vehicleModel",
          },
        ],
      },
      {
        Header: "Customer Details",
        columns: [
          {
            Header: "Name",
            accessor: "customerName",
          },
          {
            Header: "Mobile No",
            accessor: "customerMobile1",
          },
          {
            Header: "Email",
            accessor: "customerEmail",
          },
          {
            Header: "Retail Invoice No",
            accessor: "retailInvoiceNumber",
          },
          {
            Header: "License",
            accessor: "licenseNumber",
          },
          {
            Header: "Aadhar",
            accessor: "aadharNumber",
          },
        ],
      },
      {
        Header: "Dealer Details",
        columns: [
          {
            Header: "Code",
            accessor: "dealerCode",
          },
          {
            Header: "Name",
            accessor: "dealerName",
          },
          {
            Header: "Email",
            accessor: "dealerContactName",
          },
          {
            Header: "Mobile",
            accessor: "dealerContactNumber",
          },
          {
            Header: "GST No",
            accessor: "gstInNew",
          },
        ],
      },
      {
        Header: "KYC Mode",
        accessor: "kycType",
      },
      {
        Header: "Valid KYC Count",
        accessor: "recordCount",
      },
      {
        Header: "Ticket",
        accessor: "createTicket",
      },
      {
        Header: "Last KYC Rejected Time",
        accessor: "lastKycRejectedTime",
      },
      {
        Header: "KYC Approved Time",
        accessor: "kycApprovedTime",
      },

      {
        Header: "Active Status",
        accessor: "state",
      },
      {
        Header: "KYC Status",
        accessor: "kycStatus",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditKyc(itemEdited);

    auditPayload.message = "Reports > KYC Log > KYC Processing > Edit";
    auditPayload.action = "Click";
    auditPayload.featureName = "KYC Processing";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };



  // const callbackLoadFn = async (request1) => {
  //   if (
  //     "null" !== JSON.parse(localStorage.getItem("menuList")) &&
  //     null !== JSON.parse(localStorage.getItem("menuList"))
  //   ) {
  //     if (JSON.parse(localStorage.getItem("menuList")) !== null) {
  //       for (
  //         let i = 0;
  //         i < JSON.parse(localStorage.getItem("menuList")).length;
  //         i++
  //       ) {
  //         if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
  //           for (
  //             let j = 0;
  //             j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
  //             j++
  //           ) {
  //             if (
  //               JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
  //               "KycLog"
  //             ) {
  //               var dataStr = {};
  //               dataStr.create = false;
  //               dataStr.edit = false;
  //               dataStr.delete = false;
  //               dataStr.view = false;
  //               dataStr.recentActivation = false;
  //               dataStr.importfunction = false;
  //               dataStr.excel = false;
  //               dataStr.pdf = false;
  //               var strval;
  //               strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
  //                 j
  //               ].button.split(",");
  //               var edit = false;
  //               for (var k = 0; k < strval.length; k++) {
  //                 if (parseInt(strval[k]) === 1) {
  //                   dataStr.create = true;
  //                 }
  //                 if (parseInt(strval[k]) === 2) {
  //                   dataStr.view = true;
  //                 }
  //                 if (parseInt(strval[k]) === 3) {
  //                   dataStr.edit = true;
  //                 }
  //                 if (parseInt(strval[k]) === 4) {
  //                   dataStr.delete = true;
  //                 }
  //                 if (parseInt(strval[k]) === 18) {
  //                   dataStr.recentActivation = true;
  //                 }
  //                 if (parseInt(strval[k]) === 9) {
  //                   dataStr.importfunction = true;
  //                 }
  //                 if (parseInt(strval[k]) === 5) {
  //                   dataStr.excel = true;
  //                 }
  //                 if (parseInt(strval[k]) === 6) {
  //                   dataStr.pdf = true;
  //                 }
  //               }
  //               setCommonFilter(dataStr);

  //               break;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   var request = {};
  //   request.startDate = request1.defaultFrom;
  //   request.endDate = request1.defaultTo;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = request1.defaultSearch;
  //   request.vinVal = request1.defaultVin;
  //   request.retailVal = request1.defaultRetailVal;
  //   request.state = request1.defaultStatus;
  //   request.kycStatus = request1.defaultKycStatus;

  //   const responseData = (
  //     await customerKYCServices.getGridViewForProcessedFeed(request)
  //   ).data.data;
  //   setResponseData(responseData.content);

  //   var request = {};
  //   request.message = "Logs > KYCLog >KYCProcessing";
  //   request.action = "View";
  //   request.featureName = "KYCLog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName = globalUserName;

  //   const responseData1 = await logServices.saveAuditLogDetails(request);

  //   console.log(responseData.content);
  // };

  // const handleSearch = (searchText) => {
  // };

  //   const callbackChangePage = async (newPage, rowsPerPage) => {
  //     setNewPage(newPage);
  //     setRowsPerPage(rowsPerPage);
  //     var request = {};

  //     request.batteryId = 2;
  //     request.page = newPage;
  //     request.sortBy = "desc";
  //     request.batteryModel = "All";
  //     request.batteryType = "All";
  //     request.size = rowsPerPage;

  //     const responseData = (await BatteryServices.getGridViewForBat(request)).data
  //       .data;
  //     setResponseData([]);
  //     setResponseData(responseData.content);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.totalElements);
  //     setTotalPages(responseData.totalPages);
  //   };


  // const handleSubmit = async (selectSearch,selectedVin, selectedStatus,selectedKycStatus,startDate,endDate,selectedRetailVal) => {
  //   var request = {};
  //   request.startDate = startDate;
  //   request.endDate = endDate;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = selectSearch;
  //   request.vinVal = selectedVin;
  //   request.state = selectedStatus;
  //   request.kycStatus = selectedKycStatus;
  //   request.retailVal=selectedRetailVal;
  //   setPageStatus(request);
  //   try {
  //     const responseData = (await customerKYCServices.getGridViewForProcessedFeed(request)).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };



  const [ getListFeedPro, {data:kycFeedData, isLoading, isSuccess} ] = useListFeedProcessMutation();
  let commonPayload = { }
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortBy = 'desc';
    commonPayload.selectedType = data.searchType;
    commonPayload.vinVal = 0;
    commonPayload.state = data.statusId;
    commonPayload.kycStatus = data.kycStatus;
    commonPayload.retailVal = data.invoiceNo;
    commonPayload.search = '';
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    setPayload(commonPayload);
    getListFeedPro(commonPayload);
    getActionBtn();
  }

  const getActionBtn = () => {
    if ("null" !== JSON.parse(localStorage.getItem("menuList")) && null !== JSON.parse(localStorage.getItem("menuList"))) {
    if (JSON.parse(localStorage.getItem("menuList")) !== null) {
      for ( let i = 0; i < JSON.parse(localStorage.getItem("menuList")).length; i++) 
      {
        if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) 
        {
          for (
            let j = 0;
            j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
            j++
          ) {
            if (
              JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
              "KycLog"
            ) {
              var dataStr = {};
              dataStr.create = false;
              dataStr.edit = false;
              dataStr.delete = false;
              dataStr.view = false;
              dataStr.recentActivation = false;
              dataStr.importfunction = false;
              dataStr.excel = false;
              dataStr.pdf = false;
              var strval;
              strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                j
              ].button.split(",");
              var edit = false;
              for (var k = 0; k < strval.length; k++) {
                if (parseInt(strval[k]) === 1) {
                  dataStr.create = true;
                }
                if (parseInt(strval[k]) === 2) {
                  dataStr.view = true;
                }
                if (parseInt(strval[k]) === 4) {
                  dataStr.edit = true;
                }
                if (parseInt(strval[k]) === 3) {
                  dataStr.delete = true;
                }
                if (parseInt(strval[k]) === 18) {
                  dataStr.recentActivation = true;
                }
                if (parseInt(strval[k]) === 9) {
                  dataStr.importfunction = true;
                }
                if (parseInt(strval[k]) === 5) {
                  dataStr.excel = true;
                }
                if (parseInt(strval[k]) === 6) {
                  dataStr.pdf = true;
                }
              }
              setCommonFilter(dataStr);
              break;
            }
          }
        }
      }
    } 
  }
}

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (kycFeedData.data !== null) {
        setResponseData(kycFeedData.data.content);
        setTotalElements(kycFeedData.data.totalElements);
        setTotalPages(kycFeedData.data.totalPages);
      }

      //console.log(oversppedData.data.totalElements);
    }


    auditPayload.message = "Reports > KYC Log > KYC Processing";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Processing";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, [isSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getListFeedPro(payload);
    } else if (payload.search.length === 0) {
      getListFeedPro(payload);
    }
  };


  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getListFeedPro(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getListFeedPro(payload);
    }
  };


  return (
    <Box>
      {/* <KycDropDown tabValue={tabValue} handleSmt={handleSubmit}  callbackLoadFn={callbackLoadFn}/> */}
      <KycLogDropdown getData={getData} isShowAll={false} showCalendar={false} tabValue={tabValue}/>
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardHeader 
          title={<StyledTableTitle>KYC Processing</StyledTableTitle>}
        />
        <CardContent sx={{py:0}}>
        <DataTable
          isLoading={isLoading}
          includeSearchBox={true}
          includeExportButton={false}
          handleChange={handleSearch}
          onEdit={onEdit}
          //   onView={onView}
          columns={columns}
          data={responseData}
          styles={{ maxWidth: "100vw" }}
          numberOfStickyColumns={1}
          page={newPage}
          rowsPerPage={rowsPerPage}
          callbackChangePage={pageChange}
          totalElements={totalElements}
          totalPages={totalPages}
          commonFilter={commonFilter}
        />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default ListKycProcessedFeed;
