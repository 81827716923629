import React, { useCallback, useEffect, useState } from "react";
import {
  NoVehicleBox,
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import {
  Box,
  CardMedia,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  HiMiniFolderMinus,
  HiMiniFolderPlus,
  HiMiniUserGroup,
} from "react-icons/hi2";
import { FaShop } from "react-icons/fa6";
import Region from "./Region";
import {
  useGetRegionListMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCountMutation,
} from "../../../store/slice/ApiSlice";
import { ScaleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  dropVehicles,
  isLoadingState,
  refresh,
  selectedType,
  selectedVehicleData,
  selectedVehicleTrailsData,
  vehicleCountSlice,
} from "../../../store/slice/ControlsSlice";
import { HiUser } from "react-icons/hi";
import Customer from "./Customer";
import Fleet from "./Fleet";
import Dealer, { DealerWise } from "./Dealer";
import { OfflineBike, OfflineAuto, OfflineTruck } from "../../Constans";
import { width } from "@mui/system";
import Vehicle from "./Vehicle";

function Reseller(props) {
  const { userData, whoisLogin, isSearch } = props;
  const [open, setOpen] = useState(true);
  const [fleetPayload,setFleetPayload]= useState(null);
  const theme = useTheme();

  //dispatch
  const dispatch = useDispatch();

  useEffect(() => {
   
    if (whoisLogin === 2) {
      //Reseller
      getRegoinList();
      getCountVehicle(2);
      //dispatch(selectedType(['resellerId', userData.orgId]));
    } else if (whoisLogin === 5) {
      // Dealer
      getCustomers();
      getCountVehicle(5);
      //dispatch(selectedType(['dealerId', userData.orgId]));
    } else if (whoisLogin === 3) {
      // Customer
      getFleetList();
      getCountVehicle(3);
      //dispatch(selectedType(['customerId', userData.orgId]));
    }else if (whoisLogin === 1) {
      // Customer
      getFleetList();
      getCountVehicle(1);
      //dispatch(selectedType(['fleetId', userData.orgId]));
    }
  }, [whoisLogin, userData?.resellerId]);

  //Api Slice onload
  //get Region
  const [getRegion, { data, isLoading, isSuccess, isError }] = useGetRegionListMutation();
  //get Customer
  const [
    getCustomer,
    {
      data: customerData,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetRegionListMutation();
  //get Fleet
  const [
    getFleet,
    { data: fleetData, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetRegionListMutation();
  //get Rider
  const [
    getRider,
    { data: RiderData, isLoading: riderLoading, isSuccess: riderSuccess },
  ] = useGetRegionListMutation();

  //onload Vehicle Count & Vehicle
  const [
    getVehicleCount,
    {
      data: vehicleCountData,
      isLoading: countLoading,
      isSuccess: countSuccess,
    },
  ] = useGetVehicleCountMutation();
  const [
    getVehicleByReseller,
    {
      data: getVehicleData,
      isLoading: getVehicleLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleByResellerMutation();

  //get vehicle count based on login
  const getCountVehicle = (whoisLogin) => {
    dispatch(isLoadingState(true));
    if (whoisLogin === 2) {
      //dispatch(selectedType(['resellerId', userData.orgId]));
      // Reseller
      let commonCountPayload = {
        resellerId: userData.resellerId,
        selectId: "",
        selectType: "",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: userData.resellerId,
        regionId: 0,
        dealerId: 0,
        customerId: 0,
        orgId: userData.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    } else if (whoisLogin === 5) {
      //dispatch(selectedType(['dealerId', userData.orgId]));
      //Dealer Admin
      let commonCountPayload = {
        resellerId: userData.resellerId,
        selectId: userData.orgId,
        selectType: "dealer",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: userData.resellerId,
        dealerId: userData.orgId,
        customerId: 0,
        orgId: userData.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    } else if (whoisLogin === 3) {
      //dispatch(selectedType(['customerId', userData.orgId]));
      let commonCountPayload = {
        resellerId: userData.resellerId,
        selectId: userData.orgId,
        selectType: "customer",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: userData.resellerId,
        dealerId: 0,
        customerId: userData.orgId,
        orgId: userData.resellerId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
      };
      getVehicleByReseller(commonPayload);
    } 
    else if (whoisLogin === 1) {
      //dispatch(selectedType(['fleetid', userData.orgId]));
      let commonCountPayload = {
        resellerId: userData.resellerId,
        selectId: userData.orgId,
        selectType: "fleet",
      };
      getVehicleCount(commonCountPayload);
      let commonPayload = {
        resellerId: userData.resellerId,
        dealerId: 0,
        customerId:0,
        orgId: userData.orgId,
        checkboxStatus: false,
        page: 0,
        size: 1000,
        region: 0,
        vehModel: "All",
        searchText: "",
        zoneId: 0,
        orgType:userData.userOrgType
      };
      getVehicleByReseller(commonPayload);
    }
  };
  //set data from based on login
  //if reseller login get region
  const [regionList, setRegionList] = useState(null);
  //if dealer login get customer
  let [customerList, setCustomerList] = useState(null);
  //if customer get fleet
  let [fleetList, setFleetList] = useState(null);

  //get vehicle count
  let [count, setCount] = useState(0);

  //search keyword
  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );

  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );

  const getRegoinList = useCallback(
    (isSearch) => {
      //Onload Get Region
      //get Region Payload
      let getRegionPayload = {
        resellerId: userData.resellerId,
        orgId: userData.resellerId,
        page: 0,
        size: 10,
        value: "reseller",
        vehmodel: "All",
        regionId: -1,
        statusFlag: 9,
        search: "",
      };
      if (isSearchKeyword !== null) {
        getRegionPayload.search = isSearchKeyword;
      } else {
        getRegionPayload.search = "";
      }
      if (
        regionList === null ||
        (isSearchKeyword !== null && isSearchKeyword >= 3)
      ) {
        getRegion(getRegionPayload);
      } else {
        getRegion(getRegionPayload);
      }
    },
    [regionList, isSearch]
  );

  const getCustomers = useCallback(() => {
    //onload get Customer
    let getCustomerPayload = {
      resellerId: userData.resellerId,
      dealerId: userData.orgId,
      orgId: userData.resellerId,
      page: 0,
      size: 10,
      statusFlag: 11,
      value: "dealer",
      vehmodel: "All",
      search: "",
    };
    if (isSearchKeyword !== null) {
      getCustomerPayload.search = isSearchKeyword;
    } else {
      getCustomerPayload.search = "";
    }
    if (
      customerList === null ||
      (isSearchKeyword !== null && isSearchKeyword > 3)
    ) {
      getCustomer(getCustomerPayload);
    } else {
      getCustomer(getCustomerPayload);
    }
  }, [customerList, isSearch]);

  const getFleetList = useCallback(() => {
    //onload get Customer
    let getFleetPayload = {
      resellerId: userData.resellerId,
      orgId: userData.orgId,
      regionId: 0,
      page: 0,
      size: 10,
      statusFlag: 6,
      value: "fleet",
      vehmodel: "All",
      search: "",
    };
    setFleetPayload(getFleetPayload);
    if (isSearchKeyword !== null) {
      getFleetPayload.search = isSearchKeyword;
    } else {
      getFleetPayload.search = "";
    }
    if (
      fleetList === null ||
      (isSearchKeyword !== null && isSearchKeyword > 3)
    ) {
      getFleet(getFleetPayload);
    } else {
      getFleet(getFleetPayload);
    }
  }, [fleetList, isSearchKeyword]);

  //Get Region Data
  useEffect(() => {
    dispatch(isLoadingState(true));
    if (isSuccess) {
      dispatch(isLoadingState(false));
      setRegionList(data);
      if(data.treeviewList !== null && data.treeviewList !== undefined){
        let getCount = data?.treeviewList?.reduce(
          (total, current) => (total = total + current.vehicleCount),
          0
        );
        setCount(getCount);
    }

    } else if (customerSuccess) {
      dispatch(isLoadingState(false));
      setCustomerList(customerData);
      
      if(customerData.treeviewList !== null && customerData.treeviewList !== undefined){
          let getCount = customerData?.treeviewList?.reduce(
            (total, current) => (total = total + current.vehicleCount),
            0
          );
          setCount(getCount);
      }
    
    } else if (fleetSuccess) {
      dispatch(isLoadingState(false));
      setFleetList(fleetData);
      let getCount = fleetData?.treeviewList?.reduce(
        (total, current) => (total = total + current.vehicleCount),
        0
      );
      if(fleetData.treeviewList !== null && fleetData.treeviewList !== undefined){
        setCount(getCount);
      }
     
    }
  }, [isSuccess, customerSuccess, fleetSuccess]);

  //Onload VehicleCount & Marker
  useEffect(() => {
    if (getVehicleSuccess) {
      dispatch(vehicleCountSlice(vehicleCountData));
      dispatch(dropVehicles(getVehicleData));
      dispatch(isLoadingState(false));
    }
  }, [countSuccess, getVehicleSuccess]);

  //Collabse Open
  const collabseOpen = (whoisLogin) => {
    setOpen(!open);
    //dispatch(selectedType(['resellerId', userData.orgId]));
    dispatch(selectedVehicleTrailsData(null));
    dispatch(refresh([false, false]));
    if (whoisLogin === 2) {
      if (open && regionList === null) {
        getRegoinList();
        //dispatch(selectedType(['resellerId', userData.orgId]));
      }
    } else if (whoisLogin === 5) {
      if (open && customerList === null) {
        getCustomers();
        //dispatch(selectedType(['dealerId', userData.orgId]));
      }
    } else if (whoisLogin === 3) {
      if (open && fleetList === null) {
        getFleetList();
        //dispatch(selectedType(['customerId', userData.orgId]));
      }
    }
  };

  //getCount
  const getCount = () => {
    getCountVehicle(whoisLogin);
    //getVehicleCount(commonCountPayload);
    //getVehicleByReseller(commonPayload);
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
    }
  };

  //search
  const searchVehicle = () => {
    if (whoisLogin === 2) {
      setRegionList(null);
      getRegoinList(isSearch);
    } else if (whoisLogin === 5) {
      setCustomerList(null);
      getCustomers(isSearch);
    } else if (whoisLogin === 3) {
      setFleetList(null);
      getFleetList(isSearch);
    }
  };

  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (isSearch !== null && isSearch.length >= 3) {
        searchVehicle();
      } else {
        searchVehicle();
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearch]);

  let VehicleType = userData.vehicleType;

  const VehicleNotFound = () => {
    return (
      <NoVehicleBox>
        <CardMedia src={VehicleType === 'TwoWheeler' ? OfflineBike : VehicleType === 'ThreeWheeler' ? OfflineAuto : VehicleType === 'Truck' && OfflineTruck } component={"img"} sx={{ width: "80px" }} />
        No Vehicles Found
      </NoVehicleBox>
    );
  };


  const [openRegId, setOpenRegId] = useState(null);
  const [openDealerId, setOpenDealerId] = useState(null);
  const [openCustomerId, setOpenCustomerId] = useState(null);
  const [openfleetId, setOpenFleetId] = useState(null);




  //Onload Open Region
  const onLoadOpenReseller = () => {
    //
    if(userData.resellerId === 109951 && isSearch === null){ //KG 2W
       setOpenRegId(1);
       setOpenDealerId(113749);
       setOpenCustomerId(113754);
       setOpenFleetId(1);
    }
  }

  useEffect(() => {
    onLoadOpenReseller(); //Onload Time set Region ID
  },[userData?.resellerId])

  useEffect(() => {
      if(isSearch !== null || isSearch !== ""){
        setOpenRegId(null);
        setOpenDealerId(null);
        setOpenCustomerId(null);
        setOpenFleetId(null);
      }
  },[isSearch])









  return (
    <TreeViewAccodionBox rolles={"reseller"} isOpen={open} lineOffset={14}>
      <TreeViewListItemBtn
        primary={"reseller"}
        secondaryAction={
          <IconButton onClick={() => collabseOpen(whoisLogin)}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        onClick={getCount}
        isOpen={open}
      >
        <ListItemIcon>
          {whoisLogin === 2 ? (
            <FaShop />
          ) : whoisLogin === 3 ? (
            <HiUser />
          ) : whoisLogin === 5 ? (
            <HiMiniUserGroup />
          ) : (
            ""
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{userData?.orgName}</Typography>
              <Typography>{`(${count})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>

      {/* Collabse Region */}
      <Collapse in={open}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
            <ScaleLoader color={theme.palette.secondary.main} height={20} />
          </Box>
        ) : isError ? (
          <Typography>Somthing Went wrong</Typography>
        ) : (
          <>
            {whoisLogin === 2 ? (
              data?.treeviewList.length > 0 ? (
                data?.treeviewList.map((item, index) => (
                  <Region
                    regionItem={item}
                    index={index}
                    key={index}
                    userData={userData}
                    buttonIndex={25}
                    OpenRegoinId={openRegId}
                    openDealerId={openDealerId}
                    openCustomerId={openCustomerId}
                    openfleetId={openfleetId}
                  />
                ))
              ) : (
                <VehicleNotFound />
              )
            ) : whoisLogin === 3 ? (
              fleetData?.treeviewList?.length > 0 ? (
                fleetData?.treeviewList[0]?.orgs?.map((fleetItem, index) => (
                  <Fleet
                    fleetItem={fleetItem}
                    buttonIndex={25}
                    isCutomer={true}
                    index={index}
                    fleetPayload={fleetPayload}
                  />
                ))
              ) : (
                <VehicleNotFound />
              )
            ) : whoisLogin === 5 ? (
              customerData !== undefined &&
              customerData?.treeviewList.length > 0 ? (
                customerData?.treeviewList.map((custmerItem, index) => (
                  <Customer
                    customers={custmerItem}
                    userData={userData}
                    dealerId={userData.orgId}
                    buttonIndex={10}
                    isCutomer={true}
                  />
                ))
              ) : (
                <VehicleNotFound />
              )
            ) : whoisLogin === 1 ? (
              fleetData !== undefined &&
              fleetData?.treeviewList.length > 0 ? (
                fleetData?.treeviewList[0]?.orgs[0]?.vehicles?.map((custmerItem, index) => (
                  <Vehicle
                    vehiclesItem={custmerItem}
                    userData={userData}
                    dealerId={userData.orgId}
                    buttonIndex={10}
                    //isCutomer={true}

                  />
                ))
              ) : (
                <VehicleNotFound />
              )
            ) : ''}
          </>
        )}
      </Collapse>
    </TreeViewAccodionBox>
  );
}
//<Fleet userData={userData} isCutomer={true}/>
export default Reseller;
