import React, { useEffect, useState } from 'react'
import { RouteListbar, StyledSwitch } from '../../../theme/StyledEle'
import { Checkbox, Fab, Icon, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { ListItemButton } from '@mui/material';
import { useGetEditRouteTripMutation, useGetRouteListForMapMutation } from '../../../store/slice/RouteTripSlice/RouteTripSlice';
import { routeTrips } from '../../../store/slice/ControlsSlice';
import { PiCaretLeftBold } from 'react-icons/pi';
import { FaRoute } from "react-icons/fa6";
import RouteAccordian from './RouteAccordian';
import NoDataFound from '../../../Utils/NoDataFound';

function RoutesBar(props) {
    const { showTrails, handleTrails, RouteGeoZones } = props;
    const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
    //Route call
    const [ getrouteList, {data:routeList, isLoading:routeListLoading, isSuccess:routeListSuccess}] = useGetRouteListForMapMutation();
    const dispatch = useDispatch();
    const GetRouetList = async () => {
        let getRoutePayload = {
            'vehicleId': selectedVehicle
        }
        const RouteTrips = await getrouteList(getRoutePayload);
  
    }
    useEffect(() => {
        if(selectedVehicle !== null && selectedVehicle !== undefined){
            GetRouetList();
        }
    },[selectedVehicle]);

    const [ getSingleRouteTrip ] = useGetEditRouteTripMutation();
    const [routeId, setRouteId] = useState(null);

    const getRouteSelect = (id) => {
        if(id === routeId){
            setRouteId(null);
            dispatch(routeTrips(null));
        }else{
            setRouteId(id);
            getRouteTrips(id);
        }
    }

    const date = useSelector((state) => state.controls.treeview.date);
    const trailsTime = useSelector((state) => state.controls.treeview.trailsTime);

    useEffect(() => {
        setRouteId(null); // Change date or Trails time reset check box
    },[date, trailsTime, selectedVehicle])

    useEffect(() => {
        if(RouteGeoZones === null){
            setRouteId(null);
        }
    },[RouteGeoZones])




    
    const getRouteTrips = async (id) => {
        const singleRouteData = await getSingleRouteTrip({'routeId': id});
        dispatch(routeTrips(singleRouteData.data.data));
    }
    const [show, setShow] = useState(true);
  return (
    <RouteListbar sx={{right:show ? '0px' : '-320px', height:show ? 'auto' : '70px'}} >
        <Fab size='small' 
            sx={{
              boxShadow:'none', 
              position:'absolute', 
              top:'50%', 
              left:show ? '-30px' : '-20px',
              transform:`translate(0px, -50%) rotate(${show ? '0deg' : '180deg'})`,
              transition:'.5s'
              }} color='secondary' onClick={() => setShow(!show)}>
            <PiCaretLeftBold />
        </Fab>
        {

        }
        { show  &&  
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={{px:1, py:.5}}>Route List <Icon sx={{fontSize:'16px', display:'inline'}} color='info'><FaRoute /></Icon></Typography>
            <Tooltip title={showTrails ? 'Hide trails' : 'Show Trails'} arrow>
                <StyledSwitch
                    onChange={(event) => handleTrails(!showTrails)}
                    checked={showTrails}
                />
            </Tooltip>
        </Stack>
        }
       
       {
        show && 
        <List dense={true} sx={{p:0, maxHeight:'300px', height:'100%', overflowY:'auto',}}>
            {/* {
                 routeList !== undefined && routeList?.data?.length > 0 ? 
                 routeList?.data.map((RouteListItem, index) => {
                    return(  <RouteAccordian routeListItem={RouteListItem} key={index}/> )
                }) : <NoDataFound description={'No tripsfound'}/> 
            } */}
          
        {
            routeList !== undefined && routeList.data.length > 0 && routeList.data.map((item, index) => (
                <ListItem dense={true} sx={{p:0}} key={index}>
                    <ListItemButton dense={true} sx={{p:0}} onClick={() => getRouteSelect(item.routeId)}>
                        <ListItemIcon sx={{p:0, minWidth:'auto'}}>
                            <Checkbox size='small' checked={routeId === item.routeId ? true : false }/>
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant='body2'>{item.routeName}</Typography>}/>
                    </ListItemButton>
                </ListItem>
            ))
        }
    </List>
       } 
    </RouteListbar>
  )
}

export default RoutesBar