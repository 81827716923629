import React, { useEffect, useRef, useState } from "react";
import AccordianComponent from "./AccordianComponent";
import {
  Box,
  Collapse,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TreeViewBox, TreeViewInnerBox } from "./TreeviewStyledEle";
import { BsArrowsExpand } from "react-icons/bs";
import { BiExpandVertical } from "react-icons/bi";
import TreeviewControls from "./TreeviewControls";
import TreeviewList from "./TreeviewList";
import TreeViewNewList from "../NewTreeView/TreeViewNewList";
import Draggable from "react-draggable";
import { RiDraggable } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { searchKeyWord } from "../../../store/slice/ControlsSlice";
function Treeview({ regionData, userData, autoRef, autoRefresh, geoZone, handleGeoZone, vehicleTrails, activeFeature, handleFeatureChange, showTrails, handleTrails, treeRef, showCategory }) {
  const theme = useTheme();
  let [open, setOpen] = useState(true);
  let [fullHeight, setFullHeight] = useState(false);
  const nodeRef = useRef(null);
  const [searchKey, setsearchKey] = useState(null);
  //Seach keyword
  const dispatch = useDispatch();

  //Search
  const searchVehicle = (event) => {
    setsearchKey(event.target.value);
    dispatch(searchKeyWord(event.target.value));
  };

  return (
    <Draggable
      handle=".draggHandler"
      bounds={".ContentArea"}
      allowAnyClick={true}
      nodeRef={nodeRef}
      //enableUserSelectHack={true}
      defaultPosition={{ x: 25, y: 80 }}
      
    >
      <TreeViewBox ref={nodeRef} sx={{visibility: userData.resellerId !== 110050 && showCategory? 'hidden' : 'visible'}}>
        {/* base tree view accordion*/}
        <TreeViewInnerBox ref={treeRef}>
          <Box sx={{ px: 1, py: 1, color: "#fff", position: "relative" }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <IconButton
                size="small"
                onClick={() => setOpen(!open)}
                sx={{ color: "#fff" }}
              >
                <BsArrowsExpand />
              </IconButton>
              <Box className="draggHandler">
                <Typography variant="subtitle2">Click to Drag</Typography>
              </Box>
              <IconButton
                size="small"
                onClick={() => setFullHeight(!fullHeight)}
                sx={{
                  color: "#fff",
                  transform: `rotate(${fullHeight ? "90deg" : "0deg"})`,
                  transition: ".3s",
                }}
              >
                <BiExpandVertical />
              </IconButton>
            </Stack>
          </Box>
          <Collapse in={open}>
            <Box sx={{ background: theme.palette.background.paper }}>
              <TreeviewControls
                searchVehicle={searchVehicle}
                searchKey={searchKey}
                autoRef={autoRef}
                autoRefresh={autoRefresh}
                geoZone={geoZone}
                handleGeoZone={handleGeoZone}
                vehicleTrails={vehicleTrails}
                activeFeature={activeFeature}
                handleFeatureChange={handleFeatureChange}
                showTrails={showTrails}
                handleTrails={handleTrails}
              />
              {/* <AccordianComponent/> */}
              {/* <TreeviewList regionData={regionData} userData={userData}/> */}
              <TreeViewNewList
                userData={userData}
                fullHeight={fullHeight}
                isSearch={searchKey}
              />
            </Box>
          </Collapse>
        </TreeViewInnerBox>
      </TreeViewBox>
    </Draggable>
  );
}

export default Treeview;
