import { Accordion, AccordionSummary, ListItem, ListItemButton, Paper, alpha} from "@mui/material";
import { Box, styled } from "@mui/material";

export const TreeViewBox = styled(Box)(({theme}) => ({
    position:'absolute',
    background:'transparent',
    boxShadow:'0px 10px 10px rgba(0,0,0,.25)',
    zIndex:99,
    '& .draggHandler':{
        width:'100%',
        cursor: 'move',
        height:'100%',
        textAlign:'center',
        '& .MuiTypography-root':{
            color:theme.palette.grey[600],
            opacity:0,
        }
    }
}))


export const TreeViewInnerBox = styled(Box)(({theme}) => ({
    width:'400px',
    position:'absolute',
    background:alpha(theme.palette.primary.main, .7),
    zIndex:99,
    borderRadius:'10px',
    overflow:'hidden',
    [theme.breakpoints.down("xl")]: {
        transform: "scale(.8)",
        filter: 'blur(.0px)',
    },
    [theme.breakpoints.down("lg")]: {
        transform: "scale(.7)",
        filter: 'blur(.0px)',
    },
}))




export const TreeViewAccodionBox = styled(Box)(({theme, rolles, isOpen, lineOffset}) => ({
    position:'relative',
    
    '& .MuiCollapse-root':{
        overflow:'hidden',
        position:'relative'
    },
    '& .MuiCollapse-root::before':{
        background:'transparent',
        position:'absolute',
        top:'0px',
        left: `${lineOffset}px`,
        // left: rolles === 'reseller' ? '16px' :
        //       rolles === 'region' ? '34px' : 
        //       rolles === 'dealer' ? '54px' :
        //       rolles === 'customer' ? '74px' : '96px',
        height: isOpen && rolles === 'reseller' ? '100%' : isOpen && rolles !== 'reseller' ? '100%' :  '0%',
        width:'1px',
        content:'""',
        borderRight:`0.1px dashed ${theme.palette.grey[50]}`,
        zIndex:1,
        transition:'.3s',
    },
    '& .MuiListItemIcon-root':{
        '&::after':{
            opacity: isOpen === true ? 1 : 0
        }
    }
}))

//&.verticalLine .MuiCollapse-root::before


export const TreeViewListItemBtn = styled(ListItem)(({theme, primary, isOpen, buttonIndex}) => {
    return ({
        padding: '6px 10px',
        borderBottom: `0.2px solid ${theme.palette.divider}`,
        cursor: 'pointer',
        paddingLeft: `${buttonIndex}px`,
        transition: '.3s ease-in-out',
        '& .MuiListItemIcon-root': {
            minWidth: '0px',
            marginRight: '10px',
            fontSize: '18px',
            color: primary === 'vehicle' ? '' : '#fff',
            position: 'relative',
            '&::before': {
                position: 'absolute',
                left: '-10px',
                top: '50%',
                content: '""',
                background: theme.palette.background.paper,
                width: primary === 'reseller' ? '0px' : '10px',
                height: '1px',
            },
            '&::after': {
                position: 'absolute',
                left: '50%',
                top: '50%',
                content: '""',
                background: theme.palette.background.paper,
                width: '1px',
                height: primary === 'reseller' ? '0px' : '42px',
            },
        },
        '& .MuiIconButton-root': {
            color: primary === 'vehicle' ? 'auto' : '#fff',
        },
        background: primary === 'reseller' ? alpha(theme.treeView.primary700, 1) :
            primary === 'region' ? alpha(theme.treeView.primary600, 1) :
                primary === 'dealer' ? alpha(theme.treeView.primary500, 1) :
                    primary === 'customer' ? alpha(theme.treeView.primary400, 1) :
                        primary === 'fleet' ? alpha(theme.treeView.primary300, 1) :
                            primary === 'vehicle' ? alpha(theme.palette.divider, .01) : alpha(theme.treeView.primary200, 1),
        color: '#fff',
        '&.Reseller': {
            background: alpha(theme.treeView.primary700, .8),
            color: '#fff',
        },
        '&:hover': {
            background: primary === 'reseller' ? alpha(theme.treeView.primary700, .8) :
                primary === 'region' ? alpha(theme.treeView.primary600, .8) :
                    primary === 'dealer' ? alpha(theme.treeView.primary500, .8) :
                        primary === 'customer' ? alpha(theme.treeView.primary400, .8) :
                            primary === 'fleet' ? alpha(theme.treeView.primary300, .8) :
                                primary === 'vehicle' ? alpha(theme.palette.divider, .08) : alpha(theme.treeView.primary200, 1),
        },
        '& .MuiTypography-root': {
            fontWeight: 400,
            color: primary === 'vehicle' ? theme.palette.text.primary : '#fff',
        },
        '& .MuiRadio-root':{
            padding:'0px'
        },
        '& .label':{
            margin:'0px'
        }
    });
})


export const TreeviewVehicleListBtn = styled(ListItem)(({theme}) => ({
    '& .MuiListItemButton-root':{
        paddingLeft:'116px',
    }
}))


//Infowindow
export const StyledInfoWindow = styled(Box)(({theme}) => ({
    background:alpha(theme.palette.background.paper, .7),
    height:'auto',
    width:"350px",
    border:`.5px solid ${theme.palette.background.paper}`,
    borderRadius:'10px',
    overflow:'hidden',
    backdropFilter:'blur(5px)',
    position:'relative',
    '& .infowindowHHeader':{
        padding:'5px 15px',
        borderBottom:`.5px solid ${theme.palette.divider}`,
        '& .MuiTypography-root':{
            color:theme.palette.common.white
        }
    },
    [theme.breakpoints.down("xl")]: {
        width:"280px",
        //transformOrigin: "bottom",
    },
    '& .MuiFab-root':{
        //boxShadow:'none',
        border:`1px solid ${theme.palette.background.paper}`, 
        fontSize:'.8rem',
        color:'#fff',
        position:'absolute',
        top:'35px',
        right:'20px',
        padding:'10px',
        textTransform:'capitalize',
        pointerEvents:'none',
        '& .MuiIcon-root':{
            fontSize:'1.2rem',
            //marginLeft:'10px',
            width:'auto',
            height:'auto',
            display:'flex',
            alignItems:'center'
        }
    },
    '& .MuiChip-root':{
        color:'#fff',
    },
}));

//Map DatePiker
export const StyledMapDatePiker = styled(Paper)(({theme}) => ({
    background:`${alpha(theme.palette.background.paper, 1)} !important`,
    padding:'0px',
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    border:`.5px solid ${theme.palette.divider}`,
    position:'relative',
    borderRadius:'10px !important',
    overflow:'hidden',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
    '& .MuiInputBase-input':{
        padding:'5px 10px',
        fontWeight:400,
        cursor:'pointer',
    },
    '& .CustomDateRangePicker':{
        position:'absolute',
        top:'0px',
        left:'0px',
        width:'100%',
        opacity:0,
        pointerEvents:'none',
    }
}))

export const NoVehicleBox = styled(Box)(({theme}) => ({
    width:'100%', 
    padding:'20px 10px',
    display:'flex', 
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'

}))