import { Box, Tabs} from '@mui/material'
import React, { useEffect } from 'react'
import { StyledPageLayout, StyledTab, StyledTabBox } from '../../theme/StyledEle'
import { Breadcrumb } from '../shared/Breadcrumbs'
import ListFota from './ListFota'
import FotaSupport from './FotaSupport'
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux'
import { snackService } from '../../store/slice/ControlsSlice'
import StyledSnackBar from '../../Utils/StyledSnackBar'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



function CreateFotaForm() {

const [value, setValue] = React.useState(1);

const handleChange = async (event, newValue) => {
    //var request = {};
    setValue(newValue);
    // if (newValue === 2) {
     
    //   request.message = "Dashboard > Vehicle Summary";
    // } else if (newValue === 3) {
    
    //   request.message = "Dashboard > Battery Detaills";
    // } else if (newValue === 4) {
     
    //   request.message = "Dashboard > Motor Detaills";
    // } else {
      
    //   request.message = "Dashboard > Last Connect";
    // }

    // request.action = "View";
    // request.featureName = "Dashboard";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName = globalUserName;

    //const responseData = await logServices.saveAuditLogDetails(request);
  };
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 2000);
    }
  }, [isSuccess.isSuccess]);
  return (
    <StyledPageLayout>
    <Breadcrumb mainDiv="Admin" subDiv="FOTA"></Breadcrumb>
    <Box>
            {/* Tabs */}
            <StyledTabBox>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <StyledTab value={1} label="Upload FOTA" />
                <StyledTab value={2} label="FOTA SUPPORT" />
            
              </Tabs>
            </StyledTabBox>
              {/* Tabs Panel */}
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ height: "100vh" }}>
              <ListFota/>
            </Box>
          </CustomTabPanel>
            {/* Tabs Panel */}
            <CustomTabPanel value={value} index={2}>
            <Box sx={{ height: "100vh" }}>
             <FotaSupport/>
            </Box>
          </CustomTabPanel>
          </Box>
          {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
}

export default CreateFotaForm;
