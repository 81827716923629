import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { useAuditLogGridViewMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";

const AuditLog = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let RoleId = userData.roleId

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);


  const [auditLog] = useAuditLogMutation();


  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const text = "AuditLog Captures all the activities of the User.";

  const columns = useMemo(
    () => [
      {
        Header: "Activity Date",
        accessor: "activityDate",
      },
      {
        Header: "User OrgName",
        accessor: "orgName",
      },
      {
        Header: "User",
        accessor: "userName",
      },
      {
        Header: "Actions",
        accessor: "action",
      },
      // {
      //   Header: "Action On Customer",
      //   accessor: "customerName",
      // },
      // {
      //   Header: "Action On Fleet",
      //   accessor: "fleetName",
      // },
      {
        Header: "Action Details",
        accessor: "message",
      },
      {
        Header: "Access",
        accessor: "access",
      },
      {
        Header: "Result",
        accessor: "result",
      },
    ],
    []
  );

  useEffect(async () => {
    auditPayload.message = "Logs > Audit Log";
    auditPayload.action = "View";
    auditPayload.featureName = "Audit Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload)
    
  }, []);


  const [ auditLogGridView, {data:auditData, isLoading:isAuditLoading, isSuccess:isAuditSuccess}] = useAuditLogGridViewMutation();
  const [payload, setPayload] = useState(null);
  let commonPayload = {
    'userRoleId':RoleId,
    "sortId": "",
    "sortBy": "desc",
    "logo": "",

  }

  const getData = (data) =>{

    if(data.selectedOrgType === "2"){
      commonPayload.orgId = -1;
      commonPayload.customerId = -1
      commonPayload.dealerId = -1
      commonPayload.regionId = -1
      auditPayload.message = "Logs > Audit Log  > "+data.orgTypeName+" > "+data.mainMenuName+" > "+data.subMenuName+" > "+data.accessName+" > "+data.selectedUserName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }else if(data.selectedOrgType === "5"){
      commonPayload.orgId = -1;
      commonPayload.customerId = -1;
      commonPayload.dealerId = data.dealerId;
      commonPayload.regionId = data.regionId;
      auditPayload.message = "Logs > Audit Log  > "+data.orgTypeName+" > "+data.regionName+" > "+data.dealerName+" > "+data.mainMenuName+" > "+data.subMenuName+" > "+data.accessName+" > "+data.selectedUserName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }else if(data.selectedOrgType === "3"){
      commonPayload.orgId = -1;
      commonPayload.customerId = data.customerId;
      commonPayload.dealerId = data.dealerId;
      commonPayload.regionId = data.regionId;
      auditPayload.message = "Logs > Audit Log  > "+data.orgTypeName+" > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.mainMenuName+" > "+data.subMenuName+" > "+data.accessName+" > "+data.selectedUserName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }else if(data.selectedOrgType === "1"){
      commonPayload.orgId = data.fleetId;
      commonPayload.customerId = data.customerId;
      commonPayload.dealerId = data.dealerId;
      commonPayload.regionId = data.regionId;
      auditPayload.message = "Logs > Audit Log  > "+data.orgTypeName+" > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.mainMenuName+" > "+data.subMenuName+" > "+data.accessName+" > "+data.selectedUserName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }else if(data.selectedOrgType === "4"){
      auditPayload.message = "Logs > Audit Log  > "+data.orgTypeName+" > "+data.mainMenuName+" > "+data.subMenuName+" > "+data.accessName+" > "+data.selectedUserName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }
    commonPayload.reselId = orgId;
    commonPayload.featureName = data.mainMenuId;
    commonPayload.subFeatureName = data.subMenuId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.userId = data.useListId;
    commonPayload.access = data.accessId;
    commonPayload.userName = data.selectedUserName;
    commonPayload.userRoleId = "-1";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.searchText = "";
    setPayload(commonPayload);
    auditLogGridView(commonPayload);

    auditPayload.action = "Submit";
    auditPayload.featureName = "Audit Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      auditLogGridView(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      auditLogGridView(payload);
    }
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if(payload.searchText.length >= 3) {
      auditLogGridView(payload);
    } else if (payload.searchText.length === 0) {
      auditLogGridView(payload);
    }
  };


  const onChangeXcl = async () => {

    try {
      var url =
      process.env.REACT_APP_API_BASE_URL +
      "AuditLog/getAuditLogCsvNew.csv?" +
    
      "orgId=" +
      payload.orgId +
      "&customerId=" +
      payload.customerId +
      "&dealerId=" +
      payload.dealerId +
      "&regionId=" +
      payload.regionId +
      "&reselId=" +
      payload.reselId +
      "&featureName=" +
      payload.featureName +
      "&subFeatureName=" +
      payload.subFeatureName +
      "&startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&access=" +
      payload.access +
      "&userName=" +
      payload.userName +
      "&userRoleId=" +
      payload.userRoleId +
      "&sortBy=" +
      payload.sortBy +
      "&searchText=" +
      payload.searchText +
      " ";
      window.open(url);
     

      auditPayload.message = "Logs > Audit Log > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Audit Log";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };


  useEffect(() => {
    if(isAuditSuccess){
      setResponseData([]);
      if (auditData.data !== null) {
        setResponseData(auditData.data.content);
        setTotalElements(auditData.data.totalElements);
        setTotalPages(auditData.data.totalPages);
      }
    }
  },[isAuditSuccess])

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="Audit Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Audit Log"} />
      <Box>
        <AlertDropdown  getData={getData} isShowAll={false} allowedDays={30}
        allowedMonth={12}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader title={<StyledTableTitle></StyledTableTitle>} />

          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={isAuditLoading}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default AuditLog;

