import * as React from "react";
import { Typography, Box } from "@mui/material";

const FileUpload =()=>{

    return(
        <Box>
        <Typography sx={{m:5, color:'#168FD0', fontSize:'25px'}}  >File Upload</Typography>
        </Box>
    )
}
export default FileUpload


