import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
//import CommonDropDown from "../common/commonDropdown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import exporticon from "../../assets/images/exportexcel.png";
import pdficon from "../../assets/images/pdficon.png";
//import CommonDropDown from "./StagingDropDown";
//import CommonDropDown from "./RawFeedDropDown";
import { format } from "date-fns";
import VDRDropDown from "../Reports/VDRDropDown";
import CanFeedDropDown from "./CanFeedDropdown";

import logServices from "../../services/logService";

import LogServices from "../../services/logService";
import {
  StyledCardShadow,
  StyledTableBox,
  StyledTableTitle,
  Exportoptions,
} from "../../theme/StyledEle";
import { CardContent, CardHeader } from "@mui/material";
import { useFeedLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const text =
  "Incoming feeds can rejected for any one or more of the following conditions." +
  " a) Invalid IMEI format" +
  " b) Stored feeds beyond 7 days" +
  " c) Invalid latitude, longitude values (such as zero or four digit values)";

// const globalJson1 =
//   localStorage.getItem("userData") !== null
//     ? JSON.parse(localStorage.getItem("userData"))
//     : null;
// var globalOrgId = 1;
// var globaluserId = 1;
// var globalRoleId = 0;
// var globalJson = {};
// var globalResellerId = 2;
// var globalOrgType = 2;

// if (
//   "null" === localStorage.getItem("userData") ||
//   null === localStorage.getItem("userData")
// ) {
// } else {
//   globalJson =
//     localStorage.getItem("userData") !== null
//       ? JSON.parse(localStorage.getItem("userData"))
//       : 0;
//   globalOrgId = globalJson.orgId;
//   globalOrgType = globalJson.userOrgType;
//   globaluserId = globalJson.id;
//   globalRoleId = globalJson.roleId;
//   globalResellerId = globalJson.resellerId;
// }
// // "Note: RAW feeds  have VALID Header. This is for security reasons. Feeds with Invalid Header will be moved to RAW REJECTED. All other feeds will be stored and displayed here."
// // "From RAW, feeds areMUST validated and then moved to STAGING if validation is successful.";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 180,
//     },
//   },
// };
// const status = ["All", "Moving", "Idle", "Stop", "Charging"];

const RejectedStagingFeed = ({ data, payload, isLoadingOut }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {
    auditPayload.message = "Logs > FeedLog > Rejected Staging Feed";
    auditPayload.action = "View";
    auditPayload.featureName = "FeedLog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Device Captured Date",
        accessor: "gpsDate",
        width:150,
      },
      {
        Header: "GPS Feed",
        accessor: "gpsFeed",
        width:800,
      },
      {
        Header: "Portal Received Date",
        accessor: "activityDate",
      },
      {
        Header: "Description",
        accessor: "message",
        width:120,
      },
    ],
    []
  );

  const [
    getRejectedStagingFeedList,
    { data: rejectedStagingFeedListdata, isLoading, isSuccess },
  ] = useFeedLogListMutation();

  useEffect(() => {
    if (data !== undefined) {
      setResponseData([]);
      if (data.data !== null) {
        setResponseData(data.data.content);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
      } else {
        setResponseData([]);
        setTotalElements(0);
        setTotalPages(0);
      }
    }
  }, [data]);

  const onChangeXcl = async () => {
    let xlsfeed = "rejected";
    try {
      payload.feedStage = xlsfeed;
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FeedLog/FeedlogXLS.csv?vehicleId=" +
        payload.vehicleId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&imei=" +
        payload.imei +
        "&resellerId=" +
        payload.resellerId +
        "&xlsfeed=" +
        xlsfeed +
        "&url=" +
        payload.url;

      window.open(url);

      auditPayload.message = "Logs > FeedLog > Rejected Staging Feed > Export >Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "FeedLog";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getRejectedStagingFeedList(payload);
    } else if (payload.search.length === 0) {
      getRejectedStagingFeedList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getRejectedStagingFeedList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getRejectedStagingFeedList(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (rejectedStagingFeedListdata.data !== null) {
        setResponseData(rejectedStagingFeedListdata.data.content);

        setTotalElements(rejectedStagingFeedListdata.data.totalElements);
        setTotalPages(rejectedStagingFeedListdata.data.totalPages);
      }
    }
  }, [isSuccess]);

  //   const [responseData, setResponseData] = useState([]);
  //   const [statusCon, setStatus] = useState(status[0]);
  //   const [open, setOpen] = useState(false);
  //   const [loadStatus, setLoadStatus] = useState(true);
  //   const [loading, setLoading] = useState("");
  //   const theme = useTheme();

  //   var loadrequest = [];

  //   const globalJson1 =
  //   localStorage.getItem("userData") !== null
  //     ? JSON.parse(localStorage.getItem("userData"))
  //     : null;
  // var globalOrgId = 1;
  // var globaluserId = 1;
  // var globalRoleId = 0;
  // var globalJson = {};
  // var globalResellerId = 2;
  // var globalOrgType = 2;
  // var globalCustomerId = 0;
  // var globalUserName = "";
  // var globalOrgName = "";
  // if (
  //   "null" === localStorage.getItem("userData") ||
  //   null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   globalJson =
  //     localStorage.getItem("userData") !== null
  //       ? JSON.parse(localStorage.getItem("userData"))
  //       : 0;
  //   globalOrgId = globalJson.orgId;
  //   globalOrgType = globalJson.userOrgType;
  //   globaluserId = globalJson.id;
  //   globalRoleId = globalJson.roleId;
  //   globalResellerId = globalJson.resellerId;
  //   globalCustomerId = globalJson.customerId;
  //   globalOrgName = globalJson.orgName;
  //   globalUserName = globalJson.userName;

  // }

  // useEffect(async () => {

  //   var request = {};
  //   request.message = "Logs > FeedLog > Rejected Staging Feed";
  //   request.action = "View";
  //   request.featureName = "FeedLog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName= globalUserName;

  //   const responseData = await logServices.saveAuditLogDetails(request);

  // }, []);
  // const callbackLoadFn = async (
  //   selResellerId,
  //   selRegionId,
  //   selDealerId,
  //   selCustomerId,
  //   selFleetId,
  //   selVehicleId,
  //   selimei
  // ) => {
  //   // console.log(selectedOrgType,selectedResellerId,selectedCustomerId,selectedFleetId,selectedLastOrgId,selectedVehicleId,selectedImei);
  //   loadrequest = assignedval;
  //   loadrequest.vehicleId = selVehicleId;
  //   const date = format(new Date(), "yyyy-MM-dd") + " 00:00:00";
  //   const date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  //   loadrequest.startDate = date;
  //   loadrequest.endDate = date1;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.search = "";
  //   loadrequest.page = newPage;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.imei = selimei;
  //   loadrequest.orgId = selFleetId;
  //   loadrequest.url = 3;
  //   setAssignedval(loadrequest);
  //   if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //     //setResponseData(responseData.data.content);
  //   }else{
  //   setLoading("Success");
  //   setTotalElements(0);
  //   setTotalPages(0)
  //   }
  // };

  // const handleChange = (event) => {
  //   setStatus(event.target.value);
  // };

  // const labelStyle = {
  //   fontSize: theme.text.fontSize,
  //   color: "black",
  //   fontWeight: 600,
  // };
  // const menuStyles = {
  //   fontSize: theme.text.fontSize,
  //   lineHeight: "1",
  // };
  // const selectDropdown = {
  //   ".MuiOutlinedInput-input": {
  //     fontSize: theme.text.fontSize,
  //     padding: "1.5px 4px",
  //   },
  // };

  // const [newPage, setNewPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [totalElements, setTotalElements] = useState(5);
  // const [totalPages, setTotalPages] = useState(1);
  // const [selectedDropdownAry, setSelectedDropdownAry] = useState([]);

  // const [assignedval, setAssignedval] = useState({});
  // const [searchval, setSearchval] = useState("");

  // const onSubmit = async (data) => {
  //   console.log(data);
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   // loadrequest.regionId= data.region;
  //   loadrequest.resellerId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   // loadrequest.dealerId= data.dealer;
  //   // loadrequest.customerId = data.customer;
  //   // loadrequest.dealerId= data.dealer;
  //   loadrequest.imei = data.imei;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.sortBy = "desc";
  //   loadrequest.search = searchval;
  //   console.log("check" + loadrequest);
  //   setAssignedval(loadrequest);
  //   if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //     //setResponseData(responseData.data.content);
  //   }else{
  //   setLoading("Success");
  //   setTotalElements(0);
  //   setTotalPages(0)
  //   }
  // };

  // const fetchRawFeedDetails = async (searchText, columnName) => {
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;

  //   if (columnName === undefined) {
  //     loadrequest.columnName = "MESSAGE";
  //   } else {
  //     loadrequest.columnName = columnName;
  //   }
  //   const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const handleSearch = (searchText) => {
  //   fetchRawFeedDetails(searchText);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);

  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (await LogServices.FeedLog(loadrequest)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };
  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;
  //   loadrequest.feedStage = "rejected";

  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "FeedLog/FeedlogXLS.xls?vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&imei=" +
  //       loadrequest.imei +
  //       "&feedStage=" +
  //       loadrequest.feedStage +
  //       "&url=" +
  //       loadrequest.url;

  //     window.open(url);
  //   } catch (error) {}
  // };

  // /*
  // const onChangePdf = async () => {
  //   var request = {};
  //   request.startDate = "2023-02-01 19:32:59";
  //   request.endDate = "2023-02-24 19:32:59";
  //   request.result = "string";
  //   request.search = "";
  //   request.orderDir = "desc";
  //   request.orgId = 1;
  //   request.vehicleId = 26202;
  //   request.reselId = 0;
  //   request.custId = 0;
  //   request.dealerId = 0;
  //   request.regionId = 0;
  //   request.start = 0;
  //   request.length = 10;
  //   request.size = 10;
  //   request.page = 0;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
  //       request.startDate +
  //       "&endDate=" +
  //       request.endDate +
  //       "&result=" +
  //       request.result +
  //       "&search=" +
  //       request.search +
  //       "&orderDir=" +
  //       request.orderDir +
  //       "&orgId=" +
  //       request.orgId +
  //       " &vehicleId=" +
  //       request.vehicleId +
  //       "&reselId=" +
  //       request.reselId +
  //       "&custId=" +
  //       request.custId +
  //       "&dealerId=" +
  //       request.dealerId +
  //       "&regionId=" +
  //       request.regionId +
  //       "&start=" +
  //       request.start +
  //       "&length=" +
  //       request.length +
  //       "&size=" +
  //       request.size +
  //       "&page=" +
  //       request.page;

  //     //   var url=process.env.REACT_APP_API_BASE_URL+"TravelDetailReport/travelDetailPDF.pdf?orgId="+request.orgId+"&resellerId="+request.resellerId+"&vehicleId="+request.vehicleId+"&intStatus="+request.intStatus+"&size="+request.size+"&page="+request.page+" &startDate="+request.startDate+"&endDate="+request.endDate+"&sortBy="+request.sortBy+"&chargeStatus="+request.chargeStatus;

  //     //   window.open(url);
  //   } catch (error) {}
  // };
  // */

  return (
    <>
      <MouseOverPopover text={text} title={"Rejected Staging feeds"} />

      <Box>
        {/* <CanFeedDropDown

          onSubmit={onSubmit}
          isshowimei={true}
          callbackLoadFn={callbackLoadFn}
        /> */}
        <StyledCardShadow>
          <CardHeader
            title={<StyledTableTitle>Rejected Staging feed</StyledTableTitle>}
          />
          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={isLoading || isLoadingOut}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
              // handleSearch={handleSearch}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </>
  );
};

export default RejectedStagingFeed;
