import React, { useEffect, useState } from "react";
import { Collapse, Grid, Stack, alpha, useTheme } from "@mui/material";
import { Typography, Box, CardMedia } from "@mui/material";
import { SlideData } from "./SlideData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Forgotpassword, Loginform } from "./Form";
import { StyledPaperShadow } from "../../theme/StyledEle";
import LoginBG from "../../assets/images/Lgoin-bg.jpg";
import logoPlaceHolder from "../../assets/images/fav.png";
import Favicon from "react-favicon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slice/ControlsSlice";
import bg from '../../assets/images/login_bg.avif';
import underConstruction from '../../assets/images/server_maintanence.svg';
import powerBlockNesh from '../../assets/images/NeshPoeweLogo_blck.svg'

import moment from "moment";
export default function SignIn() {
  const [showForgot, setShowForgot] = useState(false);
  const theme = useTheme();
  const HandleForgotView = () => {
    setShowForgot(!showForgot);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authData = useSelector((state) => state.controls.authToken);
  const isLogin = JSON.parse(localStorage.getItem("userData"));
  const menuList = JSON.parse(localStorage?.getItem("menuList"));
  useEffect(() => {
    if (isLogin && authData !== null) {
      navigate(menuList[0]?.sub[0]?.route !== undefined ? `/${menuList[0]?.sub[0]?.route}` : `/${menuList[1]?.sub[1]?.route}`);
    } else {
      //dispatch(logout());
      window.localStorage?.removeItem("menuList");
      window.localStorage?.removeItem("userData");
    }
  }, [isLogin, authData]);

  const [mesg, setMeg] = useState(false);
  const [page, setPage] = useState(false);
  let location = window.location.hostname;
  
  useEffect(() => {
      if(location === 'montra.nesh.live' || location === 'ipltech.nesh.live'){
        window.location.replace('https://ev.montraelectric.com');
        setMeg(false);
        setPage(false);
      }else{
        setMeg(false);
        setPage(false);
               
      }
  },[])


  const UnderConstruction = () => {
    return(
      <Box maxWidth={'sm'}>
          <Stack direction={'row'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <CardMedia
                  src={underConstruction}
                  component={'img'}
                  sx={{width:{lg:'50%', md:'65%', sm:''}}}
              />
              <Typography variant="h4" sx={{mt:2, fontWeight:'700', color:'#703456'}}>Server Under Planned Maintenance</Typography>
              <Typography variant="h6" sx={{mt:1, textAlign:'center'}}>IMPORTANT: Please take note of planned server maintenance on 28-April-2024 (Sunday) from 12:00 pm to 4:00 pm. During this time, the portal will not be available for use.</Typography>
          </Stack>
      </Box>
    )
  }




  return (
    <Box
      sx={{
        background:page ? `#FFF6FB` : `transparent`,
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        backgroundSize:'cover',
        backgroundPosition:'center, center',
        // animation:'20s linear 1s infinite alternate bgMoving;',
        // backgroundBlendMode:'lighten',
        // '@keyframes bgMoving':{
        //   'from':{backgroundPositionX:'0%'},
        //   'to':{backgroundPositionX:'60%'}
        // },
        "&::before": {
          position: "absolute",
          top: "0",
          left: "0",
          content: '""',
          width: "100%",
          height: "100%",
          //background:`url(${LoginBG})`,
          //background: `radial-gradient(${alpha(theme.palette.background.paper, .5)}, ${theme.palette.background.paper})`,
          opacity: "1",
          backdropFilter:`blur(${page ? `0px` : `5px`})`,
          display:page ? `none` : `block`,
        },
      }}
    >
      <Box sx={{height:'auto', position:'fixed', top:'0px', left:'50%', width:'100%', background:'#ffe6f4', borderRadius:'0px', transform:'translateX(-50%)', animation:'2s linear 1s infinite alternate bgMoving;',
        backgroundBlendMode:'lighten',
        '@keyframes bgMoving':{
          'from':{background:'#ffe6f4'},
          'to':{background:theme.palette.warning.light}
        },}}>
              <Collapse in={mesg && !page}>
                <Stack sx={{p:1}}>
                    <Typography sx={{color:'#703456', textAlign:'center', fontWeight:'600'}} variant="body2">IMPORTANT: Please take note of planned server maintenance on 28-April-2024 (Sunday) from 12:00 pm to 4:00 pm. During this time, the portal will not be available for use.
                    </Typography>
                </Stack>
              </Collapse>
          </Box>
      <Favicon url={logoPlaceHolder} />
      {
        showForgot ? 
        <StyledPaperShadow
          elevation={2}
          sx={{
            width: { lg: "480px", md: "450px", sm: "450px" },
            display: "block",
            maxHeight: { lg: "auto", md: "100%", sm: "100%" },
            p: 3,
            boxShadow: "none",
            height: { lg: "fit-content", md: "fit-content" },
            zIndex: 999,
            position: "relative",
            overflow: { lg: "hidden", md: "auto", sm: "auto" },
          }}
        >
            <Forgotpassword showForgot={HandleForgotView} />
        </StyledPaperShadow>
      
      : !page ?
      <StyledPaperShadow
        elevation={1}
        sx={{
          width: { lg: "380px", md: "350px", sm: "350px" },
          display: "block",
          maxHeight: { lg: "auto", md: "100%", sm: "100%" },
          p: 3,
          boxShadow: "none",
          height: { lg: "fit-content", md: "fit-content" },
          zIndex: 999,
          position: "relative",
          overflow: { lg: "hidden", md: "auto", sm: "auto" },
        }}
      >
        <Box>
            <Loginform showForgot={HandleForgotView} />
        </Box>
      </StyledPaperShadow> : <UnderConstruction />
      }
      {
        !page && 
        <>
          <Typography variant="caption" sx={{ mt: 3, zIndex:99, display:'flex', alignItems:'center'}}>
        Copyrights @ {moment().format('YYYY')}, All rights reserved nesh.live
      </Typography>
      <Typography variant="caption" sx={{ mt: 0.5, zIndex:99 }}>
        {" "}
        {process.env.REACT_APP_VERSION}
      </Typography>
        </>
      }
      
      {/* <Backdrop open={true} sx={{zIndex:1, backdropFilter:'blur(10px)', background:'transparent'}}/> */}
    </Box>
  );
}