import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Menu, Toolbar, Typography } from "@mui/material";
import { Fade, ListItemButton, ListItemIcon, Box } from "@mui/material";
import menuListService from "../../../services/menuListService";
import LogoutIcon from "@mui/icons-material/Logout";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TableViewIcon from "@mui/icons-material/TableView";
import GridOnIcon from "@mui/icons-material/GridOn";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Collapse from "@mui/material/Collapse";
import { ClickAwayListener } from "@mui/base";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  StyledSidebarListBtn,
  StyledSidebarSubLinkBox,
} from "../../../theme/StyledEle";
import { useSelector } from "react-redux";
const icons = {
  LocationOnIcon,
  LogoutIcon,
  DashboardIcon,
  TableViewIcon,
  GridOnIcon,
  SettingsIcon,
  NotificationsNoneIcon,
};

const Sidebar = ({ open }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const menuTitleTextStyles = {
    opacity: open ? 1 : 0,
    color: "#fff",
    ml: 1,
  };

  const [menuList, setMenuList] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    var globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : null;
    var request1 = {},
      region2 = {};
    request1.id = 0;
    request1.orgId = globalJson !== null ? globalJson.orgId : 1;
    request1.orgType = globalJson !== null ? globalJson.userOrgType : 1;
    request1.roleId = globalJson !== null ? globalJson.roleId : 1;
    request1.page = 0;
    request1.size = 100;
    request1.value = "region";
    request1.searchText = "";
    request1.vehModel = "1";
    region2.region = 1;

    menuListService
      .getMenuList(request1, region2)
      .then((res) => {
        //setMenuList(MENUS);
        window.localStorage.removeItem("menuList");
        window.localStorage.setItem("menuList", JSON.stringify(res.data.menus));
        setMenuList(res.data.menus);
      })
      .then(setisLoading(false));
  }, []);

  const themeCustom = useSelector((state) => state.controls.customizer.themeCustom);
  const sidebarBg = useSelector((state) => state.controls.customizer.sidebar.primary);
  const sidebarBgImg = useSelector((state) => state.controls.customizer.sidebar.backgroundImg);
  const sidebarText = useSelector((state) => state.controls.customizer.sidebar.secondary);
  
  const openedMixin = () => ({
    width: theme.sidebar.width,
    //background: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.background.paper,
    background:
      themeCustom === false
        ? theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.background.paper
        : sidebarBg === null
        ? theme.palette.primary.dark
        : sidebarBg,
    //background: sidebarBg,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    position: "relative",

    "&:before": {
      position: "absolute",
      left: "0",
      right: "0",
      content: '""',
      width: "100%",
      height: "100%",
      backgroundImage: `url(${sidebarBgImg}) !important`,
      opacity: 0.2,
      backgroundPosition: "bottom",
    },
  });

  const closedMixin = () => ({
    // background: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.background.paper,
    background:
      themeCustom === false
        ? theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.background.paper
        : sidebarBg === null
        ? theme.palette.primary.dark
        : sidebarBg,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(6)} + 12px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7.5)} + 12px)`,
    },
    [theme.breakpoints.down("sm")]: {
      width: `0px`,
    },
  });

  const drawerStyles = {
    width: theme.sidebar.width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
  };

  const HoverMenu = ({ menu }) => {
    const [isActive, setIsActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [collapseEl, setCollapseEl] = useState(null);
    const [openSub, setOpenSub] = useState(false);

    const handleHover = (event) => {
      setIsActive(true);
      setOpenSub(!openSub);
      setAnchorEl(event.currentTarget);
      setCollapseEl(event.currentTarget.innerText);
    };

    const handleClickAway = () => {
      setOpenSub(false);
    };

    const currentURL = window.location.href;
    var pathName = currentURL.split("/")[4];
    var test = null;
    let array = menu.sub;
    let mapped = array.map((ele) => ele.route);
    let found = mapped.includes(pathName);
    test = found;

    var ex = <LocationOnIcon />;
    if (menu.icon === "LocationOnIcon") {
      ex = <LocationOnIcon />;
    } else if (menu.icon === "LogoutIcon") {
      ex = <LogoutIcon />;
    } else if (menu.icon === "DashboardIcon") {
      ex = <DashboardIcon />;
    } else if (menu.icon === "TableViewIcon") {
      ex = <TableViewIcon />;
    } else if (menu.icon === "GridOnIcon") {
      ex = <GridOnIcon />;
    } else if (menu.icon === "SettingsIcon") {
      ex = <SettingsIcon />;
    } else if (menu.icon === "NotificationsNoneIcon") {
      ex = <NotificationsNoneIcon />;
    } else if (menu.icon === "HelpCenterIcon") {
      ex = <HelpCenterIcon />;
    } else if (menu.icon === "NotificationsIcon") {
      ex = <NotificationsIcon />;
    }

    console.log('Sidebar Re-render');
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ px: 1 }}>
          <StyledSidebarListBtn
            selected={test === true}
            onClick={handleHover}
            sx={{ color: themeCustom === false ? "#fff" : sidebarText }}
          >
            <ListItemIcon
              // sx={{
              //   minWidth: 0,
              //   justifyContent: "center",
              //   color: isActive
              //     ? theme.sidebar.menuColor
              //     : theme.sidebar.menuColor,
              // }}
              sx={{
                color:
                  themeCustom === false
                    ? "#fff"
                    : sidebarBg === null
                    ? "#fff"
                    : sidebarText,
              }}
            >
              {ex}
            </ListItemIcon>
            <Typography>{menu.title}</Typography>

            {menu.title !== "Dashboard" ? (
              openSub ? (
                <ExpandLess sx={{ marginLeft: "auto" }} />
              ) : (
                <ExpandMore sx={{ marginLeft: "auto" }} />
              )
            ) : (
              ""
            )}
          </StyledSidebarListBtn>
          {menu.sub.length > 0 &&
            (!open ? (
              <Menu
                elevation={1}
                anchorEl={anchorEl}
                open={collapseEl === menu.title && openSub}
                // onClose={handleClickAway}
                TransitionComponent={Fade}
                autoFocus={false}
                anchorOrigin={{
                  vertical: "down",
                  horizontal: "right",
                }}
                sx={{
                  zIndex: 999,
                  "& .MuiPaper-root": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {menu.sub.map((subMenu) => (
                  <StyledSidebarSubLinkBox key={subMenu.id}>
                    {/* <KeyboardDoubleArrowRightIcon sx={{ fontSize: "14px" }} /> */}
                    <Link to={subMenu.route}>{subMenu.title}</Link>
                  </StyledSidebarSubLinkBox>
                ))}
              </Menu>
            ) : (
              <Collapse
                in={collapseEl === menu.title && openSub}
                key={menu.title}
              >
                {menu.sub.map((subMenu) => (
                  <StyledSidebarSubLinkBox key={subMenu.id}>
                    {/* <KeyboardDoubleArrowRightIcon sx={{ fontSize: "14px" }} /> */}
                    <Link to={subMenu.route}>{subMenu.title}</Link>
                  </StyledSidebarSubLinkBox>
                ))}
              </Collapse>
            ))}
        </Box>
      </ClickAwayListener>
    );
  };

  return (
    <MuiDrawer variant="permanent" open={open} sx={drawerStyles}>
      <Toolbar />
      <List>
        {menuList.length > 0 &&
          menuList.map((menu) => <HoverMenu key={menu.id} menu={menu} />)}
      </List>
    </MuiDrawer>
  );
};

export default React.memo(Sidebar);
