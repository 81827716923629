import { Box, Divider, Drawer, Paper, Stack, Toolbar, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledIconButton } from '../theme/StyledEle';
import { IoClose } from 'react-icons/io5';
import { theme } from 'antd';

function DrawerModel(props) {
    const { position, width='350px', title, children} = props;
    let location = useLocation();
    let theme = useTheme();
    let navigate = useNavigate();


  return (
    <Drawer 
        anchor={position}
        open={location.hash === '#create'}
        elevation={0}
        PaperProps={{
            sx:{
                background:alpha(theme.palette.background.paper, 0),
                py:1,
                px:1,
                height:'100%',
                overflow:'hidden'
            }
        }}
    >
        <Box sx={{background:theme.palette.background.paper, borderRadius:'15px', height:'auto', display:'flex', flexDirection:'column', flexGrow:1, overflowY:'auto'}}>
        <Box>
        <Divider />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{px:2, py:1}}>
                <Typography variant='body2' sx={{fontWeight:500}}>{title}</Typography>
                <Tooltip title='Close' arrow>
                    <StyledIconButton color='error' size='small' onClick={() => navigate(location.pathname)}>
                        <IoClose />
                    </StyledIconButton>
                </Tooltip>
                
            </Stack>
            <Divider />
        </Box>
        <Box sx={{minWidth:width, maxWidth:width, width:"100%", px:2, py:1}}>
            {children}
        </Box>
        </Box>
    </Drawer>
  )
}

export default DrawerModel