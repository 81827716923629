import { Circle, Marker } from '@react-google-maps/api'
import React, { useState } from 'react'
import zoneMarker from '../../assets/images/geoZoneMarker.png'
import { useTheme } from '@mui/material';

function ZoneMarker(props) {
  const { isCreate, data, listZone, hoverZone, radius, isEdit, zoneType, multipleMarker, MarkerDrag} = props;
  const theme = useTheme();
  const circleOptions = {
    fillColor:theme.palette.secondary.main,
    fillOpacity:.5,
    strokeWeight:1.2,
    strokeColor:theme.palette.secondary.main,
  }
  return (
    (isCreate || isEdit) && data?.lat !== null && zoneType === 'Circles' ?
    <>
    <Marker 
    position={{lat:data?.lat, lng:data?.lng}}
    animation={window.google.maps.Animation.DROP}
    icon={zoneMarker}
    draggable={isEdit ? false : true}
    onDragEnd={(event) => MarkerDrag(event)}
    />
    <Circle
        radius={radius}
        center={{lat:data?.lat, lng:data?.lng}}
        options={circleOptions}
    />

      {/* <InfoWindow 
          position={{lat:data?.lat, lng:data?.lng}}
          onCloseClick={onClose}
          options={{pixelOffset: new window.google.maps.Size(0,-30)}}
          ref={infoWindowRef}
        >

          </InfoWindow> */}
  
    </>
    : 
    listZone !== null && listZone.length > 0 && !isCreate &&
    listZone.map((item) => item.zoneType === 'Circles' && item?.latitude !== 'null' && item?.longitude !== 'null' &&
    <>
    <Marker 
    position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
    animation={hoverZone === item.id ? 1 : 0}
    icon={zoneMarker}
    onClick={() => multipleMarker(item)}
    />
    <Circle
        radius={item.radius}
        center={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
        options={circleOptions}
    />

    </>
  ))
}

export default ZoneMarker