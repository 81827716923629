import Service from "./service";

const alertServices = {

    getUnaasignedUser: (data) =>{
        return Service.post('Alerts/unassignedUserList',data,null)
    },

    getAssignedUser: (data) =>{
        return Service.post('Alerts/getUserListForVehicle',data,null)
    },
    saveUser: (data) =>{
        return Service.post('Alerts/assignUserAlerts',data,null)
    },
    getAlertDetails: (data) =>{
        return Service.post('Alerts/getFleetAlertDetails',data)
    },
    getAlertOrg: (data) =>{
        return Service.post('Alerts/getAlertForOrgNew',data)
    },
    saveAlert: (data)=>{
        return Service.post('Alerts/editAlertSettings',data,null)
    }


    
}

export default alertServices