import { dashboardApi } from "../ApiSlice";

const serviceSlaSlice = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getServiceDetailsList: builder.mutation({ 
      query: (body) => ({
        url: `service-sla/getServiceDetails`,
        method: "POST",
        body: body,
      }),
    }),
    deleteServiceDetails: builder.mutation({ 
        query: (body) => ({
          url: `service-sla/deleteServiceDetailsById`,
          method: "POST",
          body: body,
        }),
      }),
      serviceDetailSave: builder.mutation({ 
        query: (body) => ({
          url: `service-sla/saveServiceDetails`,
          method: "POST",
          body: body,
        }),
      }),
      getServiceHistoryList: builder.mutation({ 
        query: (body) => ({
          url: `service-sla/getServiceHistoryList`,
          method: "POST",
          body: body,
        }),
      }),
  }),
})

export const { useGetServiceDetailsListMutation, useDeleteServiceDetailsMutation, useServiceDetailSaveMutation, useGetServiceHistoryListMutation } = serviceSlaSlice;
