
import { Box, Divider, Stack, Tab, Tabs, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import VehicleDetails from './VehicleDetails';
import { useDispatch, useSelector } from 'react-redux';
import reportServices from '../../../services/reportServices';
import VehicleSummary from './VehicleSummary';
import { StyledFiterButton, StyledIconButton } from '../../../theme/StyledEle';
import { MdOutlineClose } from 'react-icons/md';
import { vehicleSummaryWindow } from '../../../store/slice/ControlsSlice';
import moment from 'moment';

function VehicleMainSumary(props) {
    const { trailsData, categoryOrgId, categoryVehicleId, height = 350, open, showCategory, CloseSummary } = props;
    
    let [address, setAddress] = useState(null);
    const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);
    const [tabValue, setTabValue] = useState(1);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const StyledTabs = styled(Tabs)(({ theme }) => ({
        minHeight:'auto',
        '& .MuiTab-root':{
            padding:'10px 10px',
            minHeight:'auto',
            textTransform:'capitalize'
        }
    }))

    const getAddress = () => {
        setAddress(null);
        var requestN = {};
        requestN.latLng =
          vehicleTrails?.commonData[0]?.lat + "," + vehicleTrails?.commonData[0]?.lng;
        return reportServices
          .getGeocodeAddress(requestN)
          .then((resN) => {
            setAddress(resN.data);
          })
          .catch((err1) => {
            console.log(err1);
          });
      };

      useEffect(() => {
            getAddress()
      },[vehicleTrails, selectedVehicle])

      const dispatch = useDispatch();
      let summaryWindow = useSelector((state) => state.controls.vehicleSummary)
      const closeSumary = () => {
        dispatch(vehicleSummaryWindow(!summaryWindow));
      }

      const date = useSelector((state) => state.controls.treeview.date);
    const [isToday, setIsToday] = useState(true);
    useEffect(() => {
        if (date !== null) {
          if (
            date[0] === moment().format("YYYY-MM-DD 00:00:00") &&
            moment(date[1]).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ) {
            setIsToday(true);
          } else {
            setIsToday(false);
          }
        }
      }, [date]);

  return (
    <Box sx={{width:'380px', height:`${height}px`, overflow:'hidden'}}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <StyledTabs value={tabValue} onChange={handleTabChange}>
              <Tab label={isToday ? 'Last Connected' : 'Vehicle Details'} value={1}/>
              <Tab label='Vehicle Summary' value={2}/>
          </StyledTabs>
          {
            //: CloseSummary
            !open ? <StyledIconButton size='small' onClick={closeSumary}><MdOutlineClose /></StyledIconButton> : <StyledIconButton size='small' onClick={CloseSummary}><MdOutlineClose /></StyledIconButton>
          }
            
        </Stack>
        <Divider />
        <Box sx={{overflowY:'auto', height:'90%'}}>
            {
                tabValue === 1 && <VehicleDetails data={vehicleTrails?.commonData[0]} address={address} allData={vehicleTrails}/>
            }
            {
              tabValue === 2 && <VehicleSummary data={vehicleTrails} address={address} categoryOrgId={categoryOrgId} categoryVehicleId={categoryVehicleId} showCategory={showCategory}/>
            }
        </Box>
    </Box>
  )
}

export default VehicleMainSumary