import {  Avatar, Box,  Grid, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormControl } from '@mui/material'
import { RiUploadCloud2Fill } from 'react-icons/ri'
import { AiOutlineDelete } from 'react-icons/ai'
import { FileUploadBox, SoftButton, StyledFormControlLabel, StyledTextField } from '../../theme/StyledEle'
import { FaAndroid } from "react-icons/fa";
import { useSaveFotaMutation } from '../../store/slice/FotaSlice/FotaSlice'
import { snackService } from '../../store/slice/ControlsSlice'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

function CreateFota(props) {

  const {reload } = props

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let orgType = userData.userOrgType;
    let userName = userData.userName;
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    
    const TYPE = [
        {
          id: 0,
          value: "Select",
          key: 0,
        },
        {
          id: 1,
          value: "Apk",
          key: 1,
        },
        {
          id: 2,
          value: "Configuration",
          key: 2,
        },
      ];

 //Slice Mution
 const [ saveFota , {data:saveFotaData, isLoading:saveFotaLoading, isSuccess:saveFotaSuccess}] = useSaveFotaMutation();
    //Create State
    const [type, setType] = useState("0");
    const [appName, setAppName] = useState(null);
    const [versionName, setVersionName] = useState(null);
    //Image Upload array
    const [upload, setUpload] = useState([]);


    //Image Upload
    const appendImageName = (e) => {
        if (upload.length < 5) {
          const uploaded = [...upload];
          if (uploaded.findIndex((f) => f.name === e.target.files[0].name) === -1) {
            uploaded.push(e.target.files[0]);
          }
          setUpload(uploaded);
        }
    };

    const deleteImageName = (e, imagename) => {
        const remainImages = upload.filter((item, index) => item.name !== imagename);
        setUpload(remainImages);
    };

    //Save Ticket
    const handleSubmit = async (event,data) => {
        const formData = new FormData();
        formData.append("finaldata", JSON.stringify(data));
        
        if (upload.length > 0) {
         
            formData.append(`file`, upload[0]);
          
        } else {
          formData.append(`file`, "");
        }
        saveFota(formData)
    }


    const Validation = (event) => {
        event.preventDefault();
  
     var formData = new FormData(event.target);
     var data = Object.fromEntries(formData);
     handleSubmit(event,data)
    
    }
    useEffect(() => {
      if(saveFotaSuccess){
          if(saveFotaData.resultText === "Success"){
            dispatch(snackService([saveFotaData.resultMessage, saveFotaData.resultText, true]));
            setUpload([]);
            setAppName("");
setVersionName("");
setType("0");
navigate(location.pathname);
reload()
          }else{
            dispatch(snackService([saveFotaData.resultMessage, saveFotaData.resultText, true]));
          }
      }
    },[saveFotaSuccess])

    //resetTicket
    const resetTicket = () => {
setAppName("");
setVersionName("");
setType("0");
setUpload([]);
    }





  return (
    <form component={'form'} onSubmit={Validation} enctype="multipart/form-data">
    <Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item lg={12}>
            <FormControl fullWidth>
               <StyledFormControlLabel required>App Name</StyledFormControlLabel>
               <StyledTextField name={'appName'} size='small' placeholder='Enter app name' value={appName} fullWidth onChange={(event) => setAppName(event.target.value)}/>
           </FormControl>
            </Grid>
            <Grid item lg={6}>
            <FormControl fullWidth>
                    <StyledFormControlLabel required>Firmware Version</StyledFormControlLabel>
                    <StyledTextField name={'firmwareVersion'} size='small' placeholder='Enter firmware version' value={versionName} fullWidth onChange={(event) => setVersionName(event.target.value)}/>
                </FormControl>
               
            </Grid>
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Type</StyledFormControlLabel>
                    <StyledTextField size='small' name={'type'} fullWidth select value={type} onChange={(event) => setType(event.target.value)}>
                        {
                            TYPE !== undefined &&
                            TYPE.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>

            {/* Start Image Upload */}
            <Grid item lg={12}>
                <FileUploadBox sx={{mt:1}}>
                <Icon sx={{fontSize:'55px', mb:2}} color='primary'><RiUploadCloud2Fill  /></Icon>
                <Typography variant="body2" > Upload Attachment</Typography>
                        <input
                        id="myInput"
                        className="fileupload"
                        type={"file"}
                        //name="file"
                        onChange={appendImageName}
                    />
                </FileUploadBox>
                <Box>
                    <List sx={{ width: "100%" }} dense={true}>
                        {upload.map((item, index) => {
                             return (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                      <IconButton
                                        type="button"
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => deleteImageName(e, item.name)}
                                      >
                                        <AiOutlineDelete />
                                      </IconButton>
                                    }
                                  >
                                    <ListItemAvatar>
                                      <Avatar variant="circle">
                                      <FaAndroid />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={item?.name}
                                      //
                                    />
                                  </ListItem>
                             )
                        })}
                    </List>
                </Box>
            </Grid>

        {

        }
            
        </Grid>
        <Stack direction={'row'} spacing={2} sx={{mt:2, width:"100%"}} justifyContent={'center'}>
            <SoftButton variant='contained' color='success' className='success' type='submit'>
            {
                saveFotaLoading ? <ScaleLoader color={theme.palette.primary.contrastText} height={12}/> : 'Save'
              }
           
                
            </SoftButton>
            <SoftButton variant='contained' color='error' onClick={resetTicket} className='error'>Reset</SoftButton>
        </Stack>
    </Box>
    </form>
  )
}

export default CreateFota