import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

function Activity(props) {

    const { listsActivity } = props;
     //Render HTML
     const renderHTML = (rawHTML) => React.createElement("p", {
        dangerouslySetInnerHTML: { __html: rawHTML },
    });


  return (
    <Box>
        <Paper elevation={0}>
            <Typography sx={{px:2, py:2}}>Recent Activities</Typography>
        <Timeline
                      sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                            },
                        }}
                    >
                        {listsActivity?.data?.recentActivityList?.map((item, index) => {
    return (
      <>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary"></TimelineDot>
            <TimelineConnector />

            <TimelineConnector />
          </TimelineSeparator>
          <Typography sx={{ marginTop: "5px", px: 2 }}>
            {/* {item.key} */}
          </Typography>

          <TimelineContent
            sx={{ py: "20px", px: 1, marginRight: "10px" }}
          >
            {item.obj.map((det, index) => {
              return (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={2}
                    sx={{ marginTop: "25px" }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "lightgreen",
                        textAlign: "center",
                      }}
                    >
                      {det.requestChangeStatus}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Card
                      sx={{
                        height: "auto",
                        marginTop: "3px",
                      }}
                      
                    >
                      <CardContent>
                        <Typography sx={{ color: "blue" }}>
                          {renderHTML(
                            det.requestChangeActivity
                          )}
                        </Typography>
                        <Typography>
                          {det.createdBy +
                            "," +
                            " " +
                            det.createdDateStr}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              );
            })}
          </TimelineContent>
        </TimelineItem>
      </>
    );
  })}
                    
        </Timeline>
        </Paper>
    </Box>
  )
}

export default Activity