import React, { useMemo, useState, useEffect } from "react";
import { DataTable } from "../shared/data_table/DataTable";
import FeatureService from "../../services/featureService";
import DraggableDialog from "../shared/DailogDrag";
import MultipleSelect from "../Logs/MenusDropdown";
import menuListService from "../../services/menuListService";
import {
  StyledCardShadow,
  StyledTableTitle,
  StyledPaperShadow,
} from "../../theme/StyledEle";
import { CardContent, CardHeader } from "@mui/material";

const ListFeature = ({ onEditFeature, menuUser }) => {
  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [menuList, setMenuList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const shouldIncludeAllOption1 = true;
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [loading, setLoading] = useState("");

  const getOptions1 = (options) => {
    const allOption = {
      title: "Select",
    };
    return shouldIncludeAllOption1 ? [allOption, ...options] : options || [];
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  var globalOrgId = 1;
  var globalJson = {};
  var globalResellerId = 1;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalResellerId = globalJson.resellerId;

  useEffect(async () => {
    await fetchDeviceDetails();

    const menuListPayload = {
      id: 0,
      orgId: globalJson !== null ? globalJson.orgId : 1,
      orgType: globalJson !== null ? globalJson.userOrgType : 1,
      roleId: globalJson !== null ? globalJson.roleId : 1,
      page: 0,
      size: 100,
      value: "region",
      searchText: "",
      vehModel: "1",
      region: 1,
    };
    const { data: menuResponse } = await menuListService.getMenuList(
      menuListPayload
    );

    const menusAll = getOptions1(menuResponse.menus);

    setMenuList(menusAll);
  }, []);

  const handleMenuSelect = async (menu) => {
    setSelectedMenu(menu);
    var selId = -1;
    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].title === menu) {
        selId = menuList[i].id;
        break;
      }
    }
    setSelectedMenuId(selId);
    fetchDeviceDetails("", menu, selId);
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: '',
      //   accessor: 'buttonCheckBox'
      // }, {
      {
        Header: "Link Feature Id",
        accessor: "linkfeatureId",
      },
      {
        Header: "Feature Title",
        accessor: "featuretitle",
      },
      {
        Header: "Feature Link",
        accessor: "featurelink",
      },
      {
        Header: "Feature Icon",
        accessor: "featureIcon",
      },
      {
        Header: "Link Order",
        accessor: "linkorder",
      },
      {
        Header: "Link Group",
        accessor: "linkgroup",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Button Operation",
        accessor: "buttonoperation",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );

  const onEdit = (itemEdited) => {
    onEditFeature(itemEdited.id);
  };

  const handleClearCls = () => {
    setOpen(false);
  };

  const onCheckbox = (itemEdited) => {};

  const fetchDeviceDetails = async (searchText, menu, selId) => {
    var dataStr = {};
    dataStr.edit = false;
    dataStr.delete = false;
    dataStr.view = false;
    dataStr.excel = false;
    dataStr.pdf = false;
    var strval;
    strval = menuUser.split(",");
    for (var i = 0; i < strval.length; i++) {
      if (parseInt(strval[i]) === 4) {
        dataStr.edit = true;
      }
      if (parseInt(strval[i]) === 3) {
        dataStr.delete = true;
      }
      if (parseInt(strval[i]) === 6) {
        dataStr.pdf = true;
      }
      if (parseInt(strval[i]) === 5) {
        dataStr.excel = true;
      }
    }
    setCommonFilter(dataStr);
    var request = {};
    request.resellerId = globalResellerId;
    request.orgId = globalOrgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.searchText = searchText === undefined ? "" : searchText;
    request.menuName = menu;
    request.menuId = selId;

    try {
      const responseData = (await FeatureService.getGridViewForFeature(request))
        .data;
      setResponseData(responseData.data.content);
      setLoading(responseData.resultText);
      setTotalElements(responseData.data.totalElements);
      setTotalPages(responseData.data.totalPages);
    } catch (error) {}
  };
  const onDelete = async (itemDeleted) => {
    try {
      setDataStatusConfirm(false);
      setOpen(true);
      var obj = {};
      obj.dialogButtonText = "Delete";
      obj.submitButtonText = "Confirm";
      obj.closeButtonText = "Close";
      obj.dialogText = "Are you Sure? Do you want to Delete";
      setDialogContent(obj);
      setDataStatus(true);
      setSavedData(itemDeleted);
    } catch (err) {
      console.error("something went wrong");
    }
  };

  const handleSbmtClose = async () => {
    var deleteRow = {};
    deleteRow.id = savedData.id;
    await FeatureService.deleteDeviceById(deleteRow);
    const updatedResponse = responseData.filter(
      (item) => item.id !== savedData.id
    );
    setResponseData(updatedResponse);
    var obj = {};
    obj.dialogButtonText = "Delete Status";
    obj.submitButtonText = "";
    obj.closeButtonText = "Close";
    obj.dialogText = "Data Deleted Successfully";
    setDialogContent(obj);
    setDataStatusConfirm(false);
    setDataStatus(true);
    setOpen(true);
  };

  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);

    var request = {};
    request.resellerId = globalResellerId;
    request.orgId = globalOrgId;
    request.page = newPage;
    request.size = rowsPerPage;
    request.searchText = "";
    request.menuName = selectedMenu;
    request.menuId = selectedMenuId;
    try {
      const responseData = (await FeatureService.getGridViewForFeature(request))
        .data;
      setResponseData([]);
      setResponseData(responseData.data.content);
      setLoading(responseData.resultText);
      setTotalElements(5);
      setTotalPages(0);
      setTotalElements(responseData.data.totalElements);
      setTotalPages(responseData.data.totalPages);
    } catch (error) {}
  };
  const handleSearch = (searchText) => {
    fetchDeviceDetails(searchText, selectedMenu, selectedMenuId);
  };

  const handleSearchList = async(searchText) =>{
  
    const menuListPayload = {
      id: 0,
      orgId: globalJson !== null ? globalJson.orgId : 1,
      orgType: globalJson !== null ? globalJson.userOrgType : 1,
      roleId: globalJson !== null ? globalJson.roleId : 1,
      page: 0,
      size: 100,
      value: "region",
      searchText: searchText,
      vehModel: "1",
      region: 1,
    };
    const { data: menuResponse } = await menuListService.getMenuList(
      menuListPayload
    );

    const menusAll = getOptions1(menuResponse.menus);

    setMenuList(menusAll);
  }

  return (
    <>
      <StyledPaperShadow>
        <MultipleSelect
          label="Menu Name"
          options={menuList}
          onChange={handleMenuSelect}
          optionsDisplayKey="title"
          isShow={userData.userOrgType === 8 ? true : false}
          fieldName={"featureName"}
          onSearch={handleSearchList}
        />
      </StyledPaperShadow>

      <StyledCardShadow sx={{ mt: 2 }}>
        <CardHeader title={<StyledTableTitle>Feature List</StyledTableTitle>} />

        <CardContent sx={{ py: 0 }}>
          <DataTable
            loading={loading}
            includeSearchBox={true}
            handleChange={handleSearch}
            onEdit={onEdit}
            onDelete={onDelete}
            onCheckbox={onCheckbox}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={callbackChangePage}
            totalElements={totalElements}
            totalPages={totalPages}
            commonFilter={commonFilter}
          />
        </CardContent>
      </StyledCardShadow>
      {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          dialogText={dialogContent.dialogText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
        />
      )}
    </>
  );
};

export default ListFeature;
