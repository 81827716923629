import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTabsGroup,
} from "../../theme/StyledEle";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { Box } from "@mui/material";
import ServiceDetailsList from "./ServiceDetailsList";
import CreateServiceDetails from "./CreateServiceDetails";
import ServiceHistoryList from "./ServiceHistoryList";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";

const ServiceSlaReport = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let roleId = userData.roleId;

  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [lableName, setLabelName] = useState("Create Service Center");
  const [serviceToEdit, setServiceToEdit] = useState(null);

  const editServiceDetails = (data) => {
    var tempProps = JSON.parse(JSON.stringify(data));
    tempProps.clickedval = "edit";
    setServiceToEdit(Object.preventExtensions(tempProps));
    setLabelName("Edit Service Center");
    setTabsValue(3);
  };

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
  });


  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  const [tabsValue, setTabsValue] = useState(1);


  const tabChange = (event, newValue) => {
    setLabelName("Create Service Center");
    setTabsValue(newValue);
  };
  const handleCancel = () => {
    setTabsValue(2);
    setServiceToEdit(null);
    setLabelName("Create Service Center");
  }
  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "ServiceSlaReport"
              ) {

                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }

                }
                setCommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }
  }
  const [tabValue, setTabValue] = useState(() => callfun());

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Service SLA" includePopup={true} />
      <Box>
        <StyledTabBox>
          <StyledTabsGroup
            value={tabsValue}
            onChange={tabChange}
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ "&  .MuiTabs-flexContainer": { flexDirection: "row" } }}
          >
            <StyledTab value={1} label="Service History" />
            { commonFilter.view && <StyledTab value={2} label="Service Center" /> }
           { ((commonFilter.create && lableName=="Create Service Center") || (commonFilter.create && lableName=="Edit Service Center"))  && <StyledTab value={3} label={lableName} /> }
          </StyledTabsGroup>
        </StyledTabBox>
        <CustomTabPanel value={tabsValue} index={1}>
          <ServiceHistoryList />
        </CustomTabPanel>
        <CustomTabPanel value={tabsValue} index={2}>
          <ServiceDetailsList editServiceDetails={editServiceDetails} commonFilter={commonFilter}/>
        </CustomTabPanel>
        <CustomTabPanel value={tabsValue} index={3}>
          <CreateServiceDetails serviceToEdit={serviceToEdit} onCancel={handleCancel} />
        </CustomTabPanel>
      </Box>
      {
        isSuccess.isSuccess &&
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      }

    </StyledPageLayout>
  );

};


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export default ServiceSlaReport;