import { React, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Checkbox,
  Button,
  Divider,
  Stack,
  Grid,
} from "@mui/material/";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { ButtonGroup } from "@mui/material";
import {
  useGetAlertPrefrenceMutation,
  useSaveAlertPrefernceMutation,
} from "../../store/slice/ProfileSlice/ProfileSlice";
import { SoftButton } from "../../theme/StyledEle";
import { ScaleLoader } from "react-spinners";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
export default function Alert() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId;
  let orgId = userData?.orgId;
  let orgType = userData?.userOrgType;
  let userId = userData?.id;
  let orgName = userData?.orgName;
  let userName = userData?.userName;

  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [push, setPush] = useState(false);

  const [DefaultAlert, setDefultAlert] = useState(null);

  const [saveAlertPre, { data: alertPreferanceData, isLoading, isSuccess }] =
    useSaveAlertPrefernceMutation();
  const [
    getAlertSettings,
    {
      data: getAlertData,
      isLoading: getAlertLoadng,
      isSuccess: getAlertSuccess,
    },
  ] = useGetAlertPrefrenceMutation();

  const dispatch = useDispatch();

  const onSumbit = () => {
    let payload = {
      userOrgId: orgId,
      orgType: orgType,
      sessionOrgType: orgType,
      createdBy: userId,
      userid: userId,
      email: email ? 1 : 0,
      sms: sms ? 1 : 0,
      push: push ? 1 : 0,
    };
    saveAlertPre(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      getAlertNofi();
      dispatch(
        snackService([
          alertPreferanceData?.resultMessage,
          alertPreferanceData?.resultCode,
          isSuccess,
        ])
      );
    }
  }, [isSuccess]);

  const getAlertNofi = async () => {
    let payload = {
      userOrgId: orgId,
      userid: userId,
    };
    const alertData = await getAlertSettings(payload);
    setEmail(alertData?.data?.data?.email);
    setSms(alertData?.data?.data?.sms);
    setPush(alertData?.data?.data?.push);
    setDefultAlert(alertData.data?.data);
  };

  useEffect(async () => {
    getAlertNofi();
  }, []);

  const reset = () => {
    setEmail(0);
    setSms(0);
    setPush(0);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        My Alert Notification Preferences
      </Typography>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          bgcolor: "background.paper",
          padding: "0.8em",
          marginTop: 2,
        }}
        // subheader={
        //   <ListSubheader sx={{ color: "#2196f3", fontWeight: "Bold" }}>
        //     My Alert Notification Preferences
        //   </ListSubheader>
        // }
      >
        <ListItem>
          <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText id="controlled" primary="Email" />
          <Checkbox
            edge="end"
            onChange={() => setEmail(!email)}
            checked={email}
            defaultChecked={
              DefaultAlert !== null && DefaultAlert.email === 0 ? false : true
            }
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>

          <ListItemText id="controlled" primary="SMS" />

          <Checkbox
            edge="end"
            onChange={() => setSms(!sms)}
            checked={sms}
            defaultChecked={
              DefaultAlert !== null && DefaultAlert.sms === 0 ? false : true
            }
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <NotificationsNoneIcon />
          </ListItemIcon>
          <ListItemText id="controlled" primary="Push" />

          <Checkbox
            edge="end"
            onChange={() => setPush(!push)}
            checked={push}
            defaultChecked={
              DefaultAlert !== null && DefaultAlert.push === 0 ? false : true
            }
            inputProps={{
              "aria-label": "controlled",
            }}
          />
        </ListItem>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <SoftButton
            color="error"
            className="error"
            size="small"
            onClick={reset}
          >
            Reset
          </SoftButton>
          <SoftButton
            variant="contained"
            color="primary"
            onClick={onSumbit}
            className="success"
            size="small"
            disabled={isLoading}
          >
            {isLoading ? <ScaleLoader height={12} color="#fff" /> : "Save"}
          </SoftButton>
        </Stack>
      </List>
    </Box>
  );
}
