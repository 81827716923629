import React, { useState, useEffect } from "react";
import OTAServices from "../../services/otaService";
import DraggableDialog from "../shared/DailogDrag";
import logServices from "../../services/logService";
import { useForm } from "react-hook-form";

import {
  Box,
  Typography,
  Button,
  MenuItem,
  CardContent,
  Stack,
  CardActions,
  FormHelperText,
  ToggleButton,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFilterMenuItem,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTextField,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";

import OTADropdown from "../OTA/OTADropdown";
import { FaPen } from "react-icons/fa";
import { IoReader } from "react-icons/io5";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 160,
    },
  },
};

const DeviceOTASupport = ({ onCancel }) => {
  var readCommandsList = [];
  var writeCommandsList = [];

  const [commands, setCommands] = useState([]);
  const [seltdCustomerId, setSeltdCustomerId] = useState("-1");
  const [seltdFleetId, setSeltdFleetId] = useState("-1");
  const [seltdDealerId, setSeltdDealerId] = useState("-1");
  const [seltdRegionId, setSeltdRegionId] = useState("-1");
  const [seltdImei, setSeltdImei] = useState("0");
  const [vehicleVal, setVehicleVal] = useState("-1");
  const [options, setOptions] = useState([]);
  const [deviceModel, setDeviceModel] = useState("0");
  const [otaId, setOtaId] = useState(0);

  const [radioVal, setRadioVal] = useState("");
  const [selectVal, setSelectVal] = useState("0");
  const [textMsg, setTextMsg] = useState("");
  const [add, setAdd] = useState(false);
  const [avl, setAvl] = useState(false);
  const [comments, setComments] = useState(false);
  const [avlId, setAvlId] = useState("");
  const [avlValue, setAvlValue] = useState("");
  const [addCommand, setAddCommand] = useState("");

  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);

  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let globalOrgId = userData.orgId;
  let globalOrgType = userData.userOrgType;
  let globaluserId = userData.id;
  let globalResellerId = userData.resellerId;
  let globalLogoName = userData.orgLogo;
  let globalOrgName = userData.orgName;
  let globalUserName = userData.userName;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(async () => {
    var request = {};
    request.message = "OTA > OTASupport";
    request.action = "View";
    request.featureName = "OTASupport";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;
    request.orgName = globalOrgName;
    request.userName = globalUserName;

    const responseData1 = await logServices.saveAuditLogDetails(request);
  }, []);

  const handleRadioChange = async (e, newValue) => {
    if (newValue !== null) {
      setRadioVal(newValue);
      setAdd(false);
      setAvl(false);
      setComments(false);
      setAvlId("");
      setAvlValue("");
      setAddCommand("");
      setSelectVal("0");
      if (newValue === "READ") {
        const commandListPayload = {
          orgType: globalOrgType,
          imei: seltdImei,
        };
        const { data: commandList } = await OTAServices.getReadCommandList(
          commandListPayload
        );
        setCommands(commandList);
        var i = 0;
        for (i = 0; i < commandList.length; i++) {
          if (commandList[i].commandType === 1) {
            readCommandsList.push(commandList[i].otaName);
          }
        }
        setOptions(readCommandsList);
      } else if (newValue === "WRITE") {
        const commandListPayload = {
          orgType: globalOrgType,
          imei: seltdImei,
        };
        const { data: commandList } = await OTAServices.getWriteCommandList(
          commandListPayload
        );
        setCommands(commandList);

        var i = 0;
        for (i = 0; i < commandList.length; i++) {
          if (commandList[i].commandType === 2) {
            writeCommandsList.push(commandList[i].otaName);
          }
        }
        setOptions(writeCommandsList);
      }
    }
  };
  const handleSelectChange = (e) => {
    setSelectVal(e.target.value);
    setValue("avlId", "");
    setValue("avlValue", "");
    setValue("comments", "");
    setAddCommand("");
    setAvlId("");
    setAvlValue("");
    setTextMsg("");
    if (radioVal === "WRITE") {
      if (e.target.value === "setparam") {
        setAdd(true);
        setAvl(true);
        setComments(false);
      } else if (e.target.value === "getparam") {
        setAdd(true);
        setAvl(false);
        setComments(false);
      } else if (
        e.target.value === "IMB-ON" ||
        e.target.value === "IMB-OFF" ||
        e.target.value === "ON DEMAND TRACKING ON" ||
        e.target.value === "ON DEMAND TRACKING OFF" ||
        e.target.value === "0" ||
        e.target.value === "IMMOBILIZER"
      ) {
        setComments(false);
        setAdd(false);
        setAvl(false);
      } else {
        setAdd(false);
        setAvl(false);
        setComments(true);
      }
    } else if (radioVal === "READ") {
      setAdd(false);
      setComments(false);
      setAvl(false);
    }
    for (var i = 0; i < commands.length; i++) {
      if (e.target.value === commands[i].otaName) {
        setOtaId(commands[i].otaId);
        setDeviceModel(commands[i].deviceModel);
      }
    }
  };

  const handleCommand = (text) => {
    setAddCommand(text);
  };

  const handleAvlId = (text) => {
    setAvlId(text);
    setAddCommand(text);
  };

  const handleAvlCommand = (text) => {
    setAvlValue(text);
    setAddCommand(avlId + ":" + text);
  };

  const handleAdd = () => {
    setTextMsg("");
    if (selectVal === "$CANCF" || selectVal === "$CANCC") {
      setTextMsg(addCommand);
    } else {
      setTextMsg(" " + addCommand);
    }
    setAddCommand("");
    setAvlId("");
    setAvlValue("");
    setValue("avlId", "");
    setValue("avlValue", "");
    setValue("comments", "");
  };
  const getData = (data) => {
    setSeltdImei(data.imei);
    setSeltdDealerId(data.dealer);
    setSeltdCustomerId(data.customer);
    setSeltdRegionId(data.region);
    setSeltdFleetId(data.fleet);
    setVehicleVal(data.vehicle);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    var request = {};
    request.imei = seltdImei;
    request.message = selectVal + textMsg;
    request.commandType = radioVal;
    request.orgType = globalOrgType;
    request.firmwareId = 0;
    request.vehicleId = "-1";
    request.otaType = radioVal;
    request.modelId = deviceModel;
    request.otaId = otaId;
    request.otaType = 0;
    request.otaCommand = selectVal;
    request.dealerId = seltdDealerId;
    request.customerId = seltdCustomerId;
    request.regionId = seltdRegionId;
    request.fleetId = seltdFleetId;
    request.resellerId = globalResellerId;
    request.logoName = globalLogoName;

    const responseData = await OTAServices.saveCommands(request);

    request.message = "OTA > OTASubmit";
    request.action = "View";
    request.featureName = "OTASubmit";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;
    request.orgName = globalOrgName;
    request.userName = globalUserName;

    const responseData1 = await logServices.saveAuditLogDetails(request);

    var obj1 = {};
    if (responseData.data === "success") {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm("Success");
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  };

  const handleReset = async () => {
    setTextMsg("");
    setAddCommand("");
    setAvlId("");
    setAvlValue("");
    setValue("avlId", "");
    setValue("avlValue", "");
    setValue("comments", "");
    if (radioVal === "WRITE") {
      if (selectVal === "setparam") {
        setAdd(true);
        setAvl(true);
        setComments(false);
      } else if (selectVal === "getparam") {
        setAdd(true);
        setAvl(false);
        setComments(false);
      } else if (
        selectVal === "IMB-ON" ||
        selectVal === "IMB-OFF" ||
        selectVal === "ON DEMAND TRACKING ON" ||
        selectVal === "ON DEMAND TRACKING OFF" ||
        selectVal === "0" ||
        selectVal === "IMMOBILIZER"
      ) {
        setComments(false);
        setAdd(false);
        setAvl(false);
      } else {
        setAdd(false);
        setAvl(false);
        setComments(true);
      }
    } else {
      setAdd(false);
      setComments(false);
      setAvl(false);
    }

    var request = {};
    request.message = "OTA > OTAReset";
    request.action = "View";
    request.featureName = "OTAReset";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;
    request.orgName = globalOrgName;
    request.userName = globalUserName;

    const responseData1 = await logServices.saveAuditLogDetails(request);
  };

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
    setAdd(false);
    setAvl(false);
    setAvlId("");
    setAddCommand("");
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OTADropdown
          getData={getData}
          imeiDrop={true}
          onLoadData={false}
          otaSupport={true}
        />

        <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
          <CardContent>
            <Stack direction={"row"} spacing={5} alignItems={"center"}>
              <StyledToggleButtonGroup
                sx={{ width: "fit-content" }}
                value={radioVal}
                exclusive
                onChange={handleRadioChange}
                aria-label="theme mode"
              >
                <ToggleButton value="READ" sx={{ width: "100%" }}>
                  {" "}
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IoReader /> <Typography>READ</Typography>
                  </Stack>
                </ToggleButton>
                <ToggleButton value="WRITE" sx={{ width: "100%" }}>
                  {" "}
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {" "}
                    <FaPen /> <Typography>WRITE</Typography>{" "}
                  </Stack>
                </ToggleButton>
              </StyledToggleButtonGroup>
              <StyledFormControl sx={{ minWidth: "370px" }} size="small">
                <StyledFormControlLabel required>
                  Command Details
                </StyledFormControlLabel>
                <StyledDropDown
                  error={errors.commandDetails && true}
                  {...register("commandDetails", {
                    required: {
                      value: true,
                      message: "Required Field",
                    },
                    validate: (fieldValue) => {
                      return (
                        fieldValue !== "0" || " Please select Command Details"
                      );
                    },
                  })}
                  size="small"
                  value={selectVal}
                  onChange={handleSelectChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"0"}>Select</MenuItem>
                  {options.map((option) => (
                    <StyledFilterMenuItem value={option}>
                      {option}
                    </StyledFilterMenuItem>
                  ))}
                </StyledDropDown>
                <FormHelperText error={errors.commandDetails && true}>
                  {errors.commandDetails?.message}
                </FormHelperText>
              </StyledFormControl>
            </Stack>
            {add === true && (
              <Box sx={{ marginTop: 5 }}>
                <StyledFormControl sx={{ minWidth: "390px" }} size="small">
                  <StyledFormControlLabel>Avl Id</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.avlId && true}
                    size="small"
                    fullWidth
                    {...register("avlId", {
                      required: {
                        value: false,
                        message: "Avl Id required ",
                      },
                    })}
                    onChange={(e) => {
                      setValue("avlId", e.target.value);
                      handleAvlId(e.target.value);
                    }}
                    helperText={errors.avlId && errors.avlId.message}
                  />
                </StyledFormControl>
              </Box>
            )}

            {avl === true && (
              <Box sx={{ marginTop: 5 }}>
                <StyledFormControl sx={{ minWidth: "390px" }} size="small">
                  <StyledFormControlLabel>Avl Value</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.avlValue && true}
                    size="small"
                    fullWidth
                    {...register("avlValue", {
                      required: {
                        value: false,
                        message: "Avl Value required ",
                      },
                    })}
                    onChange={(e) => {
                      setValue("avlValue", e.target.value);
                      handleAvlCommand(e.target.value);
                    }}
                    helperText={errors.avlValue && errors.avlValue.message}
                  />
                </StyledFormControl>
              </Box>
            )}
            {comments === true && (
              <Box sx={{ marginTop: 5 }}>
                <StyledFormControl sx={{ minWidth: "390px" }} size="small">
                  <StyledFormControlLabel>Comments</StyledFormControlLabel>
                  <StyledTextField
                    error={errors.comments && true}
                    size="small"
                    fullWidth
                    {...register("comments", {
                      required: {
                        value: false,
                        message: "comments required ",
                      },
                    })}
                    onChange={(e) => {
                      setValue("comments", e.target.value);
                      handleCommand(e.target.value);
                    }}
                    helperText={errors.comments && errors.comments.message}
                  />
                </StyledFormControl>
              </Box>
            )}

            <StyledFormControl sx={{ minWidth: "600px", marginTop: 5 }}>
              <StyledFormControlLabel>DATA</StyledFormControlLabel>
              <TextareaAutosize
                variant="filled"
                disabled
                value={selectVal === "0" ? "" : selectVal + textMsg}
                maxRows="5"
              />
            </StyledFormControl>
          </CardContent>
          <CardActions>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 2, width: "100%" }}
              justifyContent={"flex-end"}
            >
              {(add === true || avl === true || comments === true) && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ color: "white", textTransform: "none" }}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              )}
              <SoftButton
                variant="contained"
                size="small"
                type="submit"
                color="success"
              >
                Send
              </SoftButton>
              {/* <SoftButton
                variant="contained"
                size="small"
                color="secondary"
                className="secondary"
              >
                Preview
              </SoftButton> */}
              <SoftButton
                variant="contained"
                size="small"
                onClick={handleReset}
                color="info"
                className="secondary"
              >
                Clear Command
              </SoftButton>
            </Stack>
          </CardActions>
        </StyledCardShadow>
        {open && (
          <StyledSnackBar
            open={true}
            message={dialogContent.dialogText}
            status={dataStatusConfirm}
          />
        )}
      </form>
    </Box>
  );
};

export default DeviceOTASupport;
