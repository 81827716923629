import * as React from "react";
import { useState,useEffect } from "react";
import {
  Grid,
  ListItemText,
  ListItem,
  useTheme,
  Typography,
  Alert,
  Stack,
  Divider,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  SoftButton,
  StyledPageLayout,
  StyledPaperShadow,
  StyledTextField,
} from "../../theme/StyledEle";
import { Box, alpha } from "@mui/system";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";
import { ScaleLoader } from "react-spinners";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useForm } from "react-hook-form";
import { useGetTargetDistMutation, useUpdateTargetDistMutation } from "../../store/slice/AdminSlice/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";


const TargetDistance = () => {

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const text = "The Maximum Target distance per day is 1000 km.";
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.controls.alert);
  
     React.useEffect(() => {
       if (isSuccess.isSuccess) {
         setTimeout(() => dispatch(snackService([null, null, null])), 6000);
       }
     }, [isSuccess.isSuccess]);
  let CommonPayload = {
   
  };

 const daysList = [{value:"Default Distance",name:"defaultDist"},{value:"Sunday",name:"sunDist"},{value:"Monday",name:"monDist"},{value:"Tuesday",name:"tueDist"},{value:"Wednesday",name:"wedDist"},{value:"Thursday",name:"thuDist"},{value:"Friday",name:"friDist"},{value:"Saturday",name:"satDist"}];

 const [
  updateTarget,
  {
    data: updateTargetData,
    isLoading: updateTargetLoading,
    isSuccess: updateTargetSuccess,
  },
] = useUpdateTargetDistMutation();


const [
  getTarget,
  {
    data: getTargetData,
    isLoading: getTargetLoading,
    isSuccess: getTargetSuccess,
  },
] = useGetTargetDistMutation();


const [payload, setPayload] = useState(null);

  const getData = (data) =>{
    CommonPayload.vehicleId = data.vehicleId;
    getTarget(CommonPayload)
    CommonPayload.customerId = data.customerId;
    setPayload(CommonPayload);
  }

  useEffect(() => {
    if (getTargetSuccess) {
     
      if (getTargetData.resultData !== null) {
      reset({
        defaultDist:0,
        sunDist:getTargetData?.resultData[0]?.sunDist,
        monDist:getTargetData?.resultData[0]?.monDist,
        tueDist:getTargetData?.resultData[0]?.tueDist,
        wedDist:getTargetData?.resultData[0]?.wedDist,
        thuDist:getTargetData?.resultData[0]?.thuDist,
        friDist:getTargetData?.resultData[0]?.friDist,
        satDist:getTargetData?.resultData[0]?.satDist,
        minSpeedRange:getTargetData?.resultData[0]?.minSpeedRange,
        maxSpeedRange:getTargetData?.resultData[0]?.maxSpeedRange,
      });

      }
    }
  }, [getTargetSuccess]);

  const changeDayValue=(defaultValue)=>{
    if(defaultValue>=0){
      reset({
        deafultDist:defaultValue,
        sunDist:defaultValue,
        monDist:defaultValue,
        tueDist:defaultValue,
        wedDist:defaultValue,
        thuDist:defaultValue,
        friDist:defaultValue,
        satDist:defaultValue,
      });
    }
  }

const onSubmit = async(obj) =>{
  obj.vehicleId = payload.vehicleId;
  obj.customerId = payload.customerId;

  const responseData = await updateTarget(obj)
  
      dispatch(snackService([responseData.data.resultMessage, responseData.data.resultCode, true]));

}
const theme = useTheme();
  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Admin"
        subDiv="Assign Target Distance"
      ></Breadcrumb>
      
      <AlertDropdown getData={getData} isShowAll={false} />
      <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container maxWidth={'sm'} spacing={2} sx={{ my: 2, justifyContent: "center", margin:'auto' }}>
            <Grid item lg={12}>
            
                <StyledPaperShadow sx={{ my: 1 }}>
                  <Box sx={{width:'100%'}}>
                      <Typography>
                            TARGET DISTANCE
                      </Typography>
                      <Alert severity="info" sx={{alignItems:'center', my:2}}>
                          {text}
                      </Alert>
                      <Grid
                              container
                              rowSpacing={1}
                              lg={12}
                            >
                      {daysList?.map((org) => (
                           <>
                              <Grid item lg={6}>
                                <ListItem dense={true}>
                                  <ListItemText primary={org.value} />
                                </ListItem>
                              </Grid>
                              <Grid
                                    item
                                    xs={6}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledTextField
               
                    size="small"
                    fullWidth
                    {...register(`${org.name}`, {
                    })}
                    onChange={(e) => 
                      {org.name=='defaultDist'? changeDayValue( e.target.value):
                      setValue(`${org.name}`, e.target.value)}}
                    value={watch(`${org.name}`)}
                    inputProps={{autoComplete:"off"}}
                  />
                              </Grid>
                              
                              </>
                          ))
                        }
                        </Grid>
                        <Grid
                              container
                              columnSpacing={1}
                              rowSpacing={1}
                              lg={12}
                              sx={{my:1}}
                            >
                              <Grid item xs={6}>
                                <ListItem dense={true}>
                                  <ListItemText primary={'Sweetspot'} />
                                </ListItem>
                              </Grid>


                              <Grid item lg={1}>
                                  <ListItemText primary={'Min'} />
                              </Grid>

                              <Grid
                                    item
                                    lg={2}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StyledTextField
               
                    size="small"
                    fullWidth
                    {...register(`minSpeedRange`, {
                    })}
                    onChange={(e) => setValue(`minSpeedRange`, e.target.value)}
                    value={watch(`minSpeedRange`)}
                    inputProps={{autoComplete:"off"}}
                  />
                  </Grid>
                              <Grid item lg={1}>
                                  <ListItemText primary={'Max'} />
                              </Grid>
                                  <Grid
                                    item
                                    lg={2}
                                  >
                                    <StyledTextField
               
                    size="small"
                    fullWidth
                    {...register(`maxSpeedRange`, {
                    })}
                    onChange={(e) => setValue(`maxSpeedRange`, e.target.value)}
                    value={watch(`maxSpeedRange`)}
                    inputProps={{autoComplete:"off"}}
                  />
                                  </Grid>
                            
                          </Grid>

                        <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ my: 2 }}
                >
                  <SoftButton
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{cursor:updateTargetLoading ? 'wait' : 'pointer'}}
                  >
                   {updateTargetLoading ? (
                      <ScaleLoader
                        color={theme.palette.primary.contrastText}
                        height={12}
                      />
                    ) : ( 
                      "Save"
                     )} 
                  </SoftButton>
                </Stack>
                  </Box>
                </StyledPaperShadow>
            </Grid>
          </Grid>
      </Box>
     {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )} 
    </StyledPageLayout>
  );
};

export default TargetDistance;