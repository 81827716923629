import MockAdapter from 'axios-mock-adapter';
import { overSpeedData } from './overSpeedData';
import { playBackData } from './playBackData';
import { listOrganizationData } from './listOrganizationData';
import { listUserData } from './listUserData';
import { listDeviceData } from './listDeviceData';

export const isMockEnabled = () => {
    return process.env.REACT_APP_MOCK_ENABLED === 'true';
};

export const initializeAxiosMockAdapter = (instance) => {
    const mock = new MockAdapter(instance);
    mock.onGet('/hello').reply(202, { test: "data" })
    mock.onGet('/overspeed').reply(200, { data: overSpeedData })
    mock.onGet('/gridviewfororg').reply(200, { data: listOrganizationData })
    mock.onGet('/getplaybackdata').reply(200, {data: playBackData})
    mock.onGet('/deleteorgbyid').reply(200, {data: playBackData})
    mock.onGet('/gridviewforuser').reply(200, { data: listUserData })
    mock.onGet('/deleteuserbyid').reply(200, {data: playBackData})
    mock.onGet('/editorgbyid').reply(200, {data: listOrganizationData[10]})
    mock.onGet('/gridviewfordevice').reply(200, { data: listDeviceData })   
};
