import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L, { polyline } from 'leaflet';
import { Box } from '@mui/material';
import { MovingTruck, ChargingTruck, IdleTruck, OfflineTruck, StopTruck , ChargingDotIcon, GreenFirst, GreenLast, MovingDotIcon, OfflineDotIcon, StopDotIcon } from '../Constans';
import { bikePath, car, truck, auto } from './resource'
import startMarker from '../../assets/images/playback/Start_Marker.svg'
import endMarker from '../../assets/images/playback/End_Marker.svg'
const LeafletPlayback = (props) => {
  const { playbackData, trailsData,animPath,pathData,rotation,mapzoom,mapType} = props;
  console.log("rotation",rotation)
  console.log("animPath",animPath)
  const mapRef = useRef(null);
  const [initialCoords, setInitialCoords] = useState([23.5120, 80.3290]);
  const [zoomLevel, setZoomLevel] = useState(5);
  console.log("initialCoords",initialCoords)
  console.log("zoomLevel",zoomLevel)


  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('map', {
        center: initialCoords,
        zoom: zoomLevel,
        zoomControl: false
      }).setView(initialCoords, zoomLevel);
      mapRef.current = map;
    } else {
      mapRef.current.setView(initialCoords, zoomLevel); 
    }

    if (mapRef.current && mapRef.current.tileLayer) {
      mapRef.current.removeLayer(mapRef.current.tileLayer);
    }

    let tileLayerUrl = '';
    switch (mapType) {
      case 'roadmap':
        tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        break;
      case 'terrain':
        tileLayerUrl = 'https://tile.openstreetmap.de/{z}/{x}/{y}.png';
        break;
      case 'satellite':
        tileLayerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
        break;
      default:
        tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        break;
    }
  
    mapRef.current.tileLayer = L.tileLayer(tileLayerUrl, {}).addTo(mapRef.current);
  }, [initialCoords, zoomLevel, mapType]);
  

useEffect(() => {
  if (mapzoom !== undefined && mapzoom !== null && mapzoom >= 3 && mapzoom <= 18) {
    setZoomLevel(mapzoom);
  }
}, [mapzoom]);

useEffect(() => {
  if (playbackData !== undefined && playbackData !== null && trailsData.length === 0) {
    const firstItem = playbackData?.playBackList[0]?.playBackList[0];
    const [lat, lng] = firstItem.latlng.split(',').map(coord => parseFloat(coord));
    setInitialCoords([lat, lng]);
    setZoomLevel(12);
  } else if (animPath && animPath.length > 0) {
    const latestItem = animPath[animPath.length - 1];
    setInitialCoords([latestItem.lat, latestItem.lng]);
    setZoomLevel(15);
  } else {
    setInitialCoords([23.5120, 80.3290]);
    setZoomLevel(5);
  }
}, [playbackData, trailsData, animPath]);


  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer(layer => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          mapRef.current.removeLayer(layer);
        }
      });
  
      if (animPath && animPath.length > 0) {
        createPolylineLeaflet(animPath, mapRef.current);
      } else if (playbackData && playbackData.playBackList) {
        createPolyline(playbackData.playBackList, mapRef.current);
      }
    }
  }, [playbackData, trailsData, animPath]);
  
  

  const createPolylineLeaflet = (animPath, map) => {
    if (!animPath || animPath.length === 0) return;
    const svgPathD = truck;
  
    const latlngs = animPath.map(({ lat, lng }) => [lat, lng]);
    if (latlngs.length > 0) {
      const latestData = trailsData[0];
  
      const movingTruckIcon = L.divIcon({
        className: 'custom-icon', 
        html: `
        <svg style="transform: rotate(${rotation}deg);display:flex;align-item:center; transform-origin: center;margin:7px " xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 30 30" fill="#008019">
          <path d="${svgPathD}" fill="#008019" fill-opacity="1.9" width="40" height="40"/>
        </svg>`,
        iconSize: [50, 50],
        iconAnchor: [30, 30]
      });
      
  
      const marker = L.marker(latlngs[0], { icon: movingTruckIcon }).addTo(map);
  
      const popupContent = document.createElement('div');
      const updatePopupContent = () => {
        popupContent.innerHTML = `
          <div style="width:300px; padding:10px;padding-right: 0px;">
            <div style="display: flex; flex-direction: row; justify-content:space-between;font-weight: 400;font-size: 0.875rem;line-height: 1.43;">
              <div>${latestData.startDate}</div>
              <div style="background-color: ${latestData?.vehicleStatus === '0' ? '#D32F2F' : latestData?.vehicleStatus === '1' ? '#27AE60' : ''}; padding: 3px 6px; border-radius: 12px;">
                ${latestData.vehicleStatus === '1' ? 'Moving' : 'Default'}
              </div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content:space-between;font-weight: 400;font-size: 0.75rem;line-height: 1.66;">
              <div style="margin-right: 10px;">
                <div>SOC</div>
                <div style="font-weight: 400;font-size: 0.875rem;line-height: 1.43;"> ${latestData.soc}</div>
              </div>
              <div style="margin-right: 10px;">
                <div>Odometer</div>
                <div style="font-weight: 400;font-size: 0.875rem;line-height: 1.43;">${latestData.odometer}</div>
              </div>
              <div style="margin-right: 10px;">
                <div>Speed</div>
                <div style="font-weight: 400;font-size: 0.875rem;line-height: 1.43;">${latestData.speed}</div>
              </div>
            </div>
          </div>
        `;
      };
  
      updatePopupContent();
      marker.bindPopup(popupContent, { autoClose: false });
  
      const polyline = L.polyline(latlngs, { color: 'black', weight: 3 }).addTo(map);
      const updateMarkerPosition = () => {
        const latestLatLng = latlngs[latlngs.length - 1];
        marker.setLatLng(latestLatLng);
        updatePopupContent();
      };
      updateMarkerPosition();
      polyline.on('add', updateMarkerPosition);
    }
  };
  
  

  const createPolyline = (list, map) => {
    const latlngs = [];
    list.forEach(item => {
      if (item.latlng) {
        const [lat, lng] = item.latlng.split(',').map(coord => parseFloat(coord));
        latlngs.push([lat, lng]);
      }
      if (item.playBackList) {
        createPolyline(item.playBackList, map);
      }
    });
    if (latlngs.length > 0) {
      L.polyline(latlngs, { color: 'black', weight: 3 }).addTo(map);
      if (latlngs.length > 0) {
        const [firstLat, firstLng] = latlngs[0];
        const [lastLat, lastLng] = latlngs[latlngs.length - 1];
        const greenFirst = L.icon({
          iconUrl: startMarker,
          iconSize: [40, 40],
          iconAnchor: [20, 20]
        });
        const greenLast = L.icon({
          iconUrl: endMarker,
          iconSize: [40, 40],
          iconAnchor: [20, 20]
        });
        L.marker([firstLat, firstLng], { icon: greenFirst }).addTo(map);
        L.marker([lastLat, lastLng], { icon: greenLast }).addTo(map);
      }
    }
  };

  return (
    <Box id="map" sx={{ width: '100%', height: '100%', zIndex: "1" }}></Box>
  );
}

export default LeafletPlayback;
