import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, CardHeader, Grid, Paper } from "@mui/material";
import customerService from "../../services/customerService";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormLabel, Button, Typography, InputLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectWithSearch from "../shared/SelectWithSearch";
import { ORG_LIST_DETAIL } from "../../constants/orgFormFields";
import AdminSelectInput from "../shared/AdminSelectInput";
import DraggableDialog from "../shared/DailogDrag";
import customerKYCServices from "../../services/customerKYCService";
import KycDropDown from "./KycLogDropdown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import KYCUpload from "./KYCImport";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import logServices from "../../services/logService";
import KycLogDropdown from "../LogDropdown/KYCDropdown";
import { useListKYCFeedsMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const ListKycFeed = ({ tabValue }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [kycImport, setKycImport] = useState(false);
  

  const theme = useTheme();
  // useEffect(async () => {
  //   await fetchKYCFeedDetails();
  // }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Portal Received Time",
        accessor: "createdDate",
      },
      {
        Header: "KYC Sent Time",
        accessor: "kycSentTime",
        width:180,
      },
      {
        Header: "KYC Captured Time",
        accessor: "kyccapturedTime",
        width:180,
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "VIN",
            accessor: "vin",
          },
          {
            Header: "Engine Number",
            accessor: "engineNumber",
          },
          {
            Header: "Year Of MFG",
            accessor: "yearOfmg",
          },
          {
            Header: "Vehicle Make",
            accessor: "vehicleMake",
          },
          {
            Header: "Vehicle Model",
            accessor: "vehicleModel",
          },
          // {
          //   Header: "Fuel Type",
          //   accessor: "fuelType",
          // },
        ],
      },
      {
        Header: "Customer Details",
        columns: [
          {
            Header: "Customer ID",
            accessor: "customerID",
          },
          {
            Header: "Name",
            accessor: "customerName",
          },
          {
            Header: "Mobile",
            accessor: "customerMobile1",
          },
          {
            Header: "Retail Invoice Number",
            accessor: "retailInvoiceNumber",
          },
          {
            Header: "Email",
            accessor: "customerEmail",
          },
          {
            Header: "Address",
            accessor: "customerAddress",
          },
          {
            Header: "KYC Proof1",
            accessor: "proofOfAddress",
          },
          {
            Header: "KYC Proof2 (Optional)",
            accessor: "proofOfIdentity",
          },
        ],
      },
      {
        Header: "Dealer Details",
        columns: [
          {
            Header: "Code",
            accessor: "dealerCode",
          },
          {
            Header: "Name",
            accessor: "dealerName",
          },
          {
            Header: "Address",
            accessor: "dealerAddress",
          },
          {
            Header: "Town",
            accessor: "dealerTown",
          },
          {
            Header: "Contact name",
            accessor: "dealerContactName",
          },
          {
            Header: "Mobile",
            accessor: "dealerContactNumber",
          },
        ],
      },

      {
        Header: "KYC Mode",
        accessor: "kycType",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Message",
        accessor: "errorMessage",
      },
    ],
    []
  );

  //   const onView = (itemViewed) => {
  //     onViewBat(itemViewed.batteryId);
  //   };

  // const callbackLoadFn = async (request1) => {
  //   var request = {};

  //   request.startDate = request1.defaultFrom;
  //   request.endDate = request1.defaultTo;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = request1.defaultSearch;
  //   request.vinVal = request1.defaultVin;
  //   request.retailVal = request1.defaultRetailVal;
  //   request.state = request1.defaultStatus;

  //   const responseData = (
  //     await customerKYCServices.getGridViewForKYCFeed(request)
  //   ).data.data;
  //   setResponseData(responseData.content);

  //   var request = {};
  //   request.message = "Logs > KYCLog >KYCFeed";
  //   request.action = "View";
  //   request.featureName = "KYCLog";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName = globalUserName;

  //   const responseData1 = await logServices.saveAuditLogDetails(request);

  //   console.log(responseData.content);
  // };

  // const handleSubmit = async (selectSearch,selectedVin, selectedStatus,startDate,endDate,selectedRetailVal) => {
  //   var request = {};
  //   request.startDate = startDate;
  //   request.endDate = endDate;
  //   request.search = "";
  //   request.page = 0;
  //   request.size = 5;
  //   request.sortBy = "desc";
  //   request.selectedType = selectSearch;
  //   request.vinVal = selectedVin;
  //   request.state = selectedStatus;
  //   request.retailVal=selectedRetailVal;
  //   setPageStatus(request);
  //   try {
  //     const responseData = (await customerKYCServices.getGridViewForKYCFeed(request)).data.data;
  //     setResponseData(responseData.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  const handleOnClick = () => {
    setKycImport(true);
  };
  //   const callbackChangePage = async (newPage, rowsPerPage) => {
  //     setNewPage(newPage);
  //     setRowsPerPage(rowsPerPage);
  //     var request = {};

  //     request.batteryId = 2;
  //     request.page = newPage;
  //     request.sortBy = "desc";
  //     request.batteryModel = "All";
  //     request.batteryType = "All";
  //     request.size = rowsPerPage;

  //     const responseData = (await BatteryServices.getGridViewForBat(request)).data
  //       .data;
  //     setResponseData([]);
  //     setResponseData(responseData.content);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.totalElements);
  //     setTotalPages(responseData.totalPages);
  //   };

  const [
    listKYCFeeds,
    { data: ListKycFeed, isLoading: ListKycLoading, isSuccess: ListKycSuccess },
  ] = useListKYCFeedsMutation();
  let commonPayload = {};
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    console.log("data", data);
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortBy = "desc";
    commonPayload.selectedType = data.searchType;
    commonPayload.vinVal = 0;
    commonPayload.state = data.statusId;
    commonPayload.retailVal = data.searchType;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.resellerId = resellerId;
    setPayload(commonPayload);
    listKYCFeeds(commonPayload);
  };

  useEffect(() => {
    if (ListKycSuccess) {
      setResponseData([]);
      if (ListKycFeed.data !== null) {
        setResponseData(ListKycFeed.data.content);
        setTotalElements(ListKycFeed.data.totalElements);
        setTotalPages(ListKycFeed.data.totalPages);
      }

      //console.log(oversppedData.data.totalElements);
    }

    auditPayload.message = "Reports > KYC Log > KYC Feeds";
    auditPayload.action = "View";
    auditPayload.featureName = "KYC Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, [ListKycSuccess]);

  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(50);
    payload.search = searchText;
    if (payload.search.length >= 3) {
      listKYCFeeds(payload);
    } else if (payload.search.length === 0) {
      listKYCFeeds(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    listKYCFeeds(payload);
  };

  return (
    <Box>
      {/* <KycDropDown tabValue={tabValue} callbackLoadFn={callbackLoadFn} handleSmt={handleSubmit}/> */}
      <KycLogDropdown
        getData={getData}
        isShowAll={false}
        showCalendar={false}
        tabValue={tabValue}
      />
      <StyledCardShadow sx={{ my: 2 }}>
        {/* <CardHeader 
            title={<StyledTableTitle>KYC Feeds</StyledTableTitle>}
            action={<Button onClick={handleOnClick} size="small" variant="contained">Import KYC</Button>}
          /> */}
        <CardContent>
          {kycImport ? (
            <KYCUpload />
          ) : (
            <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
              <DataTable
                isLoading={ListKycLoading}
                includeSearchBox={true}
                handleChange={handleSearch}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
                includeExportButton={false}
              />
            </Box>
          )}
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default ListKycFeed;
