import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardContent, Typography } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { StyledCardShadow, StyledPageLayout,StyledToggleButton, StyledToggleButtonGroup } from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import { Button } from "@mui/material";
import logServices from "../../services/logService";

import {
  useAuditLogMutation,
  useChargingMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";

const text =
"This report shows the instances of vehicles when temperature rise is greater than 25 degree celsius .";

const text1 =
"This report shows the instances of vehicles when maximum temperature is greater than 30 degree celsius .";


const ChargingTempBehaviour = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId; 

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  const [hideDetails, setHidedetails] = useState("tempRise");
  const [alignment, setAlignment] = React.useState("tempRise");
  let commonPayload = {};
  const handleAlignment = (event, newAlignment) => {
    if(newAlignment !== null) {
      setAlignment(newAlignment);
      setHidedetails(newAlignment);
      setResponseData([]);
    if(newAlignment === 'maxTemp'){
      payload.maxTemp = 30;
      payload.tempRise  = 0;
   
      getOverSpeed(payload);
      setPayload(payload);
    }else {
      payload.maxTemp = 0;
      payload.tempRise  = 25;
      getOverSpeed(payload);
      setPayload(payload);
    }

    //Service Call
    // 
    //   setTimeout(() => {
    //     
    //   },0)
      
    }

  };
  const theme = useTheme();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > Charging";
    auditPayload.action = "View";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);


 
  
  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },
      // {
      //   Header: "Customer Name",
      //   accessor: "customerName",
      //   width:120,
      // },
      {
        Header: "GPS Location",
        accessor: "location",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
              <Box
                className="addresslink"
                id={row.original.location}
                sx={{ display: "flex", alignItems: "center" }}
              >
                  
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.location}
                  ref={getAddressBtn}
                  size="small"
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.location}
                >
                  {row.original.location}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.location}
                  key={row.original.location}
                ></a>
              </Box>
    
          );
        },
      },
    
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: "Threshold Temperature Rise (ºC)",
        accessor: "tempRiseThreshold",
       
      },
      {
        Header: "Temperature Rise",
        accessor: "tempRise",
      },
     
      {
        Header: "Start SOC (%)",
        accessor: "sstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "sendsoc",
      }
    ],
    []
  );

  
  const columns1 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },
      // {
      //   Header: "Customer Name",
      //   accessor: "customerName",
      //   width:120,
      // },
      {
        Header: "GPS Location",
        accessor: "location",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
              <Box
                className="addresslink"
                id={row.original.location}
                sx={{ display: "flex", alignItems: "center" }}
              >
                  
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.location}
                  ref={getAddressBtn}
                  size="small"
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.location}
                >
                  {row.original.location}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.location}
                  key={row.original.location}
                ></a>
              </Box>
    
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: "Threshold Max Battery Temp (ºC)",
        
        accessor: "maxBttTempThreshold",
       
      },
      {
        Header: "Max Battery Temp (ºC)",
        accessor: "smaxBtTemp",
      },
    
     
      {
        Header: "Start SOC (%)",
        accessor: "sstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "sendsoc",
      }
    ],
    []
  );

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
    arr.push("chargingType");
    // arr.push("zoneName");
    // arr.push("customerName");
    initialState1.hiddenColumns = arr;
   
  }

  

  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useChargingMutation();

  // const [exportOpt, setExportOpt] = useState(false);

  const onSubmitForm = (data) => {

    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.chargingType = data.chargeType
    

    if(hideDetails === 'maxTemp'){
      commonPayload.maxTemp = 30;
      commonPayload.tempRise  = 0;
    }else{
      commonPayload.maxTemp = 0;
      commonPayload.tempRise  = 25;
    }

    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Charging Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ChargingReport/chargingreportXLS.csv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId +
        "&chargingType=" +
        payload.chargingType +
        "&maxTemp=" +
        payload.maxTemp +
        "&tempRise=" +
        payload.tempRise 
        ;
     
      window.open(url);

      auditPayload.message = "Reports > Charging > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ChargingReport/ChargingreportPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId +
        "&chargingType=" +
        payload.chargingType +
        "&maxTemp=" +
        payload.maxTemp +
        "&tempRise =" +
        payload.tempRise  ;

      window.open(url);

      auditPayload.message = "Reports > Charging > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    if (payload.search.length >= 3) {
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      getOverSpeed(payload);
    }

  };
  
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);



  return (
    <StyledPageLayout sx={{ p: 0}}>
      
      <MouseOverPopover text={hideDetails === 'tempRise' ?  text :text1} title={"Charging Report"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {7} allowedMonth = {resellerId === 110224 ? 6 : 3} time = {true}  thisMonth={true} currentMonth={true} chargeTypeShow={resellerId === 110224 ? true : false} />
        <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            size="small"
            sx={{ mt: 2 }}
          >
            <StyledToggleButton value={"tempRise"}>
            Temperature Rise
            </StyledToggleButton>
            
            <StyledToggleButton value={"maxTemp"} >Max Temperature</StyledToggleButton> 
        
          </StyledToggleButtonGroup>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
            payload={payload}
            exportWithAll={false}
              isLoading={isLoading}
                columns={hideDetails === 'tempRise' ? columns:columns1}
  
              initialState={initialState1}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              includeExportButton={true}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ChargingTempBehaviour;
