import React, { Fragment, useEffect, useState } from "react";
import { CREATE_GEOZONE } from "../../constants/GeozoneFormFields";
import TextInput from "../shared/TextInput";
import SelectInput from "../shared/SelectInput";
import { Grid, Box, Button, MenuItem } from "@mui/material";
import { TextField, FormLabel, FormControl } from "@mui/material";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { SoftButton, StyledDropDown, StyledFiterButton, StyledFormControl, StyledFormControlLabel, StyledTextField } from "../../theme/StyledEle";
import { Stack } from "@mui/system";

const CreateZone = ({
  handleCancel,
  dataObjZone,
  dataObjRadius,
  zoneName,
  zoneCategory,
  handleSave,
  isEdit,
  handleZoneType,
  zoneModel,
  handleZoneName,
  handleZoneCategory,
  zoneRadius,
  handleZoneRadius,
  selectedData,
  resellerId
}) => {
  const theme = useTheme();
  // const changefn = (value, name) => {
  //   changefnZone(value, name);
  // };

  // const FormFields = ({ fields }) => {
  //   return (
  //     <>
  //       {fields.map((field, index) => {
  //         if (field.type === "text") {
  //           return (
  //             <TextInput
  //               defaultValue={zoneName}
  //               label={field.label}
  //               name={field.name}
  //               key={index}
  //               callbackfnStatus={true}
  //               textInputCallBackFn={changefn}
  //               size="small"
  //               autoComplete='off'
  //             />
  //           );
  //         } else if (field.type === "select") {
  //           return (
  //             <SelectInput
  //               name={field.name}
  //               label={field.label}
  //               options={field.options}
  //               key={index}
  //               defaultValue={
  //                 field.name === "zoneType"
  //                   ? dataObjZone
  //                   : field.name === "zoneRadius"
  //                   ? dataObjRadius
  //                   : field.options[0].value
  //               }
  //               callbackfnStatus={true}
  //               callBackFn={changefn}
  //               size="small"
  //             />
  //           );
  //         }
  //       })}
  //     </>
  //   );
  // };

  

  return (
    <Stack direction='row' spacing={1} alignItems='end'>
      {/* <FormFields fields={CREATE_GEOZONE} /> */}
      <Stack direction={'row'} spacing={1}>
          <StyledFormControl fullWidth >
              <StyledFormControlLabel required>Zone Type</StyledFormControlLabel>
              <StyledDropDown size="small" value={zoneModel} onChange={(event, value) => handleZoneType(value)} disabled={isEdit}>
                  <MenuItem value={'Circles'}>Circle</MenuItem>
                  <MenuItem value={'Polygons'}>Polygon</MenuItem>
              </StyledDropDown>
          </StyledFormControl>
          
        {resellerId === 110224 && (
          <StyledFormControl fullWidth >
          <StyledFormControlLabel required>Zone Category</StyledFormControlLabel>
              <StyledDropDown  size="small"  value={selectedData} onChange={(event) => handleZoneCategory(event.target.value)} >
              {Array.isArray(zoneCategory) && zoneCategory.map((category, index) => (
                    <MenuItem key={index} value={category}>{category}</MenuItem>
                  ))}
              </StyledDropDown>
          </StyledFormControl>
        )}
          <StyledFormControl fullWidth >
              <StyledFormControlLabel required>Zone Name</StyledFormControlLabel>
              <StyledTextField 
                size="small"
                fullWidth
                onChange={(event) => handleZoneName(event.target.value)}
                value={zoneName}

              />
          </StyledFormControl>
          {
            zoneModel === 'Circles' && 
            <StyledFormControl fullWidth>
              <StyledFormControlLabel required >Radius</StyledFormControlLabel>
              <StyledDropDown size="small" value={zoneRadius}  onChange={(event, value) => handleZoneRadius(value)}>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={300}>300</MenuItem>
                  <MenuItem value={400}>400</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={600}>600</MenuItem> 
                  <MenuItem value={700}>700</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                  <MenuItem value={1500}>1500</MenuItem>
                  <MenuItem value={2000}>2000</MenuItem>
              </StyledDropDown>
          </StyledFormControl>
          }
          
      </Stack>

      <input type="hidden" name="savetype" value={isEdit} />
        <SoftButton
          type="submit"
          variant="contained"
          onClick={() => handleSave(isEdit ? 'edit' : 'save')}
          size="small"
          className="success"
        >
          {isEdit ? 'Update' : 'Save'}
        </SoftButton>

      <SoftButton
        onClick={handleCancel}
        variant="contained"
        size="small"
        className="error"
      >
        Cancel
      </SoftButton>
    </Stack>
  );
};
export default CreateZone;
