import React, { useState, useEffect } from "react";
import dropdownServices from "../../services/dropdownServices";

import DropDown from "../shared/DropDown";

import "../../components/vehicle/vehicle.css";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";

const CommonDropDown = ({ onSubmit }) => {
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;

  const [customers, setCustomers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true; //TODO: add
  const [orgListJSON, setOrgListJSON] = useState([]);

  const [seltdResellerId, setSeltdResellerId] = useState("0");
  const [seltdCustomerId, setSeltdCustomerId] = useState("-1");
  const [seltdFleetId, setSeltdFleetId] = useState("-1");

  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };
  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
  }

  useEffect(async () => {
    setFleet(getOptions);

    const resellerPayload = {
      value: "reseller",
      orgType: "2",
      resellerId: globalOrgId,
      dealerId: 0,
      roleId: globalRoleId,
      id: globalOrgId,
      searchText: "",
    };
    const { data: resellerResponseNew } = await dropdownServices.getResellers(
      resellerPayload
    );
    const { content: orgnListJSON } = resellerResponseNew.data;

    setOrgListJSON(orgnListJSON);

    setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");

    const customersListPayload = {
      value: "customer",
      resellerId: orgnListJSON[0].key,
      dealerId: 0,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const customerAll = getOptions(customersList);
    setCustomers([]);
    setCustomers(customerAll);
    setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

    //callbackLoadFn(selectedVehicleId,orgnListJSON.length>0?orgnListJSON[0].key:"-1");
  }, []);

  const handleResellerSelect = async (resellerId) => {
    setSeltdResellerId(resellerId);

    const customersListPayload = {
      value: "customer",
      orgType: "3",
      id: userData.userOrgType === 8 ? resellerId : globalResellerId,
      resellerId: userData.userOrgType === 8 ? resellerId : globalResellerId,
      dealerId: 0,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    const customerAll = getOptions(customersList);
    setCustomers(customerAll);
    setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
  };

  const handleCustomerSelect = async (customerId) => {
    if (customerId !== -1) {
      selectedCustomerId = customerId;
      setSeltdCustomerId(customerId);

      const fleetListPayload = {
        value: "fleet",
        customerId: customerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;

      const fleets = getOptions(fleetListResponse);

      setFleet(fleets);

      setSeltdFleetId(-1);
    } else {
      setFleet([]);
      setFleet(getOptions);
      setSeltdFleetId(-1);
    }

    handleFleetSelect(-1);
  };

  const handleFleetSelect = async (fleetId) => {
    setSeltdFleetId(fleetId);
    selectedFleetId = fleetId;
  };

  const handleCustomerSearch = async (searchText) => {
    if (searchText.length > 2) {
      const customersListPayload = {
        value: "customer",
        resellerId: seltdResellerId,
        dealerId: 0,
        searchText,
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(getOptions(customersList));
    }
  };

  const handleFleetSearch = async (searchText) => {
    if (searchText.length > 2) {
      const fleetListPayload = {
        value: "fleet",
        resellerId: selectedResellerId,
        dealerId: 0,
        customerId: seltdCustomerId,
        searchText: searchText,
      };
      const { data: fleetListResponse } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetList } = fleetListResponse.data;
      setFleet(getOptions(fleetList));
    }
  };

  const handleResellerSearch = async (searchText) => {
    if (searchText.length > 2) {
      const resellersListPayload = {
        value: "reseller",
        orgType: userData.userOrgType,
        roleId: userData.roleId,
        id: userData.resellerId,
        searchText,
      };
      const { data: responseBody } = await dropdownServices.getResellers(
        resellersListPayload
      );
      const { content: resellersList } = responseBody.data;
      setOrgListJSON(resellersList);
    }
  };

  const newHandleSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      reseller: seltdResellerId,
      customer: seltdCustomerId,
      fleet: seltdFleetId,
    });
  };

  return (
    <form>
      <StyledPaperShadow elevation={0}>
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={true}
          fieldName="Organization"
        />
        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={
            globalOrgType === 8 ||
            globalOrgType === 2 ||
            globalOrgType === 5 ||
            globalOrgType === 3
              ? true
              : false
          }
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Fleet"
          options={fleet}
          onChange={handleFleetSelect}
          optionsDisplayKey="value"
          isShow={
            globalOrgType === 8 ||
            globalOrgType === 2 ||
            globalOrgType === 5 ||
            globalOrgType === 3 ||
            globalOrgType === 1
              ? true
              : false
          }
          onSearch={handleFleetSearch}
        />
        <StyledFiterButton
          type="button"
          onClick={newHandleSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
