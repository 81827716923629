import { configureStore } from "@reduxjs/toolkit";
import ControlsSlice from "./slice/ControlsSlice";
import { dashboardApi } from "./slice/ApiSlice";

export const NewStore = configureStore({
    reducer:{
        controls:ControlsSlice,
        [dashboardApi.reducerPath] : dashboardApi.reducer
    },
    middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(dashboardApi.middleware)
})