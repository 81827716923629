import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, useTheme } from '@mui/material';
import { MovingTruck, ChargingTruck, IdleTruck, OfflineTruck, StopTruck, ChargingDotIcon, GreenFirst, GreenLast, MovingDotIcon, OfflineDotIcon, StopDotIcon } from '../../Constans';
import { theme } from 'antd';
import { FaParking, FaRoad } from 'react-icons/fa';
import { FaPlugCircleBolt } from 'react-icons/fa6';
import { MdEnergySavingsLeaf, MdSignalWifiBad } from 'react-icons/md';
import reportServices from '../../../services/reportServices';

const LeafletMap = (props) => {
  const mapRef = useRef(null);
  const theme = useTheme();
  const { data, vehicleTrails, mapzoom, mapType, imgData } = props;
  const [useVehicleData, setUseVehicleData] = useState(true);
  const [initialCoords, setInitialCoords] = useState([23.5120, 80.3290]);
  const [zoomLevel, setZoomLevel] = useState(5);
  let [address, setAddress] = useState(null);
  console.log("address", address)
  let [selectMarker, setSelectMarker] = useState(null);
  console.log("selectMarker", selectMarker)

  useEffect(() => {
    if (vehicleTrails !== null) {
      setUseVehicleData(false);
    }
    else if (vehicleTrails === null) {
      setUseVehicleData(true);
    }
  }, [vehicleTrails]);


  useEffect(() => {
    if (selectMarker !== null) {
      setAddress(null);
      var requestN = {};
      requestN.latLng = selectMarker;
      return reportServices.getGeocodeAddress(requestN)
        .then((resN) => {
          setAddress(resN.data);
        })
        .catch((err1) => {
          console.log(err1);
        });
    }
  }, [selectMarker])

  useEffect(() => {
    if (vehicleTrails && vehicleTrails.vehicleTrails.length > 0) {
      const firstVehicle = vehicleTrails.vehicleTrails[0];
      setInitialCoords([firstVehicle.lat, firstVehicle.lng]);
      setZoomLevel(16);
    } else {
      setInitialCoords([23.5120, 80.3290]);
      setZoomLevel(5);
    }
  }, [vehicleTrails]);

  useEffect(() => {
    // Update zoom level when mapzoom prop changes
    if (mapzoom !== undefined && mapzoom !== null && mapzoom >= 3 && mapzoom <= 18) {
      setZoomLevel(mapzoom);
    }
  }, [mapzoom]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(initialCoords, zoomLevel);
    }
  }, [initialCoords, zoomLevel]);

  useEffect(() => {
    if ((data && data.vehicles) || vehicleTrails !== null) {
      if (!mapRef.current) {
        const map = L.map('map', {
          center: initialCoords,
          zoom: zoomLevel,
          zoomControl: false
        });

        mapRef.current = map;
      }

      let tileLayerUrl = '';
      switch (mapType) {
        case 'roadmap':
          tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
          break;
        case 'terrain':
          tileLayerUrl = 'https://tile.openstreetmap.de/{z}/{x}/{y}.png';
          break;
        case 'satellite':
          tileLayerUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';
          break;
        default:
          tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
          break;
      }

      console.log("Selected tile layer URL:", tileLayerUrl);

      if (mapRef.current && tileLayerUrl) {
        // Clear existing tile layer
        if (mapRef.current.tileLayer) {
          mapRef.current.removeLayer(mapRef.current.tileLayer);
        }

        // Add new tile layer
        const tileLayer = L.tileLayer(tileLayerUrl, {}).addTo(mapRef.current);
        mapRef.current.tileLayer = tileLayer;
      }
    }
  }, [data, vehicleTrails, mapType]);



  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer(layer => {
        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          mapRef.current.removeLayer(layer);
        }
      });

      if (useVehicleData && data && data.vehicles && vehicleTrails === null) {
        // Add markers based on data.vehicles
        data.vehicles.forEach(vehicle => {
          const { lat, lng, vehicleNo, lastConnected, vehicleStatus, odometer, soc, gpsSpeed } = vehicle;
          const popupContent = generatePopupContent(vehicle);
          const customIcon = getCustomIcon(vehicleStatus);
          const marker = L.marker([lat, lng], { icon: customIcon }).addTo(mapRef.current)
            .bindPopup(popupContent, { autoClose: false });

          marker.on('click', () => {
            const selectedLatLng = `${lat},${lng}`;
            setSelectMarker(selectedLatLng);
          });
        });
      } else if (!useVehicleData && vehicleTrails !== null) {
        // Add marker with custom icon and draw polyline when vehicleTrails are provided
        vehicleTrails.vehicleTrails.forEach((vehicle, index) => {
          const { lat, lng, name, dispDate, vehicleStatus, odometer, soc, globalAddress, speed } = vehicle;

          const popupContent = PopupContentData(vehicle);
          const customIcon = getCustomDotIcon(vehicleStatus, index);
          const marker = L.marker([lat, lng], { icon: customIcon }).addTo(mapRef.current)
            .bindPopup(popupContent, { autoClose: false });
          marker.on('click', () => {
            const selectedLatLng = `${lat},${lng}`;
            setSelectMarker(selectedLatLng);
          });
          if (index === 0) {
            marker.openPopup();
          }
          // Draw polyline if there are more than one point
          if (index > 0) {
            const prevVehicle = vehicleTrails.vehicleTrails[index - 1];
            const prevLat = prevVehicle.lat;
            const prevLng = prevVehicle.lng;
            const polylinePoints = [
              [prevLat, prevLng],
              [lat, lng]
            ];
            const polyline = L.polyline(polylinePoints, { color: 'green', weight: 3 }).addTo(mapRef.current);
          }
        });
      }
    }
  }, [data, vehicleTrails, useVehicleData, address]);

  const generatePopupContent = (vehicle) => {
    const { vehicleNo, lastConnected, vehicleStatus, odometer, soc, gpsSpeed } = vehicle;
    return `
      <div style="opacity:0.9;background-color:#fcfbf9;min-width:330px">
        <div style="padding:5px 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;background-color: ${vehicleStatus === 'Stop' ? "#DF4448" : vehicleStatus === 'Charging' ? "#f77b3a" : vehicleStatus === 'Offline' ? "#707070" : vehicleStatus === 'Moving' ? "#18b571" : vehicleStatus === 'Breakdown' ? "#7d7940" : vehicleStatus === 'Accident' ? "#0087cd" : ""};">
          <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Vehicle No</div>
          <div style="border:1px solid white;position:absolute;border-radius: 26px;margin-left: 250px;margin-top: 10px;padding:10px;background-color:${vehicleStatus === 'Stop' ? "#DF4448" : vehicleStatus === 'Charging' ? "#f76d25" : vehicleStatus === 'Offline' ? "#707070" : vehicleStatus === 'Moving' ? "#18b571" : vehicleStatus === 'Breakdown' ? "#7d7940" : vehicleStatus === 'Accident' ? "#0087cd" : ""}">
            <i class="${vehicleStatus === 'Moving' ? 'fa-solid fa-road' : vehicleStatus === 'Offline' ? 'fa-regular fa-wifi-slash ' : vehicleStatus === 'Stop' ? 'fa-solid fa-square-parking fa-xl' : vehicleStatus === 'Charging' ? 'fa-solid fa-plug-circle-bolt' : ''}" style="color: white"></i>
          </div>
          <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${vehicleNo}</div>
        </div>
        <div style="margin:10px">
          <div style="padding:5px 1px">
            <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Date</div>
            <div>${lastConnected}</div>
          </div>
          <div style="padding:5px 1px">
            <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Vehicle Status</div>
            <div>${vehicleStatus}</div>
          </div>
          <div style="display: flex; flex-direction: row;padding:5px 1px; justify-content:space-between;font-weight: 400;font-size: 0.875rem;line-height: 1.43;">
            <div>
              <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Odometer </div>
              <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${odometer}</div>
            </div>
            <div>
              <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">SOC</div>
              <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${soc}</div>
            </div>
            <div>
              <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Speed</div>
              <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${gpsSpeed}</div>
            </div>
          </div>
          <div  style="padding:5px 1px">
            <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">address</div>
            <div>${address !== null ? address : "-"}</div>
          </div>
        </div>
      </div>`;
  };
  const PopupContentData = (vehicle) => {
    const { lat, lng, name, dispDate, vehicleStatus, odometer, soc, globalAddress, speed } = vehicle;
    return ` <div style="opacity:0.9;background-color:#fcfbf9;min-width:330px">
    <div style="padding:5px 10px;border-top-left-radius: 10px;border-top-right-radius: 10px;background-color: ${vehicleStatus === 'Stop' ? "#DF4448" : vehicleStatus === 'Charging' ? "#f77b3a" : vehicleStatus === 'Offline' ? "#707070" : vehicleStatus === 'Moving' ? "#18b571" : vehicleStatus === 'Breakdown' ? "#7d7940" : vehicleStatus === 'Accident' ? "#0087cd" : ""};"> 
      <div style="border:1px solid white;position:absolute;border-radius: 26px;margin-left: 250px;margin-top: 30px;padding:10px;background-color:${vehicleStatus === 'Stop' ? "#DF4448" : vehicleStatus === 'Charging' ? "#f76d25" : vehicleStatus === 'Offline' ? "#707070" : vehicleStatus === 'Moving' ? "#18b571" : vehicleStatus === 'Breakdown' ? "#7d7940" : vehicleStatus === 'Accident' ? "#0087cd" : ""}">
        <i class="${vehicleStatus === 'Moving' ? 'fa-solid fa-road ' : vehicleStatus === 'Offline' ? 'fa-regular fa-wifi-slash ' : vehicleStatus === 'Stop' ? 'fa-solid fa-square-parking ' : vehicleStatus === 'Charging' ? 'fa-solid fa-plug-circle-bolt' : ''}" style="color: white"></i>
      </div>  
      <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;color:#ffffff"> Vehicle No</div>
      <div style="font-weight: 500;font-size: 0.875rem;line-height: 1.57;color:#ffffff">${name}</div>
    </div>
    <div style="margin:10px">
      <div style="padding:5px 1px">
        <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Date</div>
        <div>${dispDate}</div>
      </div>
      <div style="padding:5px 1px">
        <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Vehicle Status</div>
        <div>${vehicleStatus}</div>
      </div>
      <div style="display: flex; flex-direction: row;padding:5px 1px; justify-content:space-between;font-weight: 400;font-size: 0.875rem;line-height: 1.43;">
        <div>
          <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Odometer </div>
          <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${odometer}</div>
        </div>
        <div>
          <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">SOC</div>
          <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${soc}</div>
        </div>
        <div>
          <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">Speed</div>
          <div style="font-weight: 600;font-size: 0.875rem;line-height: 1.57;"> ${speed}</div>
        </div>
      </div>
      <div  style="padding:5px 1px">
        <div style="font-weight: 400;font-size: 0.75rem;line-height: 1.66;">address</div>
        <div> ${address}  </div>
      </div>
    </div>
  </div>`;
  };
  // Function to get custom icon based on vehicle status
  const getCustomIcon = (vehicleStatus) => {
    let iconUrl = imgData?.moving !== "-" ? `https://${imgData?.moving}` : MovingTruck;
    switch (vehicleStatus) {
      case 'Charging':
        iconUrl = imgData?.charging !== "-" ? `https://${imgData?.charging}` : ChargingTruck;
        break;
      case 'Moving':
        iconUrl = imgData?.moving !== "-" ? `https://${imgData?.moving}` : MovingTruck;
        break;
      case 'Stop':
        iconUrl = imgData?.stop !== "-" ? `https://${imgData?.stop}` : StopTruck;
        break;
      case 'Offline':
        iconUrl = imgData?.offline !== "-" ? `https://${imgData?.offline}` : OfflineTruck;
        break;
      default:
        break;
    }
    return L.icon({
      iconUrl: iconUrl,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32]
    });
  };
  const getCustomDotIcon = (vehicleStatus, index) => {
    let iconUrl = MovingDotIcon;
    let iconSize = [10, 10];
    let iconAnchor = [8, 10];

    switch (vehicleStatus) {
      case 'Charging':
        iconUrl = ChargingDotIcon;
        break;
      case 'Moving':
        iconUrl = MovingDotIcon;
        break;
      case 'Offline':
        iconUrl = OfflineDotIcon;
        break;
      case 'Stop':
        iconUrl = StopDotIcon;
        break;
      default:
        break;
    }

    if (index === 0) {
      iconUrl = GreenFirst;
      iconSize = [40, 40];
      iconAnchor = [20, 20];
    }

    if (index === vehicleTrails.vehicleTrails.length - 1) {
      iconUrl = GreenLast;
      iconSize = [40, 40];
      iconAnchor = [20, 20];
    }

    return L.icon({
      iconUrl: iconUrl,
      iconSize: iconSize,
      iconAnchor: iconAnchor,
      popupAnchor: [0, -10]
    });
  };




  return (
    <Box id="map" sx={{ width: '100%', height: '100%', zIndex: "1" }}></Box>
  );
}

export default LeafletMap;