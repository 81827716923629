import React, { useState, useEffect, useMemo } from "react";
import dropdownServices from "../../services/dropdownServices";
import { Paper } from "@mui/material";
import DropDown from "../shared/DropDown";
import { useTheme } from "@mui/styles";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";

const CommonDropDown = ({ handleSmt, callbackLoadFn }) => {
  const theme = useTheme();
  const dropdownStyle = {
    // //boxShadow: theme.palette.shadow.boxShadow,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px",
  };
  var selectedResellerId = null;
  var selectedCustomerId = null;
  var selectedFleetId = null;
  var selectedRoleId = null;
  var selectedDealerId = null;
  var selectedAgencyId = null;
  var selectedOrgTypeId = null;
  var selectedSystemId = null;
  var dealerVal=0;
  var regionVal=0;

  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [agency, setAgency] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [system, setSystem] = useState([]);
  const [regions, setRegions] = useState([]);


  const userData = JSON.parse(localStorage.getItem("userData"));
  const shouldIncludeAllOption = true;
  const [orgTypeRes, setOrgTypeRes] = useState([]);
  const [orgListJSON, setOrgListJSON] = useState([]);

  const [seltdOrgTypeId, setSeltdOrgTypeId] = useState("-1");
  const [seltdAgencyId, setSeltdAgencyId] = useState("-1");
  const [seltdResellerId, setSeltdResellerId] = useState("-1");
  const [seltdCustomerId, setSeltdCustomerId] = useState("-1");
  const [seltdFleetId, setSeltdFleetId] = useState("-1");
  const [seltdRoleId, setSeltdRoleId] = useState("-1");
  const [seltdDealerId, setSeltdDealerId] = useState("-1");
  const [seltdSystemId, setSeltdSystemId] = useState("-1");
  const [seltdRegionId, setSeltdRegionId] = useState("-1");

  const [seltdLastOrgId, setSeltdLastOrgId] = useState("-1");

  const [isOrgType, setIsOrgType] = useState(true);
  const [isReseller, setIsReseller] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  const [isDealer, setIsDealer] = useState(true);
  const [isFleet, setIsFleet] = useState(true);
  const [isAgency, setIsAgency] = useState(true);
  const [isVendor, setIsVendor] = useState(true);
  const [isSystem, setIsSystem] = useState(true);

  const [isStatus, setIsStatus] = useState(false);

  const getResellerId = (listOfResellers) => {
    return listOfResellers[0].key;
  };
  const getOptions = (options) => {
    const allOption = {
      key: -1,
      value: "All",
    };
    return shouldIncludeAllOption ? [allOption, ...options] : options || [];
  };
  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalDisplayView = 0;
  var dealerVal = 0;
  var regionVal =0

  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globalDisplayView = globalJson.displayView;
  }

  useEffect(async () => {
    if (2 === parseInt(globalOrgType)) {  
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const orgTypePayload = {
        value: "orgType",

        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
        roleId: globalRoleId,
      };
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
        orgTypePayload
      );
      const { content: orgTypeResponse } = orgTypeResponseNew.data;
      setOrgTypeRes(orgTypeResponse);
      setSeltdOrgTypeId(orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "");
      selectedOrgTypeId =orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "";

    const regionListPayload = {
      value: "region",
      searchText:""
    };
    const { data: regionResponseBody } = await dropdownServices.getResellers(
      regionListPayload
    );
    const { content: regionList } = regionResponseBody.data;
    const _regions = getOptions(regionList);
    setRegions(_regions);
    setSeltdRegionId(_regions[0]?.key);

    const dealerListPayload = {
      value: "dealer",
      resellerId: globalResellerId,
      searchText: "",
      regionId: _regions[0]?.key,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(dealerListPayload);
    const { content: dealerList } = dealerListResponse.data;

    const _dealers = getOptions(dealerList);
    setDealers(_dealers);
    setSeltdDealerId(_dealers[0].key);
    selectedDealerId=_dealers[0].key;

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: globalDisplayView===true?_dealers[0].key:-1,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;

      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customerAll.length>0?customerAll[0].key:customerAll[0].key,
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdLastOrgId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdResellerId(globalResellerId);
      selectedResellerId = globalResellerId;
    } else if (8 === parseInt(globalOrgType) && 1 === parseInt(globalRoleId)) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const orgTypePayload = {
        value: "orgType",
        resellerId: 0,
        dealerId: 0,
        searchText: "",
      };
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
        orgTypePayload
      );
      const { content: orgTypeResponse } = orgTypeResponseNew.data;
      setOrgTypeRes(orgTypeResponse);
      setSeltdOrgTypeId(orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "");
      selectedOrgTypeId = orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "";

      const resellerPayload = {
        value: "reseller",
        orgType: "2",
        resellerId: globalResellerId,
        dealerId: 0,
        roleId: globalRoleId,
        id: globalResellerId,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;
      const resellerAll = getOptions(orgnListJSON);
      setOrgListJSON(resellerAll);
      selectedResellerId = resellerAll.length > 0 ? resellerAll[0].key : "-1";
      setSeltdResellerId(resellerAll.length > 0 ? resellerAll[0].key : "-1");

      const regionListPayload = {
        value: "region",
      };
      const { data: regionResponseBody } = await dropdownServices.getResellers(
        regionListPayload
      );
      const { content: regionList } = regionResponseBody.data;
      const _regions = getOptions(regionList);
      setRegions(_regions);
      setSeltdRegionId(_regions[0]?.key);

      const dealerListPayload = {
        value: "dealer",
        resellerId: globalResellerId,
        searchText: "",
        regionId:_regions[0]?.key
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealersList } = dealerListResponse.data;
      const _dealers=getOptions(dealersList);
      setDealers(_dealers);
      selectedDealerId = dealersList.length > 0 ? dealersList[0].key : "-1";
      setSeltdDealerId(dealersList.length > 0 ? dealersList[0].key : "-1");

      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
    } else if (1 === parseInt(globalOrgType)) {
      {
        setIsOrgType(false);
        setIsReseller(false);
        setIsCustomer(false);
        setIsFleet(false);
        setIsDealer(false);
        setIsAgency(false);
        setIsStatus(false);
        setIsVendor(false);
        setIsSystem(false);
      }
    } else if (3 === parseInt(globalOrgType)) {
      setIsOrgType(false);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const fleetListPayload = {
        value: "fleet",
        customerId: globalOrgId,
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdResellerId(globalResellerId);
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
      setSeltdLastOrgId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
    } else if (4 === parseInt(globalOrgType)) {
      setIsOrgType(false);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );
      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleet(fleetListResponse);
      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
    } else if (5 === parseInt(globalOrgType)) {
      setIsOrgType(false);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(true);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const agencyListPayload = {
        value: "agency",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: agencyListResponse } = await dropdownServices.getResellers(
        agencyListPayload
      );
      const { content: agencyList } = agencyListResponse.data;
      setAgency(agencyList);
      selectedAgencyId = agencyList.length > 0 ? agencyList[0].key : "-1";
      setSeltdAgencyId(agencyList.length > 0 ? agencyList[0].key : "-1");

      const customersListPayload = {
        value: "customer",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      const res1 = getOptions(customersList);
      setCustomers(res1);
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );
      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleet(fleetListResponse);
      const res2 = getOptions(fleetListResponse);
      setFleet(res2);
      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
      setSeltdLastOrgId(customersList.length > 0 ? customersList[0].key : "-1");
    } else if (7 === parseInt(globalOrgType)) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (8 === parseInt(globalOrgType)) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const orgTypePayload = {
        value: "orgType",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
        roleId: globalRoleId,
      };
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
        orgTypePayload
      );
      const { content: orgTypeResponse } = orgTypeResponseNew.data;
      setOrgTypeRes(orgTypeResponse);
      setSeltdOrgTypeId(
        orgTypeResponse.length > 0 ? orgTypeResponse[0].key : ""
      );

      const resellerPayload = {
        value: "reseller",
        orgType: "2",
        resellerId: globalResellerId,
        dealerId: 0,
        roleId: globalRoleId,
        id: globalResellerId,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;
      const resellerAll = getOptions(orgnListJSON);
      setOrgListJSON(resellerAll);
      selectedResellerId = resellerAll.length > 0 ? resellerAll[0].key : "-1";
      setSeltdResellerId(resellerAll.length > 0 ? resellerAll[0].key : "-1");

      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      const fleetAll = getOptions(fleetListResponse);
      setFleet(fleetAll);
      selectedFleetId = fleetAll.length > 0 ? fleetAll[0].key : "-1";
      setSeltdFleetId(fleetAll.length > 0 ? fleetAll[0].key : "-1");
    } else {
      setIsOrgType(false);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);

      const orgTypePayload = {
        value: "orgType",
        resellerId: 0,
        dealerId: 0,
        searchText: "",
      };
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
        orgTypePayload
      );
      const { content: orgTypeResponse } = orgTypeResponseNew.data;
      setOrgTypeRes(orgTypeResponse);
      setSeltdOrgTypeId(
        orgTypeResponse.length > 0 ? orgTypeResponse[0].key : ""
      );
      let objType = orgTypeResponse.length > 0 ? orgTypeResponse[0].key : "0";

      const resellerPayload = {
        value: "reseller",
        orgType: "2",
        resellerId: globalResellerId,
        dealerId: 0,
        roleId: globalRoleId,
        id: globalResellerId,
        searchText: "",
      };
      const { data: resellerResponseNew } = await dropdownServices.getResellers(
        resellerPayload
      );
      const { content: orgnListJSON } = resellerResponseNew.data;

      setOrgListJSON(orgnListJSON);
      selectedResellerId = orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1";
      setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");

      const dealerListPayload = {
        value: "dealer",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealersList } = dealerListResponse.data;
      setDealers(dealersList);
      const res3 = getOptions(dealersList);
      setDealers(res3);
      selectedDealerId = dealersList.length > 0 ? dealersList[0].key : "-1";
      setSeltdDealerId(dealersList.length > 0 ? dealersList[0].key : "-1");

      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      setCustomers(customersList);
      selectedCustomerId =
        customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(
        customersList.length > 0 ? customersList[0].key : "-1"
      );

      const fleetListPayload = {
        value: "fleet",
        customerId: customersList.length > 0 ? customersList[0].key : "",
        dealerId: 0,
        searchText: "",
      };

      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
      setFleet(fleetListResponse);
      selectedFleetId =
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(
        fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1"
      );
    }

    callbackLoadFn(selectedFleetId, selectedResellerId, selectedOrgTypeId,selectedCustomerId,dealerVal,regionVal);
  }, []);

  const handleOrgTypeSelect = async (orgType) => {
    selectedOrgTypeId=orgType;
   if (parseInt(orgType) === 2 && parseInt(globalOrgType) === 8 && parseInt(globalRoleId) === 1) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    }
    else if (parseInt(orgType) === 2) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (parseInt(orgType) === 3 && parseInt(globalOrgType) === 8 && parseInt(globalRoleId) === 1
    ) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (parseInt(orgType) === 3) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (
      parseInt(orgType) === 4 &&
      parseInt(globalOrgType) === 8 &&
      parseInt(globalRoleId) === 1
    ) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(true);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (parseInt(orgType) === 4) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(true);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (
      parseInt(orgType) === 5 &&
      parseInt(globalOrgType) === 8 &&
      parseInt(globalRoleId) === 1
    ) {     
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(true);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (parseInt(orgType) === 1) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (parseInt(orgType) === 5) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(true);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (
      parseInt(orgType) === 7 &&
      parseInt(globalOrgType) === 8 &&
      parseInt(globalRoleId) === 1
    ) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(true);
      setIsSystem(false);
    } else if (parseInt(orgType) === 7) {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(true);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    } else if (
      parseInt(orgType) === 8 &&
      parseInt(globalOrgType) === 8 &&
      parseInt(globalRoleId) === 1
    ) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(true);
    } else if (parseInt(orgType) === 8) {
      setIsOrgType(true);
      setIsReseller(false);
      setIsCustomer(false);
      setIsFleet(false);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(true);
    } else {
      setIsOrgType(true);
      setIsReseller(true);
      setIsCustomer(true);
      setIsFleet(true);
      setIsDealer(false);
      setIsAgency(false);
      setIsStatus(false);
      setIsVendor(false);
      setIsSystem(false);
    }

    setSeltdOrgTypeId(orgType);

    const resellerPayload = {
      value: "reseller",
      orgType: 2,
      dealerId: 0,
      roleId: globalRoleId,
      id: globalJson1.resellerId, 
      searchText: "",
    };
    const res=[];
    const { data: resellerResponseNew } = await dropdownServices.getResellers(resellerPayload);
    const { content: orgnListJSON } = resellerResponseNew.data;
    if(globalOrgType===8)
    {
       res = getOptions(orgnListJSON);
      setOrgListJSON(res);
      setSeltdResellerId(res.length > 0 ? res[0].key : "-1");
      selectedResellerId=res.length > 0 ? res[0].key : "-1";
    }
    else{
      setOrgListJSON(orgnListJSON);
      setSeltdResellerId(orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1");
      selectedResellerId=orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1";
    }
    
    if (parseInt(orgType) === 2) {
      setSeltdLastOrgId(selectedResellerId);
    } else if (parseInt(orgType) === 1) {
      if(globalOrgType===8){
        const regionListPayload = {
          value: "region",
        };
        const { data: regionResponseBody } = await dropdownServices.getResellers(regionListPayload);
        const { content: regionList } = regionResponseBody.data;
        const _regions = getOptions(regionList);
        setRegions(_regions);
        setSeltdRegionId(_regions[0]?.key);
      }
      else if(globalOrgType=2){
        selectedFleetId=-1;
        setSeltdFleetId(-1);
        setSeltdLastOrgId(-1);
        const customersListPayload = {
          value: "customer",
          resellerId: globalResellerId,
          dealerId: globalDisplayView===true?seltdDealerId:0,
          searchText: "",
        };
        const { data: customerListResponse } =
          await dropdownServices.getResellers(customersListPayload);
        const { content: customersList } = customerListResponse.data;
        const res1 = getOptions(customersList);
        setCustomers(res1);
        selectedCustomerId = res1.length > 0 ? res1[0].key : "-1";
        setSeltdCustomerId(res1.length > 0 ? res1[0].key : "-1");

      

      }
    } else if (parseInt(orgType) === 3) {
      selectedFleetId="-1";
      const customersListPayload = {
        value: "customer",
        resellerId: orgnListJSON.length > 0 ? orgnListJSON[0].key : "-1",
        dealerId: globalDisplayView===true?seltdDealerId:-1,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const customerAll = getOptions(customersList);
      setCustomers(customerAll);
      selectedCustomerId = customerAll.length > 0 ? customerAll[0].key : "-1";
      setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
      setSeltdLastOrgId(selectedCustomerId);

    } else if (parseInt(orgType) === 4) {
      const agencyListPayload = {
        value: "agency",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: agencyListResponse } = await dropdownServices.getResellers(
        agencyListPayload
      );
      const { content: agencyList } = agencyListResponse.data;
      const agencyAll = getOptions(agencyList);
      setAgency(agencyAll);
      selectedAgencyId = agencyAll.length > 0 ? agencyAll[0].key : "-1";
      setSeltdAgencyId(agencyAll.length > 0 ? agencyAll[0].key : "-1");
      setSeltdLastOrgId(agencyAll.length > 0 ? agencyAll[0].key : "-1");
    } else if (parseInt(orgType) === 5) {
      const dealerListPayload = {
        value: "dealer",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealersList } = dealerListResponse.data;
      const dealerAll = getOptions(dealersList);
      setDealers(dealerAll);
      selectedDealerId = dealerAll.length > 0 ? dealerAll[0].key : "-1";
      setSeltdDealerId(dealerAll.length > 0 ? dealerAll[0].key : "-1");
      setSeltdLastOrgId(dealerAll.length > 0 ? dealerAll[0].key : "-1");
      
    } else if (parseInt(orgType) === 8) {
      const systemListPayload = {
        value: "system",
        resellerId: globalResellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: systemListResponse } = await dropdownServices.getResellers(
        systemListPayload
      );
      const { content: systemList } = systemListResponse.data;
      const systemAll = getOptions(systemList);
      setSystem(systemAll);
      selectedSystemId = systemAll.length > 0 ? systemAll[0].key : "-1";
      setSeltdSystemId(systemAll.length > 0 ? systemAll[0].key : "-1");
      setSeltdLastOrgId(systemAll.length > 0 ? systemAll[0].key : "-1");
    }

    callbackLoadFn(selectedFleetId, selectedResellerId, selectedOrgTypeId,selectedCustomerId,selectedDealerId);
  };

  const handleResellerSelect = async (resellerId) => {
    if (seltdOrgTypeId === "1") {
      setSeltdResellerId(resellerId);
      setSeltdLastOrgId(resellerId);
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const _customers = getOptions(customersList);
      setCustomers(_customers);
      selectedCustomerId =_customers.length > 0 ? _customers[0].key : "-1";
      setSeltdCustomerId(_customers.length > 0 ? _customers[0].key : "-1");

      const fleetListPayload = {
        value: "fleet",
        customerId: _customers.length > 0 ? _customers[0].key : "",
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(fleetListPayload);
      const { content: fleetListResponse } = fleetListResp.data;

      if (fleetListResponse.length === 0) {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      } else {
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      }

      selectedFleetId =fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1";
      setSeltdFleetId(fleetListResponse.length > 0 ? fleetListResponse[0].key : "-1");

    } else if (seltdOrgTypeId === "2") {
      setSeltdResellerId(resellerId);
      setSeltdLastOrgId(resellerId);
    } else {
      setSeltdResellerId(resellerId);
      const customersListPayload = {
        value: "customer",
        resellerId: resellerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: customerListResponse } =
        await dropdownServices.getResellers(customersListPayload);
      const { content: customersList } = customerListResponse.data;
      const _customers = getOptions(customersList);
      setCustomers(_customers);
      selectedCustomerId =customersList.length > 0 ? customersList[0].key : "-1";
      setSeltdCustomerId(customersList.length > 0 ? customersList[0].key : "-1"
      );
      setSeltdLastOrgId(_customers.length > 0 ? _customers[0].key : "-1");
    }
  };

  const handleRegionSelect = async (regionId) => {
    setSeltdRegionId(regionId);

 if (globalOrgType === 8) {   
  if (regionId === "-1") {
    setDealers([]);
    setDealers(getOptions);
  } else {
    const dealerListPayload = {
      value: "dealer",
      // resellerId: userData.userOrgType === 8 ? seltdResellerId : globalResellerId,
      resellerId:seltdResellerId,
      dealerId: 0,
      searchText: "",
      regionId: regionId,
    };
    const { data: dealerListResponse } = await dropdownServices.getResellers(
      dealerListPayload
    );
    const { content: dealerList } = dealerListResponse.data;

    const _dealers = getOptions(dealerList);
    setDealers(_dealers);

    setSeltdRegionId(regionId);
    setSeltdDealerId(_dealers[0].key);
  }
    }
    else{
    if (regionId === "-1") {
      setDealers([]);
      setDealers(getOptions);
    } else {
      const dealerListPayload = {
        value: "dealer",
        resellerId:
          userData.userOrgType === 2 ? seltdResellerId : globalResellerId,
        dealerId: 0,
        searchText: "",
        regionId: regionId,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;

      const _dealers = getOptions(dealerList);
      setDealers(_dealers);

      setSeltdRegionId(regionId);
      setSeltdDealerId(_dealers[0].key);
    }
  }
  };
  const handleDealerSelect = async (dealerId) => {
    setSeltdDealerId(dealerId);
    if(globalDisplayView === true && (seltdOrgTypeId === "1" || seltdOrgTypeId === "3")){
    setSeltdLastOrgId(dealerId);   
    const customersListPayload = {
      value: "customer",
      orgType: "3",
      id: userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
      resellerId:userData.userOrgType === 1 ? seltdResellerId : globalResellerId,
      dealerId: dealerId,
      searchText: "",
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(customersListPayload);
    const { content: customersList } = customerListResponse.data;
    const customerAll = getOptions(customersList);
    setCustomers(customerAll);
    setSeltdCustomerId(customerAll.length > 0 ? customerAll[0].key : "-1");
    }
    else{
      setSeltdLastOrgId(dealerId)
    }
  };

  const handleCustomerSelect = async (customerId) => {
    selectedCustomerId = customerId;
    setSeltdCustomerId(customerId);

    if (seltdOrgTypeId === "1") {
      const fleetListPayload = {
        value: "fleet",
        customerId: customerId,
        dealerId: 0,
        searchText: "",
      };
      const { data: fleetListResp } = await dropdownServices.getResellers(
        fleetListPayload
      );
      const { content: fleetListResponse } = fleetListResp.data;
        const fleets = getOptions(fleetListResponse);
        setFleet(fleets);
      selectedFleetId =fleets.length > 0 ? fleets[0].key : "-1";
      setSeltdFleetId(fleets.length > 0 ? fleets[0].key : "-1");
    } else {
      selectedCustomerId = customerId;
      setSeltdCustomerId(customerId);
      setSeltdLastOrgId(customerId);
    }
  };

  const handleFleetSelect = async (fleetId) => {  
    setSeltdLastOrgId(fleetId);
  };


  const handleAgencySelect = async (agencyId) => {
    setSeltdLastOrgId(agencyId);
  };

  const handleSystemSelect = async (systemId) => {
    setSeltdLastOrgId(systemId);
  };

  const handleSubmit = (e) => {
    if(globalDisplayView === true){
    handleSmt(
      seltdOrgTypeId,
      seltdResellerId,
      seltdCustomerId,
      seltdFleetId,
      seltdLastOrgId,
      seltdAgencyId,
      seltdDealerId,
      seltdRegionId
    );
    }
    else{
      handleSmt(
        seltdOrgTypeId,
        seltdResellerId,
        seltdCustomerId,
        seltdFleetId,
        seltdLastOrgId,
        seltdAgencyId,
        dealerVal,
        regionVal
      );
      }
  };

  const handleOrgTypeSearch = async (searchText) => {
    const customersListPayload = {
      value: "customer",
      resellerId: selectedResellerId,
      dealerId: 0,
      searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
  };

  

  const handleCustomerSearch = async (searchText) => {
    if (searchText.length > 1 || searchText=="") {
    const customersListPayload = {
      value: "customer",
      resellerId: seltdResellerId,
      dealerId: 0,
      searchText: searchText,
    };
    const { data: customerListResponse } = await dropdownServices.getResellers(
      customersListPayload
    );
    const { content: customersList } = customerListResponse.data;
    setCustomers(getOptions(customersList));
    }
  };

  const handleFleetSearch = async (searchText) => {
    if (searchText.length > 1 || searchText=="") {
    const fleetListPayload = {
      value: "fleet",
      resellerId: seltdResellerId,
      dealerId: 0,
      customerId: seltdCustomerId,
      searchText: searchText,
    };
    const { data: fleetListResponse } = await dropdownServices.getResellers(
      fleetListPayload
    );
    const { content: fleetList } = fleetListResponse.data;
    setFleet(getOptions(fleetList));
    }
  };

  const handleResellerSearch = async (searchText) => {
    const resellersListPayload = {
      value: "reseller",
      orgType: userData.userOrgType,
      roleId: userData.roleId,
      id: userData.resellerId,
      searchText: searchText,
    };

    const { data: responseBody } = await dropdownServices.getResellers(
      resellersListPayload
    );
    const { content: resellersList } = responseBody.data;
    setResellers(resellersList);
  };

  return (
    <form>
      <StyledPaperShadow>
        <DropDown
          label="Organization Type"
          options={orgTypeRes}
          onChange={handleOrgTypeSelect}
          optionsDisplayKey="value"
          onSearch={handleOrgTypeSearch}
          isShow={isOrgType}
          fieldName="orgType"
        />       
        <DropDown
          label="Organization"
          options={orgListJSON}
          onChange={handleResellerSelect}
          optionsDisplayKey="value"
          onSearch={handleResellerSearch}
          isShow={isReseller}
          fieldName="Organization"
        />
         <DropDown
          label="Region"
          options={regions}
          onChange={handleRegionSelect}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : false}
          // onSearch={handleRegionSearch}
          fieldName={"regionId"}
        />     
        <DropDown
          label="Dealer"
          options={dealers}
          optionsDisplayKey="value"
          isShow={globalDisplayView === true ? true : (isDealer===true?true:false)}
          onChange={handleDealerSelect}
          fieldName={"dealerId"}
          //onSearch={handleDealerSearch}
        />      
        <DropDown
          label="Customer"
          options={customers}
          onChange={handleCustomerSelect}
          optionsDisplayKey="value"
          isShow={isCustomer}
          onSearch={handleCustomerSearch}
        />
  
        <DropDown
          label="Fleet"
          options={fleet}
          onChange={handleFleetSelect}
          optionsDisplayKey="value"
          isShow={isFleet}
          onSearch={handleFleetSearch}
        />
       
        <DropDown
          label="Agency"
          options={agency}
          onChange={handleAgencySelect}
          optionsDisplayKey="value"
          isShow={isAgency}
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Vendor"
          options={dealers}
          onChange={handleDealerSelect}
          optionsDisplayKey="value"
          isShow={isVendor}
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="System"
          options={system}
          onChange={handleDealerSelect}
          optionsDisplayKey="value"
          isShow={isSystem}
          onSearch={handleCustomerSearch}
        />
        <DropDown
          label="Status"
          options={customers}
          optionsDisplayKey="value"
          isShow={isStatus}
        />

        <StyledFiterButton
          type="button"
          onClick={handleSubmit}
          variant="contained"
          size="small"
        >
          Submit
        </StyledFiterButton>
      </StyledPaperShadow>
    </form>
  );
};

export default CommonDropDown;
