import {
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import pagenotfound from "../../assets/images/404.png";
import { useNavigate, useLocation } from "react-router";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slice/ControlsSlice";
const Home = () => {
  const naviagete = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const authData = useSelector((state) => state.controls.authToken);
  const menuList = JSON.parse(localStorage?.getItem("menuList"));
  const dispatch = useDispatch();
  //Auth user
  const goBack = () => {
    if(authData !== null){
      if(location.pathname !== '/undefined' ){
        naviagete(`/${menuList[0]?.sub[0]?.route}`)
      }else{
        dispatch(logout());
        naviagete(`/`);
      }
    }else{
      dispatch(logout());
      naviagete(`/`);
    }
  }

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        left: "0px",
        zIndex: "99999",
        background:theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {
        location.pathname !== '/undefined' ? 
        <>
          <CardMedia component="img" src={pagenotfound} sx={{ width: "450px" }} />
            <Typography variant="h4" sx={{ alignSelf: "center" }}>
              PAGE NOT FOUND
            </Typography>
            <Button variant="contained" sx={{ mt: 2 }} onClick={goBack}>
              Go Back Dashboard
          </Button>
        </> : 
        <>
        <CardMedia component="img" src={pagenotfound} sx={{ width: "450px" }} />
           <Typography variant="h4" sx={{ alignSelf: "center" }}>
              Don't access this page
            </Typography>
            <Button variant="contained" sx={{ mt: 2 }} onClick={goBack}>
              Go Back Login
          </Button>
        </>
      }
      
    </Box>
  );
};

export default Home;
