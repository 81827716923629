import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import InputWrapper from "./InputWrapper";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { StyledFormLabel } from "./StyledEle";
import {
  StyledDropDown,
  StyledFilterDropDown,
  StyledFilterMenuItem,
  StyledFormControlLabel,
} from "../../theme/StyledEle";

const SelectInput = ({
  label,
  name,
  disabledKey,
  defaultValue,
  options = [],
  callBackFn,
  callbackfnStatus = false,
  requiredStatus = true,
}) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const labelStyle = {
    // fontSize: "14px !important",
    // color: "black",
    // "&.Mui-focused": {
    //   color: theme.border.color.primary,
    // },
    // fontWeight: 600,
  };
  const asterisk = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: "red" },
        },
      },
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const selectDropdown = {
    // ".MuiOutlinedInput-input": { fontSize: 14 },
    // width: "100%",
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: theme.border.color.primary,
    // },
    // ".MuiSvgIcon-root ": {
    //   fill: theme.border.color.primary,
    // },
    // border:'3px solid black'
  };

  const selectDropdownDisabled = {
    ".MuiInputBase-input": {
      fontSize: 14,
      padding: "1px",
      paddingLeft: "7px",
      backgroundColor: "#eee",
    },
  };

  // const callBackFn1 =(e)=>{
  //   setV(e.target.value);
  //   callBackFn(e.target.value,name);
  // }

  // return (
  //   <InputWrapper >
  //     <FormControl size='small' fullWidth >
  //       <ThemeProvider theme={asterisk}>
  //       <FormLabel  sx={labelStyle} required >{label}</FormLabel>
  //       </ThemeProvider>
  //       <Select
  //         value={v}
  //         MenuProps={MenuProps}
  //         name={name}
  //         disabled={disabledKey===true?true:false}
  //         onChange={(e) => {callbackfnStatus===true?callBackFn1(e):setV(e.target.value)}}
  //          sx={disabledKey===true?selectDropdownDisabled:selectDropdown}
  // >
  //  { onChange={(e) => {setV(e.target.value);callbackfnStatus===true?callBackFn1(e.target.value):setV(e.target.value)}}

  const [v, setV] = useState(defaultValue);
  // if (defaultValue) {
  //   const initialValue = options.find(val => val.title === defaultValue)
  //   setV(initialValue)
  // }

  const callBackFn1 = (e) => {
    setV(e.target.value);
    callBackFn(e.target.value, name);
  };

  return (
    <InputWrapper>
      <FormControl fullWidth>
        {/* <ThemeProvider theme={asterisk}> */}
        {requiredStatus === true && (
          <StyledFormControlLabel required>{label}</StyledFormControlLabel>
        )}
        {requiredStatus === false && (
          <StyledFormControlLabel required>{label}</StyledFormControlLabel>
        )}
        {/* </ThemeProvider> */}
        {/* <InputLabel id={label} required={requiredStatus === true && true }>{label}</InputLabel> */}
        <StyledDropDown
          value={v}
          MenuProps={MenuProps}
          name={name}
          disabled={disabledKey === true ? true : false}
          onChange={(e) => {
            callbackfnStatus === true ? callBackFn1(e) : setV(e.target.value);
          }}
          // onKeyUp={(e)=> {
          //   if (e.charCode === 13) {
          //     callbackfnStatus === true ? callBackFn1(e) : setV(e.target.value);
          //   }
          // }
          //}

          //sx={disabledKey === true ? selectDropdownDisabled : selectDropdown}
          size="small"
          //  {onChange={(e) => {setV(e.target.value);callbackfnStatus===true?callBackFn1(e.target.value):setV(e.target.value)}}
          //  onChange={(e) => {callbackfnStatus===true?callBackFn1(e):setV(e.target.value)}}
        >
          {options.length > 0 &&
            options.map((option, index) => {
              return (
                <StyledFilterMenuItem key={index} value={option.value}>
                  {option.title}
                </StyledFilterMenuItem>
              );
            })}
        </StyledDropDown>
      </FormControl>
    </InputWrapper>
  );
};

export default SelectInput;
