import Service from "./service";

const dropdownServices = {
  getResellers: function (payload) {
    return Service.post(
      "/organization/organizationDropdownWithPagination",
      payload,
      null
    );
  },

  getFleetList: (payload) => {
    return Service.post("/vehicle/vehiclePaginationDropdown", payload);
  },
  getVinImeiList: (payload) => {
    return Service.post("/vehicle/getVinImeiList", payload);
  },
  getGstList: (payload) => {
    return Service.post("/vehicle/getGstInForVehicle", payload);
  },
  // getOrgType:() =>{
  //   return Service.get('/RoleSettings/getOrgType', null)
  // },
  getOrgListJSON: (orgType) => {
    return Service.get("/RoleSettings/getRoleSettingOrgListJSON", { orgType });
  },
  getSubResellerListJSONByLimit: (resellerId) => {
    return Service.get("/RoleSettings/getSubResellerListJSONByLimit", {
      resellerId,
    });
  },
  getOrgListByLimit: (subResellerId, resellerId) => {
    return Service.get("/RoleSettings/getOrgListByLimit", {
      subResellerId,
      resellerId,
    });
  },
  getRoleList: (orgId) => {
    return Service.get("/RoleSettings/getRoleList", { orgId });
  },
  getVehicleIMEIlistByLimit: (payload) => {
    return Service.post("/vehicle/getVehicleIMEIlistByLimit", payload);
  },
  getUserlist: (payload) => {
    return Service.post("/user/getUserlist", payload);
  },
  getAllActiveDeviceList: (payload) => {
    return Service.post("/device/getAllActiveDeviceList", payload);
  },
  getUserOrgList: (payload) => {
    return Service.post("/AuditLog/getUserOrgList", payload);
  },
  getAlertList: (payload) => {
    return Service.post("/Alerts/getDynamicAlerts", payload);
  },
};

export default dropdownServices;
