const ORGANIZATION_DETAIL = [
  // {
  //   type: "select",
  //   label: "Organization Type",
  //   name: "orgType",
  //   callbackfnStatus: "true",

  //   options: [
  //     {
  //       id: 1,
  //       title: "Select",
  //       value: "0",
  //     },
  //     {
  //       id: 2,
  //       title: "Organization",
  //       value: "2",
  //     },
  //     {
  //       id: 3,
  //       title: "Dealer",
  //       value: "5",
  //     },
  //     {
  //       id: 4,
  //       title: "Customer",
  //       value: "3",
  //     },
  //     {
  //       id: 5,
  //       title: "Fleet",
  //       value: "4",
  //     },
  //     {
  //       id: 6,
  //       title: "Agency",
  //       value: "6",
  //     },
  //     {
  //       id: 7,
  //       title: "Support",
  //       value: "7",
  //     },
  //   ],
  // },
  // {
  //   type: "select",
  //   label: "Select Organization / Customer",
  //   name: "status",
  //   callbackfnStatus: "true",
  //   options: [
  //     {
  //       id: 1,
  //       title: "null",
  //       value: 0,
  //     },
  //     // {
  //     //   id: 2,
  //     //   title: "InActive",
  //     //   value: 0,
  //     // },
  //   ],
  // },
  {
    type: "text",
    label: "Organization Name",
    name: "name",
  },
  {
    type: "text",
    label: "Address",
    name: "address",
  },
  {
    type: "select",
    label: "Region",
    name: "regionId",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "select",
        value: "-1",
      },
      {
        id: 2,
        title: "NORTH",
        value: "1",
      },
      {
        id: 3,
        title: "EAST",
        value: "2",
      },
      {
        id: 4,
        title: "WEST",
        value: "3",
      },
      {
        id: 5,
        title: "SOUTH",
        value: "4",
      },
      {
        id: 6,
        title: "UNAssigned",
        value: "5",
      },
    ],
  },
  {
    type: "select",
    label: "Country",
    name: "countryId",
    callbackfnStatus: "true",
    options: [
     {
        id: 102,
        title: "India (IND)",
        value: "102",
      },
    
    ],
  },
  {
    type: "select",
    label: "State / Province",
    name: "stateId",
    disabled: "true",
    callbackfnStatus: "true",
    options: []
  },
  {
    type: "select",
    label: "City",
    name: "cityId",
    disabled: "true",
    callbackfnStatus: "true",
    options: []
  },
  {
    type: "text",
    label: "Pin code",
    name: "zipCode",
  },
  {
    type: "select",
    label: "Time Zone",
    name: "timeZoneId",
    options: [
      {
        id: 1,
        title: "(UTC+05:30)",
        value: 1,
      },
    ],
  },
];

const DOMAIN_DETAIL = [
  {
    type: "radio",
    name: "",
    options: [
      {
        id: 1,
        label: "Non-Restricted",
        value: 1,
      },
      {
        id: 2,
        label: "Restricted",
        value: 2,
      },
    ],
  },
  // {
  //   type: "radio",
  //   name: "nonRestricted",
  //   options: [
  //     {
  //       id: 1,
  //       label: "Yes",
  //       value: 1,
  //     },
  //     {
  //       id: 2,
  //       label: "No",
  //       value: 2,
  //     },
  //   ],
  // },
];

//   {
//     type: "text",
//     label: "",
//     name: "domainEmail",
//     value: "test",
//   },

const ORG_LIST_DETAIL = [
  {
    type: "select",
    label: "Organization Type",
    name: "orgType",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "Customer",
      },
      {
        id: 2,
        title: "Fleet",
      },
      {
        id: 3,
        title: "Agency",
      },
      {
        id: 4,
        title: "Support",
      },
    ],
  },
  {
    type: "select",
    label: "Time Zone",
    name: "timeZoneId",
    options: [
      {
        id: 1,
        title: "select",
      },
    ],
  },
  {
    type: "select",
    label: "Time Zone",
    name: "timeZoneId",
    options: [
      {
        id: 1,
        title: "select",
      },
    ],
  },
];

const ORGANIZATION_ADMIN_DETAIL = [
  {
    type: "text",
    label: "User Name [Eg: abc@gmail.com]",
    name: "userName",
  },
  {
    type: "text",
    label: "First Name",
    name: "firstName",
  },
  {
    type: "text",
    label: "Last Name",
    name: "lastName",
  },
  {
    type: "text",
    label: "Email",
    name: "email",
  },
  {
    type: "select",
    label: "Country Code",
    name: "mobileCountryCode",
    callbackfnStatus: "true",
    options: [
      {
        id: 1,
        title: "select",
        value: "0",
      },
      {
        id: 2,
        title: "Australia+61",
        value: "1",
      },
      {
        id: 3,
        title: "Canada+1",
        value: "2",
      },
      {
        id: 4,
        title: "India+91",
        value: "3",
      },
      {
        id: 5,
        title: "United States+1",
        value: "4",
      },
    ],
  },
  {
    type: "text",
    label: "mobile Number",
    name: "mobile",
  },
];

module.exports = {
  ORGANIZATION_DETAIL,
  DOMAIN_DETAIL,
  ORGANIZATION_ADMIN_DETAIL,
  ORG_LIST_DETAIL,
};
