import React, { Fragment, useMemo } from 'react'
import { DataTable } from '../shared/data_table/DataTable';


function FirmwareList(props) {
    const {isLoading, data, totalElements, totalPages, newPage, rowsPerPage, pageChange, handleSearch } = props;
    
    const columns = useMemo(
        () => [
          // {
          //   Header: "Id",
          //   accessor: "id",
          // },
          // {
          //   Header: "Company Id",
          //   accessor: "companyId",
          // },
          // {
          //   Header: "Description",
          //   accessor: "description",
          // },
          {
            Header: "Created Date",
            accessor: "createdDate",
          },
          {
            Header: "Type",
            accessor: "type",
          },
          // {
          //   Header: "Created By",
          //   accessor: "createdBy",
          // },
          {
            Header: "File Path",
            accessor: "filePath",
          },
          {
            Header: "File Size",
            accessor: "fileSize",
          },
          {
            Header: "File Name",
            accessor: "fileName",
          },
          // {
          //   Header: "Updated By",
          //   accessor: "updatedBy",
          // },
          {
            Header: "Firmware Version",
            accessor: "firmwareVersion",
          },
          // {
          //   Header: "App Name",
          //   accessor: "appName",
          // }
        ],
    );

   



  return (
    <Fragment>
        <DataTable
            includeSearchBox={true}
            includeExportButton={false}
            columns={columns}
            data={data}
            isLoading={isLoading}
            totalElements={totalElements}
            totalPages={totalPages}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            handleChange={handleSearch}
        />
    </Fragment>
  )
}

export default FirmwareList