import React, { useState, useMemo, useEffect } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import logServices from "../../services/logService";
import CommonDropDown from "./LogDropDown";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import LogDropdown from "../LogDropdown/LogDropdown";
import { useUserLogsMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import GetLocation from "../Location";


const UserLog = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDropdownAry, setSelectedDropdownAry] = useState({});
  const [loading, setLoading] = useState("");

  const text = "UserLog Captures all the Login & LogOff details of the User.";
  
  
  const [auditLog] = useAuditLogMutation();


  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };
  const columns = useMemo(
    () => [
      {
        Header: "OrgName",
        accessor: "orgName",
      },
      {
        Header: "Name",
        accessor: "userName",
      },
      {
        Header: "Logged On",
        accessor: "logOnTime",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "LatLong",
        accessor: "latlong",
        width:300,
        Cell: ({ row }) => {
          return(
            row.original.latlong !== "" &&   row.original.latlong !== "NA" ?
          <GetLocation row={row} columnName={row.original.latlong}/> : '-'
          )
        }
      },
      {
        Header: "Logged Off",
        accessor: "logOffTime",
      },
      {
        Header: "TimeDuration",
        accessor: "timeDuration",
      },
      {
        Header: "Access",
        accessor: "access",
      },
    ],
    []
  );
  // const callbackLoadFn = async (fromDate, toDate) => {
  //   // setRowsPerPage(rowsPerPage);
  //   var request = {};
  //   request.reselId = globalResellerId;
  //   request.orgId = globalOrgId;
  //   request.customerId = -1;
  //   // request.fleetId = -1;
  //   request.userId = "-1";
  //   request.dealerId = "-1";
  //   //request.sortId = "1";
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.access = "All";
  //   request.sortBy = "asc";
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.columnName = "LOG_OFF_TIME";
  //   setSelectedDropdownAry(request);

  //   const responseData = (await LogServices.UserLog(request)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const handleSubmit = async (
  //   seltdOrgTypeId,
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdLastOrgId,
  //   globaluserIdCheck,
  //   fromDate,
  //   toDate,
  //   seltdAccess
  // ) => {
  //   var request = {};
  //   request.orgId = seltdResellerId;
  //   request.reselId = seltdResellerId;
  //   request.customerId = seltdCustomerId;
  //   request.userId = seltdFleetId;
  //   //request.dealerId = "-1";
  //   //request.sortId = "1";
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.access = seltdAccess;
  //   // request.dealerId = 1;
  //   request.sortBy = "asc";
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.columnName = "LOG_OFF_TIME";

  //   setSelectedDropdownAry(request);

  //   try {
  //     const responseData = (await logServices.UserLog(request)).data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };

  //  const callbackLoadFn=()=>{
  // }

  // const callbackChangePage = async (newPage, rowsPerPage, searchText) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   var request = {};

  //   request.reselId = selectedDropdownAry.reselId;
  //   request.orgId = selectedDropdownAry.orgId;
  //   request.customerId = selectedDropdownAry.customerId;
  //   // request.fleetId = -1;
  //   request.userId = selectedDropdownAry.userId;
  //   request.dealerId = "-1";
  //   // request.sortId = "1";
  //   request.startDate = selectedDropdownAry.startDate;
  //   request.endDate = selectedDropdownAry.endDate;
  //   request.access = selectedDropdownAry.access;
  //   request.sortBy = "asc";
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.columnName = "LOG_OFF_TIME";

  //   try {
  //     const responseData = (await logServices.UserLog(request)).data;
  //     setResponseData([]);
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   } catch (error) {}
  // };
  //Get Data from Dropdown

  const [getUserLog, {data:userLogData, isLoading:userLogLoading, isSuccess:userLogSuccess}] = useUserLogsMutation();

  let commonPayload = {}
  const [payload, setPayload] = useState(null);
  const getData = (data) => {
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.orgId = orgId;
    commonPayload.customerId = data.customerId;
    commonPayload.userId =   data.useListId;
    commonPayload.dealerId =  data.dealerId === "-1"? "0" : data.dealerId ;
    commonPayload.startDate =  data.fromData;
    commonPayload.endDate =  data.toDate;
    commonPayload.access =  data.accessId;
    commonPayload.sortBy =  "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.columnName =  "LogOn"; 
    commonPayload.orgType = data.orgType
    commonPayload.search = "";
    setPayload(commonPayload);
    getUserLog(commonPayload);

    auditPayload.message = "Logs > User Log > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.userName+" > "+data.accessName+" > "+"("+data.fromData+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "User Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    console.log("userMsg::"+auditPayload.message);
    auditLog(auditPayload);
  }

  

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getUserLog(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getUserLog(payload);
    }
  };



  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getUserLog(payload);
    } else if (payload.search.length === 0) {
      getUserLog(payload);
    }
  };

  useEffect(() => {
    if(userLogSuccess){
      setResponseData([]);
      if (userLogData.data !== null) {
        setResponseData(userLogData.data.content);
        setTotalElements(userLogData.data.totalElements);
        setTotalPages(userLogData.data.totalPages);
      }
    }
    auditPayload.message = "Logs > User Log";
    auditPayload.action = "View";
    auditPayload.featureName = "Logs";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
  },[userLogSuccess])


  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "UserLog/userLogListXLS.xls?reselId=" +
        payload.reselId +
        "&orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&customerId=" +
        payload.customerId +
        "&dealerId=" +
        payload.dealerId +
        "&userId=" +
        payload.userId +
        "&access=" +
        payload.access +
        "&sortBy=" +
        payload.sortBy +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);

      auditPayload.message = "Logs > User Log > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Logs";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="User Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"User Log"} />
      <Box>
        {/* <CommonDropDown
          handleSmt={handleSubmit}
          //callbackLoadFn={callbackLoadFn}
        /> */}
        <LogDropdown getData={getData} allowDays={30}
        allowedMonth={12}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader title={<StyledTableTitle>User Log</StyledTableTitle>} />

          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={userLogLoading}
              includeSearchBox={true}
              includePagination={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default UserLog;
