import { Box,Typography} from '@mui/material'
import React,{useState,useEffect} from 'react'
import { SoftButton,StyledPageLayout,StyledPaperShadow } from '../../theme/StyledEle'
import { FiPlus } from 'react-icons/fi'
import DrawerModel from '../../Utils/DrawerModel'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateBanner from './CreateBanner'
import BannerList from './GridView'
import { Breadcrumb } from '../shared/Breadcrumbs'
import { useDeleteBannerMutation, useGetBannerMutation } from '../../store/slice/BannerSlice/bannerSlice'
import StyledPreviewModel from '../../Utils/StyledPreviewImageModel'
import StyledModel from '../../Utils/StyledModel'
import { snackService } from '../../store/slice/ControlsSlice'
import { useDispatch, useSelector } from 'react-redux'
import StyledSnackBar from '../../Utils/StyledSnackBar'


function ListBanner() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let userId = userData.id;

  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [bannerList, setBannerList] = useState([]);
  const [payload, setPayload] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [commonFilter, setCommonFilter] = React.useState({
    view: false,
    delete: false,
  });
  const [deleteItem, setDeleteItem] = useState(null);

const navigate = useNavigate();
const location = useLocation();
const getActionBtn = () => {
  if ("null" !== JSON.parse(localStorage.getItem("menuList")) && null !== JSON.parse(localStorage.getItem("menuList"))) {
  if (JSON.parse(localStorage.getItem("menuList")) !== null) {
    for ( let i = 0; i < JSON.parse(localStorage.getItem("menuList")).length; i++) 
    {
      if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) 
      {
        for (
          let j = 0;
          j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
          j++
        ) {
          if (
            JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
            "banner"
          ) {
            var dataStr = {};
          
            dataStr.delete = false;
            dataStr.view = false;
        
            var strval;
            strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
              j
            ].button.split(",");
            for (var k = 0; k < strval.length; k++) {

              if (parseInt(strval[k]) === 2) {
                dataStr.view = true;
              }
              if (parseInt(strval[k]) === 3) {
                dataStr.delete = true;
              }
            }
            setCommonFilter(dataStr);
            break;
          }
        }
      }
    }
  } 
}
}

 //Slice Mution
 const [ getBannerList,  {data:bannerListData, isLoading:bannerListLoading, isSuccess:banneristSuccess}] = useGetBannerMutation();
 const [ deleteBanner,  {data:deleteBannerData, isLoading:deleteBannerLoading, isSuccess:deleteBannerSuccess}] = useDeleteBannerMutation();

 let bannerListPayload = {
 }

 const GetBanner = async () => {
  setNewPage(0);
  setRowsPerPage(25);
  bannerListPayload.page = 0;
  bannerListPayload.size = 25;
  bannerListPayload.search = "";
  bannerListPayload.resellerId = resellerId;

  setPayload(bannerListPayload);
  getActionBtn()
  const GetBannerResponse = await getBannerList(bannerListPayload);
  if (
    GetBannerResponse.data !== undefined &&
    GetBannerResponse.data !== null
  ) {
    if (GetBannerResponse.data.data.content !== null) {
      setBannerList(GetBannerResponse.data.data.content);
      setTotalElements(GetBannerResponse.data.data.totalElements);
      setTotalPages(GetBannerResponse.data.data.totalPages);
    } else {
      setBannerList([]);
    }
  } else {
    setBannerList([]);
  }
};

//Onload Get Banner List
useEffect(() => {
  GetBanner();
  
}, []);


const GetBannerViaPayload = async (payload) => {
  const GetBannerResponse = await getBannerList(payload);
  if (
    GetBannerResponse.data !== undefined &&
    GetBannerResponse.data !== null
  ) {
    if (GetBannerResponse.data.content !== null) {
      setBannerList(GetBannerResponse.data.content);
      setTotalElements(GetBannerResponse.data.totalElements);
      setTotalPages(GetBannerResponse.data.totalPages);
    } else {
      setBannerList([]);
    }
  } else {
    setBannerList([]);
  }
}

//handle pagination
const pageChange = async (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;

  GetBannerViaPayload(payload)

};


  //Search Firmware
  const handleSearch = async (searchText) => {
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetBannerViaPayload(payload)
    } else if (payload.searchText.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      GetBannerViaPayload(payload)
    }
  };
const onView = (id) =>{
  setViewData(id); 
}

const onClose  = () => {
    setViewData(null);
}
const onDelete = (itemDeleted) =>{
  navigate('#delete')
  setDeleteItem(itemDeleted);
}

useEffect(() => {
    if(location.hash === ''){
      setDeleteItem(null);
    }
},[location.hash])

const deleteItems = () => {
  var payload={}
  payload.userId=userId;
  payload.fileId=deleteItem.id;
  deleteBanner(payload);
}
const dispatch = useDispatch();
  useEffect(() => {
    if (deleteBannerSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteBannerData?.resultMessage,
          deleteBannerData?.resultText,
          deleteBannerSuccess,
        ])
      );
      GetBanner()
    }
  }, [deleteBannerSuccess]);
  const isSuccess = useSelector((state) => state.controls.alert);
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 2000);
    }
  }, [isSuccess.isSuccess]);
  return (
<StyledPageLayout>
<Breadcrumb mainDiv="Admin" subDiv="Banner Upload"></Breadcrumb>
    <Box>
      {
        bannerList.length < 6 && 
      
        <StyledPaperShadow sx={{display:'flex', justifyContent:'end'}}>

              <Box >
                 
                      <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
                          Add Banner
                      </SoftButton>
                
              </Box>
        </StyledPaperShadow>
}
        {/* Grid Start */}
        <StyledPaperShadow sx={{mt:2}}>
          <BannerList isLoading={bannerListLoading} data={bannerList} totalElements={totalElements}
              totalPages={totalPages} newPage={newPage} rowsPerPage={rowsPerPage} pageChange={pageChange} handleSearch={handleSearch} commonFilter={commonFilter} onView={onView} onDelete={onDelete}/>
        </StyledPaperShadow>
        
        {/* Create Ticket */}
        <DrawerModel position={'right'} width={'35vw'} title={'Add Banner'}>
            <CreateBanner reload={GetBanner}/>
        </DrawerModel>
        
    </Box>
    
     <StyledPreviewModel pop={viewData} onClose ={onClose} docName={viewData?.s3FileName} docOriginalName={viewData?.fileName}/>
     {deleteItem !== null && (
        <StyledModel data={deleteItem} deleteItem={deleteItems}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              File Name: {deleteItem?.fileName}
            </Typography>
          </Box>
        </StyledModel>
      )}
          {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    
</StyledPageLayout>
  
  );
}

export default ListBanner;
