import { Avatar, Box, Button, ListItemAvatar, ListItemButton, ListItemText, Menu } from '@mui/material'
import React, { useState,useEffect } from 'react'
import { ControlsBox } from '../theme/StyledEle'
import { FiLayers } from 'react-icons/fi'
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri'
import DefaultMap from '../assets/images/roadamap.png'
import Terrain from '../assets/images/terrain.png'
import Satellite from '../assets/images/satellite.png'
function MapOptions(props) {
    const { handleZoom, mapLayer, zoomValue, map,mapTypeData} = props;
    const [globalMap,setGlobalMap]= useState(1);

    const mapActoin = (value, action) =>{
        handleZoom(value, action);
    }
    useEffect(()=>{
      setGlobalMap(mapTypeData)
    },[mapTypeData])

    const [openMapEl, setOpenMapEl] = useState(null);
    const [mapType, setMapType] = useState("roadmap");
    const openMap = Boolean(openMapEl);

    const handleMapLayer = (event) => {
        setOpenMapEl(event.currentTarget);
    };
    const handleMapLayerClose = () => {
        setOpenMapEl(null);
    };

    const handleZoomIn = () => {
      if (globalMap===1) {
        mapActoin(map?.zoom + 1, 'zoom')
      } else if(globalMap===2) {
        mapActoin(prevZoom => Math.min(prevZoom + 1, 22), 'zoom');
      }else{
        mapActoin(map?.zoom + 1, 'zoom')
      }
    };
  
    const handleZoomOut = () => {
      if (globalMap===1) {
        mapActoin(map?.zoom - 1, 'zoom')
      } else if(globalMap===2) {
        mapActoin(prevZoom => Math.min(prevZoom - 1, 22), 'zoom');
      }
      else{
        mapActoin(map?.zoom - 1, 'zoom')
      }
    };

  return (
    <ControlsBox
        className="zoom-control-wrapper"
        direction="row"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          className="map-btn"
          onClick={handleZoomIn}
          disabled={zoomValue === 22}
        >
          <RiZoomInLine />
        </Button>
        <Button
          className="map-btn"
          onClick={handleZoomOut}
          disabled={zoomValue === 3}
        >
          <RiZoomOutLine />
        </Button>
        <Button
          className="map-btn"
          id="basicmaplayer-button"
          aria-controls={openMap ? "maplayer-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMap ? "true" : undefined}
          onClick={handleMapLayer}
        >
          <FiLayers />
        </Button>
        <Menu
          id="maplayer-menu"
          anchorEl={openMapEl}
          open={openMap}
          onClose={handleMapLayerClose}
          MenuListProps={{
            "aria-labelledby": "maplayer-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ListItemButton
            className="small-menuItem"
            onClick={() => mapActoin("roadmap", 'mapType')}
            selected={mapLayer === "roadmap"}
          >
            <ListItemAvatar>
              <Avatar
                src={DefaultMap}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
            <ListItemText>RoadMap</ListItemText>
          </ListItemButton>
          <ListItemButton
            className="small-menuItem"
            onClick={() => mapActoin("terrain", 'maptype')}
            selected={mapLayer === "terrain"}
          >
            <ListItemAvatar>
              <Avatar
                src={Terrain}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
            <ListItemText>Terrain</ListItemText>
          </ListItemButton>
          <ListItemButton
            className="small-menuItem"
            onClick={() => mapActoin("satellite", 'mapType')}
            selected={mapLayer === "satellite"}
          >
            <ListItemAvatar>
              <Avatar
                src={Satellite}
                variant="rounded"
                sx={{ width: "25px", height: "25px" }}
              />
            </ListItemAvatar>
          <ListItemText>Satellite</ListItemText>
          </ListItemButton>
        </Menu>
      </ControlsBox>
  )
}

export default MapOptions