import React, { Fragment, useMemo } from 'react'
import { DataTable } from '../shared/data_table/DataTable';


function BannerList(props) {
    const {isLoading, data, totalElements, totalPages, newPage, rowsPerPage, pageChange, handleSearch,commonFilter,onView,onDelete } = props;
    
    const columns = useMemo(
        () => [
          {
            Header: "File Name",
            accessor: "fileName",
          },
          {
            Header: "Type",
            accessor: "type",
          },
          {
            Header: "Created Date",
            accessor: "createdDate",
          },

          {
            Header: "Valid Till",
            accessor: "validTill",
          },
          {
            Header: "Action",
            accessor: "buttonViewDelete",
          },
        
        ],
    );

   



  return (
    <Fragment>
        <DataTable
            includeSearchBox={false}
            includeExportButton={false}
            columns={columns}
            data={data}
            isLoading={isLoading}
            totalElements={totalElements}
            totalPages={totalPages}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            handleChange={handleSearch}
            commonFilter={commonFilter}
            onView={onView}
            onDelete={onDelete}
        />
    </Fragment>
  )
}

export default BannerList