export const listOrganizationData = [
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: '1',
    value11: '12324',
    id:'1'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '13',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'2'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '14',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'3'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'4'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'5'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'6'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'7'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'8'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912New',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'9'
  },
  {
    value1: 'Fleet',
    value2: 'KA53HA9912',
    value3: '07-06-2022 13:33:37',
    value4: '13.0315016',
    value5: '12',
    value6: 'some value three',
    value7: 'some value one 1',
    value8: ['Edit', 'Delete'],
    value9: 'some value three',
    value10: 'some value one 1',
    value11: '12324',
    id:'10'
  },
  {
    countryCode: "1",
    email: "abc@gmail.com",
    firstName: "shakthi",
    lastName: "devi",
    mobileNum: "7674",
    orgAddress: "vyrvfy",
    orgCity: "1",
    orgCountry: "1",
    orgDomain: "1",
    orgName: "shsduev",
    orgPinCode: "12`32",
    orgState: "1",
    orgTimezone: "1",
    organizationType: "3",
    parentOrg: "1",
    userName: "cdwfr"
  }
]