import { dashboardApi } from "../ApiSlice";

const SimApiSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        getSimDetails: builder.query({
            query: (body) => {
            return {
                url: `/sim/getSim?id=${body}`,
                method: "GET",
            }},
        }),
        // Histroy
        getSimActivity: builder.mutation({
            query: (body) => {
            return {
                url: "/sim/getSingleSimHistory",
                method: "POST",
                body: body,
            }},
        }),
    })
})

export const { useLazyGetSimDetailsQuery, useGetSimActivityMutation } = SimApiSlice;