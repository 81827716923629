import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  FormIconStack,
  StyledFilterFormControl,
  StyledFiterButton,
  StyledFormControlLabel,
  StyledPaperShadow,
} from "../../theme/StyledEle";
import {
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
} from "../../store/slice/ApiSlice";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import {
  Box,
  ClickAwayListener,
  InputBase,
  ListItemButton,
  Menu,
  MenuItem,
  SvgIcon,
  useTheme,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { DatePicker as AntMonthPicker } from "antd";
import { Calender } from "../../constants/DualIcons";
import DatepickerDropdown from "../AdminDropdown/DatepickerDropdown";

function FeedLogDropdown(props) {
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;
  const theme = useTheme();

  //Form Props
  const {
    getData,
    isShowAll = true,
    monthPicker = false,
    allowDays,
    last7 = true,
    thisMonth = false,
    currentMonth = false,
    showCalendar = true,
    onload = true,
    value = 0,
    tabValues,
    allowedMonth
  } = props;

  //State for Dropdowns
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [imeiList, setImeiList] = useState(null);

  const [fromDate, setFromDate] = useState(
    moment().format("YYYY-MM-DD 00:00:00")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [selectedPriod, setSelectedPriod] = useState(1); // Only for vehicle summary report
  const [activeLabel, setActiveLabel] = useState("today");
  //All Single State
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [imei, setImei] = useState(null);
  const [allOption, setAllOption] = useState(isShowAll);
  const [load, setLoad] = useState(false);

  //
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);
  const [label, setLabel] = useState("Today");


  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");

  const GetDate = (fromDate, toDate,name) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLabel(name);
  };

  //Get Region List
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();
  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();
  const [
    getImeiList,
    { data: imeidata, isLoading: imeiLoading, isSuccess: imeiSuccess },
  ] = useGetDropdownListMutation();
  let CommonPayload = {
    resellerId: resellerId,
  };

  //Treeview Work based this logins
  /* 
  +----+-------------------------+--------+------+
  | ID | ROLE_DESC               | STATUS | TYPE |
  +----+-------------------------+--------+------+
  |  6 | Organization Admin      | Active |    2 |
  |  9 | Customer Admin          | Active |    3 |
  |  20 | Dealer Admin          | Active |    3 |
  */

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };
  //Dealor Login Payload
  let DealorPayload = {
    resellerId: resellerId, // Set Login Dealer ID
    value: "customerss",
    dealerId: orgId,
    searchText: "",
  };
  //Customer Login Payload
  let CustomerPayload = {
    resellerId: resellerId, // Set Login Customer ID
    value: "fleet",
    dealerId: "0",
    customerId: orgId,
    searchText: "",
  };
  //Fleet Payload
  let FleetPayload = {
    value: "orgId",
    paramValue: "113849", // Set Login Fleet ID
    searchText: "",
  };

  //Based Login Implemented
  const reportsOnload = () => {
    switch (RoleId) {
      case 6:
        getRegionList(regionPayload);
        break;
      case 17:
        getRegionList(regionPayload);
        break;
      case 20:
        getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 9:
        getFleet(orgId);
        setCustomer(orgId);
    }
  };

  useEffect(() => {
    reportsOnload();
  }, []);

  useEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
      if (!allOption && selectedVehicle === null) {
        getDealer(regionData?.data?.content[regionData?.data.content?.length - 1].key);
        setLoad(true);
      } else if (search && selectedVehicle !== null) {
        getDealer(selectedVehicle[0].regionId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setRegion("-1");
    }
  }, [regionSuccess]);

  //getDealer
  const getDealer = (regionId) => {
    setRegion(regionId);

    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setDealer("-1");
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setImei("-1");
        setDealerList(null);
        setCustomerList(null);
        setFleetList(null);
        setVehicleList(null);
        setImeiList(null);
      }
    } else {
      setDealer("-1");
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setImei("-1");
      setDealerList(null);
      setCustomerList(null);
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
      setImeiList(null);
    }
  };
  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getCustomer(dealerdata?.data.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setDealer("-1");
    }
  }, [dealerSuccess]);

  //getCustomer
  const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = dealerId;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setImei("-1");
        setCustomerList(null);
        setFleetList(null);
        setVehicleList(null);
        setImeiList(null);
      }
    } else {
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setImei("-1");
      setCustomerList(null);
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
      setImeiList(null);
    }
  };
  useLayoutEffect(() => {
    if (customerSuccess) {
      setCustomerList(customerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getFleet(customerdata?.data?.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setCustomer("-1");
    }
  }, [customerSuccess]);

  //getFleet
  const getFleet = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setFleet("-1");
        setVehicle("-1");
        setImei("-1");
        setFleetList(null);
        setVehicleList(null);
        setImeiList(null);
      }
    } else {
      setFleet("-1");
      setVehicle("-1");
      setImei("-1");
      setFleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
      setImeiList(null);
    }
  };

  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
      if (!allOption && selectedVehicle === null) {
        getVehicle(fleetdata?.data?.content[0]?.key);
      } else if (search && selectedVehicle !== null) {
        getVehicle(selectedVehicle[0]?.fleetId);
      }
      if (RoleId === 9) {
        getVehicle(fleetdata?.data?.content[0]?.key);
      }
    }
    if (allOption && selectedVehicle === null) {
      setFleet("-1");
    }
  }, [fleetSuccess]);


  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //Get Vehicle
  const getVehicle = (fleetId) => {
    setFleet(fleetId);
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if(search){
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
      // if(allOption && selectedVehicle === null){
      //     setVehicle('-1');
      //     setVehicleList(null);
      // }
    } else {
      setVehicle("-1");
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };
  useLayoutEffect(() => {
    if (vehicleSuccess) {
      setVehicleList(vehicledata?.data);
      setImeiList(vehicledata?.data);
      if (search) {
        setSearch(false);
      }
      // if(selectedVehicle !== null){
      //     setVehicle(selectedVehicle[0].key);
      //     setVehicleList(vehicledata?.data);
      // }

      if (!allOption && selectedVehicle === null) {
        selectVehicle(vehicledata?.data?.content[0]?.key);
        //selectIemi(vehicledata?.data?.content[0]?.refVal);
        setLoad(true);
      }
      if (fleet !== undefined && RoleId === 9 && fleet !== null) {
        setLoad(true);
      }
    }
    if (allOption && selectedVehicle === null) {
      setVehicle("-1");
      setImei("-1");
      setLoad(true);
    }
    if (RoleId !== 9) {
      setLoad(true);
    }
  }, [vehicleSuccess]);

  //Select Vehicle
  const selectVehicle = (vehicleId, data) => {
    //setSelectedVehicle(null);
    setVehicle(vehicleId);
    if (search && vehicleId !== "-1" && vehicleId !== undefined) {
      const SelctedVehicleData = searchVehicles?.data?.content.filter(
        (item) => item.key === vehicleId
      );
      if (!allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setVehicle(SelctedVehicleData[0]?.key);
        setImei(SelctedVehicleData[0]?.refVal);
      }
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setVehicle(SelctedVehicleData[0]?.key);
        setImei(SelctedVehicleData[0]?.refVal);
      }

      setSelectedVehicle(SelctedVehicleData);
      //getVehicle(SelctedVehicleData[0].fleetId);
      setSearchText({ value: null });
    }

    if (vehicleId === "-1") {
      setSelectedVehicle(null);
      getVehicle(fleet);
      setVehicle("-1");
    }

    if (vehicleId !== "-1" && !search) {
      let SelectVehicleIemi = vehicledata?.data?.content?.find(
        (item, index) => item.key === vehicleId
      );
      setImei(SelectVehicleIemi?.refVal);
    }
  };
  useLayoutEffect(() => {
    if (tabValues === 43) {
      if (imeiSuccess) {
        setImeiList(imeidata?.data);
        selectIemi(imeidata?.data?.content[0]?.value);
      }
    }
  }, [imeiSuccess]);

  //Handle IEMI Select
  const selectIemi = (iemiId) => {
    setImei(iemiId);
    if (tabValues !== 43) {
      if (iemiId !== "-1") {
        let getVehicleId = vehicledata?.data?.content?.find(
          (item, index) => item.refVal === iemiId
        );
        setVehicle(getVehicleId?.key);
      } else {
        setVehicle("-1");
        setSelectedVehicle(null);
      }
    }
    setSearchText({ value: null });
  };

  useEffect(() => {
    if (!search) {
      setSelectedVehicle(null);
    }
  }, [search]);

  // useEffect(() => {
  //     if(selectedVehicle !== null){
  //             if(RoleId === 6){
  //                 setRegion(selectedVehicle[0]?.regionId);
  //                 getDealer(selectedVehicle[0]?.regionId);
  //                 setVehicle(selectedVehicle[0]?.key);
  //             }else if(RoleId === 9){
  //                 setRegion(0);
  //                 setDealer(0);
  //                 setVehicle(selectedVehicle[0]?.key);
  //             }
  //     }else{
  //     }
  // },[selectedVehicle])

  //-----------------------------------------------------------------------------------------------------------------
  // vehicleSearch

  useEffect(() => {
    CommonPayload.value = "imei";
    CommonPayload.searchText = "";
    if (tabValues === 43) {
      getImeiList(CommonPayload);
    }
  }, [tabValues]);

  const vehicleSearch = (searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  };
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };
  const ImeiSearch = (searchText) => {
    CommonPayload.value = "imei";
    CommonPayload.searchText = searchText;
    if (tabValues === 43) {
      getImeiList(CommonPayload);
    }
  };
  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Vehicle") {
      setSearchText({ value: searchText });
      setSearchText({ name: name });
      setSearch(true);
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    } else if (name === "IMEI") {
      ImeiSearch(searchText);
    }
  };

  useEffect(() => {
    if (!allOption && load && vehicle === vehicle && onload) {
      getFormData();
    }
  }, [vehicle]);

  useEffect(() => {
    if (RoleId === 9) {
      setFleet(fleetdata?.data?.content[0]?.key);
    }
  }, [fleetdata, fleet]);


  const getInnerText = (value, label) =>{
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }
  }



  //getForm Data
  const getFormData = () => {
    let FormData = {
      regionId: RoleId === 9 ? 0 : RoleId === 20 ? 0 : region,
      dealerId: RoleId === 9 ? 0 : RoleId === 20 ? orgId : dealer,
      customerId: RoleId === 9 ? orgId : customer,
      fleetId: fleet,
      vehicleId: vehicle,
      fromDate: fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      selectedPriod: selectedPriod,
      imei: imei,
      regionName:regionName,
      dealerName:dealerName,
      customerName:customerName,
      fleetName:fleetName,
      vehicleName:vehicleName,
      selectedPeriodName:label!==undefined?label:"",
    };
    //Send data to report
    getData(FormData);
  };

  //Onload Time get Data
  useEffect(() => {
    if (load && allOption && region !== null) {
      getFormData();
    } else if (load && region !== null) {
      getFormData();
    }
  }, [load]);

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {RoleId !== 20 &&
        RoleId !== 9 &&
        (tabValues === 45 || tabValues === 46 || tabValues === 47) && (
          <SingleDropdown
            label={"Region"}
            listItems={regionList}
            handleChange={getDealer}
            value={region}
            isSearch={dropDownSearch}
            showAll={allOption}
            getInnerText={getInnerText}
          />
        )}

      {RoleId !== 20 &&
        RoleId !== 9 &&
        (tabValues === 45 || tabValues === 46 || tabValues === 47) && (
          <SingleDropdown
            label={"Dealer"}
            listItems={dealerList}
            handleChange={getCustomer}
            value={dealer}
            isSearch={dropDownSearch}
            showAll={allOption}
            getInnerText={getInnerText}
          />
        )}

      {RoleId !== 9 &&
        (tabValues === 45 || tabValues === 46 || tabValues === 47) && (
          <SingleDropdown
            label={"Customer"}
            listItems={customerList}
            handleChange={getFleet}
            value={customer}
            isSearch={dropDownSearch}
            showAll={allOption}
            getInnerText={getInnerText}
          />
        )}
      {(tabValues === 45 || tabValues === 46 ||tabValues === 47) && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getVehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={RoleId === 9 ? !allOption : allOption}
          getInnerText={getInnerText}
        />
      )}
      {value === 0 &&
        (tabValues === 45 || tabValues === 46 || tabValues === 47) && (
          <SingleDropdown
            label={"Vehicle"}
            listItems={vehicleList}
            onClick={() => setSearch(false)}
            handleChange={selectVehicle}
            value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
            isSearch={dropDownSearch}
            searchText={searchText}
            showAll={allOption}
            getInnerText={getInnerText}
          />
        )}
      {value === 0 &&
        (tabValues === 43 ||
          tabValues === 45 ||
          tabValues === 46 ||
          tabValues === 47) && (
          <SingleDropdown
            label={"IMEI"}
            listItems={imeiList}
            onClick={() => setSearch(false)}
            handleChange={selectIemi}
            value={imei}
            isSearch={tabValues === 43 ? dropDownSearch : false}
            showAll={tabValues === 43 ? true : allOption}
          />
        )}

      <DatepickerDropdown
        getDate={GetDate}
        activeLabel={activeLabel}
        showAll={false}
        showLast7Days={false}
        time={true}
        allowedMonth={allowedMonth}
        allowDays={allowDays}
      />
      {/* Submit */}
      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={getFormData}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );
}

export default FeedLogDropdown;
