import * as React from "react";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  TextField,
} from "@mui/material";
import { SearchTextBox } from "../../theme/StyledEle";
import { FiSearch } from "react-icons/fi";


export default function SearchBox({ filterText, onSearch }) {
  const handleSearch = (searchText) => {
    onSearch(searchText);
  };
  return (
    <Box>
      <SearchTextBox
      placeholder="Search"
      value={filterText}
      onChange={(e) => handleSearch(e.target.value)}
      size="small"
    />
          <IconButton size="small" disabled>
        <FiSearch/>
      </IconButton>
    </Box>
  );
}
