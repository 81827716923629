import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-hook-form";
import {
    MenuItem,
    Button,
    FormHelperText,
    CardActions,
    CardHeader,
    Box,
    Grid,
    CardContent,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputAdornment,
    Stack,
    Collapse,
    Divider,
    useTheme,
} from "@mui/material";
import {
    SoftButton,
    StyledCardShadow,
    StyledDropDown,
    StyledFilterSearch,
    StyledFormButtonsRow,
    StyledFormControl,
    StyledFormControlLabel,
    StyledPaperShadow,
    StyledTableTitle,
    StyledTextField,
} from "../../theme/StyledEle";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useServiceDetailSaveMutation } from "../../store/slice/ReportSlice/ServiceSlaSlice";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import dropdownServices from "../../services/dropdownServices";
import SearchIcon from "@mui/icons-material/Search";

const CreateServiceDetails = ({serviceToEdit,onCancel}) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    const [clickedval, setClickedval] = useState(false);
    let showroomId = 0;
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        watch,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: {
            showroomRadius: '100',

        }
    });

    const [dealers, setDealers] = useState([]);
    const [dealer, setDealer] = useState(0);
    const [dealerName, setDealerName] = useState(null);

    
  const [
    serviceDetailSave,
    {
      data: serviceDetailSaveData,
      isLoading: serviceDetailSaveLoading,
      isSuccess: serviceDetailSaveSuccess,
    },
  ] = useServiceDetailSaveMutation();

  const [
    serviceDetailUpdate,
    {
      data: serviceDetailUpdateData,
      isLoading: serviceDetailUpdateLoading,
      isSuccess: serviceDetailUpdateSuccess,
    },
  ] = useServiceDetailSaveMutation();

    const showroomRadiusList = [
        {
            id: 1,
            title: "100",
            value: 100,
        },
        {
            id: 2,
            title: "200",
            value: 200,
        },
        {
            id: 3,
            title: "300",
            value: 300,
        },
        {
            id: 4,
            title: "400",
            value: 400,
        },
        {
            id: 5,
            title: "500",
            value: 500,
        },

    ];

    useEffect(async () => {
        getDealer();
        const formData = new FormData();
        formData.append("resellerId", resellerId);
        if(serviceToEdit){
            formData.append("id", serviceToEdit.id);
            setDealer(serviceToEdit.dealerId);
            let obj={
                ...serviceToEdit,
                showroomLat:Number(serviceToEdit.gpsLocation.split(",")[0]),
                showroomLong:Number(serviceToEdit.gpsLocation.split(",")[1])

            };
            reset(obj);
        }
    }, []);

    const getDealer = async () => {
        const dealerListPayload = {
            value: "dealer",
            resellerId: resellerId,
            searchText: "",
            regionId: -1,
        };
        const { data: dealerListResponse } = await dropdownServices.getResellers(
            dealerListPayload
        );
        const { content: dealerList } = dealerListResponse.data;
        setDealers(dealerList);
        if(!serviceToEdit){
            setDealer(dealerList.length > 0 ? dealerList[0].key : 0);
        }       
        setDealerName(dealerList.length > 0 ? dealerList[0].value : "");
    };

    const onSubmit = (data) => {
        data.gpsLocation=data.showroomLat+","+data.showroomLong;
        data.dealerId=dealer;
        data.resellerId=resellerId;
        if(data.id>0){
            serviceDetailUpdate(data);
        }else{
            serviceDetailSave(data);
        }
    };
    useEffect(async () => {

        if (serviceDetailUpdateSuccess) {
            console.log(serviceDetailUpdateData?.resultText);
          dispatch(
            snackService([
                serviceDetailUpdateData?.resultText ==="success"?"Service Center Updated Sucessfully":"Failed to Update Service Center",
                serviceDetailUpdateData?.resultText ==="success"?"Success":"Failed",
                serviceDetailUpdateSuccess,
            ])
          );
          if(serviceDetailUpdateData?.resultText ==="success"){
            onCancel();
          }
        }else if (serviceDetailSaveSuccess) {
          dispatch(
            snackService([
                serviceDetailSaveData?.resultText ==="success"?"Service Center Saved Sucessfully":"Failed to Create Service Center",
                serviceDetailSaveData?.resultText ==="success"?"Success":"Failed",
                serviceDetailSaveSuccess,
            ])
          );
          if(serviceDetailSaveData?.resultText==="success"){
            onCancel();
          }
         
        }
    },[serviceDetailUpdateSuccess,serviceDetailSaveSuccess]);   

    const handleCancelClick = () => {
        onCancel();
      };

    const handleSearchDropdown = async (searchText, name) => {
        if (name === "dealer") {
            const dealerListPayload = {
                value: "dealer",
                resellerId: resellerId,
                searchText: searchText,
                regionId: -1,
            };
            const { data: dealerListResponse } = await dropdownServices.getResellers(
                dealerListPayload
            );
            const { content: dealerList } = dealerListResponse.data;
            setDealers(dealerList);
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledCardShadow>
                    <Collapse
                        in={true}
                        sx={{
                            border: `.5px solid ${theme.palette.divider}`,
                            borderRadius: "10px",
                        }}

                    >
                        <Box sx={{ px: 2, py: 1, my: 0.5 }}>
                            <Stack
                                direction={"row"}
                                spacing={1}
                                alignItems={"end"}
                                divider={<Divider orientation="vertical" flexItem />}
                            >
                                <Stack
                                    direction={"row"}
                                    alignItems={"end"}
                                    spacing={1.5}
                                    sx={{ Width: "100%" }}
                                >
                                    <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                                        <StyledFormControlLabel required>
                                            Dealer
                                        </StyledFormControlLabel>
                                        <StyledDropDown
                                            size="small"
                                            placeholder=""
                                            value={dealer}
                                            onChange={(event, newPlan) => {
                                                setDealer(event.target.value);
                                            }}
                                            disabled={serviceToEdit !== null ? true : false}
                                        >
                                            <StyledFilterSearch
                                                size="small"
                                                name="dealer"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SearchIcon sx={{ fontSize: "inherit" }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) =>
                                                    handleSearchDropdown(e.target.value, e.target.name)
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                            {dealers.length > 0 &&
                                                dealers.map((option, index) => {
                                                    return (
                                                        <MenuItem key={index} value={option.key}>
                                                            {option.value}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </StyledDropDown>
                                    </StyledFormControl>
                                </Stack>
                            </Stack>
                        </Box>
                    </Collapse>
                </StyledCardShadow>
                <StyledCardShadow elevation={0} sx={{ mt: 2 }}>
                    <CardHeader
                        title={<StyledTableTitle>Service Center Details</StyledTableTitle>}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={6}>
                                <StyledFormControl fullWidth>
                                    <StyledFormControlLabel required>
                                        Service Location
                                    </StyledFormControlLabel>
                                    <StyledTextField
                                        error={errors.showroomName && true}
                                        size="small"
                                        fullWidth
                                        {...register("showroomName", {
                                            required: {
                                                value: true,
                                                message: "Required Field ",
                                            },
                                            validate: {
                                                emailAvailable: async (fieldValue) => {
                                                    const formData = new FormData();
                                                    formData.append("showroomName", fieldValue);
                                                    formData.append("id", showroomId);
                                                    return fieldValue !== "" || " Required Field";
                                                },
                                            },
                                        })}
                                        onChange={(e) => setValue("showroomName", e.target.value)}
                                        helperText={errors.showroomName && errors.showroomName.message}
                                        inputProps={{ maxLength: "25" }}
                                        disabled={clickedval}
                                    />
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl fullWidth error={errors.showroomRadius}>
                                    <StyledFormControlLabel required>
                                        Radius
                                    </StyledFormControlLabel>
                                    <StyledDropDown
                                        {...register("showroomRadius", {
                                            required: {
                                                value: true,
                                                message: "Required Field",
                                            },
                                            validate: (fieldValue) => {
                                                return fieldValue !== "0" || " Required Field";
                                            },
                                        })}
                                        onChange={(e) => {
                                            setValue("showroomRadius", e.target.value);
                                        }}
                                        defaultValue={
                                            serviceToEdit !== null ? serviceToEdit?.showroomRadius : 100
                                        }

                                        size="small"
                                        disabled={clickedval}
                                        value={watch('showroomRadius')}
                                    >
                                        {showroomRadiusList.length > 0 &&
                                            showroomRadiusList.map((option, index) => {
                                                return (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                );
                                            })}
                                    </StyledDropDown>
                                    {errors.showroomRadius && (
                                        <FormHelperText>
                                            {errors.showroomRadius.message}
                                        </FormHelperText>
                                    )}
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl fullWidth>
                                    <StyledFormControlLabel required>
                                        Service Center Latitude
                                    </StyledFormControlLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        error={errors.showroomLat && true}
                                        {...register("showroomLat", {
                                            required: {
                                                value: true,
                                                message: "Required Field ",
                                            },
                                            pattern: {
                                                value: /^-?\d*\.?\d*$/,
                                                message: "Invalid Latitude Address",
                                              },
                                        })}
                                        onChange={(e) => setValue("showroomLat", e.target.value)}
                                        helperText={errors.showroomLat && errors.showroomLat.message}
                                        inputProps={{ maxLength: "12" }}
                                        disabled={clickedval}
                                    />
                                </StyledFormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <StyledFormControl fullWidth>
                                    <StyledFormControlLabel required>
                                        Service Center Longitude
                                    </StyledFormControlLabel>
                                    <StyledTextField
                                        fullWidth
                                        size="small"
                                        error={errors.showroomLong && true}
                                        {...register("showroomLong", {
                                            required: {
                                                value: true,
                                                message: "Required Field ",
                                            },
                                            pattern: {
                                                value: "/^-?\d*\.?\d*$/",
                                                message: "Invalid Longitude Address",
                                              },
                                        })}
                                        onChange={(e) => setValue("showroomLong", e.target.value)}
                                        helperText={errors.showroomLong && errors.showroomLong.message}
                                        inputProps={{ maxLength: "12" }}
                                        disabled={clickedval}
                                    />
                                </StyledFormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </StyledCardShadow>
                <CardActions>
          <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
            <SoftButton size="small" onClick={handleCancelClick}  className="error"  color="error">
              Cancel
            </SoftButton>
          {!clickedval &&  <SoftButton size="small" className="success"  color="success" type="submit">
              {serviceToEdit ? "Update" : "Submit"}
            </SoftButton> }
          </StyledFormButtonsRow>
        </CardActions>
            </form>
        </Box>
        

    );
};

export default CreateServiceDetails;