import Service from "./service";


const SimOnBoardServices = {

  deleteSim: (id) => {
      return Service.post('/simOnBoard/deleteSim', {id})
    },
  
    updateSim: (data) => {
      return Service.post('/simOnBoard/updateSim',data,null)
    },
    getSim: function (resource) {
      return Service.post('/simOnBoard/listSim',resource,null)
    },
    simView: function (id) {
      return Service.get('/simOnBoard/getSimView',{id})
    },
    getSimHistory: function (resource) {
      return Service.post('/simOnBoard/listSimHistory',resource,null)
    },
      saveSim: function (data){
        return Service.post('/simOnBoard/createSim',data,null)
    },
    // deleteSimById: (orgId) => {
    //   return Service.post("/sim/deleteSim", orgId, null);
    // },
    editSimById: function (id) {
      return Service.get('/simOnBoard/getSim', {id})
    },
    getSimPlanStatus: function(){
      return Service.post('/simOnBoard/getSimPlanStatus', null,null)
    },
    getSimProvider: function(){
      return Service.post('/simOnBoard/simProvider', null,null)
    },
    getSimPlanList: function(data){
      return Service.post('/simOnBoard/simPlanList', data,null)
    },
    validateSim: function (data) {
      return Service.post('/simOnBoard/validateExcel',
        data,null,
        { "Content-Type": "multipart/form-data", accept: "*/*" }
      );
    },
    processSim: function (data) {
      return Service.post('/simOnBoard/processExcel',
        data,null,
        { "Content-Type": "multipart/form-data", accept: "*/*" }
      );
    },
    
  };
  

  
  export default SimOnBoardServices;