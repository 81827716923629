import { Circle, InfoWindow, Marker, Polygon } from '@react-google-maps/api'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledInfoWindow } from '../Treeview/TreeviewStyledEle';
import { Box, Divider, Stack, Typography, alpha, useTheme } from '@mui/material';


function RouteMarkers(props) {
  
    const { data } = props;
    const theme = useTheme();
    const [openMarkerId, setOpenMarkerId] = useState(false);
    let getMarkerData = (zoneDetails) => {
        setOpenMarkerId(zoneDetails);
    };

    const circleOptions = {
      fillColor:theme.palette.secondary.main,
      fillOpacity:.5,
      strokeWeight:0,
    }

  

            return (
                <>
                {
                    data.map((item, index) => {
                        let number = item.sequenceNo.toString();
                        let coords = item.geoZoneType === 'Polygons' && JSON.parse(item.cordinates !== undefined ? item.cordinates : "");
                        
                        return(
                          item.geoZoneType !== 'Polygons' ?
                            <>
                            <Marker
                            position={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
                            label={{text:number, color:'#fff'}}
                            zIndex={9999}
                            onClick={() => getMarkerData(index)}
                            clickable
                            />
                             <Circle 
                                center={{lat:parseFloat(item.latitude), lng:parseFloat(item.longitude)}}
                                radius={item.radius}
                                options={circleOptions}
                             />
                    { openMarkerId === index &&
                        <InfoWindow
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -80),
                        }}
                        position={{
                          lat: parseFloat(item.latitude),
                          lng: parseFloat(item.longitude),
                        }}
                        onCloseClick={() => getMarkerData(false)}
                      >
                        <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                        <Box
                              className='infowindowHHeader'
                              sx={{background:theme.palette.warning.main, width:'100%'}}
                          >
                             <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                            <Box>
                              <Typography variant='caption'>Zone Name</Typography>
                              <Typography variant='subtitle2'>{item?.geoZoneName}</Typography>
                            </Box>
                        </Stack>
                          </Box>
                          <Box sx={{padding:'5px 15px', width:'100%'}}>
                          <Stack
                            direction="column"
                            justifyContent="space-between"
                            spacing={.5}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Typography variant="caption" sx={{ width: "25%" }}>
                                Radius
                              </Typography>
                              <Typography variant="subtitle2">
                                {item?.radius} m
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="caption">Address</Typography>
                              <Typography variant="subtitle2">
                                {item.address === undefined ? "" : item.address}
                              </Typography>
                            </Box>
                          </Stack>
                          </Box>
                        </StyledInfoWindow>
                        </InfoWindow>
                    }
                
                
                            </> : 
                            <>
                              <Marker
                                position={coords[0]}
                                label={{text:number, color:'#fff'}}
                                zIndex={9999}
                                onClick={() => getMarkerData(index)}
                                clickable
                            />
                            <Polygon
                              path={coords}
                              options={{ 
                                strokeColor: theme.palette.secondary.main,
                                fillColor: theme.palette.secondary.main,
                               }}
                            />
                            { openMarkerId === index &&
                        <InfoWindow
                        options={{
                          pixelOffset: new window.google.maps.Size(0, -80),
                        }}
                        position={coords[0]}
                        onCloseClick={() => getMarkerData(false)}
                      >
                        <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                        <Box
                              className='infowindowHHeader'
                              sx={{background:theme.palette.warning.main, width:'100%'}}
                          >
                             <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                            <Box>
                              <Typography variant='caption'>Zone Name</Typography>
                              <Typography variant='subtitle2'>{item?.geoZoneName}</Typography>
                            </Box>
                        </Stack>
                          </Box>
                          <Box sx={{padding:'5px 15px', width:'100%'}}>
                          <Stack
                            direction="column"
                            justifyContent="space-between"
                            spacing={.5}
                          >
                            <Box>
                              <Typography variant="caption">Address</Typography>
                              <Typography variant="subtitle2">
                                {item.address === undefined ? "" : item.address}
                              </Typography>
                            </Box>
                          </Stack>
                          </Box>
                        </StyledInfoWindow>
                        </InfoWindow>
                    }
                            </>
                        )
                    })
                }
                
               
                </>
                
    )
}

export default RouteMarkers