

const JWTOKEN = "jwToken";
const RFRSHJWTOKEN = "rshjwToken";
const EXPJWTOKEN = "expjwToken";

export const getToken = () => {
    return window.localStorage.getItem(JWTOKEN);
}
export const getRefreshToken = () => {
    return window.localStorage.getItem(RFRSHJWTOKEN);
}
export const saveToken = (token,refreshtoken) => {
    window.localStorage.setItem(JWTOKEN,JSON.stringify(token));
    window.localStorage.setItem(RFRSHJWTOKEN,refreshtoken);
    //window.localStorage.setItem(EXPJWTOKEN,"false");
    window.localStorage.setItem(EXPJWTOKEN,null);
    
};
export const saveExpToken = () => {
    window.localStorage.setItem(EXPJWTOKEN,"true");
}
export const deleteRefreshToken = () => {
    window.localStorage.removeItem(RFRSHJWTOKEN);  
}

export const deleteToken = () => {
    window.localStorage.removeItem(JWTOKEN);      
    localStorage.removeItem("isRefreshToken");
};
export const deleteExpJwToken = () => {
window.localStorage.removeItem(EXPJWTOKEN);  
}

export default {
    getToken,
    saveToken,
    getRefreshToken,
    saveExpToken,
    deleteToken,
    deleteRefreshToken    
};