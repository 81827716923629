import { Box } from '@mui/material'
import React from 'react'
import VehicleMainSumary from './VehicleMainSumary';

function SummaryWindow(props) {
    const { position, trailsData, showCategory } = props;
    
    let top = position.current?.getBoundingClientRect().top;
    let left = position.current?.getBoundingClientRect().width;
    let height = position.current?.getBoundingClientRect().height;
  return (
    <Box sx={{position:'absolute', top:`${top}px`, left:`${left + 50}px`, background:'#fff', width:'fit-content', height:height, borderRadius:'10px', boxShadow:'0px 5px 10px rgba(0,0,0,.1)'}}>
        <VehicleMainSumary trailsData={trailsData} height={height} showCategory={showCategory}/>
    </Box>  
  )
}

export default SummaryWindow