import { React, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Checkbox,
  Button,
  Divider,
  Stack,
  Grid,
  ToggleButtonGroup,
  Snackbar,
  Alert,  
} from "@mui/material/";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { FormControl } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {TextField,Dialog,DialogActions,DialogContent} from "@mui/material/";
import usernameService from "../../services/userServices";
import DraggableDialog from "../shared/DailogDrag";
import { ToggleButton } from "@mui/material";
import { StyledToggleButtonGroup } from "../../theme/StyledEle";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleTreeView } from "../../store/slice/ControlsSlice";
export default function TreeView() {
  const [checked, setChecked] = useState(["email"]);
  //var userData={};
  //userData=JSON.parse(localStorage.getItem("userData"));
  

  const [value, setValue] = useState(JSON.parse(localStorage.getItem("userData")).displayView === true ? "1" : "0");
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  
  var globaluserId=1;
  var globalJson={};
  if("null"===localStorage.getItem("userData") || null===localStorage.getItem("userData")){    
  } else{
    globalJson=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):0;
    globaluserId=globalJson.id;
  }


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const dispatch = useDispatch();

  const changeView = (event, newValue) => {
    setValue(newValue);
  }
  useEffect(() => {
    dispatch(toggleTreeView(value));
  },[])

  const handleClose = () => {
    setOpen(false);
   };

  const fncall=async ()=>{
    
    var obj={};
    obj.displayvalue=value;
    obj.userId=globaluserId;
    const responseData=  (await usernameService.saveTreeViewDetails(obj))  
    
    var obj1 = {};
    if (responseData.data === "success") {
      dispatch(toggleTreeView(value));
      var userData={};
      userData=JSON.parse(localStorage.getItem("userData"));
      if(value==1){
        userData.displayView=true;
      }else{
        userData.displayView=false;
      }
      localStorage.setItem("userData",JSON.stringify(userData));
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Saved Successfully";
      
    } else {
      obj1.dialogButtonText = "Saved Status";
      obj1.submitButtonText = "";
      obj1.closeButtonText = "Close";
      obj1.dialogText = "Data Not Saved Properly";
    }
    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(false);
    setOpen(true);
    
    if (responseData.status === 200) {
        setTimeout(() =>   {setOpen(false);setDataStatus(false); setDataStatusConfirm(true);}, 2000);
    }
  }

  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };


  
  return (
    <Box>
            {dataStatusConfirm !== false && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          dialogButtonText={dialogContentConfirm.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContentConfirm.submitButtonText}
          closeButtonText={dialogContentConfirm.closeButtonText}
          handleClearCls={handleClearCls}
          sx={{mt:10}}
        >
          <Alert severity="success">
              {dialogContent.dialogText}
          </Alert>
        </Snackbar>
      )}
      {dataStatus !== false && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          dialogButtonText={dialogContent.dialogButtonText}
          handleSbmtClose={handleSbmtClose}
          submitButtonText={dialogContent.submitButtonText}
          closeButtonText={dialogContent.closeButtonText}
          handleClearCls={handleClearCls}
          color="success"
          sx={{mt:10}}
        >
          <Alert severity="success">
              {dialogContent.dialogText}
          </Alert>
        </Snackbar>
      )}
      <Typography variant="h6" gutterBottom sx={{mb:4}}>Tree View Settings</Typography>
         <StyledToggleButtonGroup
                row
                name="vehicleNoDisplay"
                value={value}
                onChange={changeView}
                exclusive
                fullWidth
                >
                <ToggleButton
                    value="0"
                    // checked={value === 1 ? true : false}
                >Customer Tree View</ToggleButton>
                <ToggleButton
                    value="1"
                    // checked={value === 2 ? true : false}
                >Dealer Tree View</ToggleButton>
                {/* <input type="radio" checked={value} /> */}
                </StyledToggleButtonGroup>
          <Stack
            direction="row"
            sx={{justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={fncall}
              sx={{mt:3}}
            >
              Apply
            </Button>
          
          </Stack>

    </Box>
  );
}
