import React, { useState } from "react";
import StyledTabs from "../shared/StyledTabs";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";
import DeviceOTALog from "./DeviceOTALog";
import DeviceOTASupport from "./DeviceOTASupport";
import { useLocation, useNavigate } from "react-router-dom";

const DeviceOTA = () => {
  const [tabValue, setTabValue] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue === 0){
      navigate('#otaSupport');
    }else{
      navigate(location.pathname);
    }
    
  };

  const handleCancel = () => {
    setTabValue(1);
  };

  const TABS = [
    {
      label: "Device-OTA Support",
      children: <DeviceOTASupport onCancel={handleCancel} />,
    },
    {
      label: "Device-OTA Log",
      children: <DeviceOTALog />,
    },
  ];

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="OTA" subDiv="Device-OTA Log"></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
    </StyledPageLayout>
  );
};

export default DeviceOTA;
