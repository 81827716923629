import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  CardContent
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import {
  StyledCardShadow,
  StyledPageLayout,
} from "../../theme/StyledEle";
import {useGetSlotDetailListMutation} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import TestDriveDropDown from "../NewDropdown/TestDriveDropdown";


const TestDriveReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgType = userData.userOrgType;
  let orgId =  userData.orgId;


  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);

  const [getSlotDetailList, { data: slotData, isLoading, isSuccess }] = useGetSlotDetailListMutation();

  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact No",
        accessor: "mobileNumber",
      },
      {
        Header: "Booking Date",
        accessor: "slotDate",
      },
      {
        Header: "Booking Id",
        accessor: "bookingId",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
    ],
    []
  );

  // Get Data from New Dropdown

  let commonPayload = {};
  const onSubmit = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.page = 0;
    commonPayload.size = 25;
    commonPayload.search = "";
    getSlotDetailList(commonPayload);
    setPayload(commonPayload);
  
  };

   
     //Search Text
     const handleSearch = async (searchText) => {
        setNewPage(0);
        setRowsPerPage(25);
        payload.page = 0;
        payload.rowsPerPage = 25;
       payload.search = searchText;
       if(payload.search.length >= 3){
         getSlotDetailList(payload);
       }else if(payload.search.length === 0){
        getSlotDetailList(payload);
       }
       
   };

   
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getSlotDetailList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getSlotDetailList(payload);
    }
  };

   useEffect(() => {
     if(isSuccess){
       setResponseData([]);
       if(slotData.data !== null){
        setResponseData(slotData.data.content);
        setTotalElements(slotData.data.totalElements);
        setTotalPages(slotData.data.totalPages);
      }

    }
  }, [isSuccess]);
  

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Admin"
        subDiv="Test Ride Details"
      ></Breadcrumb>
      <Box>
        <TestDriveDropDown
          getData={onSubmit}
          status={false}
          allowDays={1}
          lastMonth={false}
          currentMonth={false}
          thismonth={false}
          allowFutre={true}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
            <CardContent sx={{ py: 1 }}>
              <DataTable
                isLoading={isLoading}
                columns={columns}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}           
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
                includeSearchBox={true}
                handleChange={handleSearch}
                includeExportButton={false}
              />
            </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default TestDriveReport;
