import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button, CardContent, CardHeader } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";
import exporticon from "../../assets/images/exportexcel.png";
import { StyledCardShadow, StyledPageLayout, StyledPaperShadow, StyledTableTitle } from "../../theme/StyledEle";

const text =
  "Travel Summary Report gets the Summarized data about the vehicle's movement for a selected time period.You can choose any date range with a maximum of 31 days.";

const TravelSummaryReport = () => {
  const [responseData, setResponseData] = useState([]);

  //   useEffect(async () => {
  //     const responseData = (await ReportServices.getOverSpeed()).data.data;
  //     setResponseData(responseData);
  //   }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle No",
        accessor: "value1",
      },
      {
        Header: "Date",
        accessor: "value2",
      },
      {
        Header: "Start Location",
        accessor: "value3",
      },
      {
        Header: "End Location",
        accessor: "value4",
      },
      {
        Header: "Start Odometer",
        accessor: "value5",
      },
      {
        Header: "End Odometer",
        accessor: "value6",
      },
      {
        Header: "Distance(KM)",
        accessor: "value7",
      },
      {
        Header: "Energy Consumed",
        accessor: "value8",
      },
      {
        Header: "Idle(HH:MM)",
        accessor: "value9",
      },
      {
        Header: "Running(HH:MM)",
        accessor: "value10",
      },
      {
        Header: "Stop(HH:MM)",
        accessor: "value11",
      },
      {
        Header: "Charge Details",

        columns: [
          {
            Header: "Charging(HH:MM)",
            accessor: "charging",
          },
          {
            Header: "Charging Count",
            accessor: "chargingCount",
          },
          {
            Header: "Minimum Voltage",
            accessor: "minimumVoltage",
          },
          {
            Header: "Maximum Voltage",
            accessor: "maximumVoltage",
          },
        ],
      },
      {
        Header: "Harsh Usage(Count)",

        columns: [
          {
            Header: "Acceleration",
            accessor: "acceleration",
          },
          {
            Header: "Brake",
            accessor: "Brake",
          },
          {
            Header: "Cornering",
            accessor: "cornering",
          },
        ],
      },
      {
        Header: "Over Speeding",

        columns: [
          {
            Header: "Over Speed Count",
            accessor: "overSpeedCount",
          },
          {
            Header: "Over Speed Duration",
            accessor: "overSpeedDuration",
          },
        ],
      },
      {
        Header: "Vehicle Speed",

        columns: [
          {
            Header: "Maximum Speed",
            accessor: "maximumSpeed",
          },
          {
            Header: "Frequent Speed",
            accessor: "frequentSpeed",
          },
        ],
      },
    ],
    []
  );
  const onChangeXcl = async () => {
    var request = {};
    request.orgId = 103334;
    request.regionId = 0;
    request.reselId = 0;
    request.customerId = 0;
    request.vehId = 25909;
    request.startDate = "2022-10-02 00:00:00";
    request.endDate = "2022-10-02 23:59:59";
    request.sortBy = "desc";
    request.page = 0;
    request.size = 25;

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "EVDataReport/evDataXLS.xls?orgId=" +
        request.orgId +
        "&regionId=" +
        request.regionId +
        "&reselId=" +
        request.reselId +
        "&customerId=" +
        request.customerId +
        "&vehId=" +
        request.vehId +
        "&startDate=" +
        request.startDate +
        "&endDate=" +
        request.endDate +
        "&sortBy=" +
        request.sortBy +
        "&page=" +
        request.page +
        "&size=" +
        request.size;
      window.open(url);
    } catch (error) {}
  };

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Travel Summary" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={" Travel (Day-Wise) Summary"} />
      <StyledPaperShadow sx={{mb:2}}>
      <ReportDropDown />
      <Button
              variant="contained"
              size="small"
            >
              Submit
            </Button>
      </StyledPaperShadow>
      <Typography>
            <b>Note</b>:Travel Summary Report gets the Summarized data about the
            vehicle's movement for a selected time period.
          </Typography>
      <StyledCardShadow sx={{my:2}}>
        <CardHeader 
          title={<StyledTableTitle>Travel Summary</StyledTableTitle>}
        />
        <CardContent>
       
        <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
            />
        </CardContent>
      </StyledCardShadow>
      
            {/* <img
              alt="excel"
              style={{
                marginLeft: 20,
                width: 25,
                marginTop: 10,
                marginBottom: 20,
              }}
              onClick={onChangeXcl}
              title="Export Excel"
              src={exporticon}
            /> */}
    </StyledPageLayout>
  );
};

export default TravelSummaryReport;
