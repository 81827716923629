import Service from "./service";


const simServices = {

  deleteSim: (id) => {
      return Service.post('/sim/deleteSim', {id})
    },
  
    updateSim: (data) => {
      return Service.post('/sim/updateSim',data,null)
    },
    getSim: function (resource) {
      return Service.post('/sim/listSim',resource,null)
    },
    getSimHistory: function (resource) {
      return Service.post('/sim/listSimHistory',resource,null)
    },
      saveSim: function (data){
        return Service.post('/sim/createSim',data,null)
    },
    // deleteSimById: (orgId) => {
    //   return Service.post("/sim/deleteSim", orgId, null);
    // },
    editSimById: function (id) {
      return Service.get('/sim/getSim', {id})
    },
    getSimPlanStatus: function(){
      return Service.post('/sim/getSimPlanStatus', null,null)
    },
    getSimProvider: function(){
      return Service.post('/sim/simProvider', null,null)
    },
    getSimPlanList: function(data){
      return Service.post('/sim/simPlanList', data,null)
    },
    uploadSim: function (data) {
      return Service.post('/sim/uploadExcel',
        data,null,
        { "Content-Type": "multipart/form-data", accept: "*/*" }
      );
    },
    processSim: function (data) {
      return Service.post('/sim/processExcel',
        data,null,
        { "Content-Type": "multipart/form-data", accept: "*/*" }
      );
    },
    chkSimNumber: function(data){
      return Service.post('/sim/chkSimNumber', data,null)
    },
    chkMobileNumber: function(data){
      return Service.post('/sim/chkMobileNumber', data,null)
    },
    
  };
  

  
  export default simServices;