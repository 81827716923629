import { useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { AccordionSummary } from "@mui/material";

export const StyledTreeViewAccordianSummary = styled(AccordionSummary)(({ theme }) => ({
  gap: 0.1, 
  pointerEvents: "none",
  position: "relative",
  paddingTop:"12px",
  paddingBottom:"12px",
  overflow:'hidden',
  minHeight:'auto !important',
  color:'#28282b',
  //borderBottom:'1px solid #888',
  "&:before": {
    pointerEvents: "none",
    content: '""',
    position: "absolute",
    width: 32,
    left: 32,
    top: 0,     
    borderLeft: "1px solid #000",
    height: "170px",
    fontWeight:"400px",
    display:'none'
    }, 
    "&:after": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 12,
        left: 32,
        top: 18,     
        borderBottom: "1px solid #28282b",        
        fontWeight:"400px",
        display:'none'
        },
        ".Mui-expanded":{
          margin:'0px',
          minHeight:'0px !important'
        },
        ".MuiAccordionSummary-root":{
          minHeight:'40px !important'
        },
        ".MuiAccordionSummary-content":{
          margin:'0px'
        }
}))