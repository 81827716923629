import React, { useEffect, useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { StyledPaperShadow } from "../../theme/StyledEle";
import { InputBase, useTheme } from "@mui/material";

const GeoZoneSearchBox = ({
  callbackgeometry,
  addressSelted,
  isEdit,
  zoneType,
  address
}) => {
  const [searchBox, setSearchBox] = useState(null);
  const theme = useTheme();
  const onPlacesChanged = () => {
    callbackgeometry(
      searchBox.getPlaces()[0].geometry.location.lat(),
      searchBox.getPlaces()[0].geometry.location.lng(),
      searchBox.getPlaces()[0].formatted_address
    );
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  
  return (
      <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={onSBLoad}>
        <InputBase 
          placeholder="Search Location for Geozone"
          type="text"
          defaultValue={isEdit === true ? address : ''}
          disabled={ isEdit ? true : false }
          sx={{
            width: `270px`,
            height: `40px`,
            background: theme.palette.background.paper,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            position:'absolute',
            left:'65%',
            top:'20px',
            transform:'translateX(-50%)',
            zIndex:999,
            borderRadius:'15px',
            padding:'10px 15px'
          }}
          
        />
      </StandaloneSearchBox>
  );
};
export default GeoZoneSearchBox;
