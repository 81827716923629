import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import {
  CardHeader,
  CardContent,
  Button,
  Collapse,
  useTheme,
  Stack,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import DeviceService from "../../services/deviceService";
import DraggableDialog from "../shared/DailogDrag";
import CommonDropDown from "./deviceDropdown";
import {
  SoftButton,
  StyledCardShadow,
  StyledDropDown,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
} from "../../theme/StyledEle";
import logServices from "../../services/logService";
import ReportServices from "../../services/reportServices";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useAuditLogMutation,
  useDeleteDeviceMutation,
  useListDeviceMutation,
  useResumeFromTempDisconnectMutation,
  useSaveWhitelistMutation,
  useSimActivateMutation,
  useSimDisconnectMutation,
  useSimOutOfSafeCustodyMutation,
  useSimSafeCustodyMutation,
} from "../../store/slice/ApiSlice";
import { MenuItem } from "@mui/material";
import StyledModel from "../../Utils/StyledModel";
import { useLocation,useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const ListDevice = ({ onEditDevice, menuUser ,onViewOrg}) => {
  const location = useLocation();
  let hashId = location.hash;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [arraySortOrder, setArraySortOrder] = useState({});
  const [pageStatus, setPageStatus] = useState([]);
  const [commonFilter, setCommonFilter] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "buttonCheckBox",
      // },
      {
        Header: "Region",
        accessor: "region",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Dealer",
        accessor: "dealer",
        show:orgType !== 3 && orgType !== 5 && true
      },
      {
        Header: "Customer",
        accessor: "customer",
        show:orgType !== 3 && true
      },
      {
        Header: "Device Code",
        accessor: "deviceNumber",
      },
      {
        Header: "Device IMEI Number",
        accessor: "imei",
      },
      {
        Header: "Device Model",
        accessor: "model",
      },
      {
        Header: "Device Status",
        accessor: "deviceStatusValue",
      },
      {
        Header: "Config Status",
        accessor: "configurationStatus",
      },
      {
        Header: "SIM Number",
        accessor: "simNumber",
      },
      {
        Header: "First Mobile No",
        accessor: "simMobileNumber",
      },
      // {
      //   Header: "IMSI Number",
      //   accessor: "imsiNumber",
      // },
      {
        Header: "SIM Status",
        accessor: "simStatusValue",
      },
      {
        Header: "Vehicle VIN",
        accessor: "vehicleNo",
      },
      {
        Header: "Last Connected",
        accessor: "gpsModifyDate",
      },
      {
        Header: "Last Location",
        accessor: "deviceLocation",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return row.original.deviceLocation !== "Not applicable" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.deviceLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.deviceLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.deviceLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.deviceLocation}
                >
                  {row.original.deviceLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.deviceLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.deviceLocation}
                  key={row.original.deviceLocation}
                ></a>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography>NA</Typography>
            </Box>
          );
        },
      },
      {
        Header: "Last Received Packet",
        accessor: "vehGPSdate",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Modified Date",
        accessor: "modifiedDate",
      },
      {
        Header: "Location",
        accessor: "firstLocation",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };

          return row.original.firstLocation !== "NA" ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.firstLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.firstLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>

                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.firstLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.firstLocation}
                >
                  {row.original.firstLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.firstLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.firstLocation}
                  key={row.original.firstLocation}
                ></a>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography>NA</Typography>
            </Box>
          );
        },
      },
      {
        Header: "Time",
        accessor: "firstFeedRecievedTime",
      },
      {
        Header: "Sim Activation Date",
        accessor: "simActivationDate",
      },
      {
        Header: "Lot Number",
        accessor: "lotNumber",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  
  const onView = async (handleOrgView) => {
    onViewOrg(handleOrgView);

    auditPayload.message = "Admin > Device > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

 

  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   var obj = { sortColumn };
  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "imei") {
  //     if (obj1.imei !== undefined && obj1.imei === "asc") {
  //       obj1.imei = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.imei = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "IMEI";
  //   } else if (obj.sortColumn.id === "simNumber") {
  //     if (obj1.simNumber !== undefined && obj1.simNumber === "asc") {
  //       obj1.simNumber = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.simNumber = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "SIM_NUMBER";
  //   }
  //   setArraySortOrder(obj1);
  //   var objSel = { ...pageStatus };
  //   fetchDeviceDetails(
  //     "",
  //     objSel.resellerId,
  //     objSel.orgId,
  //     seltdsrtngcolumn,
  //     orderdir
  //   );
  // };

  const onEdit = (itemEdited) => {
    onEditDevice(itemEdited);

    auditPayload.message = "Admin > Device > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  // const fetchDeviceDetails = async (
  //   searchText,
  //   seltdResellerId,
  //   seltdLastOrgId,
  //   columnName,
  //   orderdir
  // ) => {
  //   var request = {};
  //   request.orgId = seltdLastOrgId;
  //   request.resellerOrgId = seltdResellerId;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.searchText = "String";
  //   request.customerId = pageStatus.customerId;
  //   request.fleetId = pageStatus.fleetId;
  //   request.vehicleId = pageStatus.vehicleId;
  //   request.imei = pageStatus.imei;
  //   request.devModel = pageStatus.devModel;
  //   request.statusId = pageStatus.statusId;
  //   request.search = searchText;
  //   request.countFrom = newPage;
  //   request.countTo = rowsPerPage;
  //   request.dealerId = pageStatus.dealerId;
  //   request.regionId = pageStatus.regionId;
  //   request.startDate = pageStatus.startDate;
  //   request.endDate = pageStatus.endDate;
  //   request.lastConnectStatus = pageStatus.devicelastconnectStatus;
  //   request.dateOnOrBefore = pageStatus.dateOnOrBefore;
  //   if (columnName === undefined) {
  //     request.columnName = "IMEI";
  //   } else {
  //     request.columnName = columnName;
  //   }
  //   if (orderdir === undefined) {
  //     request.orderDir = "desc";
  //   } else {
  //     request.orderDir = orderdir;
  //   }

  //   try {
  //     const responseData = (await DeviceService.getGridViewForDevice(request))
  //       .data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //   } catch (error) {}
  // };

  // const callbackLoadFn = async (
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdVehicleId,
  //   seltdLastOrgId,
  //   modelVal,
  //   statusVal,
  //   seltdRegionId,
  //   seltdDealerId,
  //   fromDate,
  //   toDate,
  //   selConnected,
  //   selCreatedDate
  // ) => {
  //   var dataStr = {};
  //   dataStr.edit = false;
  //   dataStr.delete = false;
  //   dataStr.view = false;
  //   dataStr.excel = false;
  //   dataStr.pdf = false;
  //   var strval;
  //   strval = menuUser.split(",");
  //   for (var i = 0; i < strval.length; i++) {
  //     if (parseInt(strval[i]) === 3) {
  //       dataStr.edit = true;
  //     }
  //     if (parseInt(strval[i]) === 4) {
  //       dataStr.delete = true;
  //     }
  //     if (parseInt(strval[i]) === 6) {
  //       dataStr.pdf = true;
  //     }
  //     if (parseInt(strval[i]) === 5) {
  //       dataStr.excel = true;
  //     }
  //   }
  //   setCommonFilter(dataStr);
  //   if (loadStatus === true) {
  //     var request = {};
  //     request.orgId = seltdLastOrgId;
  //     request.resellerOrgId = seltdResellerId;
  //     request.page = newPage;
  //     request.size = rowsPerPage;
  //     request.customerId = seltdCustomerId;
  //     request.fleetId = seltdFleetId;
  //     request.vehicleId = seltdVehicleId;
  //     request.imei = 1;
  //     request.devModel = modelVal;
  //     request.statusId = statusVal;
  //     request.search = "";
  //     request.columnName = "IMEI";
  //     request.orderDir = "desc";
  //     request.dealerId = seltdDealerId;
  //     request.regionId = seltdRegionId;
  //     request.startDate = fromDate;
  //     request.endDate = toDate;
  //     request.devicelastconnectStatus = selConnected;
  //     request.dateOnOrBefore = selCreatedDate;
  //     setPageStatus(request);

  //     const responseData = (await DeviceService.getGridViewForDevice(request))
  //       .data;
  //     setResponseData(responseData.data.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(responseData.data.totalElements);
  //     setTotalPages(responseData.data.totalPages);
  //   }
  //   setLoadStatus(false);
  // };

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
    navigate('#delete');
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "device/deviceListXLS.xls?resellerOrgId=" +
        payload.resellerOrgId +
        "&devModel=" +
        payload.devModel +
        "&deviceImei=" +
        payload.imei +
        "&vehicleId=" +
        payload.vehicleId +
        "&fleetId=" +
        payload.fleetId +
        "&customerId=" +
        payload.customerId +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&statusId=" +
        payload.statusId +
        "&lastConnectStatus=" +
        payload.lastConnectStatus +
        "&dateOnorBefore=" +
        payload.dateOnOrBefore +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&lotNumber=" +
        payload.lotNumber +
        "&search=" +
        payload.search +
        " ";
      window.open(url);
  
      auditPayload.message = "Admin > Device > List > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const onChangepdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "device/deviceListPDF.pdf?resellerOrgId=" +
        payload.resellerOrgId +
        "&devModel=" +
        payload.devModel +
        "&deviceImei=" +
        payload.deviceImei +
        "&vehicleId=" +
        payload.vehicleId +
        "&fleetId=" +
        payload.fleetId +
        "&customerId=" +
        payload.customerId +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&statusId=" +
        payload.statusId +
        "&devicelastconnectStatus=" +
        payload.devicelastconnectStatus +
        "&dateOnorBefore=" +
        payload.dateOnorBefore +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        " ";
      window.open(url);

      auditPayload.message = "Admin > Device > List > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "deviceList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const [
    getDeviceList,
    {
      data: deviceListData,
      isLoading: deviceListLoading,
      isSuccess: deviceListSuccess,
    },
  ] = useListDeviceMutation();
  const [
    deleteDevice,
    {
      data: deviceDeleteData,
      isLoading: deviceDeleteLoading,
      isSuccess: deviceDeleteSuccess,
    },
  ] = useDeleteDeviceMutation();
  const [payload, setPayload] = useState(null);
  let commonPayload = {};
  //get Data
  const getData = (data) => {
    
    commonPayload.resellerOrgId = resellerId;
    commonPayload.customerId = data.customer;
    commonPayload.fleetId = data.fleet;
    commonPayload.vehicleId = data.vehicle;
    commonPayload.devModel = data.DeviceModel;
    commonPayload.statusId = data.deviceStatus;
    commonPayload.orgType = orgType;
    commonPayload.dealerId = data.dealer;
    commonPayload.regionId = data.region;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.statusId = data.deviceStatus;
    commonPayload.dateOnOrBefore = data.createDate;
    commonPayload.firmwareversion = data.firmware;
    commonPayload.lotNumber = data.lotNummber;
    commonPayload.lastConnectStatus = data.lastconnnect;
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.search = "";
    commonPayload.imei = data.imei;
    commonPayload.orgId = orgId;
    commonPayload.size = 25;
    commonPayload.page = 0;
    setPayload(commonPayload);
    getDeviceList(commonPayload);

    auditPayload.message = "Admin > Device > List Device > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+">"+data.deviceStatusName+" > "+data.deviceModelName+" > "+data.selectedPeriod+"("+data.fromDate+"-"+data.toDate+")"+" > "+data.lastConnectedName+" > "+data.lotNummber+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    
  };

  useEffect(() => {
    if (deviceListSuccess) {
      setResponseData([]);
      if (deviceListData.data !== null) {
        setResponseData(deviceListData.data.content);
        setTotalElements(deviceListData.data.totalElements);
        setTotalPages(deviceListData.data.totalPages);
        var dataStr = {};
        dataStr.edit = false;
        dataStr.delete = false;
        dataStr.view = false;
        dataStr.excel = false;
        dataStr.pdf = false;
        var strval;
        strval = menuUser.split(",");
        for (var i = 0; i < strval.length; i++) {
          if (parseInt(strval[i]) === 4) {
            dataStr.edit = true;
          }
          if (parseInt(strval[i]) === 3) {
            dataStr.delete = true;
          }
          if (parseInt(strval[i]) === 6) {
            dataStr.pdf = true;
          }
          if (parseInt(strval[i]) === 5) {
            dataStr.excel = true;
          }
          if (parseInt(strval[i]) === 2) {
            dataStr.view = true;
          }
        }
        setCommonFilter(dataStr);
      }
    }
  }, [deviceListSuccess]);

  //search
  // const handleSearch = (searchText) => {
  //   var selObj = { ...pageStatus };
  //   fetchDeviceDetails(searchText, selObj.resellerOrgId, selObj.orgId);
  // };
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getDeviceList(payload);
    } else if (payload.search.length === 0) {
      getDeviceList(payload);
      payload.page = 0;
      payload.size = 25;
      setNewPage(0);
      setRowsPerPage(25);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    
  

    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getDeviceList(payload);
  };

  //Delete
  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    deleteDevice(payload);
  };

  useEffect(() => {
    if (deviceDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deviceDeleteData?.resultMessage,
          deviceDeleteData?.resultText,
          deviceDeleteSuccess,
        ])
      );
      getDeviceList(payload);
    }
  }, [deviceDeleteSuccess]);

  //Device Send & White List Function
  const [commondType, setCommonType] = useState(0);
  const [simCommond, setSimCommond] = useState(0);
  const [plan, setPlan] = useState(0);
  const [testPlan, setTestPlan] = useState(0);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const [supportType, setSupportType] = useState("support");
  const [supportNo, setSupportNo] = useState("9790706090");

  //get row data from data table
  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };

  const [
    saveSimActivation,
    {
      data: simsaveactdata,
      isLoading: getSimActLoading,
      isSuccess: getSimActSuccess,
    },
  ] = useSimActivateMutation();

  const [
    saveSimSafe,
    {
      data: simsaveSafedata,
      isLoading: getSimSafeLoading,
      isSuccess: getSimSafeSuccess,
    },
  ] = useSimSafeCustodyMutation();

  const [
    saveSimOutOfSafe,
    {
      data: simsaveOutOfSafedata,
      isLoading: getSimOutOfSafeLoading,
      isSuccess: getSimOutOfSafeSuccess,
    },
  ] = useSimOutOfSafeCustodyMutation();

  const [
    saveSimDisconnect,
    {
      data: simsaveDisconnectdata,
      isLoading: getSimDisconnectLoading,
      isSuccess: getSimDisconnectSuccess,
    },
  ] = useSimDisconnectMutation();

  const [
    saveSimResumeFromTempDisconnect,
    {
      data: simsaveResumeFromTempDisconnectdata,
      isLoading: getSimResumeFromTempDisconnectLoading,
      isSuccess: getSimResumeFromTempDisconnectSuccess,
    },
  ] = useResumeFromTempDisconnectMutation();

  const [
    saveWhitelist,
    {
      data: saveWhitelistdata,
      isLoading: getWhitelistLoading,
      isSuccess: getWhitelistSuccess,
    },
  ] = useSaveWhitelistMutation();

  const handleSend = () => {
    let mobArry = [];
    let simArry = [];
    let imeiArry = [];
    for (let i = 0; i < selectRows.length; i++) {
      mobArry.push(selectRows[i].values.simMobileNumber);
      simArry.push(selectRows[i].values.simNumber);
      imeiArry.push(selectRows[i].values.imei);
    }
    var payload = {};
    payload.deviceSimNo = simArry.toString();
    payload.deviceMobileNo = mobArry.toString();
    payload.devIMEI = imeiArry.toString();
    payload.planCode = plan;
    payload.testPlan = testPlan;
    payload.orgId = resellerId;
    payload.orgType = orgType;
    payload.userId = userId;
    if (simCommond === 0) {
      // simActivate
      saveSimActivation(payload);
    } else if (simCommond === 1) {
      // simSafeCustody
      saveSimSafe(payload);
    } else if (simCommond === 2) {
      // simOutOfSafeCustody
      saveSimOutOfSafe(payload);
    } else if (simCommond === 3) {
      // simDisconnect
      saveSimDisconnect(payload);
    } else if (simCommond === 4) {
      // resumeFromTempDisconnect
      saveSimResumeFromTempDisconnect(payload);
    } 
  };
  const [whiteListItem, setWhiteListItem] = useState(false);

  const handleWhiteList = () => {
    setWhiteListItem(true);
  };

  const whiteListItems = () => {
    let payload = {
      simMobNum: selectRows[0]?.values.mobileNumber,
      userMobNum: "",
      supportMobileNum: supportNo,
      whiteListVal: supportType,
    };
    saveWhitelist(payload);
  };

  // //Success
  useEffect(() => {
    if (
      getSimSafeSuccess ||
      getSimActSuccess ||
      getSimOutOfSafeSuccess ||
      getSimDisconnectSuccess ||
      getSimResumeFromTempDisconnectSuccess ||
      getWhitelistSuccess
    ) {
 
      var obj1 = {};

      obj1.dialogText = "Sent Successfully";
  
      setDataStatus(true);
      setDialogContent(obj1);
      setDataStatusConfirm("Success");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        getDeviceList(payload);
      }, 2000);

     
    }
  }, [
    getSimSafeSuccess,
    getSimActSuccess,
    getSimOutOfSafeSuccess,
    getSimDisconnectSuccess,
    getSimResumeFromTempDisconnectSuccess,
  ]);

  return (
    <>
      <Box>
        <AdminDropdown
          getData={getData}
          regionDrop={true}
          dealerDrop={true}
          customerDrop={true}
          fleetDrop={true}
          vehicleDropD={true}
          deviceDrop={true}
          createdate={true}
          allowDays={30}
          allowedMonth={24}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Device List</StyledTableTitle>}
          />
          <Box sx={{ px: "15px", pb: "15px" }}>
            <Collapse
              in={selectRows?.length > 0}
              sx={{
                border: `.5px solid ${theme.palette.divider}`,
                borderRadius: "10px",
              }}
            >
              <Box sx={{ px: 2, py: 1, my: 0.5 }}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"end"}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"end"}
                    spacing={1.5}
                    sx={{ Width: "100%" }}
                  >
                    <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                      <StyledFormControlLabel required>
                        Command Type
                      </StyledFormControlLabel>
                      <StyledDropDown
                        size="small"
                        placeholder=""
                        value={commondType}
                        onChange={(event, newType) =>
                          setCommonType(event.target.value)
                        }
                      >
                        <MenuItem value={0}>Select</MenuItem>
                        <MenuItem value={1}>Airtel</MenuItem>
                      </StyledDropDown>
                    </StyledFormControl>
                    {commondType !== 0 && (
                      <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                        <StyledFormControlLabel required>
                          Sim Command
                        </StyledFormControlLabel>
                        <StyledDropDown
                          size="small"
                          placeholder=""
                          value={simCommond}
                          onChange={(event, newCommond) =>
                            setSimCommond(event.target.value)
                          }
                        >
                          <MenuItem value={0}>Activate</MenuItem>
                          <MenuItem value={1}>Safe Custody</MenuItem>
                          <MenuItem value={2}>Out Of Safe Custody</MenuItem>
                          <MenuItem value={3}>Disconnection</MenuItem>
                          <MenuItem value={4}>Resume from TD</MenuItem>
                        </StyledDropDown>
                      </StyledFormControl>
                    )}
                    {commondType !== 0 && simCommond === 0 && (
                      <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                        <StyledFormControlLabel required>
                          Commercial Plan
                        </StyledFormControlLabel>
                        <StyledDropDown
                          size="small"
                          placeholder=""
                          value={plan}
                          onChange={(event, newPlan) =>
                            setPlan(event.target.value)
                          }
                        >
                          <MenuItem value={0}>None</MenuItem>
                          <MenuItem value={"PKG_28683"}>Plus</MenuItem>
                          <MenuItem value={"PKG_28684"}>Basic</MenuItem>
                        </StyledDropDown>
                      </StyledFormControl>
                    )}

                    {commondType !== 0 && simCommond === 0 && (
                      <StyledFormControl sx={{ minWidth: "165px" }} fullWidth>
                        <StyledFormControlLabel required>
                          Test Plan
                        </StyledFormControlLabel>
                        <StyledDropDown
                          size="small"
                          placeholder=""
                          value={testPlan}
                          onChange={(event, newTestPlan) =>
                            setTestPlan(event.target.value)
                          }
                        >
                          <MenuItem value={0}>None</MenuItem>
                          <MenuItem value={"PKG_36396"}>Test</MenuItem>
                        </StyledDropDown>
                      </StyledFormControl>
                    )}

                    {commondType !== 0 && (
                      <StyledFormControl fullWidth>
                        <SoftButton
                          className="success"
                          color="success"
                          onClick={handleSend}
                        >
                          {(getSimActLoading ||
                            getSimSafeLoading ||
                            getSimOutOfSafeLoading ||
                            getSimDisconnectLoading ||
                            getSimResumeFromTempDisconnectLoading ||
                            getWhitelistLoading) && (
                            <CircularProgress
                              sx={{
                                width: "20px !important",
                                height: "20px !important",
                                mr: 1,
                              }}
                              color="success"
                            />
                          )}
                          Send
                        </SoftButton>
                      </StyledFormControl>
                    )}
                  </Stack>
                  {/* <SoftButton color="info" className="info">
                    White List
                  </SoftButton> */}
                </Stack>
              </Box>
            </Collapse>
          </Box>

          <CardContent sx={{ py: 0 }}>
            <DataTable
              onEdit={onEdit}
              onDelete={onDelete}
              //onCheckbox={onCheckbox}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangepdf}
              columns={columns}
              onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              includeSearchBox={true}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              commonFilter={commonFilter}
              isLoading={deviceListLoading}
              checkbox={true}
              selectRow={getRow}
            />
          </CardContent>
        </StyledCardShadow>
        {deleteItem !== null  && hashId === "#delete" && (
          <StyledModel data={deleteItem} deleteItem={deleteItems}>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Device No: {deleteItem.imei}
              </Typography>
            </Box>
          </StyledModel>
        )}
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
};

export default ListDevice;
