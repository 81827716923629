import Service from "./service";

const userServices = {
  chkUserName: function (data) {
    return Service.post("/user/chkUserName", data);
  },
  chkUserEmail: function (data) {
    return Service.post("/user/chkUserEmail", data);
  },
  // getGridViewForUser: function (resource) {
  //   return Service.post('user/listUser')
  // },

  // deleteUserById: (orgId) => {
  //   return Service.get('/deleteuserbyid', {orgId})
  // },

  //test
  getPublicContent() {
    return Service.get("/test/all");
  },
  getUserBoard() {
    return Service.get("/test/user");
  },
  getModeratorBoard() {
    return Service.get("/test/mod");
  },
  getAdminBoard() {
    return Service.get("/test/admin");
  },
  deleteUserById: (id) => {
    return Service.post("user/deleteUser", { id });
  },
  saveTreeViewDetails: (data) => {
    return Service.post("user/treeviewdisplay", data, null);
  },
  updateUser: (data) => {
    return Service.post("/user/updateUser ", data, null);
  },
  getUser: function (resource) {
    return Service.post("/user/listUser", resource, null);
  },
  saveUser: function (data) {
    return Service.post("/user/createUser", data, null);
  },
  editUserById: function (id) {
    return Service.post("/user/getUser", { id });
  },
  getUserListXLS: function (request) {
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.get("/user/userListXLS.xls", { request });
  },
  getUserListPDF: function (request) {
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.get("/user/userListPDF.pdf", { request });
  },
  saveUserPassword: function (data) {
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.post("/user/setpassword", data, null);
  },
  getaccountActivation: function (data) {
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.post("/user/accountActivation", data, null);
  },
  getForgetPassword: function (data) {
    return Service.post("/user/forgotpassword", data, null);
  },
  saveLogout: function (data) {
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.post("/user/logoutService", data, null);
  },
  getRecentActivity: function (resource) {
    return Service.post("/user/listUserHistory", resource, null);
  },
};

export default userServices;
