import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";

const text = "";

const TripSummary = () => {
  const [responseData, setResponseData] = useState([]);

  useEffect(async () => {
    const responseData = (await ReportServices.getOverSpeed()).data.data;
    setResponseData(responseData);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Trip",
        accessor: "trip",
      },
      {
        Header: "Driver Name",
        accessor: "driverName",
      },
      {
        Header: "Start Point/(Start Time)",
        accessor: "startPoint",
      },
      {
        Header: "End Point/(End Point)",
        accessor: "endPoint",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },
      {
        Header: " Avg/Max Speed (km/h)",
        accessor: "odometer",
      },
      {
        Header: "Distance Traveled",
        accessor: "distanceTravelled",
      },
    ],
    []
  );

  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb mainDiv="Reports" subDiv="Trip Summary" includePopup={true}></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"Trip Summary Report"} />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:A Trip is defined as distance traveled by the vehicle
            between ignition on and off status. The minimum distance traveled
            must be greater than 500m to be considered in this trip report.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#eeeeee", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default TripSummary;
