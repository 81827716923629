import React, { useMemo, useState } from "react";
import { Typography, Box } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";


const Tasks =()=>{

    const columns = useMemo(
        () => [
          {
            Header: "IMEI",
            accessor: "orgTypeName",
          },
          {
            Header: "Date",
            accessor: "orgName",
          },
          {
            Header: "Command",
            accessor: "userName",
          },
          {
            Header: "Command Type",
            accessor: "orgBizEmail",
          },
          {
            Header: "Queued Date",
            accessor: "orgPhoneNumber",
          },
          {
            Header: "Initiated Date",
            accessor: "orgZip",
          },
          {
            Header: "Responded Message",
            accessor: "orgCreatedDate",
          },
          {
            Header: "Responded Date",
            accessor: "respondedDate",
          },  
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Complete Percent(%)",
            accessor: "buttonEditDelete",
          },
        ],
        []
      );

      const[responseData, setResponseData]=useState([])

    return(
        <Box>
        <Typography sx={{m:5, color:'#168FD0', fontSize:'25px'}}  >Tasks</Typography>
        <DataTable
            includeSearchBox={true}
            columns={columns}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}   
            data={responseData}        
          />
        </Box>
    )
}
export default Tasks


