import React, { useEffect, useState } from "react";
import {
  FormIconStack,
  StyledFilterFormControl,
  StyledFormControlLabel,
} from "../../theme/StyledEle";
import {
  Box,
  ClickAwayListener,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  useTheme,
} from "@mui/material";
import { Calender } from "../../constants/DualIcons";
import { DateRangePicker } from "rsuite";
import moment from "moment";

function DatepickerDropdown({
  allowDays,
  getDate,
  createDateStatus=false,
  activeLabel,
  createDateValue,
  showAll = true,
  showToday = true,
  showLast7Days = true,
  time=false,
  title='Date',
  thismonth = false,
  allowFutre = false,
  allowedMonth = 3,
  yesToday=false
}) {

  const theme = useTheme();
  let [label, setLabel] = useState(null);
  const [fromDate, setFromDate] = useState(moment().subtract(3, 'month').format("YYYY-MM-DD 00:00:00"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { afterToday, allowedRange, allowedMaxDays, combine } = DateRangePicker;
  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - allowedMonth));
  useEffect(() => {
    if(activeLabel !== null){
      selectDate(activeLabel);
    }
  }, [activeLabel]);

  //  //selectDate
  const selectDate = (name) => {
    //ranges
    if (name === "today") {
      // Sel - 0
      setFromDate(moment().format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD HH:mm:ss"));
      getDate(
        moment().format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD HH:mm:ss"), "Today"
      );
      setLabel("Today");
      handleClose();
    } else if (name === "yesterday") {
      // Sel - 1
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      getDate(
        moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"),
        moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),"Yesterday"
      );
      setLabel("Yesterday");
      handleClose();
    } else if (name === "last7days") {
      // Sel - 7
      setFromDate(moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"));
      getDate(
        moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
        moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59"),"Last 7 Days"
      );
      setLabel("Last 7 Days");
      handleClose();
    } else if (name === "lastmonth") {
      // Sel - 3
      setFromDate(
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00")
      );
      setToDate(
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59")
      );
      getDate(
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59"),"Last Month"
      );
      setLabel("Last Month");
      handleClose();
    } else if (name === "thismonth") {
      // Sel - 2
      setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD hh:mm:ss"));
      getDate(
        moment().startOf("month").format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD HH:mm:ss"),"This Month"
      );
      setLabel("This Month");
      handleClose();
    } else if(name === "yesToday"){
      setFromDate(moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"));
      setToDate(moment().format("YYYY-MM-DD hh:mm:ss"));
      getDate(
        moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"),
        moment().format("YYYY-MM-DD HH:mm:ss"),"Yerterday + Today"
      );
      setLabel("Yesterday + Today");
      handleClose();
    }else if (name === "custom") {
      // Sel - 9
      handleClose();
      setOpenCustomCal(true);
    }
  };

  // //get custom date
  const getCustomDate = (event) => {
    setOpenCustomCal(false);
    if(time){
      setLabel(
        `${moment(event[0]).format("DD-MM-YYYY HH:mm:ss")} - ${moment(
          event[1]
        ).format("DD-MM-YYYY 23:59:59")}`
      );
      setFromDate(moment(event[0]).format("YYYY-MM-DD 00:00:00"));
      setToDate(moment(event[1]).format("YYYY-MM-DD 23:59:59"));
      getDate(
        moment(event[0]).format("YYYY-MM-DD HH:mm:ss"), moment(event[1]).format("YYYY-MM-DD HH:mm:ss"), "Custom"
      )
    }else{
      setLabel(
        `${moment(event[0]).format("DD-MM-YYYY 00:00:00")} - ${moment(
          event[1]
        ).format("DD-MM-YYYY 23:59:59")}`
      );
      setFromDate(moment(event[0]).format("YYYY-MM-DD 00:00:00"));
      setToDate(moment(event[1]).format("YYYY-MM-DD 23:59:59"));
      getDate(
        moment(event[0]).format("YYYY-MM-DD 00:00:00"), moment(event[1]).format("YYYY-MM-DD 23:59:59")
      )
    }
    
  };

  //open custom calendar
  let [openCustomCal, setOpenCustomCal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if(openCustomCal){
      setOpenCustomCal(false);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickAway = () => {
    if(openCustomCal){
      setOpenCustomCal(false);
    }
  }

  const [date,setDate] = useState(moment().subtract(1, "days").format('yyyy-MM-DD'));


  let text = document.getElementsByClassName('rs-picker-toolbar');
  const addText = () => {
    if(createDateStatus && createDateValue !== "4"){
      var type = document.createElement('div')
      type.innerHTML =  `<p class='customtext'>Maximum custom range is ${allowDays} ${allowDays === 1 ? 'day' : 'days'}</p>`
      text[0].prepend(type) 
    }
    else{
      var type = document.createElement('div')
      type.innerHTML =  `<p class='customtext'></p>`
      text[0].prepend(type)  
    }
    
  }

  useEffect(() => {
    if(text.length > 0){
       addText();
    }
  },[openCustomCal])

  //Time with vehicle
  let [timeHide, setTimeHide] = useState(false);

  const showTimeSingvehicle = () => {
    if(time){
      setTimeHide(true);
    }else{
      setTimeHide(false);
      //getDatePan.classList.add("test");
    }
  }

useEffect(() => {
  showTimeSingvehicle();
},[time])

useEffect(() => {
  let getDatePan = document.getElementsByClassName('rs-picker-daterange-calendar-group');
  if(openCustomCal && time){
    getDatePan[0]?.classList?.add('addTimerPicker');
  }else if(!time && getDatePan.length > 0){
    getDatePan[0]?.classList?.remove('addTimerPicker');
  }
},[openCustomCal])






  return (
    <StyledFilterFormControl className="dateRangePicker">
      <StyledFormControlLabel>{title}</StyledFormControlLabel>
      <ClickAwayListener onClickAway={clickAway}>
        <FormIconStack direction={"row"} alignItems={"center"} spacing={1}>
          <SvgIcon
            sx={{
              fontSize: "22px",
              color:
                theme.palette.mode === "light"
                  ? theme.palette.secondary.main
                  : theme.palette.grey[300],
            }}
          >
            <Calender />
          </SvgIcon>
          <Box className="dateRangePicker_box">
            <DateRangePicker
              format={time && timeHide ? "dd-MM-yyyy HH:mm:ss" : "dd-MM-yyyy" }
              disabledDate={createDateStatus?(
                 (createDateValue === "4")
                    ? combine(allowedMaxDays(allowDays), allowedRange(last3months,moment()))
                    : combine(afterToday(), allowedMaxDays(1))):
                    (!allowFutre ? combine(
                        allowDays === 0 ? allowedMaxDays() : allowedMaxDays(allowDays),
                        allowedRange(last3months, moment())
                      ) : combine(allowedMaxDays(allowDays), allowedRange(last3months)))
                 
              }
              placeholder="Select Date Range"
              ranges={[]}
              placement="bottomEnd"
              open={openCustomCal}
              onOk={(event) => getCustomDate(event)}
              size="xs"
              className="CustomDateRangePicker"
              preventOverflow={true}
              value={[new Date(moment(fromDate)), new Date(moment(toDate))]}
            />
            <InputBase
              value={label}
              readOnly
              size="small"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event)}
              autoComplete="off"
              title={`${fromDate} - ${toDate}`}
              sx={{
                padding: "4px 5px",
                [theme.breakpoints.down("xl")]: {
                  fontSize: "10px",
                },
              }}
            />
          </Box>
          
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            "&.MuiPaper-root": {
              width: "250px",
            },
            "& .MuiButtonBase-root ": {
              fontWeight: 400,
            },
          },
        }}
      >
        {showAll && (
          <MenuItem
            onClick={(event) => selectDate("all")}
            id="all"
            dense={true}
            selected={label === "All"}
          >
            All
          </MenuItem>
        )}
        {
          showToday &&
            <MenuItem
            onClick={(event) => selectDate("today")}
            id="today"
            dense={true}
            selected={label === "Today"}
          >
            Today
          </MenuItem>
        }
        <MenuItem
          onClick={(event) => selectDate("yesterday")}
          id="yesterday"
          dense={true}
          selected={label === "Yesterday"}
        >
          Yesterday
        </MenuItem>
        {
        yesToday &&
        <MenuItem
          onClick={(event) => selectDate("yesToday")}
          id="yesToday"
          dense={true}
          selected={label === "Yesterday + Today"}
        >
          Yesterday + Today
        </MenuItem>
        }
            {(createDateStatus && createDateValue === "4") ? (
              <MenuItem
                onClick={(event) => selectDate("last7days")}
                id="last7days"
                dense={true}
                selected={label === "Last 7 Days"}
              >
                Last 7 Days
              </MenuItem>
            )
              : (!createDateStatus &&
                <MenuItem
                  onClick={(event) => selectDate("last7days")}
                  id="last7days"
                  dense={true}
                  selected={label === "Last 7 Days"}
                >
                  Last 7 Days
                </MenuItem>

              )
            }
        {
          thismonth &&
        <MenuItem
          onClick={(event) => selectDate("thismonth")}
          id="thismonth"
          dense={true}
          selected={label === "This Month"}
        >
          This Month
        </MenuItem>
        }
        <MenuItem
          onClick={(event) => selectDate("custom")}
          id="custom"
          dense={true}
          selected={
            label !== "Today" &&
            label !== "Yesterday" &&
            label !== "Last 7 Days" &&
            label !== "All" && 
            label !== "This Month" &&
            label !== "Yesterday + Today"
          }
        >
          Custom
        </MenuItem>
        {/* <ListItem dense={true}>
            <ListItemText>
              {allowDays === 7
                ? "Maximum custom range is 7 days within the last 3 months "
                : allowDays === 30
                ? "Maximum custom range is 30 days within the last 3 months"
                : "Maximum custom range is 1 day within the last 3 months"}
            </ListItemText>
       </ListItem> */}
      </Menu>
        </FormIconStack>
      </ClickAwayListener>
    </StyledFilterFormControl>
  );
}

export default DatepickerDropdown;
