import React, { useEffect, useState } from 'react'

import { Circle, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import { StyledInfoWindow } from '../Treeview/TreeviewStyledEle';
import { Box, Divider, Stack, Typography, alpha } from '@mui/material';
import { useShowGeoZoneMutation } from '../../../store/slice/TrackingSlice/TrackingSlice';
import { useTheme } from '@emotion/react';
import zoneMarker from '../../../assets/images/geoZoneMarker.png'
function GeoMarker(props) {
    const {activeFeature, selectVehicleData, vehicleTrails} = props;
    const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState(false);
    const [geoZoneList, setGeoZoneList] = useState(null);
    const [ showGeoZone, { data: showGeoData, isLoading: showGeoLoading, isSuccess: showGeoSuccess } ] = useShowGeoZoneMutation();
    const theme = useTheme();

        //SetGeoZon
        const getGeoZoneList = async () => {
            if (vehicleTrails !== null) {
              const showGeo = await showGeoZone({
                vehicleId: selectVehicleData.vehicleId,
              });
              setGeoZoneList(showGeo?.data);
            } else {
              setGeoZoneList(null);
              setOpenInfoWindowMarkerId(null);
            }
          };
        
          useEffect(() => {
            if(activeFeature === 1){
              getGeoZoneList();
            }
          }, [activeFeature, selectVehicleData]);

          let getZoneDetails = (zoneDetails) => {
            setOpenInfoWindowMarkerId(zoneDetails);
          };
       
          
  const circleOption = {
    // strokeColor: '#FF0000',
    strokeColor: theme.palette.secondary.main,
    strokeOpacity: 0.8,
    strokeWeight: 2.5,
    fillColor: theme.palette.secondary.main,
    fillOpacity: 0.5,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    //radius: parseInt(dataObjRadius),
    zIndex: 1,
  };

    return (
        geoZoneList !== null && activeFeature === 1 &&
        geoZoneList.map((item, index) => {
          let coords = item.zoneType === 'Polygons' && JSON.parse(item.cordinates !== undefined ? item.cordinates : "");
            return item.zoneType === "Circles" ? (
                <>
                  <Marker
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    onClick={() => getZoneDetails(index)}
                    options={{
                        zIndex:99999
                    }}
                    animation={window.google.maps.Animation.DROP}
                    icon={zoneMarker}
                  />
        
                  {openInfoWindowMarkerId === index && (
                    <InfoWindow
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -20),
                      }}
                      position={{
                        lat: parseFloat(item.latitude),
                        lng: parseFloat(item.longitude),
                      }}
                      onCloseClick={() => setOpenInfoWindowMarkerId(false)}
                    >
                      <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                      <Box
                            className='infowindowHHeader'
                            sx={{background:theme.palette.warning.main, width:'100%'}}
                        >
                           <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Zone Name</Typography>
                            <Typography variant='subtitle2'>{item?.name}</Typography>
                          </Box>
                      </Stack>
                        </Box>
                        <Box sx={{padding:'5px 15px', width:'100%'}}>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          spacing={.5}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography variant="caption" sx={{ width: "25%" }}>
                              Radius
                            </Typography>
                            <Typography variant="subtitle2">
                              {item?.radius} m
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">Address</Typography>
                            <Typography variant="subtitle2">
                              {item.location === undefined ? "" : item.location}
                            </Typography>
                          </Box>
                        </Stack>
                        </Box>
                      </StyledInfoWindow>
                    </InfoWindow>
                  )}
        
                  <Circle
                    center={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    radius={item.radius}
                    options={circleOption}
                  />
                </>
              ) : (
                item.zoneType === "Polygons" && (
                  <>
                  <Marker
                    position={coords[0]}
                    onClick={() => getZoneDetails(index)}
                    options={{
                        zIndex:99999
                    }}
                    animation={window.google.maps.Animation.DROP}
                    icon={zoneMarker}
                  />
                  {openInfoWindowMarkerId === index && (
                    <InfoWindow
                      options={{
                        pixelOffset: new window.google.maps.Size(0, -80),
                      }}
                      position={coords[0]}
                      onCloseClick={() => setOpenInfoWindowMarkerId(false)}
                    >
                      <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                      <Box
                            className='infowindowHHeader'
                            sx={{background:theme.palette.warning.main, width:'100%'}}
                        >
                           <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Zone Name</Typography>
                            <Typography variant='subtitle2'>{item?.name}</Typography>
                          </Box>
                      </Stack>
                        </Box>
                        <Box sx={{padding:'5px 15px', width:'100%'}}>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          spacing={.5}
                        >
                          <Box>
                            <Typography variant="caption">Address</Typography>
                            <Typography variant="subtitle2">
                              {item.location === undefined ? "" : item.location}
                            </Typography>
                          </Box>
                        </Stack>
                        </Box>
                      </StyledInfoWindow>
                    </InfoWindow>
                  )}
                  <Polygon
                    path={coords}
                    options={{ 
                      strokeColor: theme.palette.secondary.main,
                      fillColor: theme.palette.secondary.main,
                     }}
                  />
                  </>
                )
            );
        })
    )
    
    

}

export default GeoMarker