
//DEALER LOG
export const dealerLogSearchByData = [
  {
    id: 1,
    key: "Date",
    value: "Date",
  },

];

//CELL LOG
export const cellLogTypeData = [
{
  id: 1,
  key: "Temperature",
  value: "Temperature",
},
{
  id: 1,
  key: "Voltage",
  value: "Voltage",
},

];

export const orgTypeData = [
{
  id: 1,
  key: "2",
  value: "Organization",
},
{
  id: 2,
  key: "3",
  value: "Customer",
},
{
  id: 3,
  key: "5",
  value: "Dealer",
},

{
  id: 4,
  key: "1",
  value: "Fleet",
},
{
  id: 5,
  key: "4",
  value: "Rider",
},
];

export const userLogAccessData = [
{
  id: 1,
  key: "Portal",
  value: "Portal",
},
{
  id: 2,
  key: "Mobile",
  value: "Mobile",
},
];