import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import Typography from "@mui/material/Typography";
import CreateTicket from "./createTicket";
import Toolbar from "@mui/material/Toolbar";
import helpDeskServices from "../../services/helpDeskServices";
import SimplePaper from "./Filter";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import {
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Stack,
  MenuItem,
  Grid,
  Collapse,
} from "@mui/material";
import {
  StyledCardShadow,
  StyledDropDown,
  StyledFiterButton,
  StyledFormControl,
  StyledFormControlLabel,
  StyledPageLayout,
  StyledPaperShadow,
} from "../../theme/StyledEle";
import { FaFilter } from "react-icons/fa";

const drawerBleeding = 56;

const ViewTicket = ({ fncallback, createTicketNew, createTicketData, createTicketSuccess, listTicket, listTickettData, listTicketLoading, listTicketSuccess, createTicketLoading }) => {
  var globalOrgId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalOrgType = 1;
  var globaluserId = "";
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
  }
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globalRoleId = globalJson.roleId;
  globaluserId = globalJson.id;

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const [loggedinuser, Setloggedinuser] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [gridListData, setGridListData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [arrayData, setArrayData] = useState(null);
  const [ticketType, setTicketType] = React.useState([]);
  const [ticketStatus, setTicketStatus] = React.useState([
    "Open",
    "Reopen",
    "In Progress",
    "Clarification",
  ]);
  const [assigneeList, setAssigneeList] = React.useState([]);

  useEffect(() => {
    fetchResellerDetails();
    getTicketList(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
    //listTicketDet(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
    getTicketType();
  }, []);

  const fetchResellerDetails = async () => {
    var request = {};
    request.orgId = globalJson.orgId;
    request.userOrgType = globalJson.userOrgType;
    request.roleId = globalJson.roleId;
    request.id = globalJson.id;

    try {
      const responseData = (await helpDeskServices.getResellerdetails(request)).data;

      Setloggedinuser(responseData[0]);
    } catch (error) {
      console.log("fetchResellerDetails", error);
    }
  };


  const toggleFilterDrawer = () => {
    setFilterOpen(!filterOpen);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleclose = () => {
    setOpen(false);
  };



  // const listTicketDet = async (
  //   newPage,
  //   rowsPerPage,
  //   arrayData,
  //   arry,
  //   assigneeArry
  // ) => {
  //   var request = {};
  //   request.orgId = globalJson.orgId;
  //   request.orgType = globalJson.userOrgType;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.statusList = arry;
  //   request.categoryList = [];
  //   request.severityList = [];
  //   request.typeList = [];
  //   request.customerList = [];
  //   request.assignedList = assigneeArry;
  //   if (arrayData !== null) {
  //     request.categoryList = arrayData.categoryList;
  //     request.severityList = arrayData.serevityList;
  //     request.statusList = arrayData.statusList;
  //     request.typeList = arrayData.typeList;
  //     request.customerList = arrayData.customerList;
  //     request.assignedList = arrayData.AssignList;
  //   }

  //   try {
  //     const responseData = (await helpDeskServices.listticketdet(request)).data;
  //     setGridListData([]);
  //     setGridListData(responseData.content);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(responseData.totalElements);
  //     setTotalPages(responseData.totalPages);
  //   } catch (error) {
  //     console.log("listticketdet", error);
  //   }
  // };

  const theme = useTheme();


  const columns = useMemo(
    () => [
      {
        Header: "Ticket Id",
        accessor: "ticketno",
        Cell: ({ row }) => (
          <span
            id={row.original.id}
            style={{ color: "blue" }}
            onClick={fncallback}
          >
            {row.original.ticketno}
          </span>
        ),
      },
      {
        Header: "Ticket Title",
        accessor: "title",
      },
      {
        Header: "Vehicle",
        accessor: "vehicleNo",
      },
      {
        Header: "Vehicle ORG",
        accessor: "vehicleOrg",
      },
      {
        Header: "Reporter",
        accessor: "reporter",
      },
      {
        Header: "Reporter ORG",
        accessor: "reporterOrg",
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Assignee",
        accessor: "assignedTo",
      },
      {
        Header: "Assignee Org Name",
        accessor: "assigneeOrgName",
      },
      {
        Header: "Category",
        accessor: "categoryval",
      },
      {
        Header: "Status",
        accessor: "statusval",
      },
      {
        Header: "Severity",
        accessor: "servityval",
      },
      {
        Header: "Last Modified Date",
        accessor: "lastModifiedDate",
      },
      {
        Header: "Closed Date",
        accessor: "closedDate",
      },
      {
        Header: "Time Taken",
        accessor: "timeTaken",
      },
    ],
    []
  );



  const callbackChangePage = async (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    getTicketList(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
    //listTicketDet(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
  };

  const filterOpenfn = () => {
    setFilterOpen(!filterOpen);
  };

  const fncallback1 = (arrayData) => {
   // listTicketDet(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
   getTicketList(newPage, rowsPerPage, arrayData, ticketStatus, assigneeList);
  
  };

  const onEdit = (itemEdited) => {
    // onEditOrg(itemEdited.id);
  };


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let orgType = userData.userOrgType;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;


  //get Ticket list
  const ticketPaylaod = {
    "orgId": orgId,
    "orgType": orgType,
  }


  
  const getStatusType = (type) => {
    const arry = [];
    const assigneeArry = [];
    if (type === "All Open") {
      arry.push("Open", "Reopen", "In Progress", "Clarification");
    } else if (type === "All Bugs") {
      arry.push();
    } else if (type === "All Closed") {
      arry.push("Closed");
    } else if (type === "My Open") {
      arry.push("Open", "Reopen");
      assigneeArry.push(globaluserId);
    } else if (type === "My Closed") {
      arry.push("Closed");
      assigneeArry.push(globaluserId);
    } else if (type === "My Assigned") {
      arry.push("Open", "In Progress", "Reopen");
      assigneeArry.push(globaluserId);
    }

    setTicketStatus(arry);
    setAssigneeList(assigneeArry);
    getTicketList(0, 25, arrayData, arry, assigneeArry);
    setNewPage(0);
    setRowsPerPage(25);
    //listTicketDet(newPage, rowsPerPage, arrayData, arry, assigneeArry);
  };

  const getTicketType = () => {
    setTicketType([
      {
        key: "All Bugs",
        value: "All Bugs",
      },
      {
        key: "All Open",
        value: "All Open",
      },
      {
        key: "All Closed",
        value: "All Closed",
      },
      {
        key: "My Open",
        value: "My Open",
      },
      {
        key: "My Closed",
        value: "My Closed",
      },
      {
        key: "My Assigned",
        value: "My Assigned",
      },
    ]);
  };


  const getTicketList = async (newPage, rowsPerPage, arrayData, arry, assigneeArry) => {
    ticketPaylaod.page = newPage;
    ticketPaylaod.size = rowsPerPage;
    ticketPaylaod.statusList = arry;
    ticketPaylaod.categoryList = [];
    ticketPaylaod.severityList = [];
    ticketPaylaod.typeList = [];
    ticketPaylaod.customerList = [];
    ticketPaylaod.assignedList = [];
    if(arrayData !== null){
      ticketPaylaod.categoryList = arrayData.categoryList;
      ticketPaylaod.severityList = arrayData.serevityList;
      ticketPaylaod.typeList = arrayData.typeList;
      ticketPaylaod.statusList = arrayData.statusList;
      ticketPaylaod.customerList = arrayData.customerList;
      ticketPaylaod.assignedList = arrayData.AssignList;
    }

    
    const ticketList = await listTicket(ticketPaylaod);
  }

  
  useEffect(() => {
    if(createTicketSuccess){
        setOpen(false);
        getTicketList(0, 25, arrayData, ticketStatus, assigneeList);
        setNewPage(0);
        setRowsPerPage(25);
    }
  },[createTicketSuccess])

  useEffect(() => {
    if(listTicketSuccess){
          setGridListData([]);
          if(listTickettData!== null){
          setGridListData(listTickettData.content);
          setTotalElements(5);
          setTotalPages(0);
          setTotalElements(listTickettData.totalElements);
          setTotalPages(listTickettData.totalPages);
          }
      }
  },[listTicketSuccess])






  return (
      <Box>
        <StyledPaperShadow elevation={0}>
          <Grid container>
            <Grid item lg={6}>
              <StyledFormControl fullWidth sx={{ maxWidth: "165px" }}>
                <StyledFormControlLabel>Category</StyledFormControlLabel>
                <StyledDropDown
                  {...register("ticketType", {})}
                  onChange={(e) => {
                    setValue("ticketType", e.target.value);
                    getStatusType(e.target.value);
                  }}
                  size="small"
                  defaultValue={"All Open"}
                >
                  {ticketType.length > 0 &&
                    ticketType.map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.key}>
                          {option.value}
                        </MenuItem>
                      );
                    })}
                </StyledDropDown>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IconButton
                //expand={filterOpen}
                onClick={toggleFilterDrawer}
                aria-expanded={filterOpen}
                size="small"
              >
                <FaFilter />
              </IconButton>

              <StyledFiterButton
                variant="contained"
                onClick={toggleDrawer(true)}
                size="small"
                disableElevation
                color="success"
              >
                Create Ticket
              </StyledFiterButton>
            </Grid>
          </Grid>
        </StyledPaperShadow>

        
        <Collapse in={filterOpen} >
          <Grid container sx={{mt:2}}>
              <Grid item lg={12}>
                <SimplePaper cancel={filterOpenfn} fncallback={fncallback1} filterOpen={filterOpen}/>
              </Grid>
          </Grid>
          </Collapse>
        <StyledCardShadow sx={{ mt: 2 }}>
          
          {/* <Drawer open={filterOpen} anchor="top">
        <Toolbar/>
          hu
      </Drawer> */}
          <CardContent sx={{ py: 1 }}>
            <Drawer
              open={false}
              anchor="top"
              sx={{ zIndex: "9999" }}
              onClose={(e) => setFilterOpen(false)}
              elevation={0}
            >
              <Box
                sx={{
                  width: "350px",
                  height: "-webkit-fill-available",
                  position: "relative",
                }}
              >
                <Stack
                  sx={{ px: 2, py: 1 }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {/* <Typography variant="subtitle1">Filter</Typography> */}
                  <Typography variant="body1">Filter</Typography>
                  {/* <Button size="small">Reset</Button> */}
                </Stack>
                <Divider />
                <Box>
                  <SimplePaper cancel={filterOpenfn} fncallback={fncallback1} />
                </Box>
              </Box>
            </Drawer>
            {/* <Collapse in={filterOpen} timeout="auto" unmountOnExit>
          <SimplePaper cancel={filterOpenfn} fncallback={fncallback1} />
        </Collapse> */}
            <DataTable
              includeSearchBox
              includeExportButton={false}
              onEdit={onEdit}
              columns={columns}
              data={gridListData}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={callbackChangePage}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={listTicketLoading}
            />
          </CardContent>
          <Drawer
            anchor="right"
            open={open}
            //onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
            //sx={{zIndex:99999}}
            elevation={0}
            sx={{'& .MuiPaper-root':{ width: "45%"} }}
          >
            <Toolbar />
            <Box>
              <CardHeader
                title={<Typography color="primary">Create Ticket</Typography>}
              />
              <CardContent sx={{ p: 0 }}>
                <CreateTicket userdet={loggedinuser} onCancel={handleclose} createTicketNew={createTicketNew} createTicketData={createTicketData} createTicketSuccess={createTicketSuccess} createTicketLoading={createTicketLoading}/>
              </CardContent>
            </Box>
          </Drawer>
        </StyledCardShadow>
      </Box>
  );
};

export default ViewTicket;
