import Service from "./service";

const deviceServices = {
  getGridViewForDevice: function (resource) {
    return Service.post('/device/listDevice',resource,null)
  },
  saveDeviceDetails: function (data) {
    return Service.post('/device/createDevice',data,null)
  },
  deleteDeviceById: (id) => {
    return Service.post('/device/deleteDevice',{id})
  },
  editOrgById: (deviceId) =>{
    return Service.get('/device/getDevice',{deviceId})
  },
  validateDevice: function (data) {
    return Service.post('/device/validateExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  uploadDevice: function (data) {
    return Service.post('/device/processExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  updateDeviceDetails: function (data) {
    return Service.post('/device/updateDevice',data,null)
  },
  getLicenseKeyList:function (data){
    return Service.post('/device/getLicenseKey',data,null)
  },
  getlistofPlansList:function(data){
    return Service.post('/vehicle/getlistofPlans',data,null)
  },
  getlistofDevicePlans:function(data){
    return Service.post('/device/getDevicePlan',data,null)
  },
  getlistofMFGSiteCode:function(data){
    return Service.post('/device/getMFGSiteCode',data,null)
  },
  getlistofPartNumberList:function(data){
    return Service.post('/device/getPartNumber',data,null)
  },
  getDeviceModelNameList:function(data){
    return Service.post('/device/getDeviceModelName',data,null)
  },
  getSimPlanStatusList:function(data){
    return Service.post('/sim/getSimPlanStatus',data,null);
  },
  getDeviceStatusList:function(data){
    return Service.post('/device/getDeviceStatus',data,null)
  },
  getDeviceSimNumberLists:function(data){
    return Service.post('/device/getSIMNumberList',data,null)
  },
  getDeviceSimDetails:function(data){
    return Service.post('/device/getSimDetails',data,null)
  }, 
  getDeviceListXLS:function(request){
    // return Service.post('/device/deviceListXLS',data,null)
    return Service.get('/device/deviceListXLS',{request})  
  },
  getDeviceListPDF:function(request){
    return Service.get('/device/deviceListPDF',{request})  
  },
  getDeviceHistory: function (resource) {
    return Service.post('/device/listDeviceHistory',resource,null)
  },
};

export default deviceServices;
