import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import React, { useMemo, useState, useEffect, Suspense, lazy } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import SimOnBoardServices from "../../services/simOnBoardService";
import { useAuditLogMutation } from '../../store/slice/ApiSlice';



import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
export default function OppositeContentTimeline() {
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
  
    setPage(value);
   callbackChangePage(value);
  };

  

  const [responseData, setResponseData] = useState([]);
  const [newPage,setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const style1 = {color: "green"};
  const style2 = {color: "blue"};
  const style3 = {color: "red"};
  
    const [file, setFile] = useState([]);
    
    const globalJson1=localStorage.getItem("userData")!==null?JSON.parse(localStorage.getItem("userData")):null;

    var userName = globalJson1.userName;
    var orgId = globalJson1.orgId;
    var userId = globalJson1.id;
    var orgName = globalJson1.orgName;
  
    const [auditLog] = useAuditLogMutation();
  
    let auditPayload = {
      //Audit Common Payload
      orgId: orgId,
      userId: userId, 
      orgName: orgName, 
      userName: userName,
    };
    useEffect(async () => {
      await callbackLoadFn();

      auditPayload.message = "Admin > Sim > Recent Activity";
      auditPayload.action = "View";
      auditPayload.featureName = "Sim";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
     }, [])
  
  const callbackLoadFn=async ()=>{
  
   
      var request={};
     
      // request. simId = 62;
      // request.page = 0;
      request.page = newPage;
      request.size = rowsPerPage;
      request.simHistoryId = 0;
      //request.type = "string";
     
     
    const responseData=(await SimOnBoardServices.getSimHistory(request)).data.dataObj
    setResponseData(responseData);  
      
   }

   const callbackChangePage=async (value)=>{
   
   
    var request = {};
       // request. simId = 62;
       request.page = parseInt(value)-1;
       request.size = rowsPerPage;

      request.simHistoryId = 0;
      //request.type = "string";
     
     
    const responseData=(await SimOnBoardServices.getSimHistory(request)).data.dataObj
    setResponseData(responseData);  
    
       
  }

  return ( 
    <> 
   
 
      {responseData.length>0 && responseData.map((item) => {
        
         
      return(
        <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
      
        },
      }}
    >
      <Typography  sx={{ 
            Color: "blue"
            }}>{item.key}</Typography>
       
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
            sx={{ 
              backgroundColor: '#00FF00'
            }}
            />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            
          { 
            item.obj.length>0 && item.obj.map((det) => {
          
           
          return(
            
            <Paper elevation={12} sx={{width:"550px",height:"auto", mb:"20px", padding:"20px" }}>
        
              <Typography>{userName}</Typography>
              <Typography>{det.description}</Typography>
              <Typography  sx={(det.action === "create")?style1:(det.action ==="update")?style2:style3}>{det.action}</Typography>
              <Typography>{det.createdDate}</Typography>                          
            </Paper>
            
          
          
          )
          
            })}
            </TimelineContent>
        </TimelineItem>
        
        <TimelineDot />
  
        </Timeline>
     )})}
   
   
   <Stack spacing={2} sx={{marginBottom:"12%"}}>
      <Typography>Page: {page}</Typography>
      <Pagination count={10} page={page} onChange={handleChange} />
      <p></p>
    </Stack>
   

  </>
  );
}

//export default OppositeContentTimeline