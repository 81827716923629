import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import routes from "./routes";
import { getElement } from "../Utils/helper";
import PageNotFound from "../components/page-not-found/PageNotFound";
import { useSelector } from "react-redux";
import Login from "../components/login/login";
import { useIdleTimer } from "react-idle-timer";
import SignIn2 from "../components/login/userActivation";
import SignIn1 from "../components/login/forgotPassword";
import BookRide from "../components/BookRide/BookRide";
import ShareLocation from "../components/trackLocation/ShareLocation";
import CheckAuthValidation from "../components/login/CheckAuthValidation";

const RouteView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  var globalJson = {};
  // if (
  //   "null" === localStorage.getItem("userData") || null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   globalJson =
  //     localStorage.getItem("userData") !== null
  //       ? JSON.parse(localStorage.getItem("userData"))
  //       : 0;

  //       var count = window.location.href.split("#").length;
  //       if (count === 2) {
  //         if (
  //           window.location.href.split("#")[1].split("?")[0] ==="/"
  //         ) {
  //           if (
  //             "null" !== localStorage.getItem("userData") ||
  //             null !== localStorage.getItem("userData")
  //           ) {
  //            navigate(`/`);
  //           }
  //         }
  //       }
  // }
  // if (JSON.stringify(globalJson) === "{}") {
  //   var count = window.location.href.split("#").length;
  //   if (count === 2) {
  //     if (
  //       window.location.href.split("#")[1].split("?")[0] ===
  //         "/userActivation" ||
  //       window.location.href.split("#")[1].split("?")[0] ===
  //         "/forgotPassword" ||
  //       window.location.href.split("#")[1].split("?")[0] ===
  //         "/resetActivationLink"
  //     ) {
  //     } else {
  //       if (
  //         "null" === localStorage.getItem("userData") ||
  //         null === localStorage.getItem("userData")
  //       ) {
  //         window.location.href = window.location.origin + "/";
  //       }
  //     }
  //   } else {
  //     if (
  //       "null" === localStorage.getItem("userData") ||
  //       null === localStorage.getItem("userData")
  //     ) {
  //       window.location.href = window.location.origin + "/";
  //     }
  //   }
  // }else{

  // }

  const getRoutes = (parent, index) => {
    return (
        <Route path={parent.path} element={getElement(parent)} key={index + 1}>
          {parent.children && parent.children.map((child) => getRoutes(child))}
        </Route>
    );
  };

  //get site title from service
  const siteTitle = useSelector((state) => state.controls.siteIdentity.title);
  const siteTagLine = useSelector((state) => state.controls.siteIdentity.tagLine);


  useEffect(() => {
    document.title = `${siteTitle} - ${siteTagLine}`;
  },[siteTagLine, siteTitle]);

  return (
    <Routes>
    <Route element={<Login />} path="/" />
    <Route element={<SignIn2 />} path="/userActivation" />
    <Route element={<SignIn1 />} path="/forgotPassword" />
    <Route element={<BookRide />} path="/testRide" />
    <Route element={<ShareLocation />} path="/publictrack/Tracking/" />
    <Route element={<CheckAuthValidation />} path="/sign-verify" />
    <Route path="*" element={<PageNotFound />} />
      {routes.map((parent, index) => getRoutes(parent, index))}
    </Routes>)
};

export default RouteView;
