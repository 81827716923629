import { dashboardApi } from "../ApiSlice";

const TrackingSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
    //mapView/getGeoFenceForVehicle
    showGeoZone: builder.mutation({
        query: (body) => ({
          url: "mapView/getGeoFenceForVehicle",
          method: "POST",
          body: body,
        }),
      }),
    })
})

export const { useShowGeoZoneMutation } = TrackingSlice;