import { Box } from '@mui/material'
import React,{useState} from 'react'
import StyledTabs from '../shared/StyledTabs'
//import CreateRoleSettings from './CreateRoleSettings'
//import ListRoleSettings from './ListRoleSettings'
import {Breadcrumb} from '../shared/Breadcrumbs'
import CreateRoleSettings from './CreateRoleSettings'
import { StyledPageLayout } from '../../theme/StyledEle'

const Rolesettings = () => {
  //const [featureToEdit, setFeatureToEdit] = React.useState({})  
  const [tabValue, setTabValue] = useState(0)  
  /*
  const handleDevEdit  = (orgId,roleId) => {
    var jsonObj={};
    jsonObj.orgId=orgId;
    jsonObj.roleId=roleId;
    //setFeatureToEdit(jsonObj);
    setTabValue(0) 
  }
  */
  
  const handleTabChange = (event, newValue) => {
    //setFeatureToEdit({});
    setTabValue(newValue);    
  };

  /*
  const handleCancel = () => {
    setTabValue(1)
  };
  */
  
  const TABS = [   
    // {
    //   label: 'Role Setting',
    //   children: <CreateRoleSettings featureToEdit={featureToEdit} onCancel={handleCancel}/>
    // }, {
    //   label: 'Operation Role',
    //   children: <ListRoleSettings onEditFeature={handleDevEdit}  />
    // },
    {
      label:'Role Settings Update',
      children: <CreateRoleSettings />
    }
  ]

  
  return (
    <StyledPageLayout>
     <Breadcrumb mainDiv='Admin' subDiv='RoleSettings'></Breadcrumb>
      {/* <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} /> */}
      <CreateRoleSettings />
    </StyledPageLayout>
  )
}

export default Rolesettings