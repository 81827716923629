import * as yup from 'yup';

export const loginSchema = yup.object().shape({
    userName: yup.string().required('User Name is required'),
    password: yup
    .string()
    .required('Please Enter your password')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{4,})/,
        "Must Contain 4 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    })