import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import LogServices from "../../services/logService";
import { Breadcrumb } from "../shared/Breadcrumbs";
import CommonDropDown from "../shared/SendLogDropDown";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import LogsDropdown from "./LogsDropdown";
import { useSendLogListMutation } from "../../store/slice/LogsSlice/LogsSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const SendLog = () => {
  //user data form local storage
const UserData = JSON.parse(localStorage.getItem("userData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const text =
    "Alert notifications sent for users who have subscribed to receive captured alerts (via Profile -- My Alert Preferences) for each vehicle.";

  //Service call

  const [ sendList, { data: sendListdata, isLoading: getSendListLoading, isSuccess: getSendListSuccess,},] = useSendLogListMutation();

  const columns = useMemo(
    () => [
      {
        Header: "To",
        accessor: "emailTo",
      },
      {
        Header: "DateTime",
        accessor: "gpsDate",
      },
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Mode",
        accessor: "mode",
      },
    ],
    []
  );
  let commonPayload = {};
  let [payload, setPayload] = useState(null);

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  //Onload and Submit
  const getData = (data) => {
    commonPayload.vehicleId = data.vehicle;
    commonPayload.resellerId = resellerId;
    commonPayload.orgId = data.fleet;
    commonPayload.customerId = data.customer;
    commonPayload.dealerId = data.dealer;
    commonPayload.regionId = data.region;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.search = "";
    commonPayload.alertMode = data.alertMode;
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.sortId = "VEHICLE_ID";
    commonPayload.orderDir = "desc";
    setPayload(commonPayload);
    sendList(commonPayload);

    auditPayload.message = "Logs > Sent Log > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.alertModeName+" > "+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Sent Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      sendList(payload);
    } else if (payload.search.length === 0) {
      sendList(payload);
    }
  };
  //Page Change

  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      sendList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      sendList(payload);
    }
  };

  useEffect(() => {
    if (getSendListSuccess) {
      setResponseData([]);
      if (sendListdata.data !== null) {
        setResponseData(sendListdata.data.content);

        setTotalElements(sendListdata.data.totalElements);
        setTotalPages(sendListdata.data.totalPages);
      }
    }
    auditPayload.message = "Logs > Sent Log";
    auditPayload.action = "View";
    auditPayload.featureName = "Sent Log";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, [getSendListSuccess]);
  // const handleSubmit = async (
  //   seltdResellerId,
  //   seltdCustomerId,
  //   seltdFleetId,
  //   seltdVehicleId,
  //   seltdLastOrgId,
  //   seltdRegionId,
  //   seltdDealerId,
  //   fromDate,
  //   toDate,
  //   selCreatedDate
  // ) => {
  //   var request = {};
  //   request.vehicleId = 0;
  //   request.orgId = seltdResellerId;
  //   request.startDate = fromDate;
  //   request.endDate = toDate;
  //   request.search = "";
  //   request.alertMode = selCreatedDate;
  //   request.page = newPage;
  //   request.size = rowsPerPage;
  //   request.sortId = "VehicleId";
  //   setSelectedDropdownAry(request);
  //   const responseData = (await LogServices.SendLog(request)).data;
  //   setResponseData(responseData.data.content);
  //   setLoading(
  //     responseData.resultText === null ? "Success" : responseData.resultText
  //   );
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "SendLog/getSendLogCsv?vehicleId=" +
        payload.vehicleId +
        "&resellerId=" +
        payload.resellerId +
        "&orgId=" +
        payload.orgId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&regionId=" +
        payload.regionId +
        "&alertMode=" +
        payload.alertMode +
        "&sortId=" +
        payload.sortId +
        "&orderDir=" +
        payload.orderDir +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        " ";
      window.open(url);

      auditPayload.message = "Logs > Sent Log > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Sent Log";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;

      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {
          if (undefined !== blob) {
            var _url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            var link = document.body.appendChild(a);
            link.download = "VehicleList";
            link.href = _url;
            link.method = "post";
            link.click();
            window.URL.revokeObjectURL(_url);
            a.removeAttribute("download");
            a.removeAttribute("post");
            a.removeAttribute("href");
            document.body.removeChild(a);
          } else {
            //alert("unAuthorized error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };


  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="Sent Log" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Sent Log"} />
      <Box>
        {/* <CommonDropDown
          handleSmt={handleSubmit}
          //callbackLoadFn={callbackLoadFn}
        /> */}
        <LogsDropdown
          getData={getData}
          regionDrop={true}
          dealerDrop={true}
          customerDrop={true}
          fleetDrop={true}
          vehicleDrop={true}
          sentDrop={true}
          allowDays={1}
          allowedMonth={3}
        />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader title={<StyledTableTitle>Sent Log</StyledTableTitle>} />
          <CardContent sx={{ py: 0 }}>
            <DataTable
                isLoading={getSendListLoading}
                columns={columns}
                includeSearchBox={true}
                includePagination={true}
                data={responseData}
                styles={{ maxWidth: "100vw" }}
                numberOfStickyColumns={1}
                page={newPage}
                rowsPerPage={rowsPerPage}
                callbackChangePage={pageChange}
                totalElements={totalElements}
                totalPages={totalPages}
                handleChange={handleSearch}
                onChangeXcl={onChangeXcl}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default SendLog;
