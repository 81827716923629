import { dashboardApi } from "../ApiSlice";

const ProfileSlice = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    saveAlertPrefernce: builder.mutation({
      query: (body) => ({
        url: "/notificationSettings/saveNotifications",
        method: "POST",
        body: body,
      }),
    }),
    getAlertPrefrence: builder.mutation({
      query: (body) => ({
        url: "/notificationSettings/getNotificationDetails",
        method: "POST",
        body: body,
      }),
    }),
    saveVerficationCode: builder.mutation({
      query: (body) => ({
        url: "/user/saveVerficationCode",
        method: "POST",
        body: body,
      }),
    }),
    saveOtpCode: builder.mutation({
      query: (body) => ({
        url: "/user/phoneOtp",
        method: "POST",
        body: body,
      }),
    }),
    checkCodeExists: builder.mutation({
      query: (body) => ({
        url: "/user/checkCodeExists",
        method: "POST",
        body: body,
      }),
    }),
    checkOtpExists: builder.mutation({
      query: (body) => ({
        url: "/user/checkOtpExists",
        method: "POST",
        body: body,
      }),
    }),
    getUserImage: builder.mutation({
      query: (body) => ({
        url: "/user/getUserImage",
        method: "POST",
        body: body,
      }),
    }),
    updateUserImage: builder.mutation({
      query: (body) => ({
        url: "/user/updateUserImage",
        method: "POST",
        body: body,
      }),
    }),
    updateUserCustSett: builder.mutation({
      query: (body) => ({
        url: "/user/updateUserCustomerSettings",
        method: "POST",
        body: body,
      }),
    }),
    updateOrgCustSett: builder.mutation({
      query: (body) => ({
        url: "/user/updateCustomerSettings",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useSaveAlertPrefernceMutation,
  useGetAlertPrefrenceMutation,
  useSaveVerficationCodeMutation,
  useSaveOtpCodeMutation,
  useCheckCodeExistsMutation,
  useCheckOtpExistsMutation,
  useGetUserImageMutation,
  useUpdateUserImageMutation,
  useUpdateUserCustSettMutation,useUpdateOrgCustSettMutation
} = ProfileSlice;
