import React, { useEffect, useMemo, useState } from "react";
import { ExpandTable } from "../shared/data_table/ExpandTable";
import { Box, CardContent } from "@mui/material";
import { StyledCardShadow } from "../../theme/StyledEle";
import { useGetCapturedGeoZoneCategoryListMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";


const GeozoneListForSingleVehicle = ({  data,
  payload,
  isLoadingOut,}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;

  // const [responseData, setResponseData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Geozone",
        accessor: "tripDataName",
      },
      {
        Header: "Category",
        accessor: "tripDataCategory",
      },
      {
        Header: "Distance",
          columns: [
            {
              Header: "Start Odometer (km)",
              accessor: "startOdometer",
            },
            {
              Header: "End Odometer (km)",
              accessor: "endOdometer",
            },
            {
              Header: "Distance Traveled (km)",
              accessor: "distanceTraveled",
            },
          ]
       },
       {
        Header: "Time",
          columns: [
            {
              Header: "Start Time",
              accessor: "entryTime",
            },
            {
              Header: "End Time",
              accessor: "exitTime",
            },
            {
              Header: "Planned Duration (hh:mm)",
              accessor: "plannedDuration",
            },
            {
              Header: "Actual Duration (hh:mm)",
              accessor: "actualDuration",
            },
            {
              Header: "Time Saved/Lost (hh:mm)",
              accessor: "durationDifference",
            },
          ]
       },
       {
        Header: "Speed",
          columns: [
            {
              Header: "Max Speed (km/h)",
              accessor: "maxSpeed",
            },
            {
              Header: "Avg Speed (km/h)",
              accessor: "avgSpeed",
            },
          ]
       },
       {
        Header: "SOC",
          columns: [
            {
              Header: "Start SOC (%)",
              accessor: "startSOC",
            },
            {
              Header: "End SOC (%)",
              accessor: "endSOC",
            },
            {
              Header: "SOC Difference (%)",
              accessor: "socDifference",
            },
          ]
       },
       {
        Header: "Battery",
          columns: [
            {
              Header: "Energy Consumed (kWh)",
              accessor: "energyConsumed",
            },
            {
              Header: "Energy Efficiency (kWh/km)",
              accessor: "efficiency",
            },
          ]
       },
       {
        Header: "Temperature",
          columns: [
            {
              Header: "Max Temp (℃)",
              accessor: "maxTemp",
            },
            {
              Header: "Min Temp (℃)",
              accessor: "minTemp",
            },
            {
              Header: "Avg Temp (℃)",
              accessor: "avgTemp",
            },

          ]
       },
       {
        Header: "Load condition",
        accessor: "loadCondition",
      },
    ],
    []
  );

     //Export PDF & Excel
     const onChangeXcl = async () => {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "routeTrip/getCapturedGeozone.xls?startDate=" +
          payload.startDate +
          "&endDate=" +
          payload.endDate +
          "&orgId=" +
          payload.orgId +
          "&vehicleId=" +
          payload.vehicleId;
        window.open(url);
  
       //  auditPayload.message = "Reports > View Route > XLS";
       //  auditPayload.action = "View";
       //  auditPayload.featureName = "View Route";
       //  auditPayload.customerName = "All";
       //  auditPayload.fleetName = "All";
       //  auditPayload.result = "Success";
       //  auditPayload.access = "Portal";
       //  auditPayload.severity = "INFO";
       //  auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
      } catch (error) {}
    };

  // const [
  //   getCapturedGeoZoneCategory,
  //   {
  //     data: getCapturedGeoZoneCategoryData,
  //     isLoading: getCapturedGeoZoneCategoryLoading,
  //     isSuccess: getCapturedGeoZoneCategorySuccess,
  //   },
  // ] = useGetCapturedGeoZoneCategoryListMutation();
  // let commonPayload = {};
  // // let [payload, setPayload] = useState(null);
  // // Onload and Submit
  // const getData = (data) => {
  //   commonPayload.startDate = data.fromDate;
  //   commonPayload.endDate = data.toDate;
  //   commonPayload.vehicleId = data.vehicleId;
  //   getCapturedGeoZoneCategory(commonPayload);
  //   // setPayload(commonPayload);
  //         }

  // useEffect(() => {
  //   if (getCapturedGeoZoneCategorySuccess) {
  //     setResponseData([]);
  //     if (getCapturedGeoZoneCategoryData.data !== null) {
  //       setResponseData(getCapturedGeoZoneCategoryData.data);
  //     }
  //   }
  // }, [getCapturedGeoZoneCategorySuccess]);


  return (
    <>
      <Box sx={{my:2}}>
      <StyledCardShadow sx={{ my: 2 }}>
        <CardContent sx={{ py: 1 }}>
          <ExpandTable
            isLoading={isLoadingOut}
            includeSearchBox={false}
            columns={columns}
            data={data}
            styles={{ maxWidth: "100vw" }}
            includeExportButton={true}
            includePagination={false}
            onChangeXcl={onChangeXcl}
          />
        </CardContent>
      </StyledCardShadow>

      </Box>
    </>
  );
};

export default GeozoneListForSingleVehicle;
