import { Alert, Box, Grid, Icon, MenuItem, Paper, Stack, Typography } from '@mui/material'
import { ResponsiveChord } from '@nivo/chord'
import React, { useCallback, useEffect, useState } from 'react'
import { useGetChordDiagramMutation } from '../../store/slice/RouteTripSlice/RouteTripSlice'
import { StyledDropDown, StyledFilterFormControl } from '../../theme/StyledEle';
import { Backdrop } from '@mui/material';
import { FaRoute } from "react-icons/fa";


function ChordDiagram({ data }) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData?.resellerId

  const [getChordfn, { isLoading, isSuccess }] = useGetChordDiagramMutation();
  const [uState, setUState] = useState([]);
  const [name, setName] = useState([]);
  const [value, setValue] = useState('geozone');


  const [chordData, setChordData] = useState(null);
  const getChord = async () => {
    let payload = {
      "regionId": data.regionId,
      "dealerId": data.dealerId,
      "customerId": data.customerId,
      "orgId": data.fleetId,
      "vehicleId": data.vehicleId,
      "resellerId": resellerId,
      "startDate": data.fromDate,
      "endDate": data.toDate,
      "state": "all",
      "loadStatus": "all",
      "boundaries": value
    }
    const res = await getChordfn(payload);
    if (res.data !== null && res.data.content.length > 0) {
      FindUniqState(res.data.content);
    }
    //setChordData(res.data.data); 
  }


  useEffect(() => {
    getChord();
  }, [value,data])

  const handleDiagramChanage = (event, value) => {
    setValue(value.props.value);
  }

  var names = []
  const FindUniqState = (tripData) => {
    names = Array.from(new Set(tripData.flatMap(d => [d.state, d.entity])));
    setName(names)
    matrix(tripData);
  }

  const matrix = (tripData) => {
    const index = new Map(names.map((name, i) => [name, i]));
    const matrix = Array.from(index, () => new Array(names.length).fill(0));
    for (const { state, entity, value } of tripData) matrix[index.get(state)][index.get(entity)] += value;
    setUState(matrix);
    return matrix;
  }

  const RibbonTooltip = (e) => {
    return (
      <Paper sx={{ padding: '8px', minWidth: '250px', width: '100%', maxWidth: '250px' }}>
        <Stack>
          <Grid container spacing={1}>
            <Grid item lg={6}>
              <Box>
                <Typography variant='caption' color={e.ribbon.source.color}>Source</Typography>
                <Typography variant='body2'>{e.ribbon.source.label} : {e.ribbon.source.value}</Typography>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box>
                <Typography variant='caption' color={e.ribbon.target.color}>Target</Typography>
                <Typography variant='body2'>{e.ribbon.target.label} : {e.ribbon.target.value}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Typography variant='caption' color={'text.disabled'}>Total Trips</Typography>
            <Stack direction="row" spacing={1}>
              <Icon sx={{
                fontSize: "15px",
                height: "auto",
                margin: "auto",
                display: "flex",
              }}
                color='secondary'
              ><FaRoute /></Icon>
              <Typography variant='body2'>{e.ribbon.source.value + e.ribbon.target.value}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    )
  }



  const ResponciveChord = () => {
    return (
      <>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography>Chord Diagram</Typography>
          <Box>
            <StyledFilterFormControl>

              <StyledDropDown value={value} onChange={handleDiagramChanage} size='small'>
                <MenuItem value='state'>State</MenuItem>
                <MenuItem value='district'>District</MenuItem>
                <MenuItem value='city'>City</MenuItem>
                <MenuItem value='geozone'>Geo Zone</MenuItem>
              </StyledDropDown>
            </StyledFilterFormControl>
          </Box>
        </Stack>
        <Backdrop open={isLoading ? true : false} />

        <ResponsiveChord
          data={uState.length > 0 ? uState : []}
          keys={name}
          margin={{ top: 150, right: 60, bottom: 150, left: 60 }}
          ribbonTooltip={RibbonTooltip}
          // valueFormat=".2f"
          padAngle={0.04}
          innerRadiusRatio={0.96}
          innerRadiusOffset={0.02}
          inactiveArcOpacity={0.25}
          arcBorderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.6
              ]
            ]
          }}
          activeRibbonOpacity={0.75}
          inactiveRibbonOpacity={0.25}
          ribbonBorderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.6
              ]
            ]
          }}
          labelRotation={-90}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.8
              ]
            ]
          }}
          colors={{ scheme: 'red_blue' }}
          motionConfig="stiff"
          legends={[
            // {
            //     anchor: 'bottom',
            //     direction: 'row',
            //     justify: false,
            //     translateX: 0,
            //     translateY: 70,
            //     itemWidth: 80,
            //     itemHeight: 14,
            //     itemsSpacing: 0,
            //     itemTextColor: '#999',
            //     itemDirection: 'left-to-right',
            //     symbolSize: 12,
            //     symbolShape: 'circle',
            //     effects: [
            //         {
            //             on: 'hover',
            //             style: {
            //                 itemTextColor: '#000'
            //             }
            //         }
            //     ]
            // }
          ]}
        />

      </>
    )
  }

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      {uState.length > 0 ? <ResponciveChord /> :
        <Box sx={{ height: '100%', background: '#fff', padding: '25px', mt: 4 }}>
          <Alert severity="error">
            <Typography>No Data found.</Typography>
          </Alert>
        </Box>}


    </Box>
  )
}

export default ChordDiagram