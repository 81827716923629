import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material/";
import userService from "../../services/userService";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  SoftButton,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTextField,
} from "../../theme/StyledEle";
import StyledSnackBar from "../../Utils/StyledSnackBar";

export default function NameDialog(props) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?.id;
  let userName = userData?.userName;
  let orgId = userData?.orgId;
  let firstName = userData?.firstName;
  let lastName = userData?.lastName;

  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(async () => {
    reset({
      firstName: firstName,
      lastName: lastName,
    });
  }, []);

  const onSubmit = (e) => {
    updateFullName(e);

    props.setName(e.firstName + " " + e.lastName);
  };

  const updateFullName = async (e) => {
    e.id = userId;
    e.userName = userName;
    e.orgId = orgId;
    const responseData = await userService.updateFullName(e);

    var obj1 = {};
    obj1.dialogText = responseData.data.resultMessage;

    setDialogContent(obj1);
    setDataStatusConfirm(responseData.data.resultText);
    setOpen(true);
    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
        fetchDetails();
      }, 2000);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const fetchDetails = async () => {

    const responseData = (await userService.editUserById(userId));
    localStorage.setItem('userData',JSON.stringify({...userData,firstName:responseData?.data?.data?.firstName,lastName:responseData?.data?.data?.lastName}))
   
  };
  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Edit Your Name</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="grey" gutterBottom>
            You can change name here!
          </Typography>
          <Stack direction="column" spacing={3}>
            <StyledFormControl fullWidth>
              <StyledFormControlLabel required>
                First Name
              </StyledFormControlLabel>
              <StyledTextField
                error={errors.firstName && true}
                size="small"
                fullWidth
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First Name required",
                  },
                })}
                onChange={(e) => setValue("firstName", e.target.value)}
                helperText={errors.firstName && errors.firstName.message}
                inputProps={{ maxLength: 25 }}
              />
            </StyledFormControl>
            <StyledFormControl fullWidth>
              <StyledFormControlLabel required>
                Last Name
              </StyledFormControlLabel>
              <StyledTextField
                error={errors.lastName && true}
                size="small"
                fullWidth
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "Last Name required ",
                  },
                })}
                onChange={(e) => setValue("lastName", e.target.value)}
                helperText={errors.lastName && errors.lastName.message}
                inputProps={{ maxLength: 25 }}
              />
            </StyledFormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <SoftButton
            type="submit"
            sx={{ textTransform: "none" }}
            onClick={handleClose}
            variant="contained"
            color="success"
            className="success"
            size="small"
          >
            Submit
          </SoftButton>
        </DialogActions>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </Dialog>
  );
}
