import { Avatar, Box, Button, Dialog, DialogActions, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BsFillBuildingFill } from 'react-icons/bs';
import { HiBuildingStorefront } from 'react-icons/hi2';
import { TbGridDots } from 'react-icons/tb'
import { useGetMenusMutation, useGetUserdetailsMutation, useSwitchAccountMutation } from '../../../store/slice/NewAuthSlice/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuth, snackService } from '../../../store/slice/ControlsSlice';
import { DialogContent } from '@mui/material';
import { FaCircleCheck } from 'react-icons/fa6';
import { StyledHeaderMenuBtn } from '../../../theme/StyledEle';
import { PiUserSwitch } from "react-icons/pi";
import { useGetResellerListMutation } from '../../../store/slice/ApiSlice';

function MultipleAccount({resellers}) {
    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const authToken = JSON.parse(localStorage.getItem("jwToken"));
    const open = Boolean(anchorEl);
    const [popup, setPopup] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {

    })
    let userData = JSON.parse(localStorage.getItem('userData'));
    let theme = useTheme();
    console.log(localStorage.getItem('userData'));
    const [selectId, setselectId] = useState(userData.resellerId);
    const [selectReseller, setSelectResller] = useState(null);
    const [ switchAcc, {data:switchAccData, isLoading:switchAccLoading, isSuccess:switchAccSuccess} ] = useSwitchAccountMutation();
    const [ getNewMenu, {data:menuListData, isLoading:menuListLoading, isSuccess:menuListSuccess} ] = useGetMenusMutation();
    const [ getUserDetails, {data:getUserDetailsData, isLoading:getUserDetailsLoading, isSuccess:getUserDetailsSuccess} ] = useGetUserdetailsMutation();
    //Swirch Account
    const handleSwitchAccount = async () => {
      let payload = {
        //"userName": userData?.userName,
        "password": "",
        "resellerId": selectReseller.resellerId,
        "segment":selectReseller.segment
      }
    window.location.href = `${selectReseller.url}#/sign-verify#${authToken}?${selectReseller.segment}$${selectReseller.resellerId}`;
    closePopup()
    //window.location.href = `http://localhost:3001/#/check-authendicate#${authToken}?${selectId}`;
    //   const switchAccount = await switchAcc(payload);
    //   if(switchAccount.data !== undefined){
    //     setLocalStorage(switchAccount.data.accessToken, switchAccount.data.refreshToken);
    //     dispatch(getAuth([switchAccount.data.accessToken, switchAccount.data.refreshToken]));
    //     dispatch(snackService(["Your account has been changed", 0, true]));
    //     setTimeout(() => {
    //         getUserInfo();
    //     },0)
    //     //setStep(2);
    // }else{
    //     dispatch(snackService(["Not Able to Switch your Account", 1, true]));
    // }
    }

    //set LocalStorage
    const setLocalStorage = (authToken, refToken) => {
      window.localStorage.setItem('jwToken',JSON.stringify(authToken));
      window.localStorage.setItem('rshjwToken',refToken);
      window.localStorage.setItem('expjwToken',null);
  }


    //setLocalStorage
    const getUserInfo = async () => {
      const getUserDetail = await getUserDetails();
      localStorage.setItem("userData", JSON.stringify(getUserDetail.data.data));
      getMenus(getUserDetail.data.data);
    }


    //getMenu
    const getMenus = async (data) => {
      let payload = {
        "resellerId": data.resellerId,
        "orgId": data.orgId,
        "page": 0,
        "size": 100,
        "searchText": "",
        "id": 0,
        "roleId":  data.roleId,
        "orgType": data.userOrgType
    }
        const menusList = await getNewMenu(payload);
        window.localStorage.removeItem("menuList");
        window.localStorage.setItem("menuList", null);
        window.localStorage.setItem("menuList",JSON.stringify(menusList.data.menus));
        //window.localStorage.setItem("accountList",JSON.stringify(validateUserData.data));
        setPopup(false);
        navigate(menusList.data.menus[0]?.sub[0]?.route !== undefined ? `/${menusList.data.menus[0]?.sub[0]?.route}` : `/${menusList.data.menus[1]?.sub[1]?.route}`);
        //window.location.reload();
    }

    const closePopup = () => {
      setPopup(false);
      setTimeout(() => {
        setselectId(userData.resellerId);
      }, 1000)
    }

    const handleSelectReseller = (resellerId, type, url, segment) => {
      setSelectResller({resellerId:resellerId, type:type, url:url, segment:segment});
      setselectId(resellerId);
    }

  
  return (
    <>
    {
      resellers !== null && resellers.length > 1 && resellers[0].key!=1 && 
      <StyledHeaderMenuBtn dense onClick={() => setPopup(true)}>
          <ListItemIcon sx={{ minWidth: "40px", fontSize: "25px" }}>
            <PiUserSwitch />
            </ListItemIcon>
          <ListItemText primary={'Switch segment'} />
      </StyledHeaderMenuBtn>
      // <Tooltip title={'Accounts'}>
      //   <IconButton
      //     id="account-button"
      //     aria-controls={open ? 'account-menu' : undefined}
      //     aria-haspopup="true"
      //     aria-expanded={open ? 'true' : undefined}
      //     onClick={handleClick}
      //     color='secondary'
      //   >
      //     <HiBuildingStorefront />
      //   </IconButton>
      // </Tooltip>
    }
  
    {/* <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
        PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              //filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 2,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: '15%',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
      >
        {
            accountList !== null && accountList?.map((item, index) => 
            <ListItem dense sx={{p:0}} key={index}>
                <ListItemButton dense sx={{px:2, py:.8}} selected={parseInt(item.key) === userData?.resellerId ? true : false} onClick={() => handleSwitchAccount(item.key)}>
                    <ListItemIcon sx={{minWidth:'auto', mr:1}}>
                        <BsFillBuildingFill />
                    </ListItemIcon>
                    <ListItemText 
                        primary={<Typography variant='body2'>{item.title}</Typography>}
                    />
                </ListItemButton>
            </ListItem>
            )
        }
      </Menu> */}
      <Dialog maxWidth={'sm'} open={popup} fullWidth>
          <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h5">Manage Account</Typography>
                <Typography variant="body1">Select Segment</Typography>
              </Box>
              <List>
                    {
                  resellers !== null && resellers?.map((item, index) => {
                    return(
                      <ListItem 
                    dense 
                    sx={{border:'.5px solid #ddd',p:0, mb:1, '&:hover':{background:theme.palette.action.hover,}}}
                    key={index}
                    secondaryAction={
                      parseInt(selectId) === parseInt(item.key) &&
                      <Icon color="success" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                          <FaCircleCheck />
                      </Icon>
                    }
                    >
                        <ListItemButton dense sx={{px:2, py:.8}} disabled={parseInt(item.key) === userData?.resellerId ? true : false} selected={parseInt(item.key) === parseInt(selectId) ? true : false} onClick={() => handleSelectReseller(item.key, item.vehicleType, item.url, item.segment)}>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText 
                                primary={<Typography variant='body2'>{item.title}</Typography>}
                                secondary={<Typography variant='caption'>{ item.roleName}</Typography>}
                            />
                        </ListItemButton>
                    </ListItem>
                    )
                  }
                    
                  )
              }
              </List>
          </DialogContent>
          <DialogActions>
              <Stack direction={'row'} spacing={1}>
                  <Button variant='outlined' color='error' onClick={() => closePopup()}>Cancel</Button>
                  <Button variant='contained' color='success' onClick={() => handleSwitchAccount()}>Switch</Button>
              </Stack>
          </DialogActions>
      </Dialog>
    </>
  )
}

export default MultipleAccount