import { Box } from "@mui/material";
import React, { useState } from "react";
import StyledTabs from "../shared/StyledTabs";

import OrgSettings from "./orgSettings";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";

const OrgSetting = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS = [
    {
      label: "OrgSettings",
      children: <OrgSettings />,
    },
  ];

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="OrgSettings"></Breadcrumb>
      <StyledTabs tabs={TABS} handleChange={handleTabChange} value={tabValue} />
    </StyledPageLayout>
  );
};

export default OrgSetting;
