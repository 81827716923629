import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import StyledTooltip from "../shared/StyledTooltip";

import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import {
  useAuditLogMutation,
  useMonthlyDayMutation,
} from "../../store/slice/ApiSlice";

const text =
  "This Report gets the trip & battery details for Vehicle-wise or Day-wise. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day";

const MonthlySummaryDayWiseReport = ({ data, payload }) => {
  const [responseData, setResponseData] = useState([]);
  const theme = useTheme();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    // fetchmonthlySummaryDaywise();

    // var request = {};
    // request.message = "Reports > MonthlySummaryDayWise";
    // request.action = "View";
    // request.featureName = "MonthlySummaryDayWise";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName = globalUserName;

    // const responseData = await logServices.saveAuditLogDetails(request);
    auditPayload.message = "Reports > MonthlyDaywise";
    auditPayload.action = "View";
    auditPayload.featureName = "Monthly Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
        width:120,
      },
      {
        Header: "Top Line Metrics/Asset Utilization	",
        columns: [
          {
            Header: "Total operated vehicles",
            accessor: "vehiclesOperated",
          },
          {
            Header: "Distance traveled (km)",
            accessor: "tripDistance",
          },
          {
            Header: "Avg number of trips",
            accessor: "avgTripperday",
          },
          {
            Header: "Avg distance traveled",
            accessor: "averageDistance",

            Cell: ({ row, column, cell }) => {
              const averageDis = row.original.averageDistance;
    
              return row.original.averageDistance !== "" &&
                row.original.averageDistance !== null &&
                row.original.averageDistance !== "-"
                ? parseFloat(averageDis).toFixed(1)
                : "-";
            },
          },
          {
            Header: "Avg run-time (hh:mm)",
            accessor: "averageRuntime",
            Cell: ({ row }) => {
             
              return row.original.averageRuntime !== 0 &&
                row.original.averageRuntime !== null
                ? row.original.averageRuntime : "00:00" ;
                
            },
          },
          {
            Header: "Offline Vehicle",
            accessor: "offlineDays",
          },
          {
            Header: "Online Run Vehicles",
            accessor: "onlineRunDays",
          },
          {
            Header: "Online No Run Vehicles",
            accessor: "onlineNoRunDays",
          },
          // {
          //   Header: "Avg idle-time (hh:mm)",
          //   accessor: "avgIdletime",
          // },
        ],
      },
      {
        Header: " Battery Metrics",
        columns: [
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Energy Consumed (kWh)"
                    : "Energy Consumed (Wh)"
                }
              />
            ),
            accessor: "energyConsumed",
          },
          {
            Header: "Stop Energy Consumed (kWh)",
            accessor: "stopEnergyConsumedinpark",
          },
        {
          Header: "Move Energy Consumed (kWh)",
          accessor: "moveEnergyConsumed",
        },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Efficiency (km/kWh)"
                    : "Efficiency (km/Wh)"
                }
              />
            ),
            accessor: "efficiency",
          },
          {
            Header: (
              <StyledTooltip
                header={
                  resellerId === 110224
                    ? "Efficiency (kWh/km)"
                    : "Efficiency (Wh/km)"
                }
              />
            ),
            accessor: "efficiencyPerKm",
          },
          {
            Header: "Charging Instances",
            accessor: "manualChargingCount",
          },
          {
            Header: "Charging Duration(hh:mm)",
            accessor: "chargingMins",
          },
        ],
      },
      // {
      //   Header: " Alerts",
      //   columns: [
      //     {
      //       Header: "Harsh Driving Instances",
      //       accessor: "rashCount",
      //     },
      //   ],
      // },
    ],
    []
  );

  let initialState1 = {};
  var arr = [];
  if (resellerId !== 110224) {
    arr.push("efficiency");
    arr.push("stopEnergyConsumedinpark");
    arr.push("moveEnergyConsumed");
    initialState1.hiddenColumns = arr;
  }

  // var loadrequest = {};
  // const setloadvalues = () => {
  //   switch (globalOrgType) {
  //     case 8: // login as superadmin and reseller
  //     case 2:
  //       loadrequest.regionId = globalResellerId === 113802 ? 1 : -1;
  //       loadrequest.resellerId = globalOrgId;
  //       loadrequest.dealerId = globalResellerId === 113802 ? 113806 : -1;
  //       loadrequest.customerId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     // case 2: // login as reseller
  //     //     loadrequest.regionId= -1;
  //     //     loadrequest.reselId = globalOrgId;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.customerId = -1;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.orgId = -1; // fleet
  //     //     loadrequest.vehicleId = -1;
  //     //      break;

  //     case 5: // login as Dealer
  //       loadrequest.regionId = 0;
  //       loadrequest.dealerId = globalOrgId;
  //       loadrequest.customerId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 3: // login as customer
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalOrgId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //     case 1: // login as fleet
  //       loadrequest.regionId = 0;
  //       loadrequest.customerId = globalCustomerId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = globalOrgId; // fleet
  //       loadrequest.vehicleId = -1;

  //       break;
  //   }
  // };

  // const fetchmonthlySummaryDaywise = async () => {
  //   setloadvalues();

  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

  //   loadrequest.search = "";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;
  //   loadrequest.orderdir = "desc";
  //   loadrequest.page = newPage;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.columnName = "ID";
  //   loadrequest.sortBy = "desc";

  //   setAssignedval(loadrequest);
  //   const responseData = (
  //     await ReportServices.FleetSummaryReportDayWise(loadrequest)
  //   ).data;
  //   setResponseData(responseData.dataList);
  //   setLoading(responseData.resultText);
  //   // setTotalElements(5);
  //   // setTotalPages(0);
  //   // setTotalElements(responseData.data.totalElements);
  //   // setTotalPages(responseData.data.totalPages);
  // };

  // const handleSearch = async (searchText) => {
  //   setSearchval(searchText);
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (
  //     await ReportServices.FleetSummaryReportDayWise(loadrequest)
  //   ).data;
  //   setResponseData(responseData?.dataList);
  //   setLoading(responseData.resultText);
  //   // setTotalElements(5);
  //   // setTotalPages(0);
  //   // setTotalElements(responseData.data.totalElements);
  //   // setTotalPages(responseData.data.totalPages);
  // };

  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;
  //   // loadrequest.size = 1;
  //   // loadrequest.page = 25;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "FleetSummaryReportDayWise/FleetDayXLS.xls?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&customerId=" +
  //       loadrequest.customerId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "MonthlySummaryDayWise";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName = globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };
  // const handleSubmit = async (data) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   // loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   // loadrequest.dealerId= data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;

  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   if (data.from_date !== "" && data.to_date !== "") {
  //     loadrequest.startDate = data.from_date;
  //     loadrequest.endDate = data.to_date;
  //   }

  //   loadrequest.sortBy = "desc";
  //   loadrequest.search = searchval;
  //   setAssignedval(loadrequest);

  //   const responseData = (
  //     await ReportServices.FleetSummaryReportDayWise(loadrequest)
  //   ).data;

  //   if (responseData.dataList !== null) {
  //     setResponseData(responseData.dataList);
  //   } else {
  //     setResponseData([]);
  //   }

  //   setLoading(responseData.resultText);
  //   // setTotalElements(5);
  //   // setTotalPages(0);
  //   // setTotalElements(responseData.data.totalElements);
  //   // setTotalPages(responseData.data.totalPages);
  // };
  // const onChangePDF = async () => {
  //   loadrequest = assignedval;
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   // loadrequest.size = 1;
  //   // loadrequest.page = 25;
  //   // request.startDate = "2022-12-19 00:00:00";
  //   // request.endDate = "2022-12-19 23:59:59";
  //   // request.sortBy = "desc";
  //   // request.threshold = 5;
  //   // request.startOdometer ="String";
  //   // request.endOdometer = "String";

  //   setAssignedval(loadrequest);

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "FleetSummaryReportDayWise/FleetDayPDF.pdf?startDate=" +
  //       loadrequest.startDate +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&orgId=" +
  //       loadrequest.orgId +
  //       "&vehicleId=" +
  //       loadrequest.vehicleId +
  //       "&resellerId=" +
  //       loadrequest.resellerId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&customerId=" +
  //       loadrequest.customerId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > PDF";
  //     request.action = "View";
  //     request.featureName = "MonthlySummaryDayWise";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName = globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  const [
    getDayWise,
    { data: monthlyDayWiseData, isLoading, isSuccess: dayWise },
  ] = useMonthlyDayMutation();

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportDayWise/FleetDayXLS.xls?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&resellerId=" +
        payload.resellerId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > MonthlyDayWise > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportDayWise/FleetDayPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&resellerId=" +
        payload.resellerId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Monthly DayWise > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getDayWise(payload);
    } else if (payload.search.length === 0) {
      getDayWise(payload);
    }
  };

  useEffect(() => {
    console.log("data", data);
    if (data !== undefined) {
      setResponseData([]);
      if (data.dataList !== null) {
        setResponseData(data.dataList);
      } else {
        setResponseData([]);
      }
    }
  }, [data]);

  return (
    <Box>
      {/* <ReportNewDropDown
        onSubmit={handleSubmit}
        isShowVeh={true}
        displayMonth={true}
      /> */}
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardContent sx={{ py: 1 }}>
          <DataTable
            payload={payload}
            exportWithAll={resellerId === 110224 ? true : false}
            initialState={initialState1}
            columns={columns}
            data={responseData}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePDF}
            includeSearchBox={false}
            handleChange={handleSearch}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            includePagination={false}
            isLoading={isLoading}
            // page={newPage}
            // rowsPerPage={rowsPerPage}
            // callbackChangePage={callbackChangePage}
            // totalElements={totalElements}
            // totalPages={totalPages}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default MonthlySummaryDayWiseReport;
