
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { themeCreator } from "./themelist";
import { StylesProvider } from "@mui/styles";
import { useSelector } from "react-redux";
import { ThemeColorPalate } from "./CustomThemes";
import { useGetThemeListQuery } from "../store/slice/ApiSlice";
const ThemeProviderWrapper = (props) => {
  const[isTheme, setIsTheme] = useState(true);
  const resellerID = JSON.parse(localStorage.getItem("userData"))?.resellerId;
  const {data, isSuccess} = useGetThemeListQuery(resellerID,{ skip: isTheme });
  useEffect(() => {
    if (null !== localStorage.getItem("userData")) {
      setIsTheme(false);
    }
  },[resellerID]);
  
  const [themesNameList, setThemesNameList] = useState(null);

  const themeMode = useSelector((state) => state.controls.customizer.mode);
  const themes = useSelector((state) => state.controls.customizer.themeView);
  const treeView = useSelector((state) => state.controls.customizer.treeViewSettings);
  const themePallete = useSelector((state) => state.controls.customizer.themePallete);

  useEffect(() => {
   
    if(isSuccess){
      const themesNameList1 =  data.logoData.filter(theme => theme.name === themes);
      setThemesNameList(themesNameList1);
    }
  },[isSuccess])

  // theme check
  const themesNameList2 =  ThemeColorPalate.filter(theme => theme.name === themes);
  const fontFamily = useSelector((state) => state.controls.customizer.fontFamily);
  const theme = themeCreator({themeMode, themes, fontFamily, themesNameList, themesNameList2, treeView,themePallete});
  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
