import { dashboardApi } from "../ApiSlice";

const FotaSlice = dashboardApi.injectEndpoints({
    endpoints:builder => ({
      
        //firmwareList
        getFirmList: builder.mutation({
          query: (body) => ({
            url: "OTA/getFirmwareList",
            method: "POST",
            body:body
          }),
        }),
       //saveFota
        saveFota: builder.mutation({
          query: (body) => ({
            url: "OTA/uploadFOTAFile",
            method: "POST",
            body:body
          }),
        }),
    })
})


export const { useGetFirmListMutation, useSaveFotaMutation } = FotaSlice;